import dayjs from "dayjs"

//保存登录态到本地存储
export const rememberUser = (data: { token: string, user_id: number }, exdays: number) => {
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('user_id')

  localStorage.setItem('expiration_time', dayjs(new Date()).add(exdays, 'day').toISOString())
  localStorage.setItem('token', data.token)
  localStorage.setItem('user_id', String(data.user_id))
}

//保存到会话存储
export const setUser = (data: { token: string, user_id: number }) => {
  localStorage.removeItem("token")
  localStorage.removeItem("user_id")
  localStorage.removeItem("expiration_time")

  sessionStorage.setItem("token", data.token)
  sessionStorage.setItem("user_id", String(data.user_id))
}

//删除登录态
export const deleteUser = () => {
  localStorage.removeItem("token")
  localStorage.removeItem("user_id")
  localStorage.removeItem("expiration_time")
  sessionStorage.removeItem("token")
  sessionStorage.removeItem("user_id")
}

//从本地或会话获取登录态
export const getUserToCache = () => {
  let token = localStorage.getItem("token")
  let userId = localStorage.getItem("user_id") ? Number(localStorage.getItem("user_id")) : null
  let expirationTime = localStorage.getItem("expiration_time")
  if (token && userId && expirationTime && dayjs(expirationTime).diff(new Date(), "day") >= 0) {
    // console.log("本地登录态的时间", dayjs(expirationTime).diff(new Date(), "day"), dayjs(expirationTime).diff(new Date(), "day") >= 0);
    rememberUser({ token, user_id: userId }, 7)
    return { isAuthed: true, token, user_id: userId, loginLoading: false }
  }
  
  token = sessionStorage.getItem("token")
  userId = sessionStorage.getItem("user_id") ? Number(sessionStorage.getItem("user_id")) : null
  if (token && userId) return { isAuthed: true, token, user_id: userId, loginLoading: false }
  return { isAuthed: false, token: "", user_id: null, loginLoading: false }
}

