import { Drawer } from "antd";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import ResumeDetail from "../../components/resume/ResumeDetail";
import { useProject } from "../../models/project";
import { useResume } from "../../models/resume";
const MyResumeDetail: React.FC = () => {

    const navigate = useNavigate()
    const { resumeId, companyId } = useParams()

    const { updateResumeRecord,
    } = useProject(companyId!)
    const {
        getResumeProjectList, resumeProjectList,
        getResumeMarks, resumeMarks,
        createResumeRemark,
        addResumeToProject, resumeToProject,
        getResumeInProject, resumeInProjectList,
        getMyResumeDetail, myResumeDetail, putResumeRecordStatus,
        getResumeRecordStatus, resumeRecordStatus
    } = useResume(companyId!)
    useEffect(() => {
        getMyResumeDetail(resumeId!).then((res: any) => {
            // debugger
            getResumeRecordStatus(res.resume_record_id)
        })
        getResumeProjectList(resumeId!)
        getResumeMarks(resumeId!)
        getResumeInProject(resumeId!)
    }, [])
    const drawerClose = () => {
        navigate("../")
    }

    const search = (search_content: string) => {
        getResumeProjectList(resumeId!, search_content)
    }
    const tool = [
        {
            svg: (
                <svg id="组_2480" data-name="组 2480" xmlns="http://www.w3.org/2000/svg" width="22.915" height="22.915" viewBox="0 0 22.915 22.915">
                    <path id="路径_4570" data-name="路径 4570" d="M11.457,1.637a9.821,9.821,0,1,1-9.821,9.821,9.849,9.849,0,0,1,9.821-9.821m0-1.637A11.387,11.387,0,0,0,0,11.457,11.387,11.387,0,0,0,11.457,22.915,11.387,11.387,0,0,0,22.915,11.457,11.387,11.387,0,0,0,11.457,0Z" fill="#ccc" />
                    <path id="路径_4571" data-name="路径 4571" d="M476.247,219.429a.773.773,0,0,0-.818.818V231.7a.818.818,0,1,0,1.637,0V220.247A.773.773,0,0,0,476.247,219.429Z" transform="translate(-464.79 -214.518)" fill="#ccc" />
                    <path id="路径_4572" data-name="路径 4572" d="M231.7,475.428H220.247a.818.818,0,1,0,0,1.637H231.7a.818.818,0,0,0,0-1.637Z" transform="translate(-214.518 -464.79)" fill="#ccc" />
                </svg>
            ),
            text: "添加至项目",
            index: 1,
        },
        {
            svg: <svg xmlns="http://www.w3.org/2000/svg" width="20.15" height="22.915" viewBox="0 0 20.15 22.915">
                <path id="备注" d="M113.825,32a2.325,2.325,0,0,1,2.325,2.325V52.589a2.325,2.325,0,0,1-3.864,1.743l-5.7-5.031a.775.775,0,0,0-1.026,0l-5.7,5.031A2.325,2.325,0,0,1,96,52.589V34.325A2.325,2.325,0,0,1,98.325,32Zm0,1.55h-15.5a.775.775,0,0,0-.775.775V52.589a.775.775,0,0,0,1.288.581l5.7-5.031a2.325,2.325,0,0,1,3.078,0l5.7,5.031a.775.775,0,0,0,1.288-.581V34.325A.775.775,0,0,0,113.825,33.55Zm-6.2,7.75a.775.775,0,0,1,0,1.55H100.65a.775.775,0,1,1,0-1.55Zm3.875-3.875a.775.775,0,0,1,0,1.55H100.65a.775.775,0,0,1,0-1.55Z" transform="translate(-96 -32)" fill="#ccc" />
            </svg>,
            text: "备注",
            index: 2
        },
        {
            svg: <svg xmlns="http://www.w3.org/2000/svg" width="23.844" height="20.665" viewBox="0 0 23.844 20.665">
                <g id="组_2500" data-name="组 2500" transform="translate(-41.789 -94.041)">
                    <path id="路径_4601" data-name="路径 4601" d="M151.413,94.041H148.14a1.855,1.855,0,0,0-1.855,1.855v5.3a.8.8,0,0,0,.795.795h16.956a.8.8,0,0,0,.795-.795V98.83a1.855,1.855,0,0,0-1.855-1.855h-8.05a.265.265,0,0,1-.2-.091l-1.913-2.2a1.855,1.855,0,0,0-1.4-.639Zm-3.273,1.59h3.273a.265.265,0,0,1,.2.091l1.913,2.2a1.855,1.855,0,0,0,1.4.639h8.05l.048,0a.265.265,0,0,1,.217.26V100.4H147.875V95.9A.265.265,0,0,1,148.14,95.63Z" transform="translate(-101.848)" fill="#ccc" />
                    <path id="路径_4602" data-name="路径 4602" d="M64.838,344.816H42.584a.795.795,0,0,0-.788.9l1.526,11.793a1.855,1.855,0,0,0,1.839,1.617h17.1a1.855,1.855,0,0,0,1.839-1.616l1.526-11.794a.795.795,0,0,0-.788-.9Zm-21.351,1.59H63.934l-1.409,10.9a.265.265,0,0,1-.263.231H45.16l-.043,0a.265.265,0,0,1-.219-.227Z" transform="translate(0 -244.417)" fill="#ccc" />
                    <path id="路径_4603" data-name="路径 4603" d="M362.433,480.653a.795.795,0,0,1,.076,1.586l-.076,0h-6.358a.795.795,0,0,1-.076-1.586l.076,0Z" transform="translate(-305.543 -376.81)" fill="#ccc" />
                </g>
            </svg>,
            text: "所在项目",
            index: 6

        },
        {
            svg: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 64 64">
                <g id="简历库-未选中" transform="translate(0.036 0.476)">
                    <rect id="矩形_1914" data-name="矩形 1914" width="64" height="64" transform="translate(-0.036 -0.475)" fill="none" />
                    <g id="组_2494" data-name="组 2494" transform="translate(6.496 9.601)">
                        <path id="路径_4588" data-name="路径 4588" d="M111.412,123.161v6.322H67.161v-6.322h44.251m0-3.161H67.161A3.163,3.163,0,0,0,64,123.161v6.322a3.163,3.163,0,0,0,3.161,3.161h44.251a3.163,3.163,0,0,0,3.161-3.161v-6.322A3.163,3.163,0,0,0,111.412,120Z" transform="translate(-64 -120)" fill="#ccc" />
                        <path id="路径_4589" data-name="路径 4589" d="M793.58,205.58m-1.58,0a1.58,1.58,0,1,0,1.58-1.58A1.58,1.58,0,0,0,792,205.58Z" transform="translate(-750.91 -199.259)" fill="#ccc" />
                        <path id="路径_4590" data-name="路径 4590" d="M681.58,205.58m-1.58,0a1.58,1.58,0,1,0,1.58-1.58A1.58,1.58,0,0,0,680,205.58Z" transform="translate(-645.231 -199.259)" fill="#ccc" />
                        <path id="路径_4591" data-name="路径 4591" d="M111.412,403.161v6.322H67.161v-6.322h44.251m0-3.161H67.161A3.163,3.163,0,0,0,64,403.161v6.322a3.163,3.163,0,0,0,3.161,3.161h44.251a3.163,3.163,0,0,0,3.161-3.161v-6.322A3.163,3.163,0,0,0,111.412,400Z" transform="translate(-64 -384.196)" fill="#ccc" />
                        <path id="路径_4592" data-name="路径 4592" d="M793.58,485.58m-1.58,0a1.58,1.58,0,1,0,1.58-1.58A1.58,1.58,0,0,0,792,485.58Z" transform="translate(-750.91 -463.455)" fill="#ccc" />
                        <path id="路径_4593" data-name="路径 4593" d="M569.58,205.58m-1.58,0a1.58,1.58,0,1,0,1.58-1.58A1.58,1.58,0,0,0,568,205.58Z" transform="translate(-539.553 -199.259)" fill="#ccc" />
                        <path id="路径_4594" data-name="路径 4594" d="M569.58,765.58m-1.58,0a1.58,1.58,0,1,0,1.58-1.58A1.58,1.58,0,0,0,568,765.58Z" transform="translate(-539.553 -727.651)" fill="#ccc" />
                        <path id="路径_4595" data-name="路径 4595" d="M681.58,485.58m-1.58,0a1.58,1.58,0,1,0,1.58-1.58A1.58,1.58,0,0,0,680,485.58Z" transform="translate(-645.231 -463.455)" fill="#ccc" />
                        <path id="路径_4596" data-name="路径 4596" d="M111.412,683.161v6.322H67.161v-6.322h44.251m0-3.161H67.161A3.163,3.163,0,0,0,64,683.161v6.322a3.163,3.163,0,0,0,3.161,3.161h44.251a3.163,3.163,0,0,0,3.161-3.161v-6.322A3.163,3.163,0,0,0,111.412,680Z" transform="translate(-64 -648.392)" fill="#ccc" />
                        <path id="路径_4597" data-name="路径 4597" d="M793.58,765.58m-1.58,0a1.58,1.58,0,1,0,1.58-1.58A1.58,1.58,0,0,0,792,765.58Z" transform="translate(-750.91 -727.651)" fill="#ccc" />
                        <path id="路径_4598" data-name="路径 4598" d="M681.58,765.58m-1.58,0a1.58,1.58,0,1,0,1.58-1.58A1.58,1.58,0,0,0,680,765.58Z" transform="translate(-645.231 -727.651)" fill="#ccc" />
                    </g>
                </g>
            </svg>
            ,
            text: "沟通状态",
            index: 7
        },
        {
            svg: <svg xmlns="http://www.w3.org/2000/svg" width="23.845" height="23.846" viewBox="0 0 23.845 23.846">
                <g id="组_2477" data-name="组 2477" transform="translate(-1183.999 -325.592)">
                    <path id="路径_4558" data-name="路径 4558" d="M22.921,7.3a11.922,11.922,0,1,0,.934,4.626A11.922,11.922,0,0,0,22.921,7.3Zm-1.368,8.674a10.436,10.436,0,1,1,.817-4.048A10.436,10.436,0,0,1,21.553,15.971Z" transform="translate(1183.989 325.592)" fill="#fc0e0e" />
                    <path id="路径_4559" data-name="路径 4559" d="M328.2,259.82h-3.406l1.7-1.7a.743.743,0,1,0-1.05-1.05l-2.971,2.971-.025.027-.011.013-.013.015-.013.017-.009.011-.014.019-.007.01-.013.02-.007.01-.012.02-.007.012-.01.019-.007.014-.008.018-.007.016-.006.016-.007.019,0,.014-.007.021,0,.012-.006.023a.052.052,0,0,1,0,.012c0,.008,0,.016-.005.023a.069.069,0,0,1,0,.012l0,.023a.07.07,0,0,1,0,.013.137.137,0,0,0,0,.023.129.129,0,0,1,0,.018c0,.006,0,.012,0,.018v.073a.079.079,0,0,0,0,.018c0,.006,0,.012,0,.018s0,.015,0,.023a.05.05,0,0,0,0,.013s0,.016,0,.023a.067.067,0,0,0,0,.012l.005.023a.042.042,0,0,0,0,.012l.006.023,0,.012.007.021,0,.014.007.019.006.016.007.016.008.018.007.014.01.019.007.012.012.02.007.01.013.02.007.01.014.019.009.011.013.017.013.015.011.013.025.027,2.971,2.971a.743.743,0,1,0,1.05-1.051l-1.7-1.7H328.2a2.971,2.971,0,1,1,0,5.943h-3.714a.743.743,0,1,0,0,1.486H328.2a4.457,4.457,0,0,0,0-8.914Z" transform="translate(869.244 74.725)" fill="#fc0e0e" />
                </g>
            </svg>,
            text: "退回简历",
            index: 4,
        }
    ]
    const method = {
        back: {
            put: updateResumeRecord
        },
        marks: {
            post: createResumeRemark
        },
        project: {
            put: addResumeToProject,
            data: resumeToProject,
            select: search
        },
        work: {
            put: putResumeRecordStatus
        }
    }

    const toolModalData = {
        resumeMarks: resumeMarks?.results,
        resumeProject: resumeProjectList?.results,
        projectList: resumeInProjectList?.results,
        workStatus: resumeRecordStatus
    }
    return (
        <Drawer visible={true} width={763} headerStyle={{ display: "none" }} onClose={drawerClose}>
            <ResumeDetail resumeData={myResumeDetail as any} tool={tool} method={method} toolModalData={toolModalData} type={"project"} />
        </Drawer>
    )
}
export default MyResumeDetail