import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';


const Resume = (props: Partial<CustomIconComponentProps>) => {
    const svg = (attr: any) => (
        <svg xmlns="http://www.w3.org/2000/svg" {...attr} {...props} viewBox="0 0 880 880">
            <g id="简历库-未选中" transform="translate(-6447 978)">
                <rect id="矩形_1914" data-name="矩形 1914" transform="translate(6447 -978)" />
                <g id="组_2494" data-name="组 2494" transform="translate(6537 -845)">
                    <path d="M720.788,163.786v87.572h-613V163.786h613m0-43.786h-613A43.814,43.814,0,0,0,64,163.786v87.572a43.814,43.814,0,0,0,43.786,43.786h613a43.814,43.814,0,0,0,43.786-43.786V163.786A43.814,43.814,0,0,0,720.788,120Z" transform="translate(-64 -120)" />
                    <path d="M813.893,225.893m-21.893,0A21.893,21.893,0,1,0,813.893,204,21.893,21.893,0,0,0,792,225.893Z" transform="translate(-222.784 -138.321)" />
                    <path d="M701.893,225.893m-21.893,0A21.893,21.893,0,1,0,701.893,204,21.893,21.893,0,0,0,680,225.893Z" transform="translate(-198.356 -138.321)" />
                    <path d="M720.788,443.786v87.572h-613V443.786h613m0-43.786h-613A43.814,43.814,0,0,0,64,443.786v87.572a43.814,43.814,0,0,0,43.786,43.786h613a43.814,43.814,0,0,0,43.786-43.786V443.786A43.814,43.814,0,0,0,720.788,400Z" transform="translate(-64 -181.071)" />
                    <path d="M813.893,505.893m-21.893,0A21.893,21.893,0,1,0,813.893,484,21.893,21.893,0,0,0,792,505.893Z" transform="translate(-222.784 -199.392)" />
                    <path d="M589.893,225.893m-21.893,0A21.893,21.893,0,1,0,589.893,204,21.893,21.893,0,0,0,568,225.893Z" transform="translate(-173.927 -138.321)" />
                    <path d="M589.893,785.893m-21.893,0A21.893,21.893,0,1,0,589.893,764,21.893,21.893,0,0,0,568,785.893Z" transform="translate(-173.927 -260.463)" />
                    <path d="M701.893,505.893m-21.893,0A21.893,21.893,0,1,0,701.893,484,21.893,21.893,0,0,0,680,505.893Z" transform="translate(-198.356 -199.392)" />
                    <path d="M720.788,723.786v87.572h-613V723.786h613m0-43.786h-613A43.814,43.814,0,0,0,64,723.786v87.572a43.814,43.814,0,0,0,43.786,43.786h613a43.814,43.814,0,0,0,43.786-43.786V723.786A43.814,43.814,0,0,0,720.788,680Z" transform="translate(-64 -242.142)" />
                    <path d="M813.893,785.893m-21.893,0A21.893,21.893,0,1,0,813.893,764,21.893,21.893,0,0,0,792,785.893Z" transform="translate(-222.784 -260.463)" />
                    <path d="M701.893,785.893m-21.893,0A21.893,21.893,0,1,0,701.893,764,21.893,21.893,0,0,0,680,785.893Z" transform="translate(-198.356 -260.463)" />
                </g>
            </g>
        </svg>


    )
    return <Icon component={svg} {...props} />
}

const ResumeIcon: React.FC<any> = (props: any) => {
    let childProps = { ...props }
    Reflect.deleteProperty(childProps, 'onClick')
    return <span className={'icon'} onClick={props.onClick}><Resume {...childProps} /></span>
}

export default ResumeIcon
