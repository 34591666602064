import React from "react";
import "./index.less"
const EmptyItem: React.FC = () => {
    return (
        <div className="resume-empty-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="121.03" height="104.892" viewBox="0 0 121.03 104.892">
                <g id="页面-1" transform="translate(0.001 -3)">
                    <g id="空白页" transform="translate(-0.001 3)">
                        <g id="空白页_无数据" data-name="空白页/无数据" transform="translate(0 0)">
                            <g id="编组" transform="translate(0 0)">
                                <path id="Fill-1" d="M7.24,47.289a1.034,1.034,0,1,1-2.068,0V43.384a1.037,1.037,0,0,1,1.034-1.04h3.878a1.042,1.042,0,0,1,0,2.083H7.24ZM100.988,6.556h3.49a1.04,1.04,0,0,1,0,2.081h-3.49v3.515a1.035,1.035,0,1,1-2.07,0V8.637h-3.49a1.04,1.04,0,0,1,0-2.081h3.49V3.041a1.035,1.035,0,1,1,2.07,0ZM5.173,39.481a1.034,1.034,0,1,1,2.068,0v3.9a1.039,1.039,0,0,1-1.034,1.043H2.326a1.042,1.042,0,0,1,0-2.083H5.173ZM105.514,78.523a1.035,1.035,0,1,1-2.07,0V74.618a1.037,1.037,0,0,1,1.034-1.04h3.881a1.04,1.04,0,0,1,0,2.081h-2.844Zm-2.07-7.811a1.035,1.035,0,1,1,2.07,0v3.906a1.037,1.037,0,0,1-1.036,1.04H100.6a1.04,1.04,0,0,1,0-2.081h2.844Zm-57.8,36.18a1.04,1.04,0,0,1,0-2.081H85.083a1.04,1.04,0,0,1,0,2.081Zm-14.872,0a1.04,1.04,0,0,1,0-2.081h9.052a1.04,1.04,0,0,1,0,2.081Zm77.585-9.76a1.042,1.042,0,0,1,0-2.083h11.637a1.042,1.042,0,0,1,0,2.083Zm-11.639,0a1.042,1.042,0,0,1,0-2.083h3.235a1.042,1.042,0,0,1,0,2.083Zm-95.684,0a1.042,1.042,0,0,1,0-2.083H91.548a1.042,1.042,0,0,1,0,2.083Z" transform="translate(0.001 -2)" fill="#c3cbd6" />
                            </g>
                            <path id="Fill-3" d="M30.7,20.154a.646.646,0,0,1-.915-.912l1.371-1.371a.648.648,0,0,1,.915,0l1.371,1.371a.646.646,0,1,1-.915.912l-.912-.912ZM32.528,16.5a.647.647,0,1,1,.915.915l-1.371,1.371a.648.648,0,0,1-.915,0l-1.371-1.371A.647.647,0,0,1,30.7,16.5l.915.915ZM19.294,87.792a.647.647,0,0,1-.915-.915l1.371-1.368a.643.643,0,0,1,.915,0l1.371,1.368a.647.647,0,0,1-.915.915l-.915-.915Zm1.827-3.654a.646.646,0,0,1,.915.912l-1.371,1.371a.648.648,0,0,1-.915,0L18.38,85.051a.646.646,0,1,1,.915-.912l.912.912Z" transform="translate(-2.909 -5.129)" fill="#c3cbd6" />
                            <path id="Fill-5" d="M89.78,56.413V88.719A2.583,2.583,0,0,1,87.2,91.3H48.431a2.584,2.584,0,0,1-2.585-2.583V42.2a2.584,2.584,0,0,1,2.585-2.585H74.273" transform="translate(-7.297 -8.426)" fill="#fff" />
                            <path id="Fill-7" d="M49.981,87.169H85.645V58.03L72.465,43.751H49.981ZM87.2,91.3H48.431a2.587,2.587,0,0,1-2.585-2.585V42.2a2.587,2.587,0,0,1,2.585-2.583H74.276l15.5,16.8V88.719A2.587,2.587,0,0,1,87.2,91.3Z" transform="translate(-7.297 -8.426)" fill="#c3cbd6" />
                            <path id="Fill-9" d="M81.759,44.9v8.925a.52.52,0,0,0,.519.519h8.2ZM95.2,56.414H82.278a2.587,2.587,0,0,1-2.585-2.585V39.614Z" transform="translate(-12.705 -8.6)" fill="#c3cbd6" />
                            <path id="Fill-11" d="M68.775,70.437a1.294,1.294,0,0,1-1.293-1.285l-.035-6.471v-.01a1.291,1.291,0,0,1,1.283-1.29h.008a1.292,1.292,0,0,1,1.293,1.285l.038,6.471v.01a1.29,1.29,0,0,1-1.285,1.29Zm7.585,2.321a1.293,1.293,0,0,1-1-.474,1.267,1.267,0,0,1-.29-.816,1.285,1.285,0,0,1,.474-1l5.047-4.135a1.293,1.293,0,0,1,1.638,2l-5.05,4.135a1.3,1.3,0,0,1-.816.29Zm-15.232.227a1.291,1.291,0,0,1-.8-.272l-5.024-3.926a1.3,1.3,0,0,1-.5-1.02A1.294,1.294,0,0,1,56.9,66.748l5.024,3.926a1.292,1.292,0,0,1-.8,2.311Z" transform="translate(-8.733 -11.952)" fill="#c3cad7" />
                        </g>
                    </g>
                </g>
            </svg>
            <div className="resume-empty-item-tips">
                简历未填写当前信息
            </div>
        </div>
    )
}
export default EmptyItem