import { BrowserRouter, Routes as Switch } from "react-router-dom";
import { UserContext, useUser } from './global/models/user';
import { ConfigProvider } from "antd";
import 'dayjs/locale/zh-cn'
import zh_CN from "antd/lib/locale/zh_CN";
import dayjs from "dayjs"
import Routes from "./router";
import "./App.less";

// import { getThemeType } from "./global/utils/util";
// getThemeType();
dayjs.locale('zh-cn')
function App() {
  const store = useUser()
  return (
    <ConfigProvider locale={zh_CN}>
      <div className="App">
        <UserContext.Provider value={store}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </UserContext.Provider>
      </div>
    </ConfigProvider>
  );
}

export default App;
