import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';


const DownloadTemplate = (props: Partial<CustomIconComponentProps>) => {
    const svg = (attr: any) => (
        <svg {...attr} {...props} width="12.481" height="13.375" viewBox="0 0 12.481 13.375">
            <g id="下载模板" transform="translate(-1789.075 -380.313)">
                <path id="路径_4615" data-name="路径 4615" d="M12.481,6.79A2.833,2.833,0,0,1,9.644,9.619h-1.7V8.488h1.7a1.7,1.7,0,0,0,.2-3.383L9.307,5.04,9.35,4.5q.01-.126.01-.253a3.121,3.121,0,0,0-6.177-.628l-.069.337-.332.094A2.263,2.263,0,0,0,3.4,8.488H4.538V9.619H3.4A3.394,3.394,0,0,1,2.152,3.066,4.257,4.257,0,0,1,10.492,4.09,2.832,2.832,0,0,1,12.481,6.79Z" transform="translate(1789.075 380.313)" fill="#0e79fd" />
                <path id="路径_4616" data-name="路径 4616" d="M6.808,9.58V4.821H5.673V9.58l-1.55-1.1L3.463,9.4,6.24,11.375,9.017,9.4l-.659-.921Z" transform="translate(1789.075 382.313)" fill="#0e79fd" />
            </g>
        </svg>

    )
    return <Icon component={svg} {...props} />
}

const DownloadTemplateIcon: React.FC<any> = (props: any) => {
    let childProps = { ...props }
    Reflect.deleteProperty(childProps, 'onClick')
    return <span className={'icon'} onClick={props.onClick}><DownloadTemplate {...childProps} /></span>
}

export default DownloadTemplateIcon
