import { Form, Select, Space, Button } from 'antd';
import React, { useState, useEffect } from 'react';

interface BatchObtainType {
  resumeIds: any[];
  selectOptions: { job_id: number; job_title: string }[];
  onSearch: (searchContent: string) => void;
  batchResumes: (data: any) => void;
  drawClose: () => void;
  myResume?:boolean
}
interface project {
  label: string;
  value: number | string;
}
const BatchObtain: React.FC<BatchObtainType> = ({
  resumeIds,
  myResume,
  selectOptions,
  onSearch,
  batchResumes,
  drawClose,
}) => {
  const [projects, setProjects] = useState<{ value: number | string; label: string }[]>([]);
  const finish = (values: any) => {
    if (values.job_id === -1) {
      values.job_id = '';
    }
    batchResumes({ job_id: values.job_id, resume_ids: resumeIds });
  };
  useEffect(() => {
    if (selectOptions && selectOptions.length >= 0) {
      let tempArr: project[] = myResume? []:[{ label: '获取至个人简历库', value: -1 }];
      for (let index = 0; index < selectOptions.length; index++) {
        const element = selectOptions[index];
        tempArr.push({ value: element.job_id, label: element.job_title });
      }
      setProjects(tempArr);
    }
  }, [selectOptions]);

  return (
    <div className="resume-obtain">
      <div className="resume-obtain-checked">
        已经选择<span className="resume-obtain-checked-count">{resumeIds.length}</span>个简历
      </div>
      <Form layout="vertical" onFinish={finish}>
        <div style={{ marginRight: 36 }}>
          <Form.Item
            name={'job_id'}
            label="获取至"
            rules={[{ required: true, message: '请选择项目' }]}>
            <Select
              showSearch={true}
              placeholder="请选择"
              onSearch={onSearch}
              options={projects}
              filterOption={false}></Select>
          </Form.Item>
        </div>

        <div
          style={{ display: 'flex', marginTop: 80, justifyContent: 'flex-end', marginRight: 36 }}>
          <Form.Item>
            <Space>
              <Button onClick={drawClose}>取消</Button>
              <Button type="primary" htmlType="submit">
                确定分配
              </Button>
            </Space>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default BatchObtain;
