import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';


const Members = (props: Partial<CustomIconComponentProps>) => {
    const svg = (attr: any) => (
        <svg  {...attr} {...props} viewBox="0 0 880 880">
            <g id="成员-未选中" transform="translate(-3090 978)">
                <rect id="矩形_1911" data-name="矩形 1911" transform="translate(3090 -978)" />
                <g id="组_2491" data-name="组 2491" transform="translate(3157 -845)">
                    <path id="路径_4583" data-name="路径 4583" d="M385.114,527.264c-98.21,0-178.11-79.9-178.11-178.109s79.9-178.109,178.11-178.109,178.109,79.9,178.109,178.11S483.324,527.264,385.114,527.264Zm0-297.51a119.4,119.4,0,1,0,119.4,119.4A119.536,119.536,0,0,0,385.114,229.754Z" transform="translate(-98.978 -171.046)" />
                    <path id="路径_4584" data-name="路径 4584" d="M626.961,784.052A29.355,29.355,0,0,1,597.607,754.7c0-125.407-102.023-227.429-227.429-227.429S142.749,629.291,142.749,754.7a29.354,29.354,0,0,1-58.707,0c0-157.778,128.359-286.137,286.137-286.137S656.316,596.92,656.316,754.7A29.357,29.357,0,0,1,626.961,784.052ZM589.05,513.417a29.358,29.358,0,0,1-7.253-57.8c55.311-14.146,82.2-54.96,82.2-124.776,0-56.665-52.437-101.051-119.372-101.051a29.354,29.354,0,1,1,0-58.707c99.858,0,178.08,70.175,178.08,159.759,0,96.3-44.877,160.816-126.36,181.65A29.513,29.513,0,0,1,589.05,513.417Z" transform="translate(-84.042 -171.051)" />
                    <path id="路径_4585" data-name="路径 4585" d="M867.169,823.271a29.355,29.355,0,0,1-29.354-29.354c0-120.089-56.579-241.26-182.99-241.26a29.354,29.354,0,0,1,0-58.707c166.966,0,241.7,150.662,241.7,299.969A29.357,29.357,0,0,1,867.169,823.271Z" transform="translate(-149.809 -210.269)" />
                </g>
            </g>
        </svg>
    )
    return <Icon component={svg} {...props} />
}

const MembersIcon: React.FC<any> = (props: any) => {
    let childProps = { ...props }
    Reflect.deleteProperty(childProps, 'onClick')
    return <span className={'icon'} onClick={props.onClick}><Members {...childProps} /></span>
}

export default MembersIcon
