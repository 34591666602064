import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';


const Resume = (props: Partial<CustomIconComponentProps>) => {
    const svg = (attr: any) => (
        <svg xmlns="http://www.w3.org/2000/svg" {...attr} {...props} viewBox="0 0 880 880">
            <g id="组_2497" data-name="组 2497" transform="translate(-64.22 -64.22)">
                <path id="路径_4599" data-name="路径 4599" d="M647.664,64.22H189.24a83.349,83.349,0,0,0-83.349,83.349V522.644A41.671,41.671,0,0,0,64.22,564.315v83.349a83.349,83.349,0,0,0,83.349,83.349h500.1a83.349,83.349,0,0,0,83.349-83.349v-500.1A83.356,83.356,0,0,0,647.679,64.22ZM189.24,689.343H147.562a41.671,41.671,0,0,1-41.671-41.671V564.315H564.233v62.514c-1.088,24.173,3.084,45.507,12.254,62.514H189.24Zm500.1-41.671a41.671,41.671,0,0,1-41.671,41.671h-.082Q603.2,686.281,605.9,626.829V564.315a41.671,41.671,0,0,0-41.671-41.671H147.562V147.569A41.671,41.671,0,0,1,189.237,105.9H647.664a41.671,41.671,0,0,1,41.671,41.671Z"  />
                <path id="路径_4600" data-name="路径 4600" d="M655.931,288.01a20.835,20.835,0,0,1,0,41.671H364.2a20.835,20.835,0,1,1,0-41.671Zm0,0-375.075,1.244a20.835,20.835,0,1,1-20.835,20.835,20.835,20.835,0,0,1,20.835-20.835Zm-125.14,166.7a20.835,20.835,0,1,1,0,41.671H364.2a20.835,20.835,0,0,1,0-41.671Zm0,0-249.936,1.244a20.835,20.835,0,1,1-20.835,20.835,20.835,20.835,0,0,1,20.835-20.835Z" transform="translate(-49.944 -57.084)" />
            </g>
        </svg>

    )
    return <Icon component={svg} {...props} />
}

const MyuResumeIcon: React.FC<any> = (props: any) => {
    let childProps = { ...props }
    Reflect.deleteProperty(childProps, 'onClick')
    return <span className={'icon'} onClick={props.onClick}><Resume {...childProps} /></span>
}

export default MyuResumeIcon
