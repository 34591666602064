import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';


const Project = (props: Partial<CustomIconComponentProps>) => {
    const svg = (attr: any) => {
        return <svg  {...attr} {...props} viewBox="0 0 880 880">
            <g id="项目-未选中" transform="translate(-4209 978)">
                <rect id="矩形_1912" data-name="矩形 1912" transform="translate(4209 -978)" />
                <g id="组_2489" data-name="组 2489" transform="translate(4342 -845)">
                    <path id="路径_4579" data-name="路径 4579" d="M547.369,27.352H177.3a84.566,84.566,0,0,0-83.87,74.605,84.566,84.566,0,0,0-74.605,83.87v370.1a84.532,84.532,0,0,0,84.462,84.427H473.356a84.566,84.566,0,0,0,83.87-74.605,84.6,84.6,0,0,0,74.605-83.87V111.814A84.566,84.566,0,0,0,547.369,27.352ZM499.9,307.243V555.892a26.578,26.578,0,0,1-26.54,26.54H103.291a26.575,26.575,0,0,1-26.575-26.54V307.243Zm0-121.416v63.564H76.751V185.827a26.593,26.593,0,0,1,26.575-26.575H473.391A26.578,26.578,0,0,1,499.9,185.827Zm-26.54-84.462H152.888A26.68,26.68,0,0,1,177.3,85.239H547.369a26.585,26.585,0,0,1,26.54,26.575v370.1a26.565,26.565,0,0,1-16.091,24.381V185.827a84.566,84.566,0,0,0-84.462-84.462Z" transform="translate(-18.829 -27.352)" />
                    <path id="路径_4580" data-name="路径 4580" d="M500.069,264.14c-15.952,1.254-28.978,12.887-29.013,28.839v.07a28.943,28.943,0,1,0,29.013-28.909ZM389,264.105H166.924a28.978,28.978,0,0,0,0,57.887H389a28.943,28.943,0,1,0,0-57.887Z" transform="translate(-64.002 -116.079)" />
                </g>
            </g>
        </svg>


    }
    return <Icon component={svg} {...props} />
}

const ProjectIcon: React.FC<any> = (props: any) => {
    let childProps = { ...props }
    Reflect.deleteProperty(childProps, 'onClick')
    return <span className={'icon'} onClick={props.onClick}><Project {...childProps} /></span>
}

export default ProjectIcon
