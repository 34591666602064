export const city = [
    {
        "label": "北京市",
        "value": "北京市",
        "children": [
            {
                "label": "北京市",
                "value": "北京市",
                "children": [
                    {
                        "label": "东城区",
                        "value": "东城区",
                        "code": "110101"
                    },
                    {
                        "label": "西城区",
                        "value": "西城区",
                        "code": "110102"
                    },
                    {
                        "label": "朝阳区",
                        "value": "朝阳区",
                        "code": "110105"
                    },
                    {
                        "label": "丰台区",
                        "value": "丰台区",
                        "code": "110106"
                    },
                    {
                        "label": "石景山区",
                        "value": "石景山区",
                        "code": "110107"
                    },
                    {
                        "label": "海淀区",
                        "value": "海淀区",
                        "code": "110108"
                    },
                    {
                        "label": "门头沟区",
                        "value": "门头沟区",
                        "code": "110109"
                    },
                    {
                        "label": "房山区",
                        "value": "房山区",
                        "code": "110111"
                    },
                    {
                        "label": "通州区",
                        "value": "通州区",
                        "code": "110112"
                    },
                    {
                        "label": "顺义区",
                        "value": "顺义区",
                        "code": "110113"
                    },
                    {
                        "label": "昌平区",
                        "value": "昌平区",
                        "code": "110114"
                    },
                    {
                        "label": "大兴区",
                        "value": "大兴区",
                        "code": "110115"
                    },
                    {
                        "label": "怀柔区",
                        "value": "怀柔区",
                        "code": "110116"
                    },
                    {
                        "label": "平谷区",
                        "value": "平谷区",
                        "code": "110117"
                    },
                    {
                        "label": "密云县",
                        "value": "密云县",
                        "code": "110228"
                    },
                    {
                        "label": "延庆县",
                        "value": "延庆县",
                        "code": "110229"
                    }
                ],
                "code": "110000"
            }
        ],
        "code": "110000"
    },
    {
        "label": "天津市",
        "value": "天津市",
        "children": [
            {
                "label": "天津市",
                "value": "天津市",
                "children": [
                    {
                        "label": "和平区",
                        "value": "和平区",
                        "code": "120101"
                    },
                    {
                        "label": "河东区",
                        "value": "河东区",
                        "code": "120102"
                    },
                    {
                        "label": "河西区",
                        "value": "河西区",
                        "code": "120103"
                    },
                    {
                        "label": "南开区",
                        "value": "南开区",
                        "code": "120104"
                    },
                    {
                        "label": "河北区",
                        "value": "河北区",
                        "code": "120105"
                    },
                    {
                        "label": "红桥区",
                        "value": "红桥区",
                        "code": "120106"
                    },
                    {
                        "label": "东丽区",
                        "value": "东丽区",
                        "code": "120110"
                    },
                    {
                        "label": "西青区",
                        "value": "西青区",
                        "code": "120111"
                    },
                    {
                        "label": "津南区",
                        "value": "津南区",
                        "code": "120112"
                    },
                    {
                        "label": "北辰区",
                        "value": "北辰区",
                        "code": "120113"
                    },
                    {
                        "label": "武清区",
                        "value": "武清区",
                        "code": "120114"
                    },
                    {
                        "label": "宝坻区",
                        "value": "宝坻区",
                        "code": "120115"
                    },
                    {
                        "label": "滨海新区",
                        "value": "滨海新区",
                        "code": "120116"
                    },
                    {
                        "label": "宁河县",
                        "value": "宁河县",
                        "code": "120221"
                    },
                    {
                        "label": "静海县",
                        "value": "静海县",
                        "code": "120223"
                    },
                    {
                        "label": "蓟县",
                        "value": "蓟县",
                        "code": "120225"
                    }
                ],
                "code": "120000"
            }
        ],
        "code": "120000"
    },
    {
        "label": "河北省",
        "value": "河北省",
        "children": [
            {
                "label": "石家庄市",
                "value": "石家庄市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "130101"
                    },
                    {
                        "label": "长安区",
                        "value": "长安区",
                        "code": "130102"
                    },
                    {
                        "label": "桥西区",
                        "value": "桥西区",
                        "code": "130104"
                    },
                    {
                        "label": "新华区",
                        "value": "新华区",
                        "code": "130105"
                    },
                    {
                        "label": "井陉矿区",
                        "value": "井陉矿区",
                        "code": "130107"
                    },
                    {
                        "label": "裕华区",
                        "value": "裕华区",
                        "code": "130108"
                    },
                    {
                        "label": "藁城区",
                        "value": "藁城区",
                        "code": "130109"
                    },
                    {
                        "label": "鹿泉区",
                        "value": "鹿泉区",
                        "code": "130110"
                    },
                    {
                        "label": "栾城区",
                        "value": "栾城区",
                        "code": "130111"
                    },
                    {
                        "label": "井陉县",
                        "value": "井陉县",
                        "code": "130121"
                    },
                    {
                        "label": "正定县",
                        "value": "正定县",
                        "code": "130123"
                    },
                    {
                        "label": "行唐县",
                        "value": "行唐县",
                        "code": "130125"
                    },
                    {
                        "label": "灵寿县",
                        "value": "灵寿县",
                        "code": "130126"
                    },
                    {
                        "label": "高邑县",
                        "value": "高邑县",
                        "code": "130127"
                    },
                    {
                        "label": "深泽县",
                        "value": "深泽县",
                        "code": "130128"
                    },
                    {
                        "label": "赞皇县",
                        "value": "赞皇县",
                        "code": "130129"
                    },
                    {
                        "label": "无极县",
                        "value": "无极县",
                        "code": "130130"
                    },
                    {
                        "label": "平山县",
                        "value": "平山县",
                        "code": "130131"
                    },
                    {
                        "label": "元氏县",
                        "value": "元氏县",
                        "code": "130132"
                    },
                    {
                        "label": "赵县",
                        "value": "赵县",
                        "code": "130133"
                    },
                    {
                        "label": "辛集市",
                        "value": "辛集市",
                        "code": "130181"
                    },
                    {
                        "label": "晋州市",
                        "value": "晋州市",
                        "code": "130183"
                    },
                    {
                        "label": "新乐市",
                        "value": "新乐市",
                        "code": "130184"
                    }
                ],
                "code": "130100"
            },
            {
                "label": "唐山市",
                "value": "唐山市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "130201"
                    },
                    {
                        "label": "路南区",
                        "value": "路南区",
                        "code": "130202"
                    },
                    {
                        "label": "路北区",
                        "value": "路北区",
                        "code": "130203"
                    },
                    {
                        "label": "古冶区",
                        "value": "古冶区",
                        "code": "130204"
                    },
                    {
                        "label": "开平区",
                        "value": "开平区",
                        "code": "130205"
                    },
                    {
                        "label": "丰南区",
                        "value": "丰南区",
                        "code": "130207"
                    },
                    {
                        "label": "丰润区",
                        "value": "丰润区",
                        "code": "130208"
                    },
                    {
                        "label": "曹妃甸区",
                        "value": "曹妃甸区",
                        "code": "130209"
                    },
                    {
                        "label": "滦县",
                        "value": "滦县",
                        "code": "130223"
                    },
                    {
                        "label": "滦南县",
                        "value": "滦南县",
                        "code": "130224"
                    },
                    {
                        "label": "乐亭县",
                        "value": "乐亭县",
                        "code": "130225"
                    },
                    {
                        "label": "迁西县",
                        "value": "迁西县",
                        "code": "130227"
                    },
                    {
                        "label": "玉田县",
                        "value": "玉田县",
                        "code": "130229"
                    },
                    {
                        "label": "遵化市",
                        "value": "遵化市",
                        "code": "130281"
                    },
                    {
                        "label": "迁安市",
                        "value": "迁安市",
                        "code": "130283"
                    }
                ],
                "code": "130200"
            },
            {
                "label": "秦皇岛市",
                "value": "秦皇岛市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "130301"
                    },
                    {
                        "label": "海港区",
                        "value": "海港区",
                        "code": "130302"
                    },
                    {
                        "label": "山海关区",
                        "value": "山海关区",
                        "code": "130303"
                    },
                    {
                        "label": "北戴河区",
                        "value": "北戴河区",
                        "code": "130304"
                    },
                    {
                        "label": "青龙满族自治县",
                        "value": "青龙满族自治县",
                        "code": "130321"
                    },
                    {
                        "label": "昌黎县",
                        "value": "昌黎县",
                        "code": "130322"
                    },
                    {
                        "label": "抚宁县",
                        "value": "抚宁县",
                        "code": "130323"
                    },
                    {
                        "label": "卢龙县",
                        "value": "卢龙县",
                        "code": "130324"
                    }
                ],
                "code": "130300"
            },
            {
                "label": "邯郸市",
                "value": "邯郸市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "130401"
                    },
                    {
                        "label": "邯山区",
                        "value": "邯山区",
                        "code": "130402"
                    },
                    {
                        "label": "丛台区",
                        "value": "丛台区",
                        "code": "130403"
                    },
                    {
                        "label": "复兴区",
                        "value": "复兴区",
                        "code": "130404"
                    },
                    {
                        "label": "峰峰矿区",
                        "value": "峰峰矿区",
                        "code": "130406"
                    },
                    {
                        "label": "邯郸县",
                        "value": "邯郸县",
                        "code": "130421"
                    },
                    {
                        "label": "临漳县",
                        "value": "临漳县",
                        "code": "130423"
                    },
                    {
                        "label": "成安县",
                        "value": "成安县",
                        "code": "130424"
                    },
                    {
                        "label": "大名县",
                        "value": "大名县",
                        "code": "130425"
                    },
                    {
                        "label": "涉县",
                        "value": "涉县",
                        "code": "130426"
                    },
                    {
                        "label": "磁县",
                        "value": "磁县",
                        "code": "130427"
                    },
                    {
                        "label": "肥乡县",
                        "value": "肥乡县",
                        "code": "130428"
                    },
                    {
                        "label": "永年县",
                        "value": "永年县",
                        "code": "130429"
                    },
                    {
                        "label": "邱县",
                        "value": "邱县",
                        "code": "130430"
                    },
                    {
                        "label": "鸡泽县",
                        "value": "鸡泽县",
                        "code": "130431"
                    },
                    {
                        "label": "广平县",
                        "value": "广平县",
                        "code": "130432"
                    },
                    {
                        "label": "馆陶县",
                        "value": "馆陶县",
                        "code": "130433"
                    },
                    {
                        "label": "魏县",
                        "value": "魏县",
                        "code": "130434"
                    },
                    {
                        "label": "曲周县",
                        "value": "曲周县",
                        "code": "130435"
                    },
                    {
                        "label": "武安市",
                        "value": "武安市",
                        "code": "130481"
                    }
                ],
                "code": "130400"
            },
            {
                "label": "邢台市",
                "value": "邢台市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "130501"
                    },
                    {
                        "label": "桥东区",
                        "value": "桥东区",
                        "code": "130502"
                    },
                    {
                        "label": "桥西区",
                        "value": "桥西区",
                        "code": "130503"
                    },
                    {
                        "label": "邢台县",
                        "value": "邢台县",
                        "code": "130521"
                    },
                    {
                        "label": "临城县",
                        "value": "临城县",
                        "code": "130522"
                    },
                    {
                        "label": "内丘县",
                        "value": "内丘县",
                        "code": "130523"
                    },
                    {
                        "label": "柏乡县",
                        "value": "柏乡县",
                        "code": "130524"
                    },
                    {
                        "label": "隆尧县",
                        "value": "隆尧县",
                        "code": "130525"
                    },
                    {
                        "label": "任县",
                        "value": "任县",
                        "code": "130526"
                    },
                    {
                        "label": "南和县",
                        "value": "南和县",
                        "code": "130527"
                    },
                    {
                        "label": "宁晋县",
                        "value": "宁晋县",
                        "code": "130528"
                    },
                    {
                        "label": "巨鹿县",
                        "value": "巨鹿县",
                        "code": "130529"
                    },
                    {
                        "label": "新河县",
                        "value": "新河县",
                        "code": "130530"
                    },
                    {
                        "label": "广宗县",
                        "value": "广宗县",
                        "code": "130531"
                    },
                    {
                        "label": "平乡县",
                        "value": "平乡县",
                        "code": "130532"
                    },
                    {
                        "label": "威县",
                        "value": "威县",
                        "code": "130533"
                    },
                    {
                        "label": "清河县",
                        "value": "清河县",
                        "code": "130534"
                    },
                    {
                        "label": " 临西县",
                        "value": "临西县",
                        "code": "130535"
                    },
                    {
                        "label": "南宫市",
                        "value": "南宫市",
                        "code": "130581"
                    },
                    {
                        "label": "沙河市",
                        "value": "沙河市",
                        "code": "130582"
                    }
                ],
                "code": "130500"
            },
            {
                "label": "保定市",
                "value": "保定市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "130601"
                    },
                    {
                        "label": "新市区",
                        "value": "新市区",
                        "code": "130602"
                    },
                    {
                        "label": "北市区",
                        "value": "北市区",
                        "code": "130603"
                    },
                    {
                        "label": "南市区",
                        "value": "南市区",
                        "code": "130604"
                    },
                    {
                        "label": "满城县",
                        "value": "满城县",
                        "code": "130621"
                    },
                    {
                        "label": "清苑县",
                        "value": "清苑县",
                        "code": "130622"
                    },
                    {
                        "label": "涞水县",
                        "value": "涞水县",
                        "code": "130623"
                    },
                    {
                        "label": "阜平县",
                        "value": "阜平县",
                        "code": "130624"
                    },
                    {
                        "label": "徐水县",
                        "value": "徐水县",
                        "code": "130625"
                    },
                    {
                        "label": "定兴县",
                        "value": "定兴县",
                        "code": "130626"
                    },
                    {
                        "label": "唐县",
                        "value": "唐县",
                        "code": "130627"
                    },
                    {
                        "label": "高阳县",
                        "value": "高阳县",
                        "code": "130628"
                    },
                    {
                        "label": "容城县",
                        "value": "容城县",
                        "code": "130629"
                    },
                    {
                        "label": "涞源县",
                        "value": "涞源县",
                        "code": "130630"
                    },
                    {
                        "label": "望都县",
                        "value": "望都县",
                        "code": "130631"
                    },
                    {
                        "label": "安新县",
                        "value": "安新县",
                        "code": "130632"
                    },
                    {
                        "label": "易县",
                        "value": "易县",
                        "code": "130633"
                    },
                    {
                        "label": "曲阳县",
                        "value": "曲阳县",
                        "code": "130634"
                    },
                    {
                        "label": "蠡县",
                        "value": "蠡县",
                        "code": "130635"
                    },
                    {
                        "label": "顺平县",
                        "value": "顺平县",
                        "code": "130636"
                    },
                    {
                        "label": "博野县",
                        "value": "博野县",
                        "code": "130637"
                    },
                    {
                        "label": "雄县",
                        "value": "雄县",
                        "code": "130638"
                    },
                    {
                        "label": "涿州市",
                        "value": "涿州市",
                        "code": "130681"
                    },
                    {
                        "label": "定州市",
                        "value": "定州市",
                        "code": "130682"
                    },
                    {
                        "label": "安国市",
                        "value": "安国市",
                        "code": "130683"
                    },
                    {
                        "label": "高碑店市",
                        "value": "高碑店市",
                        "code": "130684"
                    }
                ],
                "code": "130600"
            },
            {
                "label": "张家口市",
                "value": "张家口市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "130701"
                    },
                    {
                        "label": "桥东区",
                        "value": "桥东区",
                        "code": "130702"
                    },
                    {
                        "label": "桥西区",
                        "value": "桥西区",
                        "code": "130703"
                    },
                    {
                        "label": "宣化区",
                        "value": "宣化区",
                        "code": "130705"
                    },
                    {
                        "label": "下花园区",
                        "value": "下花园区",
                        "code": "130706"
                    },
                    {
                        "label": "宣化县",
                        "value": "宣化县",
                        "code": "130721"
                    },
                    {
                        "label": "张北县",
                        "value": "张北县",
                        "code": "130722"
                    },
                    {
                        "label": "康保县",
                        "value": "康保县",
                        "code": "130723"
                    },
                    {
                        "label": "沽源县",
                        "value": "沽源县",
                        "code": "130724"
                    },
                    {
                        "label": "尚义县",
                        "value": "尚义县",
                        "code": "130725"
                    },
                    {
                        "label": "蔚县",
                        "value": "蔚县",
                        "code": "130726"
                    },
                    {
                        "label": "阳原县",
                        "value": "阳原县",
                        "code": "130727"
                    },
                    {
                        "label": "怀安县",
                        "value": "怀安县",
                        "code": "130728"
                    },
                    {
                        "label": "万全县",
                        "value": "万全县",
                        "code": "130729"
                    },
                    {
                        "label": "怀来县",
                        "value": "怀来县",
                        "code": "130730"
                    },
                    {
                        "label": "涿鹿县",
                        "value": "涿鹿县",
                        "code": "130731"
                    },
                    {
                        "label": "赤城县",
                        "value": "赤城县",
                        "code": "130732"
                    },
                    {
                        "label": "崇礼县",
                        "value": "崇礼县",
                        "code": "130733"
                    }
                ],
                "code": "130700"
            },
            {
                "label": "承德市",
                "value": "承德市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "130801"
                    },
                    {
                        "label": "双桥区",
                        "value": "双桥区",
                        "code": "130802"
                    },
                    {
                        "label": "双滦区",
                        "value": "双滦区",
                        "code": "130803"
                    },
                    {
                        "label": "鹰手营子矿区",
                        "value": "鹰手营子矿区",
                        "code": "130804"
                    },
                    {
                        "label": "承德县",
                        "value": "承德县",
                        "code": "130821"
                    },
                    {
                        "label": "兴隆县",
                        "value": "兴隆县",
                        "code": "130822"
                    },
                    {
                        "label": "平泉县",
                        "value": "平泉县",
                        "code": "130823"
                    },
                    {
                        "label": "滦平县",
                        "value": "滦平县",
                        "code": "130824"
                    },
                    {
                        "label": "隆化县",
                        "value": "隆化县",
                        "code": "130825"
                    },
                    {
                        "label": "丰宁满族自治县",
                        "value": "丰宁满族自治县",
                        "code": "130826"
                    },
                    {
                        "label": "宽城满族自治县",
                        "value": "宽城满族自治县",
                        "code": "130827"
                    },
                    {
                        "label": "围场满族蒙古族自治县",
                        "value": "围场满族蒙古族自治县",
                        "code": "130828"
                    }
                ],
                "code": "130800"
            },
            {
                "label": "沧州市",
                "value": "沧州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "130901"
                    },
                    {
                        "label": "新华区",
                        "value": "新华区",
                        "code": "130902"
                    },
                    {
                        "label": "运河区",
                        "value": "运河区",
                        "code": "130903"
                    },
                    {
                        "label": "沧县",
                        "value": "沧县",
                        "code": "130921"
                    },
                    {
                        "label": "青县",
                        "value": "青县",
                        "code": "130922"
                    },
                    {
                        "label": "东光县",
                        "value": "东光县",
                        "code": "130923"
                    },
                    {
                        "label": "海兴县",
                        "value": "海兴县",
                        "code": "130924"
                    },
                    {
                        "label": "盐山县",
                        "value": "盐山县",
                        "code": "130925"
                    },
                    {
                        "label": "肃宁县",
                        "value": "肃宁县",
                        "code": "130926"
                    },
                    {
                        "label": "南皮县",
                        "value": "南皮县",
                        "code": "130927"
                    },
                    {
                        "label": "吴桥县",
                        "value": "吴桥县",
                        "code": "130928"
                    },
                    {
                        "label": "献县",
                        "value": "献县",
                        "code": "130929"
                    },
                    {
                        "label": "孟村回族自治县",
                        "value": "孟村回族自治县",
                        "code": "130930"
                    },
                    {
                        "label": "泊头市",
                        "value": "泊头市",
                        "code": "130981"
                    },
                    {
                        "label": "任丘市",
                        "value": "任丘市",
                        "code": "130982"
                    },
                    {
                        "label": "黄骅市",
                        "value": "黄骅市",
                        "code": "130983"
                    },
                    {
                        "label": "河间市",
                        "value": "河间市",
                        "code": "130984"
                    }
                ],
                "code": "130900"
            },
            {
                "label": "廊坊市",
                "value": "廊坊市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "131001"
                    },
                    {
                        "label": "安次区",
                        "value": "安次区",
                        "code": "131002"
                    },
                    {
                        "label": "广阳区",
                        "value": "广阳区",
                        "code": "131003"
                    },
                    {
                        "label": "固安县",
                        "value": "固安县",
                        "code": "131022"
                    },
                    {
                        "label": "永清县",
                        "value": "永清县",
                        "code": "131023"
                    },
                    {
                        "label": "香河县",
                        "value": "香河县",
                        "code": "131024"
                    },
                    {
                        "label": "大城县",
                        "value": "大城县",
                        "code": "131025"
                    },
                    {
                        "label": "文安县",
                        "value": "文安县",
                        "code": "131026"
                    },
                    {
                        "label": "大厂回族自治县",
                        "value": "大厂回族自治县",
                        "code": "131028"
                    },
                    {
                        "label": "霸州市",
                        "value": "霸州市",
                        "code": "131081"
                    },
                    {
                        "label": "三河市",
                        "value": "三河市",
                        "code": "131082"
                    }
                ],
                "code": "131000"
            },
            {
                "label": "衡水市",
                "value": "衡水市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "131101"
                    },
                    {
                        "label": "桃城区",
                        "value": "桃城区",
                        "code": "131102"
                    },
                    {
                        "label": "枣强县",
                        "value": "枣强县",
                        "code": "131121"
                    },
                    {
                        "label": "武邑县",
                        "value": "武邑县",
                        "code": "131122"
                    },
                    {
                        "label": "武强县",
                        "value": "武强县",
                        "code": "131123"
                    },
                    {
                        "label": "饶阳县",
                        "value": "饶阳县",
                        "code": "131124"
                    },
                    {
                        "label": "安平县",
                        "value": "安平县",
                        "code": "131125"
                    },
                    {
                        "label": "故城县",
                        "value": "故城县",
                        "code": "131126"
                    },
                    {
                        "label": "景县",
                        "value": "景县",
                        "code": "131127"
                    },
                    {
                        "label": "阜城县",
                        "value": "阜城县",
                        "code": "131128"
                    },
                    {
                        "label": "冀州市",
                        "value": "冀州市",
                        "code": "131181"
                    },
                    {
                        "label": "深州市",
                        "value": "深州市",
                        "code": "131182"
                    }
                ],
                "code": "131100"
            }
        ],
        "code": "130000"
    },
    {
        "label": "山西省",
        "value": "山西省",
        "children": [
            {
                "label": "太原市",
                "value": "太原市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "140101"
                    },
                    {
                        "label": "小店区",
                        "value": "小店区",
                        "code": "140105"
                    },
                    {
                        "label": "迎泽区",
                        "value": "迎泽区",
                        "code": "140106"
                    },
                    {
                        "label": "杏花岭区",
                        "value": "杏花岭区",
                        "code": "140107"
                    },
                    {
                        "label": "尖草坪区",
                        "value": "尖草坪区",
                        "code": "140108"
                    },
                    {
                        "label": "万柏林区",
                        "value": "万柏林区",
                        "code": "140109"
                    },
                    {
                        "label": "晋源区",
                        "value": "晋源区",
                        "code": "140110"
                    },
                    {
                        "label": "清徐县",
                        "value": "清徐县",
                        "code": "140121"
                    },
                    {
                        "label": "阳曲县",
                        "value": "阳曲县",
                        "code": "140122"
                    },
                    {
                        "label": "娄烦县",
                        "value": "娄烦县",
                        "code": "140123"
                    },
                    {
                        "label": "古交市",
                        "value": "古交市",
                        "code": "140181"
                    }
                ],
                "code": "140100"
            },
            {
                "label": "大同市",
                "value": "大同市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "140201"
                    },
                    {
                        "label": "城区",
                        "value": "城区",
                        "code": "140202"
                    },
                    {
                        "label": "矿区",
                        "value": "矿区",
                        "code": "140203"
                    },
                    {
                        "label": "南郊区",
                        "value": "南郊区",
                        "code": "140211"
                    },
                    {
                        "label": "新荣区",
                        "value": "新荣区",
                        "code": "140212"
                    },
                    {
                        "label": "阳高县",
                        "value": "阳高县",
                        "code": "140221"
                    },
                    {
                        "label": "天镇县",
                        "value": "天镇县",
                        "code": "140222"
                    },
                    {
                        "label": "广灵县",
                        "value": "广灵县",
                        "code": "140223"
                    },
                    {
                        "label": "灵丘县",
                        "value": "灵丘县",
                        "code": "140224"
                    },
                    {
                        "label": "浑源县",
                        "value": "浑源县",
                        "code": "140225"
                    },
                    {
                        "label": "左云县",
                        "value": "左云县",
                        "code": "140226"
                    },
                    {
                        "label": "大同县",
                        "value": "大同县",
                        "code": "140227"
                    }
                ],
                "code": "140200"
            },
            {
                "label": "阳泉市",
                "value": "阳泉市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "140301"
                    },
                    {
                        "label": "城区",
                        "value": "城区",
                        "code": "140302"
                    },
                    {
                        "label": "矿区",
                        "value": "矿区",
                        "code": "140303"
                    },
                    {
                        "label": "郊区",
                        "value": "郊区",
                        "code": "140311"
                    },
                    {
                        "label": "平定县",
                        "value": "平定县",
                        "code": "140321"
                    },
                    {
                        "label": "盂县",
                        "value": "盂县",
                        "code": "140322"
                    }
                ],
                "code": "140300"
            },
            {
                "label": "长治市",
                "value": "长治市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "140401"
                    },
                    {
                        "label": "城区",
                        "value": "城区",
                        "code": "140402"
                    },
                    {
                        "label": "郊区",
                        "value": "郊区",
                        "code": "140411"
                    },
                    {
                        "label": "长治县",
                        "value": "长治县",
                        "code": "140421"
                    },
                    {
                        "label": "襄垣县",
                        "value": "襄垣县",
                        "code": "140423"
                    },
                    {
                        "label": "屯留县",
                        "value": "屯留县",
                        "code": "140424"
                    },
                    {
                        "label": "平顺县",
                        "value": "平顺县",
                        "code": "140425"
                    },
                    {
                        "label": "黎城县",
                        "value": "黎城县",
                        "code": "140426"
                    },
                    {
                        "label": "壶关县",
                        "value": "壶关县",
                        "code": "140427"
                    },
                    {
                        "label": "长子县",
                        "value": "长子县",
                        "code": "140428"
                    },
                    {
                        "label": "武乡县",
                        "value": "武乡县",
                        "code": "140429"
                    },
                    {
                        "label": "沁县",
                        "value": "沁县",
                        "code": "140430"
                    },
                    {
                        "label": "沁源县",
                        "value": "沁源县",
                        "code": "140431"
                    },
                    {
                        "label": "潞城市",
                        "value": "潞城市",
                        "code": "140481"
                    }
                ],
                "code": "140400"
            },
            {
                "label": "晋城市",
                "value": "晋城市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "140501"
                    },
                    {
                        "label": "城区",
                        "value": "城区",
                        "code": "140502"
                    },
                    {
                        "label": "沁水县",
                        "value": "沁水县",
                        "code": "140521"
                    },
                    {
                        "label": "阳城县",
                        "value": "阳城县",
                        "code": "140522"
                    },
                    {
                        "label": "陵川县",
                        "value": "陵川县",
                        "code": "140524"
                    },
                    {
                        "label": "泽州县",
                        "value": "泽州县",
                        "code": "140525"
                    },
                    {
                        "label": "高平市",
                        "value": "高平市",
                        "code": "140581"
                    }
                ],
                "code": "140500"
            },
            {
                "label": "朔州市",
                "value": "朔州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "140601"
                    },
                    {
                        "label": "朔城区",
                        "value": "朔城区",
                        "code": "140602"
                    },
                    {
                        "label": "平鲁区",
                        "value": "平鲁区",
                        "code": "140603"
                    },
                    {
                        "label": "山阴县",
                        "value": "山阴县",
                        "code": "140621"
                    },
                    {
                        "label": "应县",
                        "value": "应县",
                        "code": "140622"
                    },
                    {
                        "label": "右玉县",
                        "value": "右玉县",
                        "code": "140623"
                    },
                    {
                        "label": "怀仁县",
                        "value": "怀仁县",
                        "code": "140624"
                    }
                ],
                "code": "140600"
            },
            {
                "label": "晋中市",
                "value": "晋中市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "140701"
                    },
                    {
                        "label": "榆次区",
                        "value": "榆次区",
                        "code": "140702"
                    },
                    {
                        "label": "榆社县",
                        "value": "榆社县",
                        "code": "140721"
                    },
                    {
                        "label": "左权县",
                        "value": "左权县",
                        "code": "140722"
                    },
                    {
                        "label": "和顺县",
                        "value": "和顺县",
                        "code": "140723"
                    },
                    {
                        "label": "昔阳县",
                        "value": "昔阳县",
                        "code": "140724"
                    },
                    {
                        "label": "寿阳县",
                        "value": "寿阳县",
                        "code": "140725"
                    },
                    {
                        "label": "太谷县",
                        "value": "太谷县",
                        "code": "140726"
                    },
                    {
                        "label": "祁县",
                        "value": "祁县",
                        "code": "140727"
                    },
                    {
                        "label": "平遥县",
                        "value": "平遥县",
                        "code": "140728"
                    },
                    {
                        "label": "灵石县",
                        "value": "灵石县",
                        "code": "140729"
                    },
                    {
                        "label": "介休市",
                        "value": "介休市",
                        "code": "140781"
                    }
                ],
                "code": "140700"
            },
            {
                "label": "运城市",
                "value": "运城市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "140801"
                    },
                    {
                        "label": "盐湖区",
                        "value": "盐湖区",
                        "code": "140802"
                    },
                    {
                        "label": "临猗县",
                        "value": "临猗县",
                        "code": "140821"
                    },
                    {
                        "label": "万荣县",
                        "value": "万荣县",
                        "code": "140822"
                    },
                    {
                        "label": "闻喜县",
                        "value": "闻喜县",
                        "code": "140823"
                    },
                    {
                        "label": "稷山县",
                        "value": "稷山县",
                        "code": "140824"
                    },
                    {
                        "label": "新绛县",
                        "value": "新绛县",
                        "code": "140825"
                    },
                    {
                        "label": "绛县",
                        "value": "绛县",
                        "code": "140826"
                    },
                    {
                        "label": "垣曲县",
                        "value": "垣曲县",
                        "code": "140827"
                    },
                    {
                        "label": "夏县",
                        "value": "夏县",
                        "code": "140828"
                    },
                    {
                        "label": "平陆县",
                        "value": "平陆县",
                        "code": "140829"
                    },
                    {
                        "label": "芮城县",
                        "value": "芮城县",
                        "code": "140830"
                    },
                    {
                        "label": "永济市",
                        "value": "永济市",
                        "code": "140881"
                    },
                    {
                        "label": "河津市",
                        "value": "河津市",
                        "code": "140882"
                    }
                ],
                "code": "140800"
            },
            {
                "label": "忻州市",
                "value": "忻州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "140901"
                    },
                    {
                        "label": "忻府区",
                        "value": "忻府区",
                        "code": "140902"
                    },
                    {
                        "label": "定襄县",
                        "value": "定襄县",
                        "code": "140921"
                    },
                    {
                        "label": "五台县",
                        "value": "五台县",
                        "code": "140922"
                    },
                    {
                        "label": "代县",
                        "value": "代县",
                        "code": "140923"
                    },
                    {
                        "label": "繁峙县",
                        "value": "繁峙县",
                        "code": "140924"
                    },
                    {
                        "label": "宁武县",
                        "value": "宁武县",
                        "code": "140925"
                    },
                    {
                        "label": "静乐县",
                        "value": "静乐县",
                        "code": "140926"
                    },
                    {
                        "label": "神池县",
                        "value": "神池县",
                        "code": "140927"
                    },
                    {
                        "label": "五寨县",
                        "value": "五寨县",
                        "code": "140928"
                    },
                    {
                        "label": "岢岚县",
                        "value": "岢岚县",
                        "code": "140929"
                    },
                    {
                        "label": "河曲县",
                        "value": "河曲县",
                        "code": "140930"
                    },
                    {
                        "label": "保德县",
                        "value": "保德县",
                        "code": "140931"
                    },
                    {
                        "label": "偏关县",
                        "value": "偏关县",
                        "code": "140932"
                    },
                    {
                        "label": "原平市",
                        "value": "原平市",
                        "code": "140981"
                    }
                ],
                "code": "140900"
            },
            {
                "label": "临汾市",
                "value": "临汾市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "141001"
                    },
                    {
                        "label": "尧都区",
                        "value": "尧都区",
                        "code": "141002"
                    },
                    {
                        "label": "曲沃县",
                        "value": "曲沃县",
                        "code": "141021"
                    },
                    {
                        "label": "翼城县",
                        "value": "翼城县",
                        "code": "141022"
                    },
                    {
                        "label": "襄汾县",
                        "value": "襄汾县",
                        "code": "141023"
                    },
                    {
                        "label": "洪洞县",
                        "value": "洪洞县",
                        "code": "141024"
                    },
                    {
                        "label": "古县",
                        "value": "古县",
                        "code": "141025"
                    },
                    {
                        "label": "安泽县",
                        "value": "安泽县",
                        "code": "141026"
                    },
                    {
                        "label": "浮山县",
                        "value": "浮山县",
                        "code": "141027"
                    },
                    {
                        "label": "吉县",
                        "value": "吉县",
                        "code": "141028"
                    },
                    {
                        "label": "乡宁县",
                        "value": "乡宁县",
                        "code": "141029"
                    },
                    {
                        "label": "大宁县",
                        "value": "大宁县",
                        "code": "141030"
                    },
                    {
                        "label": "隰县",
                        "value": "隰县",
                        "code": "141031"
                    },
                    {
                        "label": "永和县",
                        "value": "永和县",
                        "code": "141032"
                    },
                    {
                        "label": "蒲县",
                        "value": "蒲县",
                        "code": "141033"
                    },
                    {
                        "label": "汾西县",
                        "value": "汾西县",
                        "code": "141034"
                    },
                    {
                        "label": "侯马市",
                        "value": "侯马市",
                        "code": "141081"
                    },
                    {
                        "label": "霍州市",
                        "value": "霍州市",
                        "code": "141082"
                    }
                ],
                "code": "141000"
            },
            {
                "label": "吕梁市",
                "value": "吕梁市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "141101"
                    },
                    {
                        "label": "离石区",
                        "value": "离石区",
                        "code": "141102"
                    },
                    {
                        "label": "文水县",
                        "value": "文水县",
                        "code": "141121"
                    },
                    {
                        "label": "交城县",
                        "value": "交城县",
                        "code": "141122"
                    },
                    {
                        "label": "兴县",
                        "value": "兴县",
                        "code": "141123"
                    },
                    {
                        "label": "临县",
                        "value": "临县",
                        "code": "141124"
                    },
                    {
                        "label": "柳林县",
                        "value": "柳林县",
                        "code": "141125"
                    },
                    {
                        "label": "石楼县",
                        "value": "石楼县",
                        "code": "141126"
                    },
                    {
                        "label": "岚县",
                        "value": "岚县",
                        "code": "141127"
                    },
                    {
                        "label": "方山县",
                        "value": "方山县",
                        "code": "141128"
                    },
                    {
                        "label": "中阳县",
                        "value": "中阳县",
                        "code": "141129"
                    },
                    {
                        "label": "交口县",
                        "value": "交口县",
                        "code": "141130"
                    },
                    {
                        "label": "孝义市",
                        "value": "孝义市",
                        "code": "141181"
                    },
                    {
                        "label": "汾阳市",
                        "value": "汾阳市",
                        "code": "141182"
                    }
                ],
                "code": "141100"
            }
        ],
        "code": "140000"
    },
    {
        "label": "内蒙古自治区",
        "value": "内蒙古自治区",
        "children": [
            {
                "label": "呼和浩特市",
                "value": "呼和浩特市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "150101"
                    },
                    {
                        "label": "新城区",
                        "value": "新城区",
                        "code": "150102"
                    },
                    {
                        "label": "回民区",
                        "value": "回民区",
                        "code": "150103"
                    },
                    {
                        "label": "玉泉区",
                        "value": "玉泉区",
                        "code": "150104"
                    },
                    {
                        "label": "赛罕区",
                        "value": "赛罕区",
                        "code": "150105"
                    },
                    {
                        "label": "土默特左旗",
                        "value": "土默特左旗",
                        "code": "150121"
                    },
                    {
                        "label": "托克托县",
                        "value": "托克托县",
                        "code": "150122"
                    },
                    {
                        "label": "和林格尔县",
                        "value": "和林格尔县",
                        "code": "150123"
                    },
                    {
                        "label": "清水河县",
                        "value": "清水河县",
                        "code": "150124"
                    },
                    {
                        "label": "武川县",
                        "value": "武川县",
                        "code": "150125"
                    }
                ],
                "code": "150100"
            },
            {
                "label": "包头市",
                "value": "包头市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "150201"
                    },
                    {
                        "label": "东河区",
                        "value": "东河区",
                        "code": "150202"
                    },
                    {
                        "label": "昆都仑区",
                        "value": "昆都仑区",
                        "code": "150203"
                    },
                    {
                        "label": "青山区",
                        "value": "青山区",
                        "code": "150204"
                    },
                    {
                        "label": "石拐区",
                        "value": "石拐区",
                        "code": "150205"
                    },
                    {
                        "label": "白云鄂博矿区",
                        "value": "白云鄂博矿区",
                        "code": "150206"
                    },
                    {
                        "label": "九原区",
                        "value": "九原区",
                        "code": "150207"
                    },
                    {
                        "label": "土默特右旗",
                        "value": "土默特右旗",
                        "code": "150221"
                    },
                    {
                        "label": "固阳县",
                        "value": "固阳县",
                        "code": "150222"
                    },
                    {
                        "label": "达尔罕茂明安联合旗",
                        "value": "达尔罕茂明安联合旗",
                        "code": "150223"
                    }
                ],
                "code": "150200"
            },
            {
                "label": "乌海市",
                "value": "乌海市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "150301"
                    },
                    {
                        "label": "海勃湾区",
                        "value": "海勃湾区",
                        "code": "150302"
                    },
                    {
                        "label": "海南区",
                        "value": "海南区",
                        "code": "150303"
                    },
                    {
                        "label": "乌达区",
                        "value": "乌达区",
                        "code": "150304"
                    }
                ],
                "code": "150300"
            },
            {
                "label": "赤峰市",
                "value": "赤峰市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "150401"
                    },
                    {
                        "label": "红山区",
                        "value": "红山区",
                        "code": "150402"
                    },
                    {
                        "label": "元宝山区",
                        "value": "元宝山区",
                        "code": "150403"
                    },
                    {
                        "label": "松山区",
                        "value": "松山区",
                        "code": "150404"
                    },
                    {
                        "label": "阿鲁科尔沁旗",
                        "value": "阿鲁科尔沁旗",
                        "code": "150421"
                    },
                    {
                        "label": "巴林左旗",
                        "value": "巴林左旗",
                        "code": "150422"
                    },
                    {
                        "label": "巴林右旗",
                        "value": "巴林右旗",
                        "code": "150423"
                    },
                    {
                        "label": "林西县",
                        "value": "林西县",
                        "code": "150424"
                    },
                    {
                        "label": "克什克腾旗",
                        "value": "克什克腾旗",
                        "code": "150425"
                    },
                    {
                        "label": "翁牛特旗",
                        "value": "翁牛特旗",
                        "code": "150426"
                    },
                    {
                        "label": "喀喇沁旗",
                        "value": "喀喇沁旗",
                        "code": "150428"
                    },
                    {
                        "label": "宁城县",
                        "value": "宁城县",
                        "code": "150429"
                    },
                    {
                        "label": "敖汉旗",
                        "value": "敖汉旗",
                        "code": "150430"
                    }
                ],
                "code": "150400"
            },
            {
                "label": "通辽市",
                "value": "通辽市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "150501"
                    },
                    {
                        "label": "科尔沁区",
                        "value": "科尔沁区",
                        "code": "150502"
                    },
                    {
                        "label": "科尔沁左翼中旗",
                        "value": "科尔沁左翼中旗",
                        "code": "150521"
                    },
                    {
                        "label": "科尔沁左翼后旗",
                        "value": "科尔沁左翼后旗",
                        "code": "150522"
                    },
                    {
                        "label": "开鲁县",
                        "value": "开鲁县",
                        "code": "150523"
                    },
                    {
                        "label": "库伦旗",
                        "value": "库伦旗",
                        "code": "150524"
                    },
                    {
                        "label": "奈曼旗",
                        "value": "奈曼旗",
                        "code": "150525"
                    },
                    {
                        "label": "扎鲁特旗",
                        "value": "扎鲁特旗",
                        "code": "150526"
                    },
                    {
                        "label": "霍林郭勒市",
                        "value": "霍林郭勒市",
                        "code": "150581"
                    }
                ],
                "code": "150500"
            },
            {
                "label": "鄂尔多斯市",
                "value": "鄂尔多斯市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "150601"
                    },
                    {
                        "label": "东胜区",
                        "value": "东胜区",
                        "code": "150602"
                    },
                    {
                        "label": "达拉特旗",
                        "value": "达拉特旗",
                        "code": "150621"
                    },
                    {
                        "label": "准格尔旗",
                        "value": "准格尔旗",
                        "code": "150622"
                    },
                    {
                        "label": "鄂托克前旗",
                        "value": "鄂托克前旗",
                        "code": "150623"
                    },
                    {
                        "label": "鄂托克旗",
                        "value": "鄂托克旗",
                        "code": "150624"
                    },
                    {
                        "label": "杭锦旗",
                        "value": "杭锦旗",
                        "code": "150625"
                    },
                    {
                        "label": "乌审旗",
                        "value": "乌审旗",
                        "code": "150626"
                    },
                    {
                        "label": "伊金霍洛旗",
                        "value": "伊金霍洛旗",
                        "code": "150627"
                    }
                ],
                "code": "150600"
            },
            {
                "label": "呼伦贝尔市",
                "value": "呼伦贝尔市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "150701"
                    },
                    {
                        "label": "海拉尔区",
                        "value": "海拉尔区",
                        "code": "150702"
                    },
                    {
                        "label": "扎赉诺尔区",
                        "value": "扎赉诺尔区",
                        "code": "150703"
                    },
                    {
                        "label": "阿荣旗",
                        "value": "阿荣旗",
                        "code": "150721"
                    },
                    {
                        "label": "莫力达瓦达斡尔族自治旗",
                        "value": "莫力达瓦达斡尔族自治旗",
                        "code": "150722"
                    },
                    {
                        "label": "鄂伦春自治旗",
                        "value": "鄂伦春自治旗",
                        "code": "150723"
                    },
                    {
                        "label": "鄂温克族自治旗",
                        "value": "鄂温克族自治旗",
                        "code": "150724"
                    },
                    {
                        "label": "陈巴尔虎旗",
                        "value": "陈巴尔虎旗",
                        "code": "150725"
                    },
                    {
                        "label": "新巴尔虎左旗",
                        "value": "新巴尔虎左旗",
                        "code": "150726"
                    },
                    {
                        "label": "新巴尔虎右旗",
                        "value": "新巴尔虎右旗",
                        "code": "150727"
                    },
                    {
                        "label": "满洲里市",
                        "value": "满洲里市",
                        "code": "150781"
                    },
                    {
                        "label": "牙克石市",
                        "value": "牙克石市",
                        "code": "150782"
                    },
                    {
                        "label": "扎兰屯市",
                        "value": "扎兰屯市",
                        "code": "150783"
                    },
                    {
                        "label": "额尔古纳市",
                        "value": "额尔古纳市",
                        "code": "150784"
                    },
                    {
                        "label": "根河市",
                        "value": "根河市",
                        "code": "150785"
                    }
                ],
                "code": "150700"
            },
            {
                "label": "巴彦淖尔市",
                "value": " 巴彦淖尔市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "150801"
                    },
                    {
                        "label": "临河区",
                        "value": "临河区",
                        "code": "150802"
                    },
                    {
                        "label": "五原县",
                        "value": "五原县",
                        "code": "150821"
                    },
                    {
                        "label": "磴口县",
                        "value": "磴口县",
                        "code": "150822"
                    },
                    {
                        "label": "乌拉特前旗",
                        "value": "乌拉特前旗",
                        "code": "150823"
                    },
                    {
                        "label": "乌拉特中旗",
                        "value": "乌拉特中旗",
                        "code": "150824"
                    },
                    {
                        "label": "乌拉特后旗",
                        "value": "乌拉特后旗",
                        "code": "150825"
                    },
                    {
                        "label": "杭锦后旗",
                        "value": "杭锦后旗",
                        "code": "150826"
                    }
                ],
                "code": "150800"
            },
            {
                "label": "乌兰察布市",
                "value": "乌兰察布市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "150901"
                    },
                    {
                        "label": "集宁区",
                        "value": "集宁区",
                        "code": "150902"
                    },
                    {
                        "label": "卓资县",
                        "value": "卓资县",
                        "code": "150921"
                    },
                    {
                        "label": "化德县",
                        "value": "化德县",
                        "code": "150922"
                    },
                    {
                        "label": "商都县",
                        "value": "商都县",
                        "code": "150923"
                    },
                    {
                        "label": "兴和县",
                        "value": "兴和县",
                        "code": "150924"
                    },
                    {
                        "label": "凉城县",
                        "value": "凉城县",
                        "code": "150925"
                    },
                    {
                        "label": "察哈尔右翼前旗",
                        "value": "察哈尔右翼前旗",
                        "code": "150926"
                    },
                    {
                        "label": "察哈尔右翼中旗",
                        "value": "察哈尔右翼中旗",
                        "code": "150927"
                    },
                    {
                        "label": "察哈尔右翼后旗",
                        "value": "察哈尔右翼后旗",
                        "code": "150928"
                    },
                    {
                        "label": "四子王旗",
                        "value": "四子王旗",
                        "code": "150929"
                    },
                    {
                        "label": "丰镇市",
                        "value": "丰镇市",
                        "code": "150981"
                    }
                ],
                "code": "150900"
            },
            {
                "label": "兴安盟",
                "value": "兴安盟",
                "children": [
                    {
                        "label": "乌兰浩特市",
                        "value": "乌兰浩特市",
                        "code": "152201"
                    },
                    {
                        "label": "阿尔山市",
                        "value": "阿尔山市",
                        "code": "152202"
                    },
                    {
                        "label": "科尔沁右翼前旗",
                        "value": "科尔沁右翼前旗",
                        "code": "152221"
                    },
                    {
                        "label": "科尔沁右翼中旗",
                        "value": "科尔沁右翼中旗",
                        "code": "152222"
                    },
                    {
                        "label": "扎赉特旗",
                        "value": "扎赉特旗",
                        "code": "152223"
                    },
                    {
                        "label": "突泉县",
                        "value": "突泉县",
                        "code": "152224"
                    }
                ],
                "code": "152200"
            },
            {
                "label": "锡林郭勒盟",
                "value": "锡林郭勒盟",
                "children": [
                    {
                        "label": "二连浩特市",
                        "value": "二连浩特市",
                        "code": "152501"
                    },
                    {
                        "label": "锡林浩特市",
                        "value": "锡林浩特市",
                        "code": "152502"
                    },
                    {
                        "label": "阿巴嘎旗",
                        "value": "阿巴嘎旗",
                        "code": "152522"
                    },
                    {
                        "label": "苏尼特左旗",
                        "value": "苏尼特左旗",
                        "code": "152523"
                    },
                    {
                        "label": "苏尼特右旗",
                        "value": "苏尼特右旗",
                        "code": "152524"
                    },
                    {
                        "label": "东乌珠穆沁旗",
                        "value": "东乌珠穆沁旗",
                        "code": "152525"
                    },
                    {
                        "label": "西乌珠穆沁旗",
                        "value": "西乌珠穆沁旗",
                        "code": "152526"
                    },
                    {
                        "label": "太仆寺旗",
                        "value": "太仆寺旗",
                        "code": "152527"
                    },
                    {
                        "label": "镶黄旗",
                        "value": "镶黄旗",
                        "code": "152528"
                    },
                    {
                        "label": "正镶白旗",
                        "value": "正镶白旗",
                        "code": "152529"
                    },
                    {
                        "label": "正蓝旗",
                        "value": "正蓝旗",
                        "code": "152530"
                    },
                    {
                        "label": "多伦县",
                        "value": "多伦县",
                        "code": "152531"
                    }
                ],
                "code": "152500"
            },
            {
                "label": "阿拉善盟",
                "value": "阿拉善盟",
                "children": [
                    {
                        "label": "阿拉善左旗",
                        "value": "阿拉善左旗",
                        "code": "152921"
                    },
                    {
                        "label": "阿拉善右旗",
                        "value": "阿拉善右旗",
                        "code": "152922"
                    },
                    {
                        "label": "额济纳旗",
                        "value": "额济纳旗",
                        "code": "152923"
                    }
                ],
                "code": "152900"
            }
        ],
        "code": "150000"
    },
    {
        "label": "辽宁省",
        "value": "辽宁省",
        "children": [
            {
                "label": "沈阳市",
                "value": "沈阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "210101"
                    },
                    {
                        "label": "和平区",
                        "value": "和平区",
                        "code": "210102"
                    },
                    {
                        "label": "沈河区",
                        "value": "沈河区",
                        "code": "210103"
                    },
                    {
                        "label": "大东区",
                        "value": "大东区",
                        "code": "210104"
                    },
                    {
                        "label": "皇姑区",
                        "value": "皇姑区",
                        "code": "210105"
                    },
                    {
                        "label": "铁西区",
                        "value": "铁西区",
                        "code": "210106"
                    },
                    {
                        "label": "苏家屯区",
                        "value": "苏家屯区",
                        "code": "210111"
                    },
                    {
                        "label": "浑南区",
                        "value": "浑南区",
                        "code": "210112"
                    },
                    {
                        "label": "沈北新区",
                        "value": "沈北新区",
                        "code": "210113"
                    },
                    {
                        "label": "于洪区",
                        "value": "于洪区",
                        "code": "210114"
                    },
                    {
                        "label": "辽中县",
                        "value": "辽中县",
                        "code": "210122"
                    },
                    {
                        "label": "康平县",
                        "value": "康平县",
                        "code": "210123"
                    },
                    {
                        "label": "法库县",
                        "value": "法库县",
                        "code": "210124"
                    },
                    {
                        "label": "新民市",
                        "value": "新民市",
                        "code": "210181"
                    }
                ],
                "code": "210100"
            },
            {
                "label": "大连市",
                "value": "大连市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "210201"
                    },
                    {
                        "label": "中山区",
                        "value": "中山区",
                        "code": "210202"
                    },
                    {
                        "label": "西岗区",
                        "value": "西岗区",
                        "code": "210203"
                    },
                    {
                        "label": "沙河口区",
                        "value": "沙河口区",
                        "code": "210204"
                    },
                    {
                        "label": "甘井子区",
                        "value": "甘井子区",
                        "code": "210211"
                    },
                    {
                        "label": "旅顺口区",
                        "value": "旅顺口区",
                        "code": "210212"
                    },
                    {
                        "label": "金州区",
                        "value": "金州区",
                        "code": "210213"
                    },
                    {
                        "label": "长海县",
                        "value": "长海县",
                        "code": "210224"
                    },
                    {
                        "label": "瓦房店市",
                        "value": "瓦房店市",
                        "code": "210281"
                    },
                    {
                        "label": "普兰店市",
                        "value": "普兰店市",
                        "code": "210282"
                    },
                    {
                        "label": "庄河市",
                        "value": "庄河市",
                        "code": "210283"
                    }
                ],
                "code": "210200"
            },
            {
                "label": "鞍山市",
                "value": "鞍山市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "210301"
                    },
                    {
                        "label": "铁东区",
                        "value": "铁东区",
                        "code": "210302"
                    },
                    {
                        "label": "铁西区",
                        "value": "铁西区",
                        "code": "210303"
                    },
                    {
                        "label": "立山区",
                        "value": "立山区",
                        "code": "210304"
                    },
                    {
                        "label": "千山区",
                        "value": "千山区",
                        "code": "210311"
                    },
                    {
                        "label": "台安县",
                        "value": "台安县",
                        "code": "210321"
                    },
                    {
                        "label": "岫岩满族自治县",
                        "value": "岫岩满族自治县",
                        "code": "210323"
                    },
                    {
                        "label": "海城市",
                        "value": "海城市",
                        "code": "210381"
                    }
                ],
                "code": "210300"
            },
            {
                "label": "抚顺市",
                "value": "抚顺市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "210401"
                    },
                    {
                        "label": "新抚区",
                        "value": "新抚区",
                        "code": "210402"
                    },
                    {
                        "label": "东洲区",
                        "value": "东洲区",
                        "code": "210403"
                    },
                    {
                        "label": "望花区",
                        "value": "望花区",
                        "code": "210404"
                    },
                    {
                        "label": "顺城区",
                        "value": "顺城区",
                        "code": "210411"
                    },
                    {
                        "label": "抚顺县",
                        "value": "抚顺县",
                        "code": "210421"
                    },
                    {
                        "label": "新宾满族自治县",
                        "value": "新宾满族自治县",
                        "code": "210422"
                    },
                    {
                        "label": "清原满族自治 县",
                        "value": "清原满族自治县",
                        "code": "210423"
                    }
                ],
                "code": "210400"
            },
            {
                "label": "本溪市",
                "value": "本溪市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "210501"
                    },
                    {
                        "label": "平山区",
                        "value": "平山区",
                        "code": "210502"
                    },
                    {
                        "label": "溪湖区",
                        "value": "溪湖区",
                        "code": "210503"
                    },
                    {
                        "label": "明山区",
                        "value": "明山区",
                        "code": "210504"
                    },
                    {
                        "label": "南芬区",
                        "value": "南芬区",
                        "code": "210505"
                    },
                    {
                        "label": "本溪满族自治县",
                        "value": "本溪满族自治县",
                        "code": "210521"
                    },
                    {
                        "label": "桓仁满族自治县",
                        "value": "桓仁满族自治县",
                        "code": "210522"
                    }
                ],
                "code": "210500"
            },
            {
                "label": "丹东市",
                "value": "丹东市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "210601"
                    },
                    {
                        "label": "元宝区",
                        "value": "元宝区",
                        "code": "210602"
                    },
                    {
                        "label": "振兴区",
                        "value": "振兴区",
                        "code": "210603"
                    },
                    {
                        "label": "振安区",
                        "value": "振安区",
                        "code": "210604"
                    },
                    {
                        "label": "宽甸满族自治县",
                        "value": "宽甸满族自治县",
                        "code": "210624"
                    },
                    {
                        "label": "东港市",
                        "value": "东港市",
                        "code": "210681"
                    },
                    {
                        "label": "凤城市",
                        "value": "凤城市",
                        "code": "210682"
                    }
                ],
                "code": "210600"
            },
            {
                "label": "锦州市",
                "value": "锦州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "210701"
                    },
                    {
                        "label": "古塔区",
                        "value": "古塔区",
                        "code": "210702"
                    },
                    {
                        "label": "凌河区",
                        "value": "凌河区",
                        "code": "210703"
                    },
                    {
                        "label": "太和区",
                        "value": "太和区",
                        "code": "210711"
                    },
                    {
                        "label": "黑山县",
                        "value": "黑山县",
                        "code": "210726"
                    },
                    {
                        "label": "义县",
                        "value": "义县",
                        "code": "210727"
                    },
                    {
                        "label": "凌海市",
                        "value": "凌海市",
                        "code": "210781"
                    },
                    {
                        "label": "北镇市",
                        "value": "北镇市",
                        "code": "210782"
                    }
                ],
                "code": "210700"
            },
            {
                "label": "营口市",
                "value": "营口市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "210801"
                    },
                    {
                        "label": "站前区",
                        "value": "站前区",
                        "code": "210802"
                    },
                    {
                        "label": "西市区",
                        "value": "西市区",
                        "code": "210803"
                    },
                    {
                        "label": "鲅鱼圈区",
                        "value": "鲅鱼圈区",
                        "code": "210804"
                    },
                    {
                        "label": "老边区",
                        "value": "老边区",
                        "code": "210811"
                    },
                    {
                        "label": "盖州市",
                        "value": "盖州市",
                        "code": "210881"
                    },
                    {
                        "label": "大石桥市",
                        "value": "大石桥市",
                        "code": "210882"
                    }
                ],
                "code": "210800"
            },
            {
                "label": "阜新市",
                "value": "阜新市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "210901"
                    },
                    {
                        "label": "海州区",
                        "value": "海州区",
                        "code": "210902"
                    },
                    {
                        "label": "新邱区",
                        "value": "新邱区",
                        "code": "210903"
                    },
                    {
                        "label": "太平区",
                        "value": "太平区",
                        "code": "210904"
                    },
                    {
                        "label": "清河门区",
                        "value": "清河门区",
                        "code": "210905"
                    },
                    {
                        "label": "细河区",
                        "value": "细河区",
                        "code": "210911"
                    },
                    {
                        "label": "阜新蒙古族自治县",
                        "value": "阜新蒙古族自治县",
                        "code": "210921"
                    },
                    {
                        "label": "彰武县",
                        "value": "彰武县",
                        "code": "210922"
                    }
                ],
                "code": "210900"
            },
            {
                "label": "辽阳市",
                "value": "辽阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "211001"
                    },
                    {
                        "label": "白塔区",
                        "value": "白塔区",
                        "code": "211002"
                    },
                    {
                        "label": "文圣区",
                        "value": "文圣区",
                        "code": "211003"
                    },
                    {
                        "label": "宏伟区",
                        "value": "宏伟区",
                        "code": "211004"
                    },
                    {
                        "label": "弓长岭区",
                        "value": "弓长岭区",
                        "code": "211005"
                    },
                    {
                        "label": "太子河区",
                        "value": "太子河区",
                        "code": "211011"
                    },
                    {
                        "label": "辽阳县",
                        "value": "辽阳县",
                        "code": "211021"
                    },
                    {
                        "label": "灯塔市",
                        "value": "灯塔市",
                        "code": "211081"
                    }
                ],
                "code": "211000"
            },
            {
                "label": "盘锦市",
                "value": "盘锦市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "211101"
                    },
                    {
                        "label": "双台子区",
                        "value": "双台子区",
                        "code": "211102"
                    },
                    {
                        "label": "兴隆台区",
                        "value": "兴隆台区",
                        "code": "211103"
                    },
                    {
                        "label": "大洼县",
                        "value": "大洼县",
                        "code": "211121"
                    },
                    {
                        "label": "盘山县",
                        "value": "盘山县",
                        "code": "211122"
                    }
                ],
                "code": "211100"
            },
            {
                "label": "铁岭市",
                "value": "铁岭市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "211201"
                    },
                    {
                        "label": "银州区",
                        "value": "银州区",
                        "code": "211202"
                    },
                    {
                        "label": "清河区",
                        "value": "清河区",
                        "code": "211204"
                    },
                    {
                        "label": "铁岭县",
                        "value": "铁岭县",
                        "code": "211221"
                    },
                    {
                        "label": "西丰县",
                        "value": "西丰县",
                        "code": "211223"
                    },
                    {
                        "label": "昌图县",
                        "value": "昌图县",
                        "code": "211224"
                    },
                    {
                        "label": "调兵山市",
                        "value": "调兵山市",
                        "code": "211281"
                    },
                    {
                        "label": "开原市",
                        "value": "开原市",
                        "code": "211282"
                    }
                ],
                "code": "211200"
            },
            {
                "label": "朝阳市",
                "value": "朝阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "211301"
                    },
                    {
                        "label": "双塔区",
                        "value": "双塔区",
                        "code": "211302"
                    },
                    {
                        "label": "龙城区",
                        "value": "龙城区",
                        "code": "211303"
                    },
                    {
                        "label": "朝阳县",
                        "value": "朝阳县",
                        "code": "211321"
                    },
                    {
                        "label": "建平县",
                        "value": "建平县",
                        "code": "211322"
                    },
                    {
                        "label": "喀喇沁左翼蒙古族自治县",
                        "value": "喀喇沁左翼蒙古族自治县",
                        "code": "211324"
                    },
                    {
                        "label": "北票市",
                        "value": "北票市",
                        "code": "211381"
                    },
                    {
                        "label": "凌源市",
                        "value": "凌源市",
                        "code": "211382"
                    }
                ],
                "code": "211300"
            },
            {
                "label": "葫芦岛市",
                "value": "葫芦岛市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "211401"
                    },
                    {
                        "label": "连山区",
                        "value": "连山区",
                        "code": "211402"
                    },
                    {
                        "label": "龙港区",
                        "value": "龙港区",
                        "code": "211403"
                    },
                    {
                        "label": "南票区",
                        "value": "南票区",
                        "code": "211404"
                    },
                    {
                        "label": "绥中县",
                        "value": "绥中县",
                        "code": "211421"
                    },
                    {
                        "label": "建昌县",
                        "value": "建昌县",
                        "code": "211422"
                    },
                    {
                        "label": "兴城市",
                        "value": "兴城市",
                        "code": "211481"
                    }
                ],
                "code": "211400"
            }
        ],
        "code": "210000"
    },
    {
        "label": "吉林省",
        "value": "吉林省",
        "children": [
            {
                "label": "长春市",
                "value": "长春市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "220101"
                    },
                    {
                        "label": "南关区",
                        "value": "南关区",
                        "code": "220102"
                    },
                    {
                        "label": "宽城区",
                        "value": "宽城区",
                        "code": "220103"
                    },
                    {
                        "label": "朝阳区",
                        "value": "朝阳区",
                        "code": "220104"
                    },
                    {
                        "label": "二道区",
                        "value": "二道区",
                        "code": "220105"
                    },
                    {
                        "label": "绿园区",
                        "value": "绿园区",
                        "code": "220106"
                    },
                    {
                        "label": "双阳区",
                        "value": "双阳区",
                        "code": "220112"
                    },
                    {
                        "label": "九台区",
                        "value": "九台区",
                        "code": "220113"
                    },
                    {
                        "label": "农安县",
                        "value": "农安县",
                        "code": "220122"
                    },
                    {
                        "label": "榆树市",
                        "value": "榆树市",
                        "code": "220182"
                    },
                    {
                        "label": "德惠市",
                        "value": "德惠市",
                        "code": "220183"
                    }
                ],
                "code": "220100"
            },
            {
                "label": "吉林市",
                "value": "吉林市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "220201"
                    },
                    {
                        "label": "昌邑区",
                        "value": "昌邑区",
                        "code": "220202"
                    },
                    {
                        "label": "龙潭区",
                        "value": "龙潭区",
                        "code": "220203"
                    },
                    {
                        "label": "船营区",
                        "value": "船营区",
                        "code": "220204"
                    },
                    {
                        "label": "丰满区",
                        "value": "丰满区",
                        "code": "220211"
                    },
                    {
                        "label": "永吉县",
                        "value": "永吉县",
                        "code": "220221"
                    },
                    {
                        "label": "蛟河市",
                        "value": "蛟河市",
                        "code": "220281"
                    },
                    {
                        "label": "桦甸市",
                        "value": "桦甸市",
                        "code": "220282"
                    },
                    {
                        "label": "舒兰市",
                        "value": "舒兰市",
                        "code": "220283"
                    },
                    {
                        "label": "磐石市",
                        "value": "磐石市",
                        "code": "220284"
                    }
                ],
                "code": "220200"
            },
            {
                "label": "四平市",
                "value": "四平市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "220301"
                    },
                    {
                        "label": "铁西区",
                        "value": "铁西区",
                        "code": "220302"
                    },
                    {
                        "label": "铁东区",
                        "value": "铁东区",
                        "code": "220303"
                    },
                    {
                        "label": "梨树县",
                        "value": "梨树县",
                        "code": "220322"
                    },
                    {
                        "label": "伊通满族自治县",
                        "value": "伊通满族自治县",
                        "code": "220323"
                    },
                    {
                        "label": "公主岭市",
                        "value": "公主岭市",
                        "code": "220381"
                    },
                    {
                        "label": "双辽市",
                        "value": "双辽市",
                        "code": "220382"
                    }
                ],
                "code": "220300"
            },
            {
                "label": "辽源市",
                "value": "辽源市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "220401"
                    },
                    {
                        "label": "龙山区",
                        "value": "龙山区",
                        "code": "220402"
                    },
                    {
                        "label": "西安区",
                        "value": "西安区",
                        "code": "220403"
                    },
                    {
                        "label": "东丰县",
                        "value": "东丰县",
                        "code": "220421"
                    },
                    {
                        "label": "东辽县",
                        "value": "东辽县",
                        "code": "220422"
                    }
                ],
                "code": "220400"
            },
            {
                "label": "通化市",
                "value": "通化市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "220501"
                    },
                    {
                        "label": "东昌区",
                        "value": "东昌区",
                        "code": "220502"
                    },
                    {
                        "label": "二道江区",
                        "value": "二道江区",
                        "code": "220503"
                    },
                    {
                        "label": "通化县",
                        "value": "通化县",
                        "code": "220521"
                    },
                    {
                        "label": "辉南县",
                        "value": "辉南县",
                        "code": "220523"
                    },
                    {
                        "label": "柳河县",
                        "value": "柳河县",
                        "code": "220524"
                    },
                    {
                        "label": "梅河口市",
                        "value": "梅河口市",
                        "code": "220581"
                    },
                    {
                        "label": "集安市",
                        "value": "集安市",
                        "code": "220582"
                    }
                ],
                "code": "220500"
            },
            {
                "label": "白山市",
                "value": "白山市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "220601"
                    },
                    {
                        "label": "浑江区",
                        "value": "浑江区",
                        "code": "220602"
                    },
                    {
                        "label": "江源区",
                        "value": "江源区",
                        "code": "220605"
                    },
                    {
                        "label": "抚松县",
                        "value": "抚松县",
                        "code": "220621"
                    },
                    {
                        "label": "靖宇县",
                        "value": "靖宇县",
                        "code": "220622"
                    },
                    {
                        "label": "长白朝鲜族自治县",
                        "value": "长白朝鲜族自治县",
                        "code": "220623"
                    },
                    {
                        "label": "临江市",
                        "value": "临江市",
                        "code": "220681"
                    }
                ],
                "code": "220600"
            },
            {
                "label": "松原市",
                "value": "松原市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "220701"
                    },
                    {
                        "label": "宁江区",
                        "value": "宁江区",
                        "code": "220702"
                    },
                    {
                        "label": "前郭尔罗斯蒙古族自治县",
                        "value": "前郭尔罗斯蒙古族自治县",
                        "code": "220721"
                    },
                    {
                        "label": "长岭县",
                        "value": "长岭县",
                        "code": "220722"
                    },
                    {
                        "label": "乾安县",
                        "value": "乾安县",
                        "code": "220723"
                    },
                    {
                        "label": "扶余市",
                        "value": "扶余市",
                        "code": "220781"
                    }
                ],
                "code": "220700"
            },
            {
                "label": "白城市",
                "value": "白城市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "220801"
                    },
                    {
                        "label": "洮北区",
                        "value": "洮北区",
                        "code": "220802"
                    },
                    {
                        "label": "镇赉县",
                        "value": "镇赉县",
                        "code": "220821"
                    },
                    {
                        "label": "通榆县",
                        "value": "通榆县",
                        "code": "220822"
                    },
                    {
                        "label": "洮南市",
                        "value": "洮南市",
                        "code": "220881"
                    },
                    {
                        "label": "大安市",
                        "value": "大安市",
                        "code": "220882"
                    }
                ],
                "code": "220800"
            },
            {
                "label": "延边朝鲜族自治州",
                "value": "延边朝鲜族自治州",
                "children": [
                    {
                        "label": "延吉市",
                        "value": "延吉市",
                        "code": "222401"
                    },
                    {
                        "label": "图们市",
                        "value": "图们市",
                        "code": "222402"
                    },
                    {
                        "label": "敦化市",
                        "value": "敦化市",
                        "code": "222403"
                    },
                    {
                        "label": "珲春市",
                        "value": "珲春市",
                        "code": "222404"
                    },
                    {
                        "label": "龙井市",
                        "value": "龙井市",
                        "code": "222405"
                    },
                    {
                        "label": "和龙市",
                        "value": "和龙市",
                        "code": "222406"
                    },
                    {
                        "label": "汪清县",
                        "value": "汪清县",
                        "code": "222424"
                    },
                    {
                        "label": "安图县",
                        "value": "安图县",
                        "code": "222426"
                    }
                ],
                "code": "222400"
            }
        ],
        "code": "220000"
    },
    {
        "label": "黑龙江省",
        "value": "黑龙江省",
        "children": [
            {
                "label": "哈尔滨市",
                "value": "哈尔滨市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "230101"
                    },
                    {
                        "label": "道里区",
                        "value": "道里区",
                        "code": "230102"
                    },
                    {
                        "label": "南岗区",
                        "value": "南岗区",
                        "code": "230103"
                    },
                    {
                        "label": "道外区",
                        "value": "道外区",
                        "code": "230104"
                    },
                    {
                        "label": "平房区",
                        "value": "平房区",
                        "code": "230108"
                    },
                    {
                        "label": "松北区",
                        "value": "松北区",
                        "code": "230109"
                    },
                    {
                        "label": "香坊区",
                        "value": "香坊区",
                        "code": "230110"
                    },
                    {
                        "label": "呼兰区",
                        "value": "呼兰区",
                        "code": "230111"
                    },
                    {
                        "label": "阿城区",
                        "value": "阿城区",
                        "code": "230112"
                    },
                    {
                        "label": "双城区",
                        "value": "双城区",
                        "code": "230113"
                    },
                    {
                        "label": "依兰县",
                        "value": "依兰县",
                        "code": "230123"
                    },
                    {
                        "label": "方正县",
                        "value": "方正县",
                        "code": "230124"
                    },
                    {
                        "label": "宾县",
                        "value": "宾县",
                        "code": "230125"
                    },
                    {
                        "label": "巴彦县",
                        "value": "巴彦县",
                        "code": "230126"
                    },
                    {
                        "label": "木兰县",
                        "value": "木兰县",
                        "code": "230127"
                    },
                    {
                        "label": "通河县",
                        "value": "通河县",
                        "code": "230128"
                    },
                    {
                        "label": "延寿县",
                        "value": "延寿县",
                        "code": "230129"
                    },
                    {
                        "label": "尚志市",
                        "value": "尚志市",
                        "code": "230183"
                    },
                    {
                        "label": "五常市",
                        "value": "五常市",
                        "code": "230184"
                    }
                ],
                "code": "230100"
            },
            {
                "label": "齐齐哈尔市",
                "value": "齐齐哈尔市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "230201"
                    },
                    {
                        "label": "龙沙区",
                        "value": "龙沙区",
                        "code": "230202"
                    },
                    {
                        "label": "建华区",
                        "value": "建华区",
                        "code": "230203"
                    },
                    {
                        "label": "铁锋区",
                        "value": "铁锋区",
                        "code": "230204"
                    },
                    {
                        "label": "昂昂溪区",
                        "value": "昂昂溪区",
                        "code": "230205"
                    },
                    {
                        "label": "富拉尔基区",
                        "value": "富拉尔基区",
                        "code": "230206"
                    },
                    {
                        "label": "碾子山区",
                        "value": "碾子山区",
                        "code": "230207"
                    },
                    {
                        "label": "梅里斯达斡尔族区",
                        "value": "梅里斯达斡尔族区",
                        "code": "230208"
                    },
                    {
                        "label": "龙江县",
                        "value": "龙江县",
                        "code": "230221"
                    },
                    {
                        "label": "依安县",
                        "value": "依安县",
                        "code": "230223"
                    },
                    {
                        "label": "泰来县",
                        "value": "泰来县",
                        "code": "230224"
                    },
                    {
                        "label": "甘南县",
                        "value": "甘南县",
                        "code": "230225"
                    },
                    {
                        "label": "富裕县",
                        "value": "富裕县",
                        "code": "230227"
                    },
                    {
                        "label": "克山县",
                        "value": "克山县",
                        "code": "230229"
                    },
                    {
                        "label": "克东县",
                        "value": "克东县",
                        "code": "230230"
                    },
                    {
                        "label": "拜泉县",
                        "value": "拜泉县",
                        "code": "230231"
                    },
                    {
                        "label": "讷河市",
                        "value": "讷河市",
                        "code": "230281"
                    }
                ],
                "code": "230200"
            },
            {
                "label": "鸡西市",
                "value": "鸡西市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "230301"
                    },
                    {
                        "label": "鸡冠区",
                        "value": "鸡冠区",
                        "code": "230302"
                    },
                    {
                        "label": "恒山区",
                        "value": "恒山区",
                        "code": "230303"
                    },
                    {
                        "label": "滴道区",
                        "value": "滴道区",
                        "code": "230304"
                    },
                    {
                        "label": "梨树区",
                        "value": "梨树区",
                        "code": "230305"
                    },
                    {
                        "label": "城子河区",
                        "value": "城子河区",
                        "code": "230306"
                    },
                    {
                        "label": "麻山区",
                        "value": "麻山区",
                        "code": "230307"
                    },
                    {
                        "label": "鸡东县",
                        "value": "鸡东县",
                        "code": "230321"
                    },
                    {
                        "label": "虎林市",
                        "value": "虎林市",
                        "code": "230381"
                    },
                    {
                        "label": "密山市",
                        "value": "密山市",
                        "code": "230382"
                    }
                ],
                "code": "230300"
            },
            {
                "label": "鹤岗市",
                "value": "鹤岗市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "230401"
                    },
                    {
                        "label": "向阳区",
                        "value": "向阳区",
                        "code": "230402"
                    },
                    {
                        "label": "工农区",
                        "value": "工农区",
                        "code": "230403"
                    },
                    {
                        "label": "南山区",
                        "value": "南山区",
                        "code": "230404"
                    },
                    {
                        "label": "兴安区",
                        "value": "兴安区",
                        "code": "230405"
                    },
                    {
                        "label": "东山区",
                        "value": "东山区",
                        "code": "230406"
                    },
                    {
                        "label": "兴山区",
                        "value": "兴山区",
                        "code": "230407"
                    },
                    {
                        "label": "萝北县",
                        "value": "萝北县",
                        "code": "230421"
                    },
                    {
                        "label": "绥滨县",
                        "value": "绥滨县",
                        "code": "230422"
                    }
                ],
                "code": "230400"
            },
            {
                "label": "双鸭山市",
                "value": "双鸭山市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "230501"
                    },
                    {
                        "label": "尖山区",
                        "value": "尖山区",
                        "code": "230502"
                    },
                    {
                        "label": "岭东区",
                        "value": "岭东区",
                        "code": "230503"
                    },
                    {
                        "label": "四方台区",
                        "value": "四方台区",
                        "code": "230505"
                    },
                    {
                        "label": "宝山区",
                        "value": "宝山区",
                        "code": "230506"
                    },
                    {
                        "label": "集贤县",
                        "value": "集贤县",
                        "code": "230521"
                    },
                    {
                        "label": "友谊县",
                        "value": "友谊县",
                        "code": "230522"
                    },
                    {
                        "label": "宝清县",
                        "value": "宝清县",
                        "code": "230523"
                    },
                    {
                        "label": "饶河县",
                        "value": "饶河县",
                        "code": "230524"
                    }
                ],
                "code": "230500"
            },
            {
                "label": "大庆市",
                "value": "大庆市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "230601"
                    },
                    {
                        "label": "萨尔图区",
                        "value": "萨尔图区",
                        "code": "230602"
                    },
                    {
                        "label": "龙凤区",
                        "value": "龙凤区",
                        "code": "230603"
                    },
                    {
                        "label": "让胡路区",
                        "value": "让胡路区",
                        "code": "230604"
                    },
                    {
                        "label": "红岗区",
                        "value": "红岗区",
                        "code": "230605"
                    },
                    {
                        "label": "大同区",
                        "value": "大同区",
                        "code": "230606"
                    },
                    {
                        "label": "肇州县",
                        "value": "肇州县",
                        "code": "230621"
                    },
                    {
                        "label": "肇源县",
                        "value": "肇源县",
                        "code": "230622"
                    },
                    {
                        "label": "林甸县",
                        "value": "林甸县",
                        "code": "230623"
                    },
                    {
                        "label": "杜尔伯特蒙古族自治县",
                        "value": "杜尔伯特蒙古族自治县",
                        "code": "230624"
                    }
                ],
                "code": "230600"
            },
            {
                "label": "伊春市",
                "value": "伊春市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "230701"
                    },
                    {
                        "label": "伊春区",
                        "value": "伊春区",
                        "code": "230702"
                    },
                    {
                        "label": "南岔区",
                        "value": "南岔区",
                        "code": "230703"
                    },
                    {
                        "label": "友好区",
                        "value": "友好区",
                        "code": "230704"
                    },
                    {
                        "label": "西林区",
                        "value": "西林区",
                        "code": "230705"
                    },
                    {
                        "label": "翠峦区",
                        "value": "翠峦区",
                        "code": "230706"
                    },
                    {
                        "label": "新青区",
                        "value": "新青区",
                        "code": "230707"
                    },
                    {
                        "label": "美溪区",
                        "value": "美溪区",
                        "code": "230708"
                    },
                    {
                        "label": "金山屯区",
                        "value": "金山屯区",
                        "code": "230709"
                    },
                    {
                        "label": "五营区",
                        "value": "五营区",
                        "code": "230710"
                    },
                    {
                        "label": "乌马河区",
                        "value": "乌马河区",
                        "code": "230711"
                    },
                    {
                        "label": "汤旺河区",
                        "value": "汤旺河区",
                        "code": "230712"
                    },
                    {
                        "label": "带岭区",
                        "value": "带岭区",
                        "code": "230713"
                    },
                    {
                        "label": "乌伊岭区",
                        "value": "乌伊岭区",
                        "code": "230714"
                    },
                    {
                        "label": "红星区",
                        "value": "红星区",
                        "code": "230715"
                    },
                    {
                        "label": "上甘岭区",
                        "value": "上甘岭区",
                        "code": "230716"
                    },
                    {
                        "label": "嘉荫县",
                        "value": "嘉荫县",
                        "code": "230722"
                    },
                    {
                        "label": "铁力市",
                        "value": "铁力市",
                        "code": "230781"
                    }
                ],
                "code": "230700"
            },
            {
                "label": "佳木斯市",
                "value": "佳木斯市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "230801"
                    },
                    {
                        "label": "向阳区",
                        "value": "向阳区",
                        "code": "230803"
                    },
                    {
                        "label": "前进区",
                        "value": "前进区",
                        "code": "230804"
                    },
                    {
                        "label": "东风区",
                        "value": "东风区",
                        "code": "230805"
                    },
                    {
                        "label": "郊区",
                        "value": "郊区",
                        "code": "230811"
                    },
                    {
                        "label": "桦南县",
                        "value": "桦南县",
                        "code": "230822"
                    },
                    {
                        "label": "桦川县",
                        "value": "桦川县",
                        "code": "230826"
                    },
                    {
                        "label": "汤原县",
                        "value": "汤原县",
                        "code": "230828"
                    },
                    {
                        "label": "抚远县",
                        "value": "抚远县",
                        "code": "230833"
                    },
                    {
                        "label": "同江市",
                        "value": "同江市",
                        "code": "230881"
                    },
                    {
                        "label": "富锦市",
                        "value": "富锦市",
                        "code": "230882"
                    }
                ],
                "code": "230800"
            },
            {
                "label": "七台河市",
                "value": "七台河市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "230901"
                    },
                    {
                        "label": "新兴区",
                        "value": "新兴区",
                        "code": "230902"
                    },
                    {
                        "label": "桃山区",
                        "value": "桃山区",
                        "code": "230903"
                    },
                    {
                        "label": "茄子河区",
                        "value": "茄子河区",
                        "code": "230904"
                    },
                    {
                        "label": "勃利县",
                        "value": "勃利县",
                        "code": "230921"
                    }
                ],
                "code": "230900"
            },
            {
                "label": "牡丹江市",
                "value": "牡丹江市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "231001"
                    },
                    {
                        "label": "东安区",
                        "value": "东安区",
                        "code": "231002"
                    },
                    {
                        "label": "阳明区",
                        "value": "阳明区",
                        "code": "231003"
                    },
                    {
                        "label": "爱民区",
                        "value": "爱民区",
                        "code": "231004"
                    },
                    {
                        "label": "西安区",
                        "value": "西安区",
                        "code": "231005"
                    },
                    {
                        "label": "东宁县",
                        "value": "东宁县",
                        "code": "231024"
                    },
                    {
                        "label": "林口县",
                        "value": "林口县",
                        "code": "231025"
                    },
                    {
                        "label": "绥芬河市",
                        "value": "绥芬河市",
                        "code": "231081"
                    },
                    {
                        "label": "海林市",
                        "value": "海林市",
                        "code": "231083"
                    },
                    {
                        "label": "宁安市",
                        "value": "宁安市",
                        "code": "231084"
                    },
                    {
                        "label": "穆棱市",
                        "value": "穆棱市",
                        "code": "231085"
                    }
                ],
                "code": "231000"
            },
            {
                "label": "黑河市",
                "value": "黑河市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "231101"
                    },
                    {
                        "label": "爱辉区",
                        "value": "爱辉区",
                        "code": "231102"
                    },
                    {
                        "label": "嫩江县",
                        "value": "嫩江县",
                        "code": "231121"
                    },
                    {
                        "label": "逊克县",
                        "value": "逊克县",
                        "code": "231123"
                    },
                    {
                        "label": "孙吴县",
                        "value": "孙吴县",
                        "code": "231124"
                    },
                    {
                        "label": "北安市",
                        "value": "北安市",
                        "code": "231181"
                    },
                    {
                        "label": "五大连池市",
                        "value": "五大连池市",
                        "code": "231182"
                    }
                ],
                "code": "231100"
            },
            {
                "label": "绥化市",
                "value": "绥化市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "231201"
                    },
                    {
                        "label": "北林区",
                        "value": "北林区",
                        "code": "231202"
                    },
                    {
                        "label": "望奎县",
                        "value": "望奎县",
                        "code": "231221"
                    },
                    {
                        "label": "兰西县",
                        "value": "兰西县",
                        "code": "231222"
                    },
                    {
                        "label": "青冈县",
                        "value": "青冈县",
                        "code": "231223"
                    },
                    {
                        "label": "庆安县",
                        "value": "庆安县",
                        "code": "231224"
                    },
                    {
                        "label": "明水县",
                        "value": "明水县",
                        "code": "231225"
                    },
                    {
                        "label": "绥棱县",
                        "value": "绥棱县",
                        "code": "231226"
                    },
                    {
                        "label": "安达市",
                        "value": "安达市",
                        "code": "231281"
                    },
                    {
                        "label": "肇东市",
                        "value": "肇东市",
                        "code": "231282"
                    },
                    {
                        "label": "海伦市",
                        "value": "海伦市",
                        "code": "231283"
                    }
                ],
                "code": "231200"
            },
            {
                "label": "大兴安岭地区",
                "value": "大兴安岭地区",
                "children": [
                    {
                        "label": "呼玛县",
                        "value": "呼玛县",
                        "code": "232721"
                    },
                    {
                        "label": "塔河县",
                        "value": "塔河县",
                        "code": "232722"
                    },
                    {
                        "label": "漠河县",
                        "value": "漠河县",
                        "code": "232723"
                    }
                ],
                "code": "232700"
            }
        ],
        "code": "230000"
    },
    {
        "label": "上海市",
        "value": "上海市",
        "children": [
            {
                "label": "上海市",
                "value": "上海市",
                "children": [
                    {
                        "label": "黄浦区",
                        "value": "黄浦区",
                        "code": "310101"
                    },
                    {
                        "label": "徐汇区",
                        "value": "徐汇区",
                        "code": "310104"
                    },
                    {
                        "label": "长宁区",
                        "value": "长宁区",
                        "code": "310105"
                    },
                    {
                        "label": "静安区",
                        "value": "静安区",
                        "code": "310106"
                    },
                    {
                        "label": "普陀区",
                        "value": "普陀区",
                        "code": "310107"
                    },
                    {
                        "label": "闸北区",
                        "value": "闸北区",
                        "code": "310108"
                    },
                    {
                        "label": "虹口区",
                        "value": "虹口区",
                        "code": "310109"
                    },
                    {
                        "label": "杨浦区",
                        "value": "杨浦区",
                        "code": "310110"
                    },
                    {
                        "label": "闵行区",
                        "value": "闵行区",
                        "code": "310112"
                    },
                    {
                        "label": "宝山区",
                        "value": "宝山区",
                        "code": "310113"
                    },
                    {
                        "label": "嘉定区",
                        "value": "嘉定区",
                        "code": "310114"
                    },
                    {
                        "label": "浦东新区",
                        "value": "浦东新区",
                        "code": "310115"
                    },
                    {
                        "label": "金山区",
                        "value": "金山区",
                        "code": "310116"
                    },
                    {
                        "label": "松江区",
                        "value": "松江区",
                        "code": "310117"
                    },
                    {
                        "label": "青浦区",
                        "value": "青浦区",
                        "code": "310118"
                    },
                    {
                        "label": "奉贤区",
                        "value": "奉贤区",
                        "code": "310120"
                    },
                    {
                        "label": "崇明县",
                        "value": "崇明县",
                        "code": "310230"
                    }
                ],
                "code": "310000"
            }
        ],
        "code": "310000"
    },
    {
        "label": "江苏省",
        "value": "江苏省",
        "children": [
            {
                "label": "南京市",
                "value": "南京市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "320101"
                    },
                    {
                        "label": "玄武区",
                        "value": "玄武区",
                        "code": "320102"
                    },
                    {
                        "label": "秦淮区",
                        "value": "秦淮区",
                        "code": "320104"
                    },
                    {
                        "label": "建邺区",
                        "value": "建邺区",
                        "code": "320105"
                    },
                    {
                        "label": "鼓楼区",
                        "value": "鼓楼区",
                        "code": "320106"
                    },
                    {
                        "label": "浦口区",
                        "value": "浦口区",
                        "code": "320111"
                    },
                    {
                        "label": "栖霞区",
                        "value": "栖霞区",
                        "code": "320113"
                    },
                    {
                        "label": "雨花台区",
                        "value": "雨花台区",
                        "code": "320114"
                    },
                    {
                        "label": "江宁区",
                        "value": "江宁区",
                        "code": "320115"
                    },
                    {
                        "label": "六合区",
                        "value": "六合区",
                        "code": "320116"
                    },
                    {
                        "label": "溧水区",
                        "value": "溧水区",
                        "code": "320117"
                    },
                    {
                        "label": "高淳区",
                        "value": "高淳区",
                        "code": "320118"
                    }
                ],
                "code": "320100"
            },
            {
                "label": "无锡市",
                "value": "无锡市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "320201"
                    },
                    {
                        "label": "崇安区",
                        "value": "崇安区",
                        "code": "320202"
                    },
                    {
                        "label": "南长区",
                        "value": "南长区",
                        "code": "320203"
                    },
                    {
                        "label": "北塘区",
                        "value": "北塘区",
                        "code": "320204"
                    },
                    {
                        "label": "锡山区",
                        "value": "锡山区",
                        "code": "320205"
                    },
                    {
                        "label": "惠山区",
                        "value": "惠山区",
                        "code": "320206"
                    },
                    {
                        "label": "滨湖区",
                        "value": "滨湖区",
                        "code": "320211"
                    },
                    {
                        "label": "江阴市",
                        "value": "江阴市",
                        "code": "320281"
                    },
                    {
                        "label": "宜兴市",
                        "value": "宜兴市",
                        "code": "320282"
                    }
                ],
                "code": "320200"
            },
            {
                "label": "徐州市",
                "value": "徐州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "320301"
                    },
                    {
                        "label": "鼓楼区",
                        "value": "鼓楼区",
                        "code": "320302"
                    },
                    {
                        "label": "云龙区",
                        "value": "云龙区",
                        "code": "320303"
                    },
                    {
                        "label": "贾汪区",
                        "value": "贾汪区",
                        "code": "320305"
                    },
                    {
                        "label": "泉山区",
                        "value": "泉山区",
                        "code": "320311"
                    },
                    {
                        "label": "铜山区",
                        "value": "铜山区",
                        "code": "320312"
                    },
                    {
                        "label": "丰县",
                        "value": "丰县",
                        "code": "320321"
                    },
                    {
                        "label": "沛县",
                        "value": "沛县",
                        "code": "320322"
                    },
                    {
                        "label": "睢宁县",
                        "value": "睢宁县",
                        "code": "320324"
                    },
                    {
                        "label": "新沂市",
                        "value": "新沂市",
                        "code": "320381"
                    },
                    {
                        "label": "邳州市",
                        "value": "邳州市",
                        "code": "320382"
                    }
                ],
                "code": "320300"
            },
            {
                "label": "常州市",
                "value": "常州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "320401"
                    },
                    {
                        "label": "天宁区",
                        "value": "天宁区",
                        "code": "320402"
                    },
                    {
                        "label": "钟楼区",
                        "value": "钟楼区",
                        "code": "320404"
                    },
                    {
                        "label": "戚墅堰区",
                        "value": "戚墅堰区",
                        "code": "320405"
                    },
                    {
                        "label": "新北区",
                        "value": "新北区",
                        "code": "320411"
                    },
                    {
                        "label": "武进区",
                        "value": "武进区",
                        "code": "320412"
                    },
                    {
                        "label": "溧阳市",
                        "value": "溧阳市",
                        "code": "320481"
                    },
                    {
                        "label": "金坛市",
                        "value": "金坛市",
                        "code": "320482"
                    }
                ],
                "code": "320400"
            },
            {
                "label": "苏州市",
                "value": "苏州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "320501"
                    },
                    {
                        "label": "虎丘区",
                        "value": "虎丘区",
                        "code": "320505"
                    },
                    {
                        "label": "吴中区",
                        "value": "吴中区",
                        "code": "320506"
                    },
                    {
                        "label": "相城区",
                        "value": "相城区",
                        "code": "320507"
                    },
                    {
                        "label": "姑苏区",
                        "value": "姑苏区",
                        "code": "320508"
                    },
                    {
                        "label": "吴江区",
                        "value": "吴江区",
                        "code": "320509"
                    },
                    {
                        "label": "常熟市",
                        "value": "常熟市",
                        "code": "320581"
                    },
                    {
                        "label": "张家港市",
                        "value": "张家港市",
                        "code": "320582"
                    },
                    {
                        "label": "昆山市",
                        "value": "昆山市",
                        "code": "320583"
                    },
                    {
                        "label": "太仓市",
                        "value": "太仓市",
                        "code": "320585"
                    }
                ],
                "code": "320500"
            },
            {
                "label": "南通市",
                "value": "南通市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "320601"
                    },
                    {
                        "label": "崇川区",
                        "value": "崇川区",
                        "code": "320602"
                    },
                    {
                        "label": "港闸区",
                        "value": "港闸区",
                        "code": "320611"
                    },
                    {
                        "label": "通州区",
                        "value": "通州区",
                        "code": "320612"
                    },
                    {
                        "label": "海安县",
                        "value": "海安县",
                        "code": "320621"
                    },
                    {
                        "label": "如东县",
                        "value": "如东县",
                        "code": "320623"
                    },
                    {
                        "label": "启东市",
                        "value": "启东市",
                        "code": "320681"
                    },
                    {
                        "label": "如皋市",
                        "value": "如皋市",
                        "code": "320682"
                    },
                    {
                        "label": "海门市",
                        "value": "海门市",
                        "code": "320684"
                    }
                ],
                "code": "320600"
            },
            {
                "label": "连云港市",
                "value": "连云港市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "320701"
                    },
                    {
                        "label": "连云区",
                        "value": "连云区",
                        "code": "320703"
                    },
                    {
                        "label": "海州区",
                        "value": "海州区",
                        "code": "320706"
                    },
                    {
                        "label": "赣榆区",
                        "value": "赣榆区",
                        "code": "320707"
                    },
                    {
                        "label": "东海县",
                        "value": "东海县",
                        "code": "320722"
                    },
                    {
                        "label": "灌云县",
                        "value": "灌云县",
                        "code": "320723"
                    },
                    {
                        "label": "灌南县",
                        "value": "灌南县",
                        "code": "320724"
                    }
                ],
                "code": "320700"
            },
            {
                "label": "淮安市",
                "value": "淮安市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "320801"
                    },
                    {
                        "label": "清河区",
                        "value": "清河区",
                        "code": "320802"
                    },
                    {
                        "label": "淮安区",
                        "value": "淮安区",
                        "code": "320803"
                    },
                    {
                        "label": "淮阴区",
                        "value": "淮阴区",
                        "code": "320804"
                    },
                    {
                        "label": "清浦区",
                        "value": "清浦区",
                        "code": "320811"
                    },
                    {
                        "label": "涟水县",
                        "value": "涟水县",
                        "code": "320826"
                    },
                    {
                        "label": "洪泽县",
                        "value": "洪泽县",
                        "code": "320829"
                    },
                    {
                        "label": "盱眙县",
                        "value": "盱眙县",
                        "code": "320830"
                    },
                    {
                        "label": "金湖县",
                        "value": "金湖县",
                        "code": "320831"
                    }
                ],
                "code": "320800"
            },
            {
                "label": "盐城市",
                "value": "盐城市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "320901"
                    },
                    {
                        "label": "亭湖区",
                        "value": "亭湖区",
                        "code": "320902"
                    },
                    {
                        "label": "盐都区",
                        "value": "盐都区",
                        "code": "320903"
                    },
                    {
                        "label": "响水县",
                        "value": "响水县",
                        "code": "320921"
                    },
                    {
                        "label": "滨海县",
                        "value": "滨海县",
                        "code": "320922"
                    },
                    {
                        "label": "阜宁县",
                        "value": "阜宁县",
                        "code": "320923"
                    },
                    {
                        "label": "射阳县",
                        "value": "射阳县",
                        "code": "320924"
                    },
                    {
                        "label": "建湖县",
                        "value": "建湖县",
                        "code": "320925"
                    },
                    {
                        "label": "东台市",
                        "value": "东台市",
                        "code": "320981"
                    },
                    {
                        "label": "大丰市",
                        "value": "大丰市",
                        "code": "320982"
                    }
                ],
                "code": "320900"
            },
            {
                "label": "扬州市",
                "value": "扬州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "321001"
                    },
                    {
                        "label": "广陵区",
                        "value": "广陵区",
                        "code": "321002"
                    },
                    {
                        "label": "邗江区",
                        "value": "邗江区",
                        "code": "321003"
                    },
                    {
                        "label": "江都区",
                        "value": "江都区",
                        "code": "321012"
                    },
                    {
                        "label": "宝应县",
                        "value": "宝应县",
                        "code": "321023"
                    },
                    {
                        "label": "仪征市",
                        "value": "仪征市",
                        "code": "321081"
                    },
                    {
                        "label": "高邮市",
                        "value": "高邮市",
                        "code": "321084"
                    }
                ],
                "code": "321000"
            },
            {
                "label": "镇江市",
                "value": "镇江市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "321101"
                    },
                    {
                        "label": "京口区",
                        "value": "京口区",
                        "code": "321102"
                    },
                    {
                        "label": "润州区",
                        "value": "润州区",
                        "code": "321111"
                    },
                    {
                        "label": "丹徒区",
                        "value": "丹徒区",
                        "code": "321112"
                    },
                    {
                        "label": "丹阳市",
                        "value": "丹阳市",
                        "code": "321181"
                    },
                    {
                        "label": "扬中市",
                        "value": "扬中市",
                        "code": "321182"
                    },
                    {
                        "label": "句容市",
                        "value": "句容市",
                        "code": "321183"
                    }
                ],
                "code": "321100"
            },
            {
                "label": "泰州市",
                "value": "泰州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "321201"
                    },
                    {
                        "label": "海陵区",
                        "value": "海陵区",
                        "code": "321202"
                    },
                    {
                        "label": "高港区",
                        "value": "高港区",
                        "code": "321203"
                    },
                    {
                        "label": "姜堰区",
                        "value": "姜堰区",
                        "code": "321204"
                    },
                    {
                        "label": "兴化市",
                        "value": "兴化市",
                        "code": "321281"
                    },
                    {
                        "label": "靖江市",
                        "value": "靖江市",
                        "code": "321282"
                    },
                    {
                        "label": "泰兴市",
                        "value": "泰兴市",
                        "code": "321283"
                    }
                ],
                "code": "321200"
            },
            {
                "label": "宿迁市",
                "value": "宿迁市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "321301"
                    },
                    {
                        "label": "宿城区",
                        "value": "宿城区",
                        "code": "321302"
                    },
                    {
                        "label": "宿豫区",
                        "value": "宿豫区",
                        "code": "321311"
                    },
                    {
                        "label": "沭阳县",
                        "value": "沭阳县",
                        "code": "321322"
                    },
                    {
                        "label": "泗阳县",
                        "value": "泗阳县",
                        "code": "321323"
                    },
                    {
                        "label": "泗洪县",
                        "value": "泗洪县",
                        "code": "321324"
                    }
                ],
                "code": "321300"
            }
        ],
        "code": "320000"
    },
    {
        "label": "浙江省",
        "value": "浙江省",
        "children": [
            {
                "label": "杭州市",
                "value": "杭州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "330101"
                    },
                    {
                        "label": "上城区",
                        "value": "上城区",
                        "code": "330102"
                    },
                    {
                        "label": "拱墅区",
                        "value": "拱墅区",
                        "code": "330105"
                    },
                    {
                        "label": "西湖区",
                        "value": "西湖区",
                        "code": "330106"
                    },
                    {
                        "label": "滨江区",
                        "value": "滨江区",
                        "code": "330108"
                    },
                    {
                        "label": "萧山区",
                        "value": "萧山区",
                        "code": "330109"
                    },
                    {
                        "label": "余杭区",
                        "value": "余杭区",
                        "code": "330110"
                    },
                    {
                        "label": "临平区",
                        "value": "临平区",
                        "code": ""
                    },
                    {
                        "label": "富阳区",
                        "value": "富阳区",
                        "code": "330111"
                    },
                    {
                        "label": "桐庐县",
                        "value": "桐庐县",
                        "code": "330122"
                    },
                    {
                        "label": "淳安县",
                        "value": "淳安县",
                        "code": "330127"
                    },
                    {
                        "label": "建德市",
                        "value": "建德市",
                        "code": "330182"
                    },
                    {
                        "label": "临安市",
                        "value": "临安市",
                        "code": "330185"
                    },
                    {
                        "label": "钱塘区",
                        "value": "钱塘区",
                        "code": ""
                    }
                ],
                "code": "330100"
            },
            {
                "label": "宁波市",
                "value": "宁波市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "330201"
                    },
                    {
                        "label": "海曙区",
                        "value": "海曙区",
                        "code": "330203"
                    },
                    {
                        "label": "江东区",
                        "value": "江东区",
                        "code": "330204"
                    },
                    {
                        "label": "江北区",
                        "value": "江北区",
                        "code": "330205"
                    },
                    {
                        "label": "北仑区",
                        "value": "北仑区",
                        "code": "330206"
                    },
                    {
                        "label": "镇海区",
                        "value": "镇海区",
                        "code": "330211"
                    },
                    {
                        "label": "鄞州区",
                        "value": "鄞州区",
                        "code": "330212"
                    },
                    {
                        "label": "象山县",
                        "value": "象山县",
                        "code": "330225"
                    },
                    {
                        "label": "宁海县",
                        "value": "宁海县",
                        "code": "330226"
                    },
                    {
                        "label": "余姚市",
                        "value": "余姚市",
                        "code": "330281"
                    },
                    {
                        "label": "慈溪市",
                        "value": "慈溪市",
                        "code": "330282"
                    },
                    {
                        "label": "奉化市",
                        "value": "奉化市",
                        "code": "330283"
                    }
                ],
                "code": "330200"
            },
            {
                "label": "温州市",
                "value": "温州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "330301"
                    },
                    {
                        "label": "鹿城区",
                        "value": "鹿城区",
                        "code": "330302"
                    },
                    {
                        "label": "龙湾区",
                        "value": "龙湾区",
                        "code": "330303"
                    },
                    {
                        "label": "瓯海区",
                        "value": "瓯海区",
                        "code": "330304"
                    },
                    {
                        "label": "洞头县",
                        "value": "洞头县",
                        "code": "330322"
                    },
                    {
                        "label": "永嘉县",
                        "value": "永嘉县",
                        "code": "330324"
                    },
                    {
                        "label": "平阳县",
                        "value": "平阳县",
                        "code": "330326"
                    },
                    {
                        "label": "苍南县",
                        "value": "苍南县",
                        "code": "330327"
                    },
                    {
                        "label": "文成县",
                        "value": "文成县",
                        "code": "330328"
                    },
                    {
                        "label": "泰顺县",
                        "value": "泰顺县",
                        "code": "330329"
                    },
                    {
                        "label": "瑞安市",
                        "value": "瑞安市",
                        "code": "330381"
                    },
                    {
                        "label": "乐清市",
                        "value": "乐清市",
                        "code": "330382"
                    }
                ],
                "code": "330300"
            },
            {
                "label": "嘉兴市",
                "value": "嘉兴市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "330401"
                    },
                    {
                        "label": "南湖区",
                        "value": "南湖区",
                        "code": "330402"
                    },
                    {
                        "label": "秀洲区",
                        "value": "秀洲区",
                        "code": "330411"
                    },
                    {
                        "label": "嘉善县",
                        "value": "嘉善县",
                        "code": "330421"
                    },
                    {
                        "label": "海盐县",
                        "value": "海盐县",
                        "code": "330424"
                    },
                    {
                        "label": "海宁市",
                        "value": "海宁市",
                        "code": "330481"
                    },
                    {
                        "label": "平湖市",
                        "value": "平湖市",
                        "code": "330482"
                    },
                    {
                        "label": "桐乡市",
                        "value": "桐乡市",
                        "code": "330483"
                    }
                ],
                "code": "330400"
            },
            {
                "label": "湖州市",
                "value": "湖州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "330501"
                    },
                    {
                        "label": "吴兴区",
                        "value": "吴兴区",
                        "code": "330502"
                    },
                    {
                        "label": "南浔区",
                        "value": "南浔区",
                        "code": "330503"
                    },
                    {
                        "label": "德清县",
                        "value": "德清县",
                        "code": "330521"
                    },
                    {
                        "label": "长兴县",
                        "value": "长兴县",
                        "code": "330522"
                    },
                    {
                        "label": "安吉县",
                        "value": "安吉县",
                        "code": "330523"
                    }
                ],
                "code": "330500"
            },
            {
                "label": "绍兴市",
                "value": "绍兴市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "330601"
                    },
                    {
                        "label": "越城区",
                        "value": "越城区",
                        "code": "330602"
                    },
                    {
                        "label": "柯桥区",
                        "value": "柯桥区",
                        "code": "330603"
                    },
                    {
                        "label": "上虞区",
                        "value": "上虞区",
                        "code": "330604"
                    },
                    {
                        "label": "新昌县",
                        "value": "新昌县",
                        "code": "330624"
                    },
                    {
                        "label": "诸暨市",
                        "value": "诸暨市",
                        "code": "330681"
                    },
                    {
                        "label": "嵊州市",
                        "value": "嵊州市",
                        "code": "330683"
                    }
                ],
                "code": "330600"
            },
            {
                "label": "金华市",
                "value": "金华市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "330701"
                    },
                    {
                        "label": "婺城区",
                        "value": "婺城区",
                        "code": "330702"
                    },
                    {
                        "label": "金东区",
                        "value": "金东区",
                        "code": "330703"
                    },
                    {
                        "label": "武义县",
                        "value": "武义县",
                        "code": "330723"
                    },
                    {
                        "label": "浦江县",
                        "value": "浦江县",
                        "code": "330726"
                    },
                    {
                        "label": "磐安县",
                        "value": "磐安县",
                        "code": "330727"
                    },
                    {
                        "label": "兰溪市",
                        "value": "兰溪市",
                        "code": "330781"
                    },
                    {
                        "label": "义乌市",
                        "value": "义乌市",
                        "code": "330782"
                    },
                    {
                        "label": "东阳市",
                        "value": "东阳市",
                        "code": "330783"
                    },
                    {
                        "label": "永康市",
                        "value": "永康市",
                        "code": "330784"
                    }
                ],
                "code": "330700"
            },
            {
                "label": "衢州市",
                "value": "衢州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "330801"
                    },
                    {
                        "label": "柯城区",
                        "value": "柯城区",
                        "code": "330802"
                    },
                    {
                        "label": "衢江区",
                        "value": "衢江区",
                        "code": "330803"
                    },
                    {
                        "label": "常山县",
                        "value": "常山县",
                        "code": "330822"
                    },
                    {
                        "label": "开化县",
                        "value": "开化县",
                        "code": "330824"
                    },
                    {
                        "label": "龙游县",
                        "value": "龙游县",
                        "code": "330825"
                    },
                    {
                        "label": "江山市",
                        "value": "江山市",
                        "code": "330881"
                    }
                ],
                "code": "330800"
            },
            {
                "label": "舟山市",
                "value": "舟山市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "330901"
                    },
                    {
                        "label": "定海区",
                        "value": "定海区",
                        "code": "330902"
                    },
                    {
                        "label": "普陀区",
                        "value": "普陀区",
                        "code": "330903"
                    },
                    {
                        "label": "岱山县",
                        "value": "岱山县",
                        "code": "330921"
                    },
                    {
                        "label": "嵊泗县",
                        "value": "嵊泗县",
                        "code": "330922"
                    }
                ],
                "code": "330900"
            },
            {
                "label": "台州市",
                "value": "台州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "331001"
                    },
                    {
                        "label": "椒江区",
                        "value": "椒江区",
                        "code": "331002"
                    },
                    {
                        "label": "黄岩区",
                        "value": "黄岩区",
                        "code": "331003"
                    },
                    {
                        "label": "路桥区",
                        "value": "路桥区",
                        "code": "331004"
                    },
                    {
                        "label": "玉环县",
                        "value": "玉环县",
                        "code": "331021"
                    },
                    {
                        "label": "三门县",
                        "value": "三门县",
                        "code": "331022"
                    },
                    {
                        "label": "天台县",
                        "value": "天台县",
                        "code": "331023"
                    },
                    {
                        "label": "仙居县",
                        "value": "仙居县",
                        "code": "331024"
                    },
                    {
                        "label": "温岭市",
                        "value": "温岭市",
                        "code": "331081"
                    },
                    {
                        "label": "临海市",
                        "value": "临海市",
                        "code": "331082"
                    }
                ],
                "code": "331000"
            },
            {
                "label": "丽水市",
                "value": "丽水市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "331101"
                    },
                    {
                        "label": "莲都区",
                        "value": "莲都区",
                        "code": "331102"
                    },
                    {
                        "label": "青田县",
                        "value": "青田县",
                        "code": "331121"
                    },
                    {
                        "label": "缙云县",
                        "value": "缙云县",
                        "code": "331122"
                    },
                    {
                        "label": "遂昌县",
                        "value": "遂昌县",
                        "code": "331123"
                    },
                    {
                        "label": "松阳县",
                        "value": "松阳县",
                        "code": "331124"
                    },
                    {
                        "label": "云和县",
                        "value": "云和县",
                        "code": "331125"
                    },
                    {
                        "label": "庆元县",
                        "value": "庆元县",
                        "code": "331126"
                    },
                    {
                        "label": "景宁畲族自治县",
                        "value": "景宁畲族自治县",
                        "code": "331127"
                    },
                    {
                        "label": "龙泉市",
                        "value": "龙泉市",
                        "code": "331181"
                    }
                ],
                "code": "331100"
            }
        ],
        "code": "330000"
    },
    {
        "label": "安徽省",
        "value": "安徽省",
        "children": [
            {
                "label": "合肥市",
                "value": "合肥市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "340101"
                    },
                    {
                        "label": "瑶海区",
                        "value": "瑶海区",
                        "code": "340102"
                    },
                    {
                        "label": "庐阳区",
                        "value": "庐阳区",
                        "code": "340103"
                    },
                    {
                        "label": "蜀山区",
                        "value": "蜀山区",
                        "code": "340104"
                    },
                    {
                        "label": "包河区",
                        "value": "包河区",
                        "code": "340111"
                    },
                    {
                        "label": "长丰县",
                        "value": "长丰县",
                        "code": "340121"
                    },
                    {
                        "label": "肥东县",
                        "value": "肥东县",
                        "code": "340122"
                    },
                    {
                        "label": "肥西县",
                        "value": "肥西县",
                        "code": "340123"
                    },
                    {
                        "label": "庐江县",
                        "value": "庐江县",
                        "code": "340124"
                    },
                    {
                        "label": "巢湖市",
                        "value": "巢湖市",
                        "code": "340181"
                    }
                ],
                "code": "340100"
            },
            {
                "label": "芜湖市",
                "value": "芜湖市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "340201"
                    },
                    {
                        "label": "镜湖区",
                        "value": "镜湖区",
                        "code": "340202"
                    },
                    {
                        "label": "弋江区",
                        "value": "弋江区",
                        "code": "340203"
                    },
                    {
                        "label": "鸠江区",
                        "value": "鸠江区",
                        "code": "340207"
                    },
                    {
                        "label": "三山区",
                        "value": "三山区",
                        "code": "340208"
                    },
                    {
                        "label": "芜湖县",
                        "value": "芜湖县",
                        "code": "340221"
                    },
                    {
                        "label": "繁昌县",
                        "value": "繁昌县",
                        "code": "340222"
                    },
                    {
                        "label": "南陵县",
                        "value": "南陵县",
                        "code": "340223"
                    },
                    {
                        "label": "无为县",
                        "value": "无为县",
                        "code": "340225"
                    }
                ],
                "code": "340200"
            },
            {
                "label": "蚌埠市",
                "value": "蚌埠市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "340301"
                    },
                    {
                        "label": "龙子湖区",
                        "value": "龙子湖区",
                        "code": "340302"
                    },
                    {
                        "label": "蚌山区",
                        "value": "蚌山区",
                        "code": "340303"
                    },
                    {
                        "label": "禹会区",
                        "value": "禹会区",
                        "code": "340304"
                    },
                    {
                        "label": "淮上区",
                        "value": "淮上区",
                        "code": "340311"
                    },
                    {
                        "label": "怀远县",
                        "value": "怀远县",
                        "code": "340321"
                    },
                    {
                        "label": "五河县",
                        "value": "五河县",
                        "code": "340322"
                    },
                    {
                        "label": "固镇县",
                        "value": "固镇县",
                        "code": "340323"
                    }
                ],
                "code": "340300"
            },
            {
                "label": "淮南市",
                "value": "淮南市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "340401"
                    },
                    {
                        "label": "大通区",
                        "value": "大通区",
                        "code": "340402"
                    },
                    {
                        "label": "田家庵区",
                        "value": "田家庵区",
                        "code": "340403"
                    },
                    {
                        "label": "谢家集区",
                        "value": "谢家集区",
                        "code": "340404"
                    },
                    {
                        "label": "八公山区",
                        "value": "八公山区",
                        "code": "340405"
                    },
                    {
                        "label": "潘集区",
                        "value": "潘集区",
                        "code": "340406"
                    },
                    {
                        "label": "凤台县",
                        "value": "凤台县",
                        "code": "340421"
                    }
                ],
                "code": "340400"
            },
            {
                "label": "马鞍山市",
                "value": "马鞍山市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "340501"
                    },
                    {
                        "label": "花山区",
                        "value": "花山区",
                        "code": "340503"
                    },
                    {
                        "label": "雨山区",
                        "value": "雨山区",
                        "code": "340504"
                    },
                    {
                        "label": "博望区",
                        "value": "博望区",
                        "code": "340506"
                    },
                    {
                        "label": "当涂县",
                        "value": "当涂县",
                        "code": "340521"
                    },
                    {
                        "label": "含山县",
                        "value": "含山县",
                        "code": "340522"
                    },
                    {
                        "label": "和县",
                        "value": "和县",
                        "code": "340523"
                    }
                ],
                "code": "340500"
            },
            {
                "label": "淮北市",
                "value": "淮北市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "340601"
                    },
                    {
                        "label": "杜集区",
                        "value": "杜集区",
                        "code": "340602"
                    },
                    {
                        "label": "相山区",
                        "value": "相山区",
                        "code": "340603"
                    },
                    {
                        "label": "烈山区",
                        "value": "烈山区",
                        "code": "340604"
                    },
                    {
                        "label": "濉溪县",
                        "value": "濉溪县",
                        "code": "340621"
                    }
                ],
                "code": "340600"
            },
            {
                "label": "铜陵市",
                "value": "铜陵市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "340701"
                    },
                    {
                        "label": "铜官山区",
                        "value": "铜官山区",
                        "code": "340702"
                    },
                    {
                        "label": "狮子山区",
                        "value": "狮子山区",
                        "code": "340703"
                    },
                    {
                        "label": "郊区",
                        "value": "郊区",
                        "code": "340711"
                    },
                    {
                        "label": "铜陵县",
                        "value": "铜陵县",
                        "code": "340721"
                    }
                ],
                "code": "340700"
            },
            {
                "label": "安庆市",
                "value": "安庆市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "340801"
                    },
                    {
                        "label": "迎江区",
                        "value": "迎江区",
                        "code": "340802"
                    },
                    {
                        "label": "大观区",
                        "value": "大观区",
                        "code": "340803"
                    },
                    {
                        "label": "宜秀区",
                        "value": "宜秀区",
                        "code": "340811"
                    },
                    {
                        "label": "怀宁县",
                        "value": "怀宁县",
                        "code": "340822"
                    },
                    {
                        "label": "枞阳县",
                        "value": "枞阳县",
                        "code": "340823"
                    },
                    {
                        "label": "潜山县",
                        "value": "潜山县",
                        "code": "340824"
                    },
                    {
                        "label": "太湖县",
                        "value": "太湖县",
                        "code": "340825"
                    },
                    {
                        "label": "宿松县",
                        "value": "宿松县",
                        "code": "340826"
                    },
                    {
                        "label": "望江县",
                        "value": "望江县",
                        "code": "340827"
                    },
                    {
                        "label": "岳西县",
                        "value": "岳西县",
                        "code": "340828"
                    },
                    {
                        "label": "桐城市",
                        "value": "桐城市",
                        "code": "340881"
                    }
                ],
                "code": "340800"
            },
            {
                "label": "黄山市",
                "value": "黄山市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "341001"
                    },
                    {
                        "label": "屯溪区",
                        "value": "屯溪区",
                        "code": "341002"
                    },
                    {
                        "label": "黄山区",
                        "value": "黄山区",
                        "code": "341003"
                    },
                    {
                        "label": "徽州区",
                        "value": "徽州区",
                        "code": "341004"
                    },
                    {
                        "label": "歙县",
                        "value": "歙县",
                        "code": "341021"
                    },
                    {
                        "label": "休宁县",
                        "value": "休宁县",
                        "code": "341022"
                    },
                    {
                        "label": "黟县",
                        "value": "黟县",
                        "code": "341023"
                    },
                    {
                        "label": "祁门县",
                        "value": "祁门县",
                        "code": "341024"
                    }
                ],
                "code": "341000"
            },
            {
                "label": "滁州市",
                "value": "滁州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "341101"
                    },
                    {
                        "label": "琅琊区",
                        "value": "琅琊区",
                        "code": "341102"
                    },
                    {
                        "label": "南谯区",
                        "value": "南谯区",
                        "code": "341103"
                    },
                    {
                        "label": "来安县",
                        "value": "来安县",
                        "code": "341122"
                    },
                    {
                        "label": "全椒县",
                        "value": "全椒县",
                        "code": "341124"
                    },
                    {
                        "label": "定远县",
                        "value": "定远县",
                        "code": "341125"
                    },
                    {
                        "label": "凤阳县",
                        "value": "凤阳县",
                        "code": "341126"
                    },
                    {
                        "label": "天长市",
                        "value": "天长市",
                        "code": "341181"
                    },
                    {
                        "label": "明光市",
                        "value": "明光市",
                        "code": "341182"
                    }
                ],
                "code": "341100"
            },
            {
                "label": "阜阳市",
                "value": "阜阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "341201"
                    },
                    {
                        "label": "颍州区",
                        "value": "颍州区",
                        "code": "341202"
                    },
                    {
                        "label": "颍东区",
                        "value": "颍东区",
                        "code": "341203"
                    },
                    {
                        "label": "颍泉区",
                        "value": "颍泉区",
                        "code": "341204"
                    },
                    {
                        "label": "临泉县",
                        "value": "临泉县",
                        "code": "341221"
                    },
                    {
                        "label": "太和县",
                        "value": "太和县",
                        "code": "341222"
                    },
                    {
                        "label": "阜南县",
                        "value": "阜南县",
                        "code": "341225"
                    },
                    {
                        "label": "颍上县",
                        "value": "颍上县",
                        "code": "341226"
                    },
                    {
                        "label": "界首市",
                        "value": "界首市",
                        "code": "341282"
                    }
                ],
                "code": "341200"
            },
            {
                "label": "宿州市",
                "value": "宿州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "341301"
                    },
                    {
                        "label": "埇桥区",
                        "value": "埇桥区",
                        "code": "341302"
                    },
                    {
                        "label": "砀山县",
                        "value": "砀山县",
                        "code": "341321"
                    },
                    {
                        "label": "萧县",
                        "value": "萧县",
                        "code": "341322"
                    },
                    {
                        "label": "灵璧县",
                        "value": "灵璧县",
                        "code": "341323"
                    },
                    {
                        "label": "泗县",
                        "value": "泗县",
                        "code": "341324"
                    }
                ],
                "code": "341300"
            },
            {
                "label": "六安市",
                "value": "六安市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "341501"
                    },
                    {
                        "label": "金安区",
                        "value": "金安区",
                        "code": "341502"
                    },
                    {
                        "label": "裕安区",
                        "value": "裕安区",
                        "code": "341503"
                    },
                    {
                        "label": "寿县",
                        "value": "寿县",
                        "code": "341521"
                    },
                    {
                        "label": "霍邱县",
                        "value": "霍邱县",
                        "code": "341522"
                    },
                    {
                        "label": "舒城县",
                        "value": "舒城县",
                        "code": "341523"
                    },
                    {
                        "label": "金寨县",
                        "value": "金寨县",
                        "code": "341524"
                    },
                    {
                        "label": "霍山县",
                        "value": "霍山县",
                        "code": "341525"
                    }
                ],
                "code": "341500"
            },
            {
                "label": "亳州市",
                "value": "亳州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "341601"
                    },
                    {
                        "label": "谯城区",
                        "value": "谯城区",
                        "code": "341602"
                    },
                    {
                        "label": "涡阳县",
                        "value": "涡阳县",
                        "code": "341621"
                    },
                    {
                        "label": "蒙城县",
                        "value": "蒙城县",
                        "code": "341622"
                    },
                    {
                        "label": "利辛县",
                        "value": "利辛县",
                        "code": "341623"
                    }
                ],
                "code": "341600"
            },
            {
                "label": "池州市",
                "value": "池州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "341701"
                    },
                    {
                        "label": "贵池区",
                        "value": "贵池区",
                        "code": "341702"
                    },
                    {
                        "label": "东至县",
                        "value": "东至县",
                        "code": "341721"
                    },
                    {
                        "label": "石台县",
                        "value": "石台县",
                        "code": "341722"
                    },
                    {
                        "label": "青阳县",
                        "value": "青阳县",
                        "code": "341723"
                    }
                ],
                "code": "341700"
            },
            {
                "label": "宣城市",
                "value": "宣城市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "341801"
                    },
                    {
                        "label": "宣州区",
                        "value": "宣州区",
                        "code": "341802"
                    },
                    {
                        "label": "郎溪县",
                        "value": "郎溪县",
                        "code": "341821"
                    },
                    {
                        "label": "广德县",
                        "value": "广德县",
                        "code": "341822"
                    },
                    {
                        "label": "泾县",
                        "value": "泾县",
                        "code": "341823"
                    },
                    {
                        "label": "绩溪县",
                        "value": "绩溪县",
                        "code": "341824"
                    },
                    {
                        "label": "旌德县",
                        "value": "旌德县",
                        "code": "341825"
                    },
                    {
                        "label": "宁国市",
                        "value": "宁国市",
                        "code": "341881"
                    }
                ],
                "code": "341800"
            }
        ],
        "code": "340000"
    },
    {
        "label": "福建省",
        "value": "福建省",
        "children": [
            {
                "label": "福州市",
                "value": "福州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "350101"
                    },
                    {
                        "label": "鼓楼区",
                        "value": "鼓楼区",
                        "code": "350102"
                    },
                    {
                        "label": "台江区",
                        "value": "台江区",
                        "code": "350103"
                    },
                    {
                        "label": "仓山区",
                        "value": "仓山区",
                        "code": "350104"
                    },
                    {
                        "label": "马尾区",
                        "value": "马尾区",
                        "code": "350105"
                    },
                    {
                        "label": "晋安区",
                        "value": "晋安区",
                        "code": "350111"
                    },
                    {
                        "label": "闽侯县",
                        "value": "闽侯县",
                        "code": "350121"
                    },
                    {
                        "label": "连江县",
                        "value": "连江县",
                        "code": "350122"
                    },
                    {
                        "label": "罗源县",
                        "value": "罗源县",
                        "code": "350123"
                    },
                    {
                        "label": "闽清县",
                        "value": "闽清县",
                        "code": "350124"
                    },
                    {
                        "label": "永泰县",
                        "value": "永泰县",
                        "code": "350125"
                    },
                    {
                        "label": "平潭县",
                        "value": "平潭县",
                        "code": "350128"
                    },
                    {
                        "label": "福清市",
                        "value": "福清市",
                        "code": "350181"
                    },
                    {
                        "label": "长乐市",
                        "value": "长乐市",
                        "code": "350182"
                    }
                ],
                "code": "350100"
            },
            {
                "label": "厦门市",
                "value": "厦门市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "350201"
                    },
                    {
                        "label": "思明区",
                        "value": "思明区",
                        "code": "350203"
                    },
                    {
                        "label": "海沧区",
                        "value": "海沧区",
                        "code": "350205"
                    },
                    {
                        "label": "湖里区",
                        "value": "湖里区",
                        "code": "350206"
                    },
                    {
                        "label": "集美区",
                        "value": "集美区",
                        "code": "350211"
                    },
                    {
                        "label": "同安区",
                        "value": "同安区",
                        "code": "350212"
                    },
                    {
                        "label": "翔安区",
                        "value": "翔安区",
                        "code": "350213"
                    }
                ],
                "code": "350200"
            },
            {
                "label": "莆田市",
                "value": "莆田市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "350301"
                    },
                    {
                        "label": "城厢区",
                        "value": "城厢区",
                        "code": "350302"
                    },
                    {
                        "label": "涵江区",
                        "value": "涵江区",
                        "code": "350303"
                    },
                    {
                        "label": "荔城区",
                        "value": "荔城区",
                        "code": "350304"
                    },
                    {
                        "label": "秀屿区",
                        "value": "秀屿区",
                        "code": "350305"
                    },
                    {
                        "label": "仙游县",
                        "value": "仙游县",
                        "code": "350322"
                    }
                ],
                "code": "350300"
            },
            {
                "label": "三明市",
                "value": "三明市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "350401"
                    },
                    {
                        "label": "梅列区",
                        "value": "梅列区",
                        "code": "350402"
                    },
                    {
                        "label": "三元区",
                        "value": "三元区",
                        "code": "350403"
                    },
                    {
                        "label": "明溪县",
                        "value": "明溪县",
                        "code": "350421"
                    },
                    {
                        "label": "清流县",
                        "value": "清流县",
                        "code": "350423"
                    },
                    {
                        "label": "宁化县",
                        "value": "宁化县",
                        "code": "350424"
                    },
                    {
                        "label": "大田县",
                        "value": "大田县",
                        "code": "350425"
                    },
                    {
                        "label": "尤溪县",
                        "value": "尤溪县",
                        "code": "350426"
                    },
                    {
                        "label": "沙县",
                        "value": "沙县",
                        "code": "350427"
                    },
                    {
                        "label": "将乐县",
                        "value": "将乐县",
                        "code": "350428"
                    },
                    {
                        "label": "泰宁县",
                        "value": "泰宁县",
                        "code": "350429"
                    },
                    {
                        "label": "建宁县",
                        "value": "建宁县",
                        "code": "350430"
                    },
                    {
                        "label": "永安市",
                        "value": "永安市",
                        "code": "350481"
                    }
                ],
                "code": "350400"
            },
            {
                "label": "泉州市",
                "value": "泉州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "350501"
                    },
                    {
                        "label": "鲤城区",
                        "value": "鲤城区",
                        "code": "350502"
                    },
                    {
                        "label": "丰泽区",
                        "value": "丰泽区",
                        "code": "350503"
                    },
                    {
                        "label": "洛江区",
                        "value": "洛江区",
                        "code": "350504"
                    },
                    {
                        "label": "泉港区",
                        "value": "泉港区",
                        "code": "350505"
                    },
                    {
                        "label": "惠安县",
                        "value": "惠安县",
                        "code": "350521"
                    },
                    {
                        "label": "安溪县",
                        "value": "安溪县",
                        "code": "350524"
                    },
                    {
                        "label": "永春县",
                        "value": "永春县",
                        "code": "350525"
                    },
                    {
                        "label": "德化县",
                        "value": "德化县",
                        "code": "350526"
                    },
                    {
                        "label": "金门县",
                        "value": "金门县",
                        "code": "350527"
                    },
                    {
                        "label": "石狮市",
                        "value": "石狮市",
                        "code": "350581"
                    },
                    {
                        "label": "晋江市",
                        "value": "晋江市",
                        "code": "350582"
                    },
                    {
                        "label": "南安市",
                        "value": "南安市",
                        "code": "350583"
                    }
                ],
                "code": "350500"
            },
            {
                "label": "漳州市",
                "value": "漳州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "350601"
                    },
                    {
                        "label": "芗城区",
                        "value": "芗城区",
                        "code": "350602"
                    },
                    {
                        "label": "龙文区",
                        "value": "龙文区",
                        "code": "350603"
                    },
                    {
                        "label": "云霄县",
                        "value": "云霄县",
                        "code": "350622"
                    },
                    {
                        "label": "漳浦县",
                        "value": "漳浦县",
                        "code": "350623"
                    },
                    {
                        "label": "诏安县",
                        "value": "诏安县",
                        "code": "350624"
                    },
                    {
                        "label": "长泰县",
                        "value": "长泰县",
                        "code": "350625"
                    },
                    {
                        "label": "东山县",
                        "value": "东山县",
                        "code": "350626"
                    },
                    {
                        "label": "南靖县",
                        "value": "南靖县",
                        "code": "350627"
                    },
                    {
                        "label": "平和县",
                        "value": "平和县",
                        "code": "350628"
                    },
                    {
                        "label": "华安县",
                        "value": "华安县",
                        "code": "350629"
                    },
                    {
                        "label": "龙海市",
                        "value": "龙海市",
                        "code": "350681"
                    }
                ],
                "code": "350600"
            },
            {
                "label": "南平市",
                "value": "南平市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "350701"
                    },
                    {
                        "label": "延平区",
                        "value": "延平区",
                        "code": "350702"
                    },
                    {
                        "label": "建阳区",
                        "value": "建阳区",
                        "code": "350703"
                    },
                    {
                        "label": "顺昌县",
                        "value": "顺昌县",
                        "code": "350721"
                    },
                    {
                        "label": "浦城县",
                        "value": "浦城县",
                        "code": "350722"
                    },
                    {
                        "label": "光泽县",
                        "value": "光泽县",
                        "code": "350723"
                    },
                    {
                        "label": "松溪县",
                        "value": "松溪县",
                        "code": "350724"
                    },
                    {
                        "label": "政和县",
                        "value": "政和县",
                        "code": "350725"
                    },
                    {
                        "label": "邵武市",
                        "value": "邵武市",
                        "code": "350781"
                    },
                    {
                        "label": "武夷山市",
                        "value": "武夷山市",
                        "code": "350782"
                    },
                    {
                        "label": "建瓯市",
                        "value": "建瓯市",
                        "code": "350783"
                    }
                ],
                "code": "350700"
            },
            {
                "label": "龙岩市",
                "value": "龙岩市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "350801"
                    },
                    {
                        "label": "新罗区",
                        "value": "新罗区",
                        "code": "350802"
                    },
                    {
                        "label": "永定区",
                        "value": "永定区",
                        "code": "350803"
                    },
                    {
                        "label": "长汀县",
                        "value": "长汀县",
                        "code": "350821"
                    },
                    {
                        "label": "上杭县",
                        "value": "上杭县",
                        "code": "350823"
                    },
                    {
                        "label": "武平县",
                        "value": "武平县",
                        "code": "350824"
                    },
                    {
                        "label": "连城县",
                        "value": "连城县",
                        "code": "350825"
                    },
                    {
                        "label": "漳平市",
                        "value": "漳平市",
                        "code": "350881"
                    }
                ],
                "code": "350800"
            },
            {
                "label": "宁德市",
                "value": "宁德市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "350901"
                    },
                    {
                        "label": "蕉城区",
                        "value": "蕉城区",
                        "code": "350902"
                    },
                    {
                        "label": "霞浦县",
                        "value": "霞浦县",
                        "code": "350921"
                    },
                    {
                        "label": "古田县",
                        "value": "古田县",
                        "code": "350922"
                    },
                    {
                        "label": "屏南县",
                        "value": "屏南县",
                        "code": "350923"
                    },
                    {
                        "label": "寿宁县",
                        "value": "寿宁县",
                        "code": "350924"
                    },
                    {
                        "label": "周宁县",
                        "value": "周宁县",
                        "code": "350925"
                    },
                    {
                        "label": "柘荣县",
                        "value": "柘荣县",
                        "code": "350926"
                    },
                    {
                        "label": "福安市",
                        "value": "福安市",
                        "code": "350981"
                    },
                    {
                        "label": "福鼎市",
                        "value": "福鼎市",
                        "code": "350982"
                    }
                ],
                "code": "350900"
            }
        ],
        "code": "350000"
    },
    {
        "label": "江西省",
        "value": "江西省",
        "children": [
            {
                "label": "南昌市",
                "value": "南昌市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "360101"
                    },
                    {
                        "label": "东湖区",
                        "value": "东湖区",
                        "code": "360102"
                    },
                    {
                        "label": "西湖区",
                        "value": "西湖区",
                        "code": "360103"
                    },
                    {
                        "label": "青云谱区",
                        "value": "青云谱区",
                        "code": "360104"
                    },
                    {
                        "label": "湾里区",
                        "value": "湾里区",
                        "code": "360105"
                    },
                    {
                        "label": "青山湖区",
                        "value": "青山湖区",
                        "code": "360111"
                    },
                    {
                        "label": "南昌县",
                        "value": "南昌县",
                        "code": "360121"
                    },
                    {
                        "label": "新建县",
                        "value": "新建县",
                        "code": "360122"
                    },
                    {
                        "label": "安义县",
                        "value": "安义县",
                        "code": "360123"
                    },
                    {
                        "label": "进贤县",
                        "value": "进贤县",
                        "code": "360124"
                    }
                ],
                "code": "360100"
            },
            {
                "label": "景德镇市",
                "value": "景德镇市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "360201"
                    },
                    {
                        "label": "昌江区",
                        "value": "昌江区",
                        "code": "360202"
                    },
                    {
                        "label": "珠山区",
                        "value": "珠山区",
                        "code": "360203"
                    },
                    {
                        "label": "浮梁县",
                        "value": "浮梁县",
                        "code": "360222"
                    },
                    {
                        "label": "乐平市",
                        "value": "乐平市",
                        "code": "360281"
                    }
                ],
                "code": "360200"
            },
            {
                "label": "萍乡市",
                "value": "萍乡市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "360301"
                    },
                    {
                        "label": "安源区",
                        "value": "安源区",
                        "code": "360302"
                    },
                    {
                        "label": "湘东区",
                        "value": "湘东区",
                        "code": "360313"
                    },
                    {
                        "label": "莲花县",
                        "value": "莲花县",
                        "code": "360321"
                    },
                    {
                        "label": "上栗县",
                        "value": "上栗县",
                        "code": "360322"
                    },
                    {
                        "label": "芦溪县",
                        "value": "芦溪县",
                        "code": "360323"
                    }
                ],
                "code": "360300"
            },
            {
                "label": "九江市",
                "value": "九江市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "360401"
                    },
                    {
                        "label": "庐山区",
                        "value": "庐山区",
                        "code": "360402"
                    },
                    {
                        "label": "浔阳区",
                        "value": "浔阳区",
                        "code": "360403"
                    },
                    {
                        "label": "九江县",
                        "value": "九江县",
                        "code": "360421"
                    },
                    {
                        "label": "武宁县",
                        "value": "武宁县",
                        "code": "360423"
                    },
                    {
                        "label": "修水县",
                        "value": "修水县",
                        "code": "360424"
                    },
                    {
                        "label": "永修县",
                        "value": "永修县",
                        "code": "360425"
                    },
                    {
                        "label": "德安县",
                        "value": "德安县",
                        "code": "360426"
                    },
                    {
                        "label": "星子县",
                        "value": "星子县",
                        "code": "360427"
                    },
                    {
                        "label": "都昌县",
                        "value": "都昌县",
                        "code": "360428"
                    },
                    {
                        "label": "湖口县",
                        "value": "湖口县",
                        "code": "360429"
                    },
                    {
                        "label": "彭泽县",
                        "value": "彭泽县",
                        "code": "360430"
                    },
                    {
                        "label": "瑞昌市",
                        "value": "瑞昌市",
                        "code": "360481"
                    },
                    {
                        "label": "共青城市",
                        "value": "共青城市",
                        "code": "360482"
                    }
                ],
                "code": "360400"
            },
            {
                "label": "新余市",
                "value": "新余市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "360501"
                    },
                    {
                        "label": "渝水区",
                        "value": "渝水区",
                        "code": "360502"
                    },
                    {
                        "label": "分宜县",
                        "value": "分宜县",
                        "code": "360521"
                    }
                ],
                "code": "360500"
            },
            {
                "label": "鹰潭市",
                "value": "鹰潭市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "360601"
                    },
                    {
                        "label": "月湖区",
                        "value": "月湖区",
                        "code": "360602"
                    },
                    {
                        "label": "余江县",
                        "value": "余江县",
                        "code": "360622"
                    },
                    {
                        "label": "贵溪市",
                        "value": "贵溪市",
                        "code": "360681"
                    }
                ],
                "code": "360600"
            },
            {
                "label": "赣州市",
                "value": "赣州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "360701"
                    },
                    {
                        "label": "章贡区",
                        "value": "章贡区",
                        "code": "360702"
                    },
                    {
                        "label": "南康区",
                        "value": "南康区",
                        "code": "360703"
                    },
                    {
                        "label": "赣县",
                        "value": "赣县",
                        "code": "360721"
                    },
                    {
                        "label": "信丰县",
                        "value": "信丰县",
                        "code": "360722"
                    },
                    {
                        "label": "大余县",
                        "value": "大余县",
                        "code": "360723"
                    },
                    {
                        "label": "上犹县",
                        "value": "上犹县",
                        "code": "360724"
                    },
                    {
                        "label": "崇义县",
                        "value": "崇义县",
                        "code": "360725"
                    },
                    {
                        "label": "安远县",
                        "value": "安远县",
                        "code": "360726"
                    },
                    {
                        "label": "龙南县",
                        "value": "龙南县",
                        "code": "360727"
                    },
                    {
                        "label": "定南县",
                        "value": "定南县",
                        "code": "360728"
                    },
                    {
                        "label": "全南县",
                        "value": "全南县",
                        "code": "360729"
                    },
                    {
                        "label": "宁都县",
                        "value": "宁都县",
                        "code": "360730"
                    },
                    {
                        "label": "于都县",
                        "value": "于都县",
                        "code": "360731"
                    },
                    {
                        "label": "兴国县",
                        "value": "兴国县",
                        "code": "360732"
                    },
                    {
                        "label": "会昌县",
                        "value": "会昌县",
                        "code": "360733"
                    },
                    {
                        "label": "寻乌县",
                        "value": "寻乌县",
                        "code": "360734"
                    },
                    {
                        "label": "石城县",
                        "value": "石城县",
                        "code": "360735"
                    },
                    {
                        "label": "瑞金市",
                        "value": "瑞金市",
                        "code": "360781"
                    }
                ],
                "code": "360700"
            },
            {
                "label": "吉安市",
                "value": "吉安市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "360801"
                    },
                    {
                        "label": "吉州区",
                        "value": "吉州区",
                        "code": "360802"
                    },
                    {
                        "label": "青原区",
                        "value": "青原区",
                        "code": "360803"
                    },
                    {
                        "label": "吉安县",
                        "value": "吉安县",
                        "code": "360821"
                    },
                    {
                        "label": "吉水县",
                        "value": "吉水县",
                        "code": "360822"
                    },
                    {
                        "label": "峡江县",
                        "value": "峡江县",
                        "code": "360823"
                    },
                    {
                        "label": "新干县",
                        "value": "新干县",
                        "code": "360824"
                    },
                    {
                        "label": "永丰县",
                        "value": "永丰县",
                        "code": "360825"
                    },
                    {
                        "label": "泰和县",
                        "value": "泰和县",
                        "code": "360826"
                    },
                    {
                        "label": "遂川县",
                        "value": "遂川县",
                        "code": "360827"
                    },
                    {
                        "label": "万安县",
                        "value": "万安县",
                        "code": "360828"
                    },
                    {
                        "label": "安福县",
                        "value": "安福县",
                        "code": "360829"
                    },
                    {
                        "label": "永新县",
                        "value": "永新县",
                        "code": "360830"
                    },
                    {
                        "label": "井冈山市",
                        "value": "井冈山市",
                        "code": "360881"
                    }
                ],
                "code": "360800"
            },
            {
                "label": "宜春市",
                "value": "宜春市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "360901"
                    },
                    {
                        "label": "袁州区",
                        "value": "袁州区",
                        "code": "360902"
                    },
                    {
                        "label": "奉新县",
                        "value": "奉新县",
                        "code": "360921"
                    },
                    {
                        "label": "万载县",
                        "value": "万载县",
                        "code": "360922"
                    },
                    {
                        "label": "上高县",
                        "value": "上高县",
                        "code": "360923"
                    },
                    {
                        "label": "宜丰县",
                        "value": "宜丰县",
                        "code": "360924"
                    },
                    {
                        "label": "靖安县",
                        "value": "靖安县",
                        "code": "360925"
                    },
                    {
                        "label": "铜鼓县",
                        "value": "铜鼓县",
                        "code": "360926"
                    },
                    {
                        "label": "丰城市",
                        "value": "丰城市",
                        "code": "360981"
                    },
                    {
                        "label": "樟树市",
                        "value": "樟树市",
                        "code": "360982"
                    },
                    {
                        "label": "高安市",
                        "value": "高安市",
                        "code": "360983"
                    }
                ],
                "code": "360900"
            },
            {
                "label": "抚州市",
                "value": "抚州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "361001"
                    },
                    {
                        "label": "临川区",
                        "value": "临川区",
                        "code": "361002"
                    },
                    {
                        "label": "南城县",
                        "value": "南城县",
                        "code": "361021"
                    },
                    {
                        "label": "黎川县",
                        "value": "黎川县",
                        "code": "361022"
                    },
                    {
                        "label": "南丰县",
                        "value": "南丰县",
                        "code": "361023"
                    },
                    {
                        "label": "崇仁县",
                        "value": "崇仁县",
                        "code": "361024"
                    },
                    {
                        "label": "乐安县",
                        "value": "乐安县",
                        "code": "361025"
                    },
                    {
                        "label": "宜黄县",
                        "value": "宜黄县",
                        "code": "361026"
                    },
                    {
                        "label": "金溪县",
                        "value": "金溪县",
                        "code": "361027"
                    },
                    {
                        "label": "资溪县",
                        "value": "资溪县",
                        "code": "361028"
                    },
                    {
                        "label": "东乡县",
                        "value": "东乡县",
                        "code": "361029"
                    },
                    {
                        "label": "广昌县",
                        "value": "广昌县",
                        "code": "361030"
                    }
                ],
                "code": "361000"
            },
            {
                "label": "上饶市",
                "value": "上饶市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "361101"
                    },
                    {
                        "label": "信州区",
                        "value": "信州区",
                        "code": "361102"
                    },
                    {
                        "label": "上饶县",
                        "value": "上饶县",
                        "code": "361121"
                    },
                    {
                        "label": "广丰县",
                        "value": "广丰县",
                        "code": "361122"
                    },
                    {
                        "label": "玉山县",
                        "value": "玉山县",
                        "code": "361123"
                    },
                    {
                        "label": "铅山县",
                        "value": "铅山县",
                        "code": "361124"
                    },
                    {
                        "label": "横峰县",
                        "value": "横峰县",
                        "code": "361125"
                    },
                    {
                        "label": "弋阳县",
                        "value": "弋阳县",
                        "code": "361126"
                    },
                    {
                        "label": "余干县",
                        "value": "余干县",
                        "code": "361127"
                    },
                    {
                        "label": "鄱阳县",
                        "value": "鄱阳县",
                        "code": "361128"
                    },
                    {
                        "label": "万年县",
                        "value": "万年县",
                        "code": "361129"
                    },
                    {
                        "label": "婺源县",
                        "value": "婺源县",
                        "code": "361130"
                    },
                    {
                        "label": "德兴市",
                        "value": "德兴市",
                        "code": "361181"
                    }
                ],
                "code": "361100"
            }
        ],
        "code": "360000"
    },
    {
        "label": "山东省",
        "value": "山东省",
        "children": [
            {
                "label": "济南市",
                "value": "济南市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "370101"
                    },
                    {
                        "label": "历下区",
                        "value": "历下区",
                        "code": "370102"
                    },
                    {
                        "label": "市中区",
                        "value": "市中区",
                        "code": "370103"
                    },
                    {
                        "label": "槐荫区",
                        "value": "槐荫区",
                        "code": "370104"
                    },
                    {
                        "label": "天桥区",
                        "value": "天桥区",
                        "code": "370105"
                    },
                    {
                        "label": "历城区",
                        "value": "历城区",
                        "code": "370112"
                    },
                    {
                        "label": "长清区",
                        "value": "长清区",
                        "code": "370113"
                    },
                    {
                        "label": "平阴县",
                        "value": "平阴县",
                        "code": "370124"
                    },
                    {
                        "label": "济阳县",
                        "value": "济阳县",
                        "code": "370125"
                    },
                    {
                        "label": "商河县",
                        "value": "商河县",
                        "code": "370126"
                    },
                    {
                        "label": "章丘市",
                        "value": "章丘市",
                        "code": "370181"
                    }
                ],
                "code": "370100"
            },
            {
                "label": "青岛市",
                "value": "青岛市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "370201"
                    },
                    {
                        "label": "市南区",
                        "value": "市南区",
                        "code": "370202"
                    },
                    {
                        "label": "市北区",
                        "value": "市北区",
                        "code": "370203"
                    },
                    {
                        "label": "黄岛区",
                        "value": "黄岛区",
                        "code": "370211"
                    },
                    {
                        "label": "崂山区",
                        "value": "崂山区",
                        "code": "370212"
                    },
                    {
                        "label": "李沧区",
                        "value": "李沧区",
                        "code": "370213"
                    },
                    {
                        "label": "城阳区",
                        "value": "城阳区",
                        "code": "370214"
                    },
                    {
                        "label": "胶州市",
                        "value": "胶州市",
                        "code": "370281"
                    },
                    {
                        "label": "即墨市",
                        "value": "即墨市",
                        "code": "370282"
                    },
                    {
                        "label": "平度市",
                        "value": "平度市",
                        "code": "370283"
                    },
                    {
                        "label": "莱西市",
                        "value": "莱西市",
                        "code": "370285"
                    }
                ],
                "code": "370200"
            },
            {
                "label": "淄博市",
                "value": "淄博市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "370301"
                    },
                    {
                        "label": "淄川区",
                        "value": "淄川区",
                        "code": "370302"
                    },
                    {
                        "label": "张店区",
                        "value": "张店区",
                        "code": "370303"
                    },
                    {
                        "label": "博山区",
                        "value": "博山区",
                        "code": "370304"
                    },
                    {
                        "label": "临淄区",
                        "value": "临淄区",
                        "code": "370305"
                    },
                    {
                        "label": "周村区",
                        "value": "周村区",
                        "code": "370306"
                    },
                    {
                        "label": "桓台县",
                        "value": "桓台县",
                        "code": "370321"
                    },
                    {
                        "label": "高青县",
                        "value": "高青县",
                        "code": "370322"
                    },
                    {
                        "label": "沂源县",
                        "value": "沂源县",
                        "code": "370323"
                    }
                ],
                "code": "370300"
            },
            {
                "label": "枣庄市",
                "value": "枣庄市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "370401"
                    },
                    {
                        "label": "市中区",
                        "value": "市中区",
                        "code": "370402"
                    },
                    {
                        "label": "薛城区",
                        "value": "薛城区",
                        "code": "370403"
                    },
                    {
                        "label": "峄城区",
                        "value": "峄城区",
                        "code": "370404"
                    },
                    {
                        "label": "台儿庄区",
                        "value": "台儿庄区",
                        "code": "370405"
                    },
                    {
                        "label": "山亭区",
                        "value": "山亭区",
                        "code": "370406"
                    },
                    {
                        "label": "滕州市",
                        "value": "滕州市",
                        "code": "370481"
                    }
                ],
                "code": "370400"
            },
            {
                "label": "东营市",
                "value": "东营市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "370501"
                    },
                    {
                        "label": "东营区",
                        "value": "东营区",
                        "code": "370502"
                    },
                    {
                        "label": "河口区",
                        "value": "河口区",
                        "code": "370503"
                    },
                    {
                        "label": "垦利县",
                        "value": "垦利县",
                        "code": "370521"
                    },
                    {
                        "label": "利津县",
                        "value": "利津县",
                        "code": "370522"
                    },
                    {
                        "label": "广饶县",
                        "value": "广饶县",
                        "code": "370523"
                    }
                ],
                "code": "370500"
            },
            {
                "label": "烟台市",
                "value": "烟台市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "370601"
                    },
                    {
                        "label": "芝罘区",
                        "value": "芝罘区",
                        "code": "370602"
                    },
                    {
                        "label": "福山区",
                        "value": "福山区",
                        "code": "370611"
                    },
                    {
                        "label": "牟平区",
                        "value": "牟平区",
                        "code": "370612"
                    },
                    {
                        "label": "莱山区",
                        "value": "莱山区",
                        "code": "370613"
                    },
                    {
                        "label": "长岛县",
                        "value": "长岛县",
                        "code": "370634"
                    },
                    {
                        "label": "龙口市",
                        "value": "龙口市",
                        "code": "370681"
                    },
                    {
                        "label": "莱阳市",
                        "value": "莱阳市",
                        "code": "370682"
                    },
                    {
                        "label": "莱州市",
                        "value": "莱州市",
                        "code": "370683"
                    },
                    {
                        "label": "蓬莱市",
                        "value": "蓬莱市",
                        "code": "370684"
                    },
                    {
                        "label": "招远市",
                        "value": "招远市",
                        "code": "370685"
                    },
                    {
                        "label": "栖霞市",
                        "value": "栖霞市",
                        "code": "370686"
                    },
                    {
                        "label": "海阳市",
                        "value": "海阳市",
                        "code": "370687"
                    }
                ],
                "code": "370600"
            },
            {
                "label": "潍坊市",
                "value": "潍坊市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "370701"
                    },
                    {
                        "label": "潍城区",
                        "value": "潍城区",
                        "code": "370702"
                    },
                    {
                        "label": "寒亭区",
                        "value": "寒亭区",
                        "code": "370703"
                    },
                    {
                        "label": "坊子区",
                        "value": "坊子区",
                        "code": "370704"
                    },
                    {
                        "label": "奎文区",
                        "value": "奎文区",
                        "code": "370705"
                    },
                    {
                        "label": "临朐县",
                        "value": "临朐县",
                        "code": "370724"
                    },
                    {
                        "label": "昌乐县",
                        "value": "昌乐县",
                        "code": "370725"
                    },
                    {
                        "label": "青州市",
                        "value": "青州市",
                        "code": "370781"
                    },
                    {
                        "label": "诸城市",
                        "value": "诸城市",
                        "code": "370782"
                    },
                    {
                        "label": "寿光市",
                        "value": "寿光市",
                        "code": "370783"
                    },
                    {
                        "label": "安丘市",
                        "value": "安丘市",
                        "code": "370784"
                    },
                    {
                        "label": "高密市",
                        "value": "高密市",
                        "code": "370785"
                    },
                    {
                        "label": "昌邑市",
                        "value": "昌邑市",
                        "code": "370786"
                    }
                ],
                "code": "370700"
            },
            {
                "label": "济宁 市",
                "value": "济宁市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "370801"
                    },
                    {
                        "label": "任城区",
                        "value": "任城区",
                        "code": "370811"
                    },
                    {
                        "label": "兖州区",
                        "value": "兖州区",
                        "code": "370812"
                    },
                    {
                        "label": "微山县",
                        "value": "微山县",
                        "code": "370826"
                    },
                    {
                        "label": "鱼台县",
                        "value": "鱼台县",
                        "code": "370827"
                    },
                    {
                        "label": "金乡县",
                        "value": "金乡县",
                        "code": "370828"
                    },
                    {
                        "label": "嘉祥县",
                        "value": "嘉祥县",
                        "code": "370829"
                    },
                    {
                        "label": "汶上县",
                        "value": "汶上县",
                        "code": "370830"
                    },
                    {
                        "label": "泗水县",
                        "value": "泗水县",
                        "code": "370831"
                    },
                    {
                        "label": "梁山县",
                        "value": "梁山县",
                        "code": "370832"
                    },
                    {
                        "label": "曲阜市",
                        "value": "曲阜市",
                        "code": "370881"
                    },
                    {
                        "label": "邹城市",
                        "value": "邹城市",
                        "code": "370883"
                    }
                ],
                "code": "370800"
            },
            {
                "label": "泰安市",
                "value": "泰安市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "370901"
                    },
                    {
                        "label": "泰山区",
                        "value": "泰山区",
                        "code": "370902"
                    },
                    {
                        "label": "岱岳区",
                        "value": "岱岳区",
                        "code": "370911"
                    },
                    {
                        "label": "宁阳县",
                        "value": "宁阳县",
                        "code": "370921"
                    },
                    {
                        "label": "东平县",
                        "value": "东平县",
                        "code": "370923"
                    },
                    {
                        "label": "新泰市",
                        "value": "新泰市",
                        "code": "370982"
                    },
                    {
                        "label": "肥城市",
                        "value": "肥城市",
                        "code": "370983"
                    }
                ],
                "code": "370900"
            },
            {
                "label": "威海市",
                "value": "威海市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "371001"
                    },
                    {
                        "label": "环翠区",
                        "value": "环翠区",
                        "code": "371002"
                    },
                    {
                        "label": "文登市",
                        "value": "文登市",
                        "code": "371081"
                    },
                    {
                        "label": "荣成市",
                        "value": "荣成市",
                        "code": "371082"
                    },
                    {
                        "label": "乳山市",
                        "value": "乳山市",
                        "code": "371083"
                    }
                ],
                "code": "371000"
            },
            {
                "label": "日照市",
                "value": "日照市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "371101"
                    },
                    {
                        "label": "东港区",
                        "value": "东港区",
                        "code": "371102"
                    },
                    {
                        "label": "岚山区",
                        "value": "岚山区",
                        "code": "371103"
                    },
                    {
                        "label": "五莲县",
                        "value": "五莲县",
                        "code": "371121"
                    },
                    {
                        "label": "莒县",
                        "value": "莒县",
                        "code": "371122"
                    }
                ],
                "code": "371100"
            },
            {
                "label": "莱芜市",
                "value": "莱芜市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "371201"
                    },
                    {
                        "label": "莱城区",
                        "value": "莱城区",
                        "code": "371202"
                    },
                    {
                        "label": "钢城区",
                        "value": "钢城区",
                        "code": "371203"
                    }
                ],
                "code": "371200"
            },
            {
                "label": "临沂市",
                "value": "临沂市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "371301"
                    },
                    {
                        "label": "兰山区",
                        "value": "兰山区",
                        "code": "371302"
                    },
                    {
                        "label": "罗庄区",
                        "value": "罗庄区",
                        "code": "371311"
                    },
                    {
                        "label": "河东区",
                        "value": "河东区",
                        "code": "371312"
                    },
                    {
                        "label": "沂南县",
                        "value": "沂南县",
                        "code": "371321"
                    },
                    {
                        "label": "郯城县",
                        "value": "郯城县",
                        "code": "371322"
                    },
                    {
                        "label": "沂水县",
                        "value": "沂水县",
                        "code": "371323"
                    },
                    {
                        "label": "兰陵县",
                        "value": "兰陵县",
                        "code": "371324"
                    },
                    {
                        "label": "费县",
                        "value": "费县",
                        "code": "371325"
                    },
                    {
                        "label": "平邑县",
                        "value": "平邑县",
                        "code": "371326"
                    },
                    {
                        "label": "莒南县",
                        "value": "莒南县",
                        "code": "371327"
                    },
                    {
                        "label": "蒙阴县",
                        "value": "蒙阴县",
                        "code": "371328"
                    },
                    {
                        "label": "临沭县",
                        "value": "临沭县",
                        "code": "371329"
                    }
                ],
                "code": "371300"
            },
            {
                "label": "德州市",
                "value": "德州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "371401"
                    },
                    {
                        "label": "德城区",
                        "value": "德城区",
                        "code": "371402"
                    },
                    {
                        "label": "陵城区",
                        "value": "陵城区",
                        "code": "371403"
                    },
                    {
                        "label": "宁津县",
                        "value": "宁津县",
                        "code": "371422"
                    },
                    {
                        "label": "庆云县",
                        "value": "庆云县",
                        "code": "371423"
                    },
                    {
                        "label": "临邑县",
                        "value": "临邑县",
                        "code": "371424"
                    },
                    {
                        "label": "齐河县",
                        "value": "齐河县",
                        "code": "371425"
                    },
                    {
                        "label": "平原县",
                        "value": "平原县",
                        "code": "371426"
                    },
                    {
                        "label": "夏津县",
                        "value": "夏津县",
                        "code": "371427"
                    },
                    {
                        "label": "武城县",
                        "value": "武城县",
                        "code": "371428"
                    },
                    {
                        "label": "乐陵市",
                        "value": "乐陵市",
                        "code": "371481"
                    },
                    {
                        "label": "禹城市",
                        "value": "禹城市",
                        "code": "371482"
                    }
                ],
                "code": "371400"
            },
            {
                "label": "聊城市",
                "value": "聊城市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "371501"
                    },
                    {
                        "label": "东昌府区",
                        "value": "东昌府区",
                        "code": "371502"
                    },
                    {
                        "label": "阳谷县",
                        "value": "阳谷县",
                        "code": "371521"
                    },
                    {
                        "label": "莘县",
                        "value": "莘县",
                        "code": "371522"
                    },
                    {
                        "label": "茌平县",
                        "value": "茌平县",
                        "code": "371523"
                    },
                    {
                        "label": "东阿县",
                        "value": "东阿县",
                        "code": "371524"
                    },
                    {
                        "label": "冠县",
                        "value": "冠县",
                        "code": "371525"
                    },
                    {
                        "label": "高唐县",
                        "value": "高唐县",
                        "code": "371526"
                    },
                    {
                        "label": "临清市",
                        "value": "临清市",
                        "code": "371581"
                    }
                ],
                "code": "371500"
            },
            {
                "label": "滨州市",
                "value": "滨州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "371601"
                    },
                    {
                        "label": "滨城区",
                        "value": "滨城区",
                        "code": "371602"
                    },
                    {
                        "label": "沾化区",
                        "value": "沾化区",
                        "code": "371603"
                    },
                    {
                        "label": "惠民县",
                        "value": "惠民县",
                        "code": "371621"
                    },
                    {
                        "label": "阳信县",
                        "value": "阳信县",
                        "code": "371622"
                    },
                    {
                        "label": "无棣县",
                        "value": "无棣县",
                        "code": "371623"
                    },
                    {
                        "label": "博兴县",
                        "value": "博兴县",
                        "code": "371625"
                    },
                    {
                        "label": "邹平县",
                        "value": "邹平县",
                        "code": "371626"
                    }
                ],
                "code": "371600"
            },
            {
                "label": "菏泽市",
                "value": "菏泽市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "371701"
                    },
                    {
                        "label": "牡丹区",
                        "value": "牡丹区",
                        "code": "371702"
                    },
                    {
                        "label": "曹县",
                        "value": "曹县",
                        "code": "371721"
                    },
                    {
                        "label": "单县",
                        "value": "单县",
                        "code": "371722"
                    },
                    {
                        "label": "成武县",
                        "value": "成武县",
                        "code": "371723"
                    },
                    {
                        "label": "巨野县",
                        "value": "巨野县",
                        "code": "371724"
                    },
                    {
                        "label": "郓城县",
                        "value": "郓城县",
                        "code": "371725"
                    },
                    {
                        "label": "鄄城县",
                        "value": "鄄城县",
                        "code": "371726"
                    },
                    {
                        "label": "定陶县",
                        "value": "定陶县",
                        "code": "371727"
                    },
                    {
                        "label": "东明县",
                        "value": "东明县",
                        "code": "371728"
                    }
                ],
                "code": "371700"
            }
        ],
        "code": "370000"
    },
    {
        "label": "河南省",
        "value": "河南省",
        "children": [
            {
                "label": "郑州市",
                "value": "郑州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "410101"
                    },
                    {
                        "label": "中原区",
                        "value": "中原区",
                        "code": "410102"
                    },
                    {
                        "label": "二七区",
                        "value": "二七区",
                        "code": "410103"
                    },
                    {
                        "label": "管城回族区",
                        "value": "管城回族区",
                        "code": "410104"
                    },
                    {
                        "label": "金水区",
                        "value": "金水区",
                        "code": "410105"
                    },
                    {
                        "label": "上街区",
                        "value": "上街区",
                        "code": "410106"
                    },
                    {
                        "label": "惠济区",
                        "value": "惠济区",
                        "code": "410108"
                    },
                    {
                        "label": "中牟县",
                        "value": "中牟县",
                        "code": "410122"
                    },
                    {
                        "label": "巩义市",
                        "value": "巩义市",
                        "code": "410181"
                    },
                    {
                        "label": "荥阳市",
                        "value": "荥阳市",
                        "code": "410182"
                    },
                    {
                        "label": "新密市",
                        "value": "新密市",
                        "code": "410183"
                    },
                    {
                        "label": "新郑市",
                        "value": "新郑市",
                        "code": "410184"
                    },
                    {
                        "label": "登封市",
                        "value": "登封市",
                        "code": "410185"
                    }
                ],
                "code": "410100"
            },
            {
                "label": "开封市",
                "value": "开封市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "410201"
                    },
                    {
                        "label": "龙亭区",
                        "value": "龙亭区",
                        "code": "410202"
                    },
                    {
                        "label": "顺河回族区",
                        "value": "顺河回族区",
                        "code": "410203"
                    },
                    {
                        "label": "鼓楼区",
                        "value": "鼓楼区",
                        "code": "410204"
                    },
                    {
                        "label": "禹王台区",
                        "value": "禹王台区",
                        "code": "410205"
                    },
                    {
                        "label": "祥符区",
                        "value": "祥符区",
                        "code": "410212"
                    },
                    {
                        "label": "杞县",
                        "value": "杞县",
                        "code": "410221"
                    },
                    {
                        "label": "通许县",
                        "value": "通许县",
                        "code": "410222"
                    },
                    {
                        "label": "尉氏县",
                        "value": "尉氏县",
                        "code": "410223"
                    },
                    {
                        "label": "兰考县",
                        "value": "兰考县",
                        "code": "410225"
                    }
                ],
                "code": "410200"
            },
            {
                "label": "洛阳市",
                "value": "洛阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "410301"
                    },
                    {
                        "label": "老城区",
                        "value": "老城区",
                        "code": "410302"
                    },
                    {
                        "label": "西工区",
                        "value": "西工区",
                        "code": "410303"
                    },
                    {
                        "label": "瀍河回族区",
                        "value": "瀍河回族区",
                        "code": "410304"
                    },
                    {
                        "label": "涧西区",
                        "value": "涧西区",
                        "code": "410305"
                    },
                    {
                        "label": "吉利区",
                        "value": "吉利区",
                        "code": "410306"
                    },
                    {
                        "label": "洛龙区",
                        "value": "洛龙区",
                        "code": "410311"
                    },
                    {
                        "label": "孟津县",
                        "value": "孟津县",
                        "code": "410322"
                    },
                    {
                        "label": "新安县",
                        "value": "新安县",
                        "code": "410323"
                    },
                    {
                        "label": "栾川县",
                        "value": "栾川县",
                        "code": "410324"
                    },
                    {
                        "label": "嵩县",
                        "value": "嵩县",
                        "code": "410325"
                    },
                    {
                        "label": "汝阳县",
                        "value": "汝阳县",
                        "code": "410326"
                    },
                    {
                        "label": "宜阳县",
                        "value": "宜阳县",
                        "code": "410327"
                    },
                    {
                        "label": "洛宁县",
                        "value": "洛宁县",
                        "code": "410328"
                    },
                    {
                        "label": "伊川县",
                        "value": "伊川县",
                        "code": "410329"
                    },
                    {
                        "label": "偃师市",
                        "value": "偃师市",
                        "code": "410381"
                    }
                ],
                "code": "410300"
            },
            {
                "label": "平顶山市",
                "value": "平顶山市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "410401"
                    },
                    {
                        "label": "新华区",
                        "value": "新华区",
                        "code": "410402"
                    },
                    {
                        "label": "卫东区",
                        "value": "卫东区",
                        "code": "410403"
                    },
                    {
                        "label": "石龙区",
                        "value": "石龙区",
                        "code": "410404"
                    },
                    {
                        "label": "湛河区",
                        "value": "湛河区",
                        "code": "410411"
                    },
                    {
                        "label": "宝丰县",
                        "value": "宝丰县",
                        "code": "410421"
                    },
                    {
                        "label": "叶县",
                        "value": "叶县",
                        "code": "410422"
                    },
                    {
                        "label": "鲁山县",
                        "value": "鲁山县",
                        "code": "410423"
                    },
                    {
                        "label": "郏县",
                        "value": "郏县",
                        "code": "410425"
                    },
                    {
                        "label": "舞钢市",
                        "value": "舞钢市",
                        "code": "410481"
                    },
                    {
                        "label": "汝州市",
                        "value": "汝州市",
                        "code": "410482"
                    }
                ],
                "code": "410400"
            },
            {
                "label": "安阳市",
                "value": "安阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "410501"
                    },
                    {
                        "label": "文峰区",
                        "value": "文峰区",
                        "code": "410502"
                    },
                    {
                        "label": "北关区",
                        "value": "北关区",
                        "code": "410503"
                    },
                    {
                        "label": "殷都区",
                        "value": "殷都区",
                        "code": "410505"
                    },
                    {
                        "label": "龙安区",
                        "value": "龙安区",
                        "code": "410506"
                    },
                    {
                        "label": "安阳县",
                        "value": "安阳县",
                        "code": "410522"
                    },
                    {
                        "label": "汤阴县",
                        "value": "汤阴县",
                        "code": "410523"
                    },
                    {
                        "label": "滑县",
                        "value": "滑县",
                        "code": "410526"
                    },
                    {
                        "label": "内黄县",
                        "value": "内黄县",
                        "code": "410527"
                    },
                    {
                        "label": "林州市",
                        "value": "林州市",
                        "code": "410581"
                    }
                ],
                "code": "410500"
            },
            {
                "label": "鹤壁市",
                "value": "鹤壁市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "410601"
                    },
                    {
                        "label": "鹤山区",
                        "value": "鹤山区",
                        "code": "410602"
                    },
                    {
                        "label": "山城区",
                        "value": "山城区",
                        "code": "410603"
                    },
                    {
                        "label": "淇滨区",
                        "value": "淇滨区",
                        "code": "410611"
                    },
                    {
                        "label": "浚县",
                        "value": "浚县",
                        "code": "410621"
                    },
                    {
                        "label": "淇县",
                        "value": "淇县",
                        "code": "410622"
                    }
                ],
                "code": "410600"
            },
            {
                "label": "新乡市",
                "value": "新乡市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "410701"
                    },
                    {
                        "label": "红旗区",
                        "value": "红旗区",
                        "code": "410702"
                    },
                    {
                        "label": "卫滨区",
                        "value": "卫滨区",
                        "code": "410703"
                    },
                    {
                        "label": "凤泉区",
                        "value": "凤泉区",
                        "code": "410704"
                    },
                    {
                        "label": "牧野区",
                        "value": "牧野区",
                        "code": "410711"
                    },
                    {
                        "label": "新乡县",
                        "value": "新乡县",
                        "code": "410721"
                    },
                    {
                        "label": "获嘉县",
                        "value": "获嘉县",
                        "code": "410724"
                    },
                    {
                        "label": "原阳县",
                        "value": "原阳县",
                        "code": "410725"
                    },
                    {
                        "label": "延津县",
                        "value": "延津县",
                        "code": "410726"
                    },
                    {
                        "label": "封丘县",
                        "value": "封丘县",
                        "code": "410727"
                    },
                    {
                        "label": "长垣县",
                        "value": "长垣县",
                        "code": "410728"
                    },
                    {
                        "label": "卫辉市",
                        "value": "卫辉市",
                        "code": "410781"
                    },
                    {
                        "label": "辉县市",
                        "value": "辉县市",
                        "code": "410782"
                    }
                ],
                "code": "410700"
            },
            {
                "label": "焦作市",
                "value": "焦作市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "410801"
                    },
                    {
                        "label": "解放区",
                        "value": "解放区",
                        "code": "410802"
                    },
                    {
                        "label": "中站区",
                        "value": "中站区",
                        "code": "410803"
                    },
                    {
                        "label": "马村区",
                        "value": "马村区",
                        "code": "410804"
                    },
                    {
                        "label": "山阳区",
                        "value": "山阳区",
                        "code": "410811"
                    },
                    {
                        "label": "修武县",
                        "value": "修武县",
                        "code": "410821"
                    },
                    {
                        "label": "博爱县",
                        "value": "博爱县",
                        "code": "410822"
                    },
                    {
                        "label": "武陟县",
                        "value": "武陟县",
                        "code": "410823"
                    },
                    {
                        "label": "温县",
                        "value": "温县",
                        "code": "410825"
                    },
                    {
                        "label": "沁阳市",
                        "value": "沁阳市",
                        "code": "410882"
                    },
                    {
                        "label": "孟州市",
                        "value": "孟州市",
                        "code": "410883"
                    }
                ],
                "code": "410800"
            },
            {
                "label": "濮阳市",
                "value": "濮阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "410901"
                    },
                    {
                        "label": "华龙区",
                        "value": "华龙区",
                        "code": "410902"
                    },
                    {
                        "label": "清丰县",
                        "value": "清丰县",
                        "code": "410922"
                    },
                    {
                        "label": "南乐县",
                        "value": "南乐县",
                        "code": "410923"
                    },
                    {
                        "label": "范县",
                        "value": "范县",
                        "code": "410926"
                    },
                    {
                        "label": "台前县",
                        "value": "台前县",
                        "code": "410927"
                    },
                    {
                        "label": "濮阳县",
                        "value": "濮阳县",
                        "code": "410928"
                    }
                ],
                "code": "410900"
            },
            {
                "label": "许昌市",
                "value": "许昌市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "411001"
                    },
                    {
                        "label": "魏都区",
                        "value": "魏都区",
                        "code": "411002"
                    },
                    {
                        "label": "许昌县",
                        "value": "许昌县",
                        "code": "411023"
                    },
                    {
                        "label": "鄢陵县",
                        "value": "鄢陵县",
                        "code": "411024"
                    },
                    {
                        "label": "襄城县",
                        "value": "襄城县",
                        "code": "411025"
                    },
                    {
                        "label": "禹州市",
                        "value": "禹州市",
                        "code": "411081"
                    },
                    {
                        "label": "长葛市",
                        "value": "长葛市",
                        "code": "411082"
                    }
                ],
                "code": "411000"
            },
            {
                "label": "漯河市",
                "value": "漯河市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "411101"
                    },
                    {
                        "label": "源汇区",
                        "value": "源汇区",
                        "code": "411102"
                    },
                    {
                        "label": "郾城区",
                        "value": "郾城区",
                        "code": "411103"
                    },
                    {
                        "label": "召陵区",
                        "value": "召陵区",
                        "code": "411104"
                    },
                    {
                        "label": "舞阳县",
                        "value": "舞阳县",
                        "code": "411121"
                    },
                    {
                        "label": "临颍县",
                        "value": "临颍县",
                        "code": "411122"
                    }
                ],
                "code": "411100"
            },
            {
                "label": "三门峡市",
                "value": "三门峡市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "411201"
                    },
                    {
                        "label": "湖滨区",
                        "value": "湖滨区",
                        "code": "411202"
                    },
                    {
                        "label": "渑池县",
                        "value": "渑池县",
                        "code": "411221"
                    },
                    {
                        "label": "陕县",
                        "value": "陕县",
                        "code": "411222"
                    },
                    {
                        "label": "卢氏县",
                        "value": "卢氏县",
                        "code": "411224"
                    },
                    {
                        "label": "义马市",
                        "value": "义马市",
                        "code": "411281"
                    },
                    {
                        "label": "灵宝市",
                        "value": "灵宝市",
                        "code": "411282"
                    }
                ],
                "code": "411200"
            },
            {
                "label": "南阳市",
                "value": "南阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "411301"
                    },
                    {
                        "label": "宛城区",
                        "value": "宛城区",
                        "code": "411302"
                    },
                    {
                        "label": "卧龙区",
                        "value": "卧龙区",
                        "code": "411303"
                    },
                    {
                        "label": "南召县",
                        "value": "南召县",
                        "code": "411321"
                    },
                    {
                        "label": "方城县",
                        "value": "方城县",
                        "code": "411322"
                    },
                    {
                        "label": "西峡县",
                        "value": "西峡县",
                        "code": "411323"
                    },
                    {
                        "label": "镇平县",
                        "value": "镇平县",
                        "code": "411324"
                    },
                    {
                        "label": "内乡县",
                        "value": "内乡县",
                        "code": "411325"
                    },
                    {
                        "label": "淅川县",
                        "value": "淅川县",
                        "code": "411326"
                    },
                    {
                        "label": "社旗县",
                        "value": "社旗县",
                        "code": "411327"
                    },
                    {
                        "label": "唐河县",
                        "value": "唐河县",
                        "code": "411328"
                    },
                    {
                        "label": "新野县",
                        "value": "新野县",
                        "code": "411329"
                    },
                    {
                        "label": "桐柏县",
                        "value": "桐柏县",
                        "code": "411330"
                    },
                    {
                        "label": "邓州市",
                        "value": "邓州市",
                        "code": "411381"
                    }
                ],
                "code": "411300"
            },
            {
                "label": "商丘市",
                "value": "商丘市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "411401"
                    },
                    {
                        "label": "梁园区",
                        "value": "梁园区",
                        "code": "411402"
                    },
                    {
                        "label": "睢阳区",
                        "value": "睢阳区",
                        "code": "411403"
                    },
                    {
                        "label": "民权县",
                        "value": "民权县",
                        "code": "411421"
                    },
                    {
                        "label": "睢县",
                        "value": "睢县",
                        "code": "411422"
                    },
                    {
                        "label": "宁陵县",
                        "value": "宁陵县",
                        "code": "411423"
                    },
                    {
                        "label": "柘城县",
                        "value": "柘城县",
                        "code": "411424"
                    },
                    {
                        "label": "虞城县",
                        "value": "虞城县",
                        "code": "411425"
                    },
                    {
                        "label": "夏邑县",
                        "value": "夏邑县",
                        "code": "411426"
                    },
                    {
                        "label": "永城市",
                        "value": "永城市",
                        "code": "411481"
                    }
                ],
                "code": "411400"
            },
            {
                "label": "信阳市",
                "value": "信阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "411501"
                    },
                    {
                        "label": "浉河区",
                        "value": "浉河区",
                        "code": "411502"
                    },
                    {
                        "label": "平桥区",
                        "value": "平桥区",
                        "code": "411503"
                    },
                    {
                        "label": "罗山县",
                        "value": "罗山县",
                        "code": "411521"
                    },
                    {
                        "label": "光山县",
                        "value": "光山县",
                        "code": "411522"
                    },
                    {
                        "label": "新县",
                        "value": "新县",
                        "code": "411523"
                    },
                    {
                        "label": "商城县",
                        "value": "商城县",
                        "code": "411524"
                    },
                    {
                        "label": "固始县",
                        "value": "固始县",
                        "code": "411525"
                    },
                    {
                        "label": "潢川县",
                        "value": "潢川县",
                        "code": "411526"
                    },
                    {
                        "label": "淮滨县",
                        "value": "淮滨县",
                        "code": "411527"
                    },
                    {
                        "label": "息县",
                        "value": "息县",
                        "code": "411528"
                    }
                ],
                "code": "411500"
            },
            {
                "label": "周口市",
                "value": "周口市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "411601"
                    },
                    {
                        "label": "川汇区",
                        "value": "川汇区",
                        "code": "411602"
                    },
                    {
                        "label": "扶沟县",
                        "value": "扶沟县",
                        "code": "411621"
                    },
                    {
                        "label": "西华县",
                        "value": "西华县",
                        "code": "411622"
                    },
                    {
                        "label": "商水县",
                        "value": "商水县",
                        "code": "411623"
                    },
                    {
                        "label": "沈丘县",
                        "value": "沈丘县",
                        "code": "411624"
                    },
                    {
                        "label": "郸城县",
                        "value": "郸城县",
                        "code": "411625"
                    },
                    {
                        "label": "淮阳县",
                        "value": "淮阳县",
                        "code": "411626"
                    },
                    {
                        "label": "太康县",
                        "value": "太康县",
                        "code": "411627"
                    },
                    {
                        "label": "鹿邑县",
                        "value": "鹿邑县",
                        "code": "411628"
                    },
                    {
                        "label": "项城市",
                        "value": "项城市",
                        "code": "411681"
                    }
                ],
                "code": "411600"
            },
            {
                "label": "驻马店市",
                "value": "驻马店市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "411701"
                    },
                    {
                        "label": "驿城区",
                        "value": "驿城区",
                        "code": "411702"
                    },
                    {
                        "label": "西平县",
                        "value": "西平县",
                        "code": "411721"
                    },
                    {
                        "label": "上蔡县",
                        "value": "上蔡县",
                        "code": "411722"
                    },
                    {
                        "label": "平舆县",
                        "value": "平舆县",
                        "code": "411723"
                    },
                    {
                        "label": "正阳县",
                        "value": "正阳县",
                        "code": "411724"
                    },
                    {
                        "label": "确山县",
                        "value": "确山县",
                        "code": "411725"
                    },
                    {
                        "label": "泌阳县",
                        "value": "泌阳县",
                        "code": "411726"
                    },
                    {
                        "label": "汝南县",
                        "value": "汝南县",
                        "code": "411727"
                    },
                    {
                        "label": "遂平县",
                        "value": "遂平县",
                        "code": "411728"
                    },
                    {
                        "label": "新蔡县",
                        "value": "新蔡县",
                        "code": "411729"
                    }
                ],
                "code": "411700"
            },
            {
                "label": "济源市",
                "value": "济源市",
                "code": "419001"
            }
        ],
        "code": "410000"
    },
    {
        "label": "湖北省",
        "value": "湖北省",
        "children": [
            {
                "label": "武汉市",
                "value": "武汉市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "420101"
                    },
                    {
                        "label": "江岸区",
                        "value": "江岸区",
                        "code": "420102"
                    },
                    {
                        "label": "江汉区",
                        "value": "江汉区",
                        "code": "420103"
                    },
                    {
                        "label": "硚口区",
                        "value": "硚口区",
                        "code": "420104"
                    },
                    {
                        "label": "汉阳区",
                        "value": "汉阳区",
                        "code": "420105"
                    },
                    {
                        "label": "武昌区",
                        "value": "武昌区",
                        "code": "420106"
                    },
                    {
                        "label": "青山区",
                        "value": "青山区",
                        "code": "420107"
                    },
                    {
                        "label": "洪山区",
                        "value": "洪山区",
                        "code": "420111"
                    },
                    {
                        "label": "东西湖区",
                        "value": "东西湖区",
                        "code": "420112"
                    },
                    {
                        "label": "汉南区",
                        "value": "汉南区",
                        "code": "420113"
                    },
                    {
                        "label": "蔡甸区",
                        "value": "蔡甸区",
                        "code": "420114"
                    },
                    {
                        "label": "江夏区",
                        "value": "江夏区",
                        "code": "420115"
                    },
                    {
                        "label": "黄陂区",
                        "value": "黄陂区",
                        "code": "420116"
                    },
                    {
                        "label": "新洲区",
                        "value": "新洲区",
                        "code": "420117"
                    }
                ],
                "code": "420100"
            },
            {
                "label": "黄石市",
                "value": "黄石市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "420201"
                    },
                    {
                        "label": "黄石港区",
                        "value": "黄石港区",
                        "code": "420202"
                    },
                    {
                        "label": "西塞山区",
                        "value": "西塞山区",
                        "code": "420203"
                    },
                    {
                        "label": "下陆区",
                        "value": "下陆区",
                        "code": "420204"
                    },
                    {
                        "label": "铁山区",
                        "value": "铁山区",
                        "code": "420205"
                    },
                    {
                        "label": "阳新县",
                        "value": "阳新县",
                        "code": "420222"
                    },
                    {
                        "label": "大冶市",
                        "value": "大冶市",
                        "code": "420281"
                    }
                ],
                "code": "420200"
            },
            {
                "label": "十堰市",
                "value": "十堰市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "420301"
                    },
                    {
                        "label": "茅箭区",
                        "value": "茅箭区",
                        "code": "420302"
                    },
                    {
                        "label": "张湾区",
                        "value": "张湾区",
                        "code": "420303"
                    },
                    {
                        "label": "郧阳区",
                        "value": "郧阳区",
                        "code": "420304"
                    },
                    {
                        "label": "郧西县",
                        "value": "郧西县",
                        "code": "420322"
                    },
                    {
                        "label": "竹山县",
                        "value": "竹山县",
                        "code": "420323"
                    },
                    {
                        "label": "竹溪县",
                        "value": "竹溪县",
                        "code": "420324"
                    },
                    {
                        "label": "房县",
                        "value": "房县",
                        "code": "420325"
                    },
                    {
                        "label": "丹江口市",
                        "value": "丹江口市",
                        "code": "420381"
                    }
                ],
                "code": "420300"
            },
            {
                "label": "宜昌市",
                "value": "宜昌市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "420501"
                    },
                    {
                        "label": "西陵区",
                        "value": "西陵区",
                        "code": "420502"
                    },
                    {
                        "label": "伍家岗区",
                        "value": "伍家岗区",
                        "code": "420503"
                    },
                    {
                        "label": "点军区",
                        "value": "点军区",
                        "code": "420504"
                    },
                    {
                        "label": "猇亭区",
                        "value": "猇亭区",
                        "code": "420505"
                    },
                    {
                        "label": "夷陵区",
                        "value": "夷陵区",
                        "code": "420506"
                    },
                    {
                        "label": "远安县",
                        "value": "远安县",
                        "code": "420525"
                    },
                    {
                        "label": "兴山县",
                        "value": "兴山县",
                        "code": "420526"
                    },
                    {
                        "label": "秭归县",
                        "value": "秭归县",
                        "code": "420527"
                    },
                    {
                        "label": "长阳土家族自治县",
                        "value": "长阳土家族自治县",
                        "code": "420528"
                    },
                    {
                        "label": "五峰土家族自治县",
                        "value": "五峰土家族自治县",
                        "code": "420529"
                    },
                    {
                        "label": "宜都市",
                        "value": "宜都市",
                        "code": "420581"
                    },
                    {
                        "label": "当阳市",
                        "value": "当阳市",
                        "code": "420582"
                    },
                    {
                        "label": "枝江市",
                        "value": "枝江市",
                        "code": "420583"
                    }
                ],
                "code": "420500"
            },
            {
                "label": "襄阳市",
                "value": "襄阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "420601"
                    },
                    {
                        "label": "襄城区",
                        "value": "襄城区",
                        "code": "420602"
                    },
                    {
                        "label": "樊城区",
                        "value": "樊城区",
                        "code": "420606"
                    },
                    {
                        "label": "襄州区",
                        "value": "襄州区",
                        "code": "420607"
                    },
                    {
                        "label": "南漳县",
                        "value": "南漳县",
                        "code": "420624"
                    },
                    {
                        "label": "谷城县",
                        "value": "谷城县",
                        "code": "420625"
                    },
                    {
                        "label": "保康县",
                        "value": "保康县",
                        "code": "420626"
                    },
                    {
                        "label": "老河口市",
                        "value": "老河口市",
                        "code": "420682"
                    },
                    {
                        "label": "枣阳市",
                        "value": "枣阳市",
                        "code": "420683"
                    },
                    {
                        "label": "宜城市",
                        "value": "宜城市",
                        "code": "420684"
                    }
                ],
                "code": "420600"
            },
            {
                "label": "鄂州市",
                "value": "鄂州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "420701"
                    },
                    {
                        "label": "梁子湖区",
                        "value": "梁子湖区",
                        "code": "420702"
                    },
                    {
                        "label": "华容区",
                        "value": "华容区",
                        "code": "420703"
                    },
                    {
                        "label": "鄂城区",
                        "value": "鄂城区",
                        "code": "420704"
                    }
                ],
                "code": "420700"
            },
            {
                "label": "荆门市",
                "value": "荆门市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "420801"
                    },
                    {
                        "label": "东宝区",
                        "value": "东宝区",
                        "code": "420802"
                    },
                    {
                        "label": "掇刀区",
                        "value": "掇刀区",
                        "code": "420804"
                    },
                    {
                        "label": "京山县",
                        "value": "京山县",
                        "code": "420821"
                    },
                    {
                        "label": "沙洋县",
                        "value": "沙洋县",
                        "code": "420822"
                    },
                    {
                        "label": "钟祥市",
                        "value": "钟祥市",
                        "code": "420881"
                    }
                ],
                "code": "420800"
            },
            {
                "label": "孝感市",
                "value": "孝感市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "420901"
                    },
                    {
                        "label": "孝南区",
                        "value": "孝南区",
                        "code": "420902"
                    },
                    {
                        "label": "孝昌县",
                        "value": "孝昌县",
                        "code": "420921"
                    },
                    {
                        "label": "大悟县",
                        "value": "大悟县",
                        "code": "420922"
                    },
                    {
                        "label": "云梦县",
                        "value": "云梦县",
                        "code": "420923"
                    },
                    {
                        "label": "应城市",
                        "value": "应城市",
                        "code": "420981"
                    },
                    {
                        "label": "安陆市",
                        "value": "安陆市",
                        "code": "420982"
                    },
                    {
                        "label": "汉川市",
                        "value": "汉川市",
                        "code": "420984"
                    }
                ],
                "code": "420900"
            },
            {
                "label": "荆州市",
                "value": "荆州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "421001"
                    },
                    {
                        "label": "沙市区",
                        "value": "沙市区",
                        "code": "421002"
                    },
                    {
                        "label": "荆州区",
                        "value": "荆州区",
                        "code": "421003"
                    },
                    {
                        "label": "公安县",
                        "value": "公安县",
                        "code": "421022"
                    },
                    {
                        "label": "监利县",
                        "value": "监利县",
                        "code": "421023"
                    },
                    {
                        "label": "江陵县",
                        "value": "江陵县",
                        "code": "421024"
                    },
                    {
                        "label": "石首市",
                        "value": "石首市",
                        "code": "421081"
                    },
                    {
                        "label": "洪湖市",
                        "value": "洪湖市",
                        "code": "421083"
                    },
                    {
                        "label": "松滋市",
                        "value": "松滋市",
                        "code": "421087"
                    }
                ],
                "code": "421000"
            },
            {
                "label": "黄冈市",
                "value": "黄冈市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "421101"
                    },
                    {
                        "label": "黄州区",
                        "value": "黄州区",
                        "code": "421102"
                    },
                    {
                        "label": "团风县",
                        "value": "团风县",
                        "code": "421121"
                    },
                    {
                        "label": "红安县",
                        "value": "红安县",
                        "code": "421122"
                    },
                    {
                        "label": "罗田县",
                        "value": "罗田县",
                        "code": "421123"
                    },
                    {
                        "label": "英山县",
                        "value": "英山县",
                        "code": "421124"
                    },
                    {
                        "label": "浠水县",
                        "value": "浠水县",
                        "code": "421125"
                    },
                    {
                        "label": "蕲春县",
                        "value": "蕲春县",
                        "code": "421126"
                    },
                    {
                        "label": "黄梅县",
                        "value": "黄梅县",
                        "code": "421127"
                    },
                    {
                        "label": "麻城市",
                        "value": "麻城市",
                        "code": "421181"
                    },
                    {
                        "label": "武穴市",
                        "value": "武穴市",
                        "code": "421182"
                    }
                ],
                "code": "421100"
            },
            {
                "label": "咸宁市",
                "value": "咸宁市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "421201"
                    },
                    {
                        "label": "咸安区",
                        "value": "咸安区",
                        "code": "421202"
                    },
                    {
                        "label": "嘉鱼县",
                        "value": "嘉鱼县",
                        "code": "421221"
                    },
                    {
                        "label": "通城县",
                        "value": "通城县",
                        "code": "421222"
                    },
                    {
                        "label": "崇阳县",
                        "value": "崇阳县",
                        "code": "421223"
                    },
                    {
                        "label": "通山县",
                        "value": "通山县",
                        "code": "421224"
                    },
                    {
                        "label": "赤壁市",
                        "value": "赤壁市",
                        "code": "421281"
                    }
                ],
                "code": "421200"
            },
            {
                "label": "随州市",
                "value": "随州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "421301"
                    },
                    {
                        "label": "曾都区",
                        "value": "曾都区",
                        "code": "421303"
                    },
                    {
                        "label": "随县",
                        "value": "随县",
                        "code": "421321"
                    },
                    {
                        "label": "广水市",
                        "value": "广水市",
                        "code": "421381"
                    }
                ],
                "code": "421300"
            },
            {
                "label": "恩施土家族苗族自治州",
                "value": "恩施土家族苗族自治州",
                "children": [
                    {
                        "label": "恩施市",
                        "value": "恩施市",
                        "code": "422801"
                    },
                    {
                        "label": "利川市",
                        "value": "利川市",
                        "code": "422802"
                    },
                    {
                        "label": "建始县",
                        "value": "建始县",
                        "code": "422822"
                    },
                    {
                        "label": "巴东县",
                        "value": "巴东县",
                        "code": "422823"
                    },
                    {
                        "label": "宣恩县",
                        "value": "宣恩县",
                        "code": "422825"
                    },
                    {
                        "label": "咸丰县",
                        "value": "咸丰县",
                        "code": "422826"
                    },
                    {
                        "label": "来凤县",
                        "value": "来凤县",
                        "code": "422827"
                    },
                    {
                        "label": "鹤峰县",
                        "value": "鹤峰县",
                        "code": "422828"
                    }
                ],
                "code": "422800"
            },
            {
                "label": "仙桃市",
                "value": "仙桃市",
                "code": "429004"
            },
            {
                "label": "潜江市",
                "value": "潜江市",
                "code": "429005"
            },
            {
                "label": "天门市",
                "value": "天门市",
                "code": "429006"
            },
            {
                "label": "神农架林区",
                "value": "神农架林区",
                "code": "429021"
            }
        ],
        "code": "420000"
    },
    {
        "label": "湖南省",
        "value": "湖南省",
        "children": [
            {
                "label": "长沙市",
                "value": "长沙市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "430101"
                    },
                    {
                        "label": "芙蓉区",
                        "value": "芙蓉区",
                        "code": "430102"
                    },
                    {
                        "label": "天心区",
                        "value": "天心区",
                        "code": "430103"
                    },
                    {
                        "label": "岳麓区",
                        "value": "岳麓区",
                        "code": "430104"
                    },
                    {
                        "label": "开福区",
                        "value": "开福区",
                        "code": "430105"
                    },
                    {
                        "label": "雨花区",
                        "value": "雨花区",
                        "code": "430111"
                    },
                    {
                        "label": "望城区",
                        "value": "望城区",
                        "code": "430112"
                    },
                    {
                        "label": "长沙县",
                        "value": "长沙县",
                        "code": "430121"
                    },
                    {
                        "label": "宁乡县",
                        "value": "宁乡县",
                        "code": "430124"
                    },
                    {
                        "label": "浏阳市",
                        "value": "浏阳市",
                        "code": "430181"
                    }
                ],
                "code": "430100"
            },
            {
                "label": "株洲市",
                "value": "株洲市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "430201"
                    },
                    {
                        "label": "荷塘区",
                        "value": "荷塘区",
                        "code": "430202"
                    },
                    {
                        "label": "芦淞区",
                        "value": "芦淞区",
                        "code": "430203"
                    },
                    {
                        "label": "石峰区",
                        "value": "石峰区",
                        "code": "430204"
                    },
                    {
                        "label": "天元区",
                        "value": "天元区",
                        "code": "430211"
                    },
                    {
                        "label": "株洲县",
                        "value": "株洲县",
                        "code": "430221"
                    },
                    {
                        "label": "攸县",
                        "value": "攸县",
                        "code": "430223"
                    },
                    {
                        "label": "茶陵县",
                        "value": "茶陵县",
                        "code": "430224"
                    },
                    {
                        "label": "炎陵县",
                        "value": "炎陵县",
                        "code": "430225"
                    },
                    {
                        "label": "醴陵市",
                        "value": "醴陵市",
                        "code": "430281"
                    }
                ],
                "code": "430200"
            },
            {
                "label": "湘潭市",
                "value": "湘潭市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "430301"
                    },
                    {
                        "label": "雨湖区",
                        "value": "雨湖区",
                        "code": "430302"
                    },
                    {
                        "label": "岳塘区",
                        "value": "岳塘区",
                        "code": "430304"
                    },
                    {
                        "label": "湘潭县",
                        "value": "湘潭县",
                        "code": "430321"
                    },
                    {
                        "label": "湘乡市",
                        "value": "湘乡市",
                        "code": "430381"
                    },
                    {
                        "label": "韶山市",
                        "value": "韶山市",
                        "code": "430382"
                    }
                ],
                "code": "430300"
            },
            {
                "label": "衡阳市",
                "value": "衡阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "430401"
                    },
                    {
                        "label": "珠晖区",
                        "value": "珠晖区",
                        "code": "430405"
                    },
                    {
                        "label": "雁峰区",
                        "value": "雁峰区",
                        "code": "430406"
                    },
                    {
                        "label": "石鼓区",
                        "value": "石鼓区",
                        "code": "430407"
                    },
                    {
                        "label": "蒸湘区",
                        "value": "蒸湘区",
                        "code": "430408"
                    },
                    {
                        "label": "南岳区",
                        "value": "南岳区",
                        "code": "430412"
                    },
                    {
                        "label": "衡阳县",
                        "value": "衡阳县",
                        "code": "430421"
                    },
                    {
                        "label": "衡南县",
                        "value": "衡南县",
                        "code": "430422"
                    },
                    {
                        "label": "衡山县",
                        "value": "衡山县",
                        "code": "430423"
                    },
                    {
                        "label": "衡东县",
                        "value": "衡东县",
                        "code": "430424"
                    },
                    {
                        "label": "祁东县",
                        "value": "祁东县",
                        "code": "430426"
                    },
                    {
                        "label": "耒阳市",
                        "value": "耒阳市",
                        "code": "430481"
                    },
                    {
                        "label": "常宁市",
                        "value": "常宁市",
                        "code": "430482"
                    }
                ],
                "code": "430400"
            },
            {
                "label": "邵阳市",
                "value": "邵阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "430501"
                    },
                    {
                        "label": "双清区",
                        "value": "双清区",
                        "code": "430502"
                    },
                    {
                        "label": "大祥区",
                        "value": "大祥区",
                        "code": "430503"
                    },
                    {
                        "label": "北塔区",
                        "value": "北塔区",
                        "code": "430511"
                    },
                    {
                        "label": "邵东县",
                        "value": "邵东县",
                        "code": "430521"
                    },
                    {
                        "label": "新邵县",
                        "value": "新邵县",
                        "code": "430522"
                    },
                    {
                        "label": "邵阳县",
                        "value": "邵阳县",
                        "code": "430523"
                    },
                    {
                        "label": "隆回县",
                        "value": "隆回县",
                        "code": "430524"
                    },
                    {
                        "label": "洞口县",
                        "value": "洞口县",
                        "code": "430525"
                    },
                    {
                        "label": "绥宁县",
                        "value": "绥宁县",
                        "code": "430527"
                    },
                    {
                        "label": "新宁县",
                        "value": "新宁县",
                        "code": "430528"
                    },
                    {
                        "label": "城步苗族自治县",
                        "value": "城步苗族自治县",
                        "code": "430529"
                    },
                    {
                        "label": "武冈市",
                        "value": "武冈市",
                        "code": "430581"
                    }
                ],
                "code": "430500"
            },
            {
                "label": "岳阳市",
                "value": "岳阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "430601"
                    },
                    {
                        "label": "岳阳楼区",
                        "value": "岳阳楼区",
                        "code": "430602"
                    },
                    {
                        "label": "云溪区",
                        "value": "云溪区",
                        "code": "430603"
                    },
                    {
                        "label": "君山区",
                        "value": "君山区",
                        "code": "430611"
                    },
                    {
                        "label": "岳阳县",
                        "value": "岳阳县",
                        "code": "430621"
                    },
                    {
                        "label": "华容县",
                        "value": "华容县",
                        "code": "430623"
                    },
                    {
                        "label": "湘阴县",
                        "value": "湘阴县",
                        "code": "430624"
                    },
                    {
                        "label": "平江县",
                        "value": "平江县",
                        "code": "430626"
                    },
                    {
                        "label": "汨罗市",
                        "value": "汨罗市",
                        "code": "430681"
                    },
                    {
                        "label": "临湘市",
                        "value": "临湘市",
                        "code": "430682"
                    }
                ],
                "code": "430600"
            },
            {
                "label": "常德市",
                "value": "常德市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "430701"
                    },
                    {
                        "label": "武陵区",
                        "value": "武陵区",
                        "code": "430702"
                    },
                    {
                        "label": "鼎城区",
                        "value": "鼎城区",
                        "code": "430703"
                    },
                    {
                        "label": "安乡县",
                        "value": "安乡县",
                        "code": "430721"
                    },
                    {
                        "label": "汉寿县",
                        "value": "汉寿县",
                        "code": "430722"
                    },
                    {
                        "label": "澧县",
                        "value": "澧县",
                        "code": "430723"
                    },
                    {
                        "label": "临澧县",
                        "value": "临澧县",
                        "code": "430724"
                    },
                    {
                        "label": "桃源县",
                        "value": "桃源县",
                        "code": "430725"
                    },
                    {
                        "label": "石门县",
                        "value": "石门县",
                        "code": "430726"
                    },
                    {
                        "label": "津市市",
                        "value": "津市市",
                        "code": "430781"
                    }
                ],
                "code": "430700"
            },
            {
                "label": "张家界市",
                "value": "张家界市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "430801"
                    },
                    {
                        "label": "永定区",
                        "value": "永定区",
                        "code": "430802"
                    },
                    {
                        "label": "武陵源区",
                        "value": "武陵源区",
                        "code": "430811"
                    },
                    {
                        "label": "慈利县",
                        "value": "慈利县",
                        "code": "430821"
                    },
                    {
                        "label": "桑植县",
                        "value": "桑植县",
                        "code": "430822"
                    }
                ],
                "code": "430800"
            },
            {
                "label": "益阳市",
                "value": "益阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "430901"
                    },
                    {
                        "label": "资阳区",
                        "value": "资阳区",
                        "code": "430902"
                    },
                    {
                        "label": "赫山区",
                        "value": "赫山区",
                        "code": "430903"
                    },
                    {
                        "label": "南县",
                        "value": "南县",
                        "code": "430921"
                    },
                    {
                        "label": "桃江县",
                        "value": "桃江县",
                        "code": "430922"
                    },
                    {
                        "label": "安化县",
                        "value": "安化县",
                        "code": "430923"
                    },
                    {
                        "label": "沅江市",
                        "value": "沅江市",
                        "code": "430981"
                    }
                ],
                "code": "430900"
            },
            {
                "label": "郴州市",
                "value": "郴州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "431001"
                    },
                    {
                        "label": "北湖区",
                        "value": "北湖区",
                        "code": "431002"
                    },
                    {
                        "label": "苏仙区",
                        "value": "苏仙区",
                        "code": "431003"
                    },
                    {
                        "label": "桂阳县",
                        "value": "桂阳县",
                        "code": "431021"
                    },
                    {
                        "label": "宜章县",
                        "value": "宜章县",
                        "code": "431022"
                    },
                    {
                        "label": "永兴县",
                        "value": "永兴县",
                        "code": "431023"
                    },
                    {
                        "label": "嘉禾县",
                        "value": "嘉禾县",
                        "code": "431024"
                    },
                    {
                        "label": "临武县",
                        "value": "临武县",
                        "code": "431025"
                    },
                    {
                        "label": "汝城县",
                        "value": "汝城县",
                        "code": "431026"
                    },
                    {
                        "label": "桂东县",
                        "value": "桂东县",
                        "code": "431027"
                    },
                    {
                        "label": "安仁县",
                        "value": "安仁县",
                        "code": "431028"
                    },
                    {
                        "label": "资兴市",
                        "value": "资兴市",
                        "code": "431081"
                    }
                ],
                "code": "431000"
            },
            {
                "label": "永州市",
                "value": "永州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "431101"
                    },
                    {
                        "label": "零陵区",
                        "value": "零陵区",
                        "code": "431102"
                    },
                    {
                        "label": "冷水滩区",
                        "value": "冷水滩区",
                        "code": "431103"
                    },
                    {
                        "label": "祁阳县",
                        "value": "祁阳县",
                        "code": "431121"
                    },
                    {
                        "label": "东安县",
                        "value": "东安县",
                        "code": "431122"
                    },
                    {
                        "label": "双牌县",
                        "value": "双牌县",
                        "code": "431123"
                    },
                    {
                        "label": "道县",
                        "value": "道县",
                        "code": "431124"
                    },
                    {
                        "label": "江永县",
                        "value": "江永县",
                        "code": "431125"
                    },
                    {
                        "label": "宁远县",
                        "value": "宁远县",
                        "code": "431126"
                    },
                    {
                        "label": "蓝山县",
                        "value": "蓝山县",
                        "code": "431127"
                    },
                    {
                        "label": "新田县",
                        "value": "新田县",
                        "code": "431128"
                    },
                    {
                        "label": "江华瑶族自治县",
                        "value": "江华瑶族自治县",
                        "code": "431129"
                    }
                ],
                "code": "431100"
            },
            {
                "label": "怀化市",
                "value": "怀化市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "431201"
                    },
                    {
                        "label": "鹤城区",
                        "value": "鹤城区",
                        "code": "431202"
                    },
                    {
                        "label": "中方县",
                        "value": "中方县",
                        "code": "431221"
                    },
                    {
                        "label": "沅陵县",
                        "value": "沅陵县",
                        "code": "431222"
                    },
                    {
                        "label": "辰溪县",
                        "value": "辰溪县",
                        "code": "431223"
                    },
                    {
                        "label": "溆浦县",
                        "value": "溆浦县",
                        "code": "431224"
                    },
                    {
                        "label": "会同县",
                        "value": "会同县",
                        "code": "431225"
                    },
                    {
                        "label": "麻阳苗族自治县",
                        "value": "麻阳苗族自治县",
                        "code": "431226"
                    },
                    {
                        "label": "新晃侗族自治县",
                        "value": "新晃侗族自治县",
                        "code": "431227"
                    },
                    {
                        "label": "芷江侗族自治县",
                        "value": "芷江侗族自治县",
                        "code": "431228"
                    },
                    {
                        "label": "靖州苗族侗族自治县",
                        "value": "靖州苗族侗族自治县",
                        "code": "431229"
                    },
                    {
                        "label": "通道侗族自治县",
                        "value": "通道侗族自治县",
                        "code": "431230"
                    },
                    {
                        "label": "洪江市",
                        "value": "洪江市",
                        "code": "431281"
                    }
                ],
                "code": "431200"
            },
            {
                "label": "娄底市",
                "value": "娄底市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "431301"
                    },
                    {
                        "label": "娄星区",
                        "value": "娄星区",
                        "code": "431302"
                    },
                    {
                        "label": "双峰县",
                        "value": "双峰县",
                        "code": "431321"
                    },
                    {
                        "label": "新化县",
                        "value": "新化县",
                        "code": "431322"
                    },
                    {
                        "label": "冷水江市",
                        "value": "冷水江市",
                        "code": "431381"
                    },
                    {
                        "label": "涟源市",
                        "value": "涟源市",
                        "code": "431382"
                    }
                ],
                "code": "431300"
            },
            {
                "label": "湘西土家族苗族自治州",
                "value": "湘西土家族苗族自治州",
                "children": [
                    {
                        "label": "吉首市",
                        "value": "吉首市",
                        "code": "433101"
                    },
                    {
                        "label": "泸溪县",
                        "value": "泸溪县",
                        "code": "433122"
                    },
                    {
                        "label": "凤凰县",
                        "value": "凤凰县",
                        "code": "433123"
                    },
                    {
                        "label": "花垣县",
                        "value": "花垣县",
                        "code": "433124"
                    },
                    {
                        "label": "保靖县",
                        "value": "保靖县",
                        "code": "433125"
                    },
                    {
                        "label": "古丈县",
                        "value": "古丈县",
                        "code": "433126"
                    },
                    {
                        "label": "永顺县",
                        "value": "永顺县",
                        "code": "433127"
                    },
                    {
                        "label": "龙山县",
                        "value": "龙山县",
                        "code": "433130"
                    }
                ],
                "code": "433100"
            }
        ],
        "code": "430000"
    },
    {
        "label": "广东省",
        "value": "广东省",
        "children": [
            {
                "label": "广州市",
                "value": "广州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "440101"
                    },
                    {
                        "label": "荔湾区",
                        "value": "荔湾区",
                        "code": "440103"
                    },
                    {
                        "label": "越秀区",
                        "value": "越秀区",
                        "code": "440104"
                    },
                    {
                        "label": "海珠区",
                        "value": "海珠区",
                        "code": "440105"
                    },
                    {
                        "label": "天河区",
                        "value": "天河区",
                        "code": "440106"
                    },
                    {
                        "label": "白云区",
                        "value": "白云区",
                        "code": "440111"
                    },
                    {
                        "label": "黄埔区",
                        "value": "黄埔区",
                        "code": "440112"
                    },
                    {
                        "label": "番禺区",
                        "value": "番禺区",
                        "code": "440113"
                    },
                    {
                        "label": "花都区",
                        "value": "花都区",
                        "code": "440114"
                    },
                    {
                        "label": "南沙区",
                        "value": "南沙区",
                        "code": "440115"
                    },
                    {
                        "label": "从化区",
                        "value": "从化区",
                        "code": "440117"
                    },
                    {
                        "label": "增城区",
                        "value": "增城区",
                        "code": "440118"
                    }
                ],
                "code": "440100"
            },
            {
                "label": "韶关市",
                "value": "韶关市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "440201"
                    },
                    {
                        "label": "武江区",
                        "value": "武江区",
                        "code": "440203"
                    },
                    {
                        "label": "浈江区",
                        "value": "浈江区",
                        "code": "440204"
                    },
                    {
                        "label": "曲江区",
                        "value": "曲江区",
                        "code": "440205"
                    },
                    {
                        "label": "始兴县",
                        "value": "始兴县",
                        "code": "440222"
                    },
                    {
                        "label": "仁化县",
                        "value": "仁化县",
                        "code": "440224"
                    },
                    {
                        "label": "翁源县",
                        "value": "翁源县",
                        "code": "440229"
                    },
                    {
                        "label": "乳源瑶族自治县",
                        "value": "乳源瑶族自治县",
                        "code": "440232"
                    },
                    {
                        "label": "新丰县",
                        "value": "新丰县",
                        "code": "440233"
                    },
                    {
                        "label": "乐昌市",
                        "value": "乐昌市",
                        "code": "440281"
                    },
                    {
                        "label": "南雄市",
                        "value": "南雄市",
                        "code": "440282"
                    }
                ],
                "code": "440200"
            },
            {
                "label": "深圳市",
                "value": "深圳市",
                "code": "440300",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "440301"
                    },
                    {
                        "label": "罗湖区",
                        "value": "罗湖区",
                        "code": "440303"
                    },
                    {
                        "label": "福田区",
                        "value": "福田区",
                        "code": "440304"
                    },
                    {
                        "label": "南山区",
                        "value": "南山区",
                        "code": "440305"
                    },
                    {
                        "label": "宝安区",
                        "value": "宝安区",
                        "code": "440306"
                    },
                    {
                        "label": "龙岗区",
                        "value": "龙岗区",
                        "code": "440307"
                    },
                    {
                        "label": "盐田区",
                        "value": "盐田区",
                        "code": "440308"
                    },
 
                    {
                        "label": "龙华区",
                        "value": "龙华区",
                        "code": "440309"
                    },
                    {
                        "label": "坪山区",
                        "value": "坪山区",
                        "code": "440310"
                    },
                    {
                        "label": "光明区",
                        "value": "光明区",
                        "code": "440311"
                    },
                    {
                        "label": "大鹏新区",
                        "value": "大鹏新区",
                        "code": "440312"
                    }
                ],
            },
            {
                "label": "珠海市",
                "value": "珠海市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "440401"
                    },
                    {
                        "label": "香洲区",
                        "value": "香洲区",
                        "code": "440402"
                    },
                    {
                        "label": "斗门区",
                        "value": "斗门区",
                        "code": "440403"
                    },
                    {
                        "label": "金湾区",
                        "value": "金湾区",
                        "code": "440404"
                    }
                ],
                "code": "440400"
            },
            {
                "label": "汕头市",
                "value": "汕头市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "440501"
                    },
                    {
                        "label": "龙湖区",
                        "value": "龙湖区",
                        "code": "440507"
                    },
                    {
                        "label": "金平区",
                        "value": "金平区",
                        "code": "440511"
                    },
                    {
                        "label": "濠江区",
                        "value": "濠江区",
                        "code": "440512"
                    },
                    {
                        "label": "潮阳区",
                        "value": "潮阳区",
                        "code": "440513"
                    },
                    {
                        "label": "潮南区",
                        "value": "潮南区",
                        "code": "440514"
                    },
                    {
                        "label": "澄海区",
                        "value": "澄海区",
                        "code": "440515"
                    },
                    {
                        "label": "南澳县",
                        "value": "南澳县",
                        "code": "440523"
                    }
                ],
                "code": "440500"
            },
            {
                "label": "佛山市",
                "value": "佛山市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "440601"
                    },
                    {
                        "label": "禅城区",
                        "value": "禅城区",
                        "code": "440604"
                    },
                    {
                        "label": "南海区",
                        "value": "南海区",
                        "code": "440605"
                    },
                    {
                        "label": "顺德区",
                        "value": "顺德区",
                        "code": "440606"
                    },
                    {
                        "label": "三水区",
                        "value": "三水区",
                        "code": "440607"
                    },
                    {
                        "label": "高明区",
                        "value": "高明区",
                        "code": "440608"
                    }
                ],
                "code": "440600"
            },
            {
                "label": "江门市",
                "value": "江门市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "440701"
                    },
                    {
                        "label": "蓬江区",
                        "value": "蓬江区",
                        "code": "440703"
                    },
                    {
                        "label": "江海区",
                        "value": "江海区",
                        "code": "440704"
                    },
                    {
                        "label": "新会区",
                        "value": "新会区",
                        "code": "440705"
                    },
                    {
                        "label": "台山市",
                        "value": "台山市",
                        "code": "440781"
                    },
                    {
                        "label": "开平市",
                        "value": "开平市",
                        "code": "440783"
                    },
                    {
                        "label": "鹤山市",
                        "value": "鹤山市",
                        "code": "440784"
                    },
                    {
                        "label": "恩平市",
                        "value": "恩平市",
                        "code": "440785"
                    }
                ],
                "code": "440700"
            },
            {
                "label": "湛江市",
                "value": "湛江市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "440801"
                    },
                    {
                        "label": "赤坎区",
                        "value": "赤坎区",
                        "code": "440802"
                    },
                    {
                        "label": "霞山区",
                        "value": "霞山区",
                        "code": "440803"
                    },
                    {
                        "label": "坡头区",
                        "value": "坡头区",
                        "code": "440804"
                    },
                    {
                        "label": "麻章区",
                        "value": "麻章区",
                        "code": "440811"
                    },
                    {
                        "label": "遂溪县",
                        "value": "遂溪县",
                        "code": "440823"
                    },
                    {
                        "label": "徐闻县",
                        "value": "徐闻县",
                        "code": "440825"
                    },
                    {
                        "label": "廉江市",
                        "value": "廉江市",
                        "code": "440881"
                    },
                    {
                        "label": "雷州市",
                        "value": "雷州市",
                        "code": "440882"
                    },
                    {
                        "label": "吴川市",
                        "value": "吴川市",
                        "code": "440883"
                    }
                ],
                "code": "440800"
            },
            {
                "label": "茂名市",
                "value": "茂名市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "440901"
                    },
                    {
                        "label": "茂南区",
                        "value": "茂南区",
                        "code": "440902"
                    },
                    {
                        "label": "电白区",
                        "value": "电白区",
                        "code": "440904"
                    },
                    {
                        "label": "高州市",
                        "value": "高州市",
                        "code": "440981"
                    },
                    {
                        "label": "化州市",
                        "value": "化州市",
                        "code": "440982"
                    },
                    {
                        "label": "信宜市",
                        "value": "信宜市",
                        "code": "440983"
                    }
                ],
                "code": "440900"
            },
            {
                "label": "肇庆市",
                "value": "肇庆市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "441201"
                    },
                    {
                        "label": "端州区",
                        "value": "端州区",
                        "code": "441202"
                    },
                    {
                        "label": "鼎湖区",
                        "value": "鼎湖区",
                        "code": "441203"
                    },
                    {
                        "label": "广宁县",
                        "value": "广宁县",
                        "code": "441223"
                    },
                    {
                        "label": "怀集县",
                        "value": "怀集县",
                        "code": "441224"
                    },
                    {
                        "label": "封开县",
                        "value": "封开县",
                        "code": "441225"
                    },
                    {
                        "label": "德庆县",
                        "value": "德庆县",
                        "code": "441226"
                    },
                    {
                        "label": "高要市",
                        "value": "高要市",
                        "code": "441283"
                    },
                    {
                        "label": "四会市",
                        "value": "四会市",
                        "code": "441284"
                    }
                ],
                "code": "441200"
            },
            {
                "label": "惠州市",
                "value": "惠州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "441301"
                    },
                    {
                        "label": "惠城区",
                        "value": "惠城区",
                        "code": "441302"
                    },
                    {
                        "label": "惠阳区",
                        "value": "惠阳区",
                        "code": "441303"
                    },
                    {
                        "label": "博罗县",
                        "value": "博罗县",
                        "code": "441322"
                    },
                    {
                        "label": "惠东县",
                        "value": "惠东县",
                        "code": "441323"
                    },
                    {
                        "label": "龙门县",
                        "value": "龙门县",
                        "code": "441324"
                    }
                ],
                "code": "441300"
            },
            {
                "label": "梅州市",
                "value": "梅州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "441401"
                    },
                    {
                        "label": "梅江区",
                        "value": "梅江区",
                        "code": "441402"
                    },
                    {
                        "label": "梅县区",
                        "value": "梅县区",
                        "code": "441403"
                    },
                    {
                        "label": "大埔县",
                        "value": "大埔县",
                        "code": "441422"
                    },
                    {
                        "label": "丰顺县",
                        "value": "丰顺县",
                        "code": "441423"
                    },
                    {
                        "label": "五华县",
                        "value": "五华县",
                        "code": "441424"
                    },
                    {
                        "label": "平远县",
                        "value": "平远县",
                        "code": "441426"
                    },
                    {
                        "label": "蕉岭县",
                        "value": "蕉岭县",
                        "code": "441427"
                    },
                    {
                        "label": "兴宁市",
                        "value": "兴宁市",
                        "code": "441481"
                    }
                ],
                "code": "441400"
            },
            {
                "label": "汕尾市",
                "value": "汕尾市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "441501"
                    },
                    {
                        "label": "城区",
                        "value": "城区",
                        "code": "441502"
                    },
                    {
                        "label": "海丰县",
                        "value": "海丰县",
                        "code": "441521"
                    },
                    {
                        "label": "陆河县",
                        "value": "陆河县",
                        "code": "441523"
                    },
                    {
                        "label": "陆丰市",
                        "value": "陆丰市",
                        "code": "441581"
                    }
                ],
                "code": "441500"
            },
            {
                "label": "河源市",
                "value": "河源市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "441601"
                    },
                    {
                        "label": "源城区",
                        "value": "源城区",
                        "code": "441602"
                    },
                    {
                        "label": "紫金县",
                        "value": "紫金县",
                        "code": "441621"
                    },
                    {
                        "label": "龙川县",
                        "value": "龙川县",
                        "code": "441622"
                    },
                    {
                        "label": "连平县",
                        "value": "连平县",
                        "code": "441623"
                    },
                    {
                        "label": "和平县",
                        "value": "和平县",
                        "code": "441624"
                    },
                    {
                        "label": "东源县",
                        "value": "东源县",
                        "code": "441625"
                    }
                ],
                "code": "441600"
            },
            {
                "label": "阳江市",
                "value": "阳江市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "441701"
                    },
                    {
                        "label": "江城区",
                        "value": "江城区",
                        "code": "441702"
                    },
                    {
                        "label": "阳东区",
                        "value": "阳东区",
                        "code": "441704"
                    },
                    {
                        "label": "阳西县",
                        "value": "阳西县",
                        "code": "441721"
                    },
                    {
                        "label": "阳春市",
                        "value": "阳春市",
                        "code": "441781"
                    }
                ],
                "code": "441700"
            },
            {
                "label": "清远市",
                "value": "清远市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "441801"
                    },
                    {
                        "label": "清城区",
                        "value": "清城区",
                        "code": "441802"
                    },
                    {
                        "label": "清新区",
                        "value": "清新区",
                        "code": "441803"
                    },
                    {
                        "label": "佛冈县",
                        "value": "佛冈县",
                        "code": "441821"
                    },
                    {
                        "label": "阳山县",
                        "value": "阳山县",
                        "code": "441823"
                    },
                    {
                        "label": "连山壮族瑶族自治县",
                        "value": "连山壮族瑶族自治县",
                        "code": "441825"
                    },
                    {
                        "label": "连南瑶族自治县",
                        "value": "连南瑶族自治县",
                        "code": "441826"
                    },
                    {
                        "label": "英德市",
                        "value": "英德市",
                        "code": "441881"
                    },
                    {
                        "label": "连州市",
                        "value": "连州市",
                        "code": "441882"
                    }
                ],
                "code": "441800"
            },
            {
                "label": "东莞市",
                "value": "东莞市",
                "children": [],
                "code": "441900"
            },
            {
                "label": "中山市",
                "value": "中山市",
                "children": [],
                "code": "442000"
            },
            {
                "label": "潮州市",
                "value": "潮州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "445101"
                    },
                    {
                        "label": "湘桥区",
                        "value": "湘桥区",
                        "code": "445102"
                    },
                    {
                        "label": "潮安区",
                        "value": "潮安区",
                        "code": "445103"
                    },
                    {
                        "label": "饶平县",
                        "value": "饶平县",
                        "code": "445122"
                    }
                ],
                "code": "445100"
            },
            {
                "label": "揭阳市",
                "value": "揭阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "445201"
                    },
                    {
                        "label": "榕城区",
                        "value": "榕城区",
                        "code": "445202"
                    },
                    {
                        "label": "揭东区",
                        "value": "揭东区",
                        "code": "445203"
                    },
                    {
                        "label": "揭西县",
                        "value": "揭西县",
                        "code": "445222"
                    },
                    {
                        "label": "惠来县",
                        "value": "惠来县",
                        "code": "445224"
                    },
                    {
                        "label": "普宁市",
                        "value": "普宁市",
                        "code": "445281"
                    }
                ],
                "code": "445200"
            },
            {
                "label": "云浮市",
                "value": "云浮市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "445301"
                    },
                    {
                        "label": "云城区",
                        "value": "云城区",
                        "code": "445302"
                    },
                    {
                        "label": "云安区",
                        "value": "云安区",
                        "code": "445303"
                    },
                    {
                        "label": "新兴县",
                        "value": "新兴县",
                        "code": "445321"
                    },
                    {
                        "label": "郁南县",
                        "value": "郁南县",
                        "code": "445322"
                    },
                    {
                        "label": "罗定市",
                        "value": "罗定市",
                        "code": "445381"
                    }
                ],
                "code": "445300"
            }
        ],
        "code": "440000"
    },
    {
        "label": "广西壮族自治区",
        "value": "广西壮族自治区",
        "children": [
            {
                "label": "南宁市",
                "value": "南宁市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "450101"
                    },
                    {
                        "label": "兴宁区",
                        "value": "兴宁区",
                        "code": "450102"
                    },
                    {
                        "label": "青秀区",
                        "value": "青秀区",
                        "code": "450103"
                    },
                    {
                        "label": "江南区",
                        "value": "江南区",
                        "code": "450105"
                    },
                    {
                        "label": "西乡塘区",
                        "value": "西乡塘区",
                        "code": "450107"
                    },
                    {
                        "label": "良庆区",
                        "value": "良庆区",
                        "code": "450108"
                    },
                    {
                        "label": "邕宁区",
                        "value": "邕宁区",
                        "code": "450109"
                    },
                    {
                        "label": "武鸣县",
                        "value": "武鸣县",
                        "code": "450122"
                    },
                    {
                        "label": "隆安县",
                        "value": "隆安县",
                        "code": "450123"
                    },
                    {
                        "label": "马山县",
                        "value": "马山县",
                        "code": "450124"
                    },
                    {
                        "label": "上林县",
                        "value": "上林县",
                        "code": "450125"
                    },
                    {
                        "label": "宾阳县",
                        "value": "宾阳县",
                        "code": "450126"
                    },
                    {
                        "label": "横县",
                        "value": "横县",
                        "code": "450127"
                    }
                ],
                "code": "450100"
            },
            {
                "label": "柳州市",
                "value": "柳州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "450201"
                    },
                    {
                        "label": "城中区",
                        "value": "城中区",
                        "code": "450202"
                    },
                    {
                        "label": "鱼峰区",
                        "value": "鱼峰区",
                        "code": "450203"
                    },
                    {
                        "label": "柳南区",
                        "value": "柳南区",
                        "code": "450204"
                    },
                    {
                        "label": "柳北区",
                        "value": "柳北区",
                        "code": "450205"
                    },
                    {
                        "label": "柳江县",
                        "value": "柳江县",
                        "code": "450221"
                    },
                    {
                        "label": "柳城县",
                        "value": "柳城县",
                        "code": "450222"
                    },
                    {
                        "label": "鹿寨县",
                        "value": "鹿寨县",
                        "code": "450223"
                    },
                    {
                        "label": "融安县",
                        "value": "融安县",
                        "code": "450224"
                    },
                    {
                        "label": "融水苗族自治县",
                        "value": "融水苗族自治县",
                        "code": "450225"
                    },
                    {
                        "label": "三江侗族自治县",
                        "value": "三江侗族自治县",
                        "code": "450226"
                    }
                ],
                "code": "450200"
            },
            {
                "label": "桂林市",
                "value": "桂林市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "450301"
                    },
                    {
                        "label": "秀峰区",
                        "value": "秀峰区",
                        "code": "450302"
                    },
                    {
                        "label": "叠彩区",
                        "value": "叠彩区",
                        "code": "450303"
                    },
                    {
                        "label": "象山区",
                        "value": "象山区",
                        "code": "450304"
                    },
                    {
                        "label": "七星区",
                        "value": "七星区",
                        "code": "450305"
                    },
                    {
                        "label": "雁山区",
                        "value": "雁山区",
                        "code": "450311"
                    },
                    {
                        "label": "临桂区",
                        "value": "临桂区",
                        "code": "450312"
                    },
                    {
                        "label": "阳朔县",
                        "value": "阳朔县",
                        "code": "450321"
                    },
                    {
                        "label": "灵川县",
                        "value": "灵川县",
                        "code": "450323"
                    },
                    {
                        "label": "全州县",
                        "value": "全州县",
                        "code": "450324"
                    },
                    {
                        "label": "兴安县",
                        "value": "兴安县",
                        "code": "450325"
                    },
                    {
                        "label": "永福县",
                        "value": "永福县",
                        "code": "450326"
                    },
                    {
                        "label": "灌阳县",
                        "value": "灌阳县",
                        "code": "450327"
                    },
                    {
                        "label": "龙胜各族自治县",
                        "value": "龙胜各族自治县",
                        "code": "450328"
                    },
                    {
                        "label": "资源县",
                        "value": "资源县",
                        "code": "450329"
                    },
                    {
                        "label": "平乐县",
                        "value": "平乐县",
                        "code": "450330"
                    },
                    {
                        "label": "荔浦县",
                        "value": "荔浦县",
                        "code": "450331"
                    },
                    {
                        "label": "恭城瑶族自治县",
                        "value": "恭城瑶族自治县",
                        "code": "450332"
                    }
                ],
                "code": "450300"
            },
            {
                "label": "梧州市",
                "value": "梧州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "450401"
                    },
                    {
                        "label": "万秀区",
                        "value": "万秀区",
                        "code": "450403"
                    },
                    {
                        "label": "长洲区",
                        "value": "长洲区",
                        "code": "450405"
                    },
                    {
                        "label": "龙圩区",
                        "value": "龙圩区",
                        "code": "450406"
                    },
                    {
                        "label": "苍梧县",
                        "value": "苍梧县",
                        "code": "450421"
                    },
                    {
                        "label": "藤县",
                        "value": "藤县",
                        "code": "450422"
                    },
                    {
                        "label": "蒙山县",
                        "value": "蒙山县",
                        "code": "450423"
                    },
                    {
                        "label": "岑溪市",
                        "value": "岑溪市",
                        "code": "450481"
                    }
                ],
                "code": "450400"
            },
            {
                "label": "北海市",
                "value": "北海市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "450501"
                    },
                    {
                        "label": "海城区",
                        "value": "海城区",
                        "code": "450502"
                    },
                    {
                        "label": "银海区",
                        "value": "银海区",
                        "code": "450503"
                    },
                    {
                        "label": "铁山港区",
                        "value": "铁山港区",
                        "code": "450512"
                    },
                    {
                        "label": "合浦县",
                        "value": "合浦县",
                        "code": "450521"
                    }
                ],
                "code": "450500"
            },
            {
                "label": "防城港市",
                "value": "防城港市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "450601"
                    },
                    {
                        "label": "港口区",
                        "value": "港口区",
                        "code": "450602"
                    },
                    {
                        "label": "防城区",
                        "value": "防城区",
                        "code": "450603"
                    },
                    {
                        "label": "上思县",
                        "value": "上思县",
                        "code": "450621"
                    },
                    {
                        "label": "东兴市",
                        "value": "东兴市",
                        "code": "450681"
                    }
                ],
                "code": "450600"
            },
            {
                "label": "钦州市",
                "value": "钦州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "450701"
                    },
                    {
                        "label": "钦南区",
                        "value": "钦南区",
                        "code": "450702"
                    },
                    {
                        "label": "钦北区",
                        "value": "钦北区",
                        "code": "450703"
                    },
                    {
                        "label": "灵山县",
                        "value": "灵山县",
                        "code": "450721"
                    },
                    {
                        "label": "浦北县",
                        "value": "浦北县",
                        "code": "450722"
                    }
                ],
                "code": "450700"
            },
            {
                "label": "贵港市",
                "value": "贵港市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "450801"
                    },
                    {
                        "label": "港北区",
                        "value": "港北区",
                        "code": "450802"
                    },
                    {
                        "label": "港南区",
                        "value": "港南区",
                        "code": "450803"
                    },
                    {
                        "label": "覃塘区",
                        "value": "覃塘区",
                        "code": "450804"
                    },
                    {
                        "label": "平南县",
                        "value": "平南县",
                        "code": "450821"
                    },
                    {
                        "label": "桂平市",
                        "value": "桂平市",
                        "code": "450881"
                    }
                ],
                "code": "450800"
            },
            {
                "label": "玉林市",
                "value": "玉林市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "450901"
                    },
                    {
                        "label": "玉州区",
                        "value": "玉州区",
                        "code": "450902"
                    },
                    {
                        "label": "福绵区",
                        "value": "福绵区",
                        "code": "450903"
                    },
                    {
                        "label": "容县",
                        "value": "容县",
                        "code": "450921"
                    },
                    {
                        "label": "陆川县",
                        "value": "陆川县",
                        "code": "450922"
                    },
                    {
                        "label": "博白县",
                        "value": "博白县",
                        "code": "450923"
                    },
                    {
                        "label": "兴业县",
                        "value": "兴业县",
                        "code": "450924"
                    },
                    {
                        "label": "北流市",
                        "value": "北流市",
                        "code": "450981"
                    }
                ],
                "code": "450900"
            },
            {
                "label": "百色市",
                "value": "百色市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "451001"
                    },
                    {
                        "label": "右江区",
                        "value": "右江区",
                        "code": "451002"
                    },
                    {
                        "label": "田阳县",
                        "value": "田阳县",
                        "code": "451021"
                    },
                    {
                        "label": "田东县",
                        "value": "田东县",
                        "code": "451022"
                    },
                    {
                        "label": "平果县",
                        "value": "平果县",
                        "code": "451023"
                    },
                    {
                        "label": "德保县",
                        "value": "德保县",
                        "code": "451024"
                    },
                    {
                        "label": "靖西县",
                        "value": "靖西县",
                        "code": "451025"
                    },
                    {
                        "label": "那坡县",
                        "value": "那坡县",
                        "code": "451026"
                    },
                    {
                        "label": "凌云县",
                        "value": "凌云县",
                        "code": "451027"
                    },
                    {
                        "label": "乐业县",
                        "value": "乐业县",
                        "code": "451028"
                    },
                    {
                        "label": "田林县",
                        "value": "田林县",
                        "code": "451029"
                    },
                    {
                        "label": "西林县",
                        "value": "西林县",
                        "code": "451030"
                    },
                    {
                        "label": "隆林各族自治县",
                        "value": "隆林各族自治县",
                        "code": "451031"
                    }
                ],
                "code": "451000"
            },
            {
                "label": "贺州市",
                "value": "贺州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "451101"
                    },
                    {
                        "label": "八步区",
                        "value": "八步区",
                        "code": "451102"
                    },
                    {
                        "label": "平桂管理区",
                        "value": "平桂管理区",
                        "code": "451119"
                    },
                    {
                        "label": "昭平县",
                        "value": "昭平县",
                        "code": "451121"
                    },
                    {
                        "label": "钟山县",
                        "value": "钟山县",
                        "code": "451122"
                    },
                    {
                        "label": "富川瑶族自治县",
                        "value": "富川瑶族自治县",
                        "code": "451123"
                    }
                ],
                "code": "451100"
            },
            {
                "label": "河池市",
                "value": "河池市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "451201"
                    },
                    {
                        "label": "金城江区",
                        "value": "金城江区",
                        "code": "451202"
                    },
                    {
                        "label": "南丹县",
                        "value": "南丹县",
                        "code": "451221"
                    },
                    {
                        "label": "天峨县",
                        "value": "天峨县",
                        "code": "451222"
                    },
                    {
                        "label": "凤山县",
                        "value": "凤山县",
                        "code": "451223"
                    },
                    {
                        "label": "东兰县",
                        "value": "东兰县",
                        "code": "451224"
                    },
                    {
                        "label": "罗城仫佬族自治县",
                        "value": "罗城仫佬族自治县",
                        "code": "451225"
                    },
                    {
                        "label": "环江毛南族自治县",
                        "value": "环江毛南族自治县",
                        "code": "451226"
                    },
                    {
                        "label": "巴马瑶族自治县",
                        "value": "巴马瑶族自治县",
                        "code": "451227"
                    },
                    {
                        "label": "都安瑶族自治县",
                        "value": "都安瑶族自治县",
                        "code": "451228"
                    },
                    {
                        "label": "大化瑶族自治县",
                        "value": "大化瑶族自治县",
                        "code": "451229"
                    },
                    {
                        "label": "宜州市",
                        "value": "宜州市",
                        "code": "451281"
                    }
                ],
                "code": "451200"
            },
            {
                "label": "来宾市",
                "value": "来宾市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "451301"
                    },
                    {
                        "label": "兴宾区",
                        "value": "兴宾区",
                        "code": "451302"
                    },
                    {
                        "label": "忻城县",
                        "value": "忻城县",
                        "code": "451321"
                    },
                    {
                        "label": "象州县",
                        "value": "象州县",
                        "code": "451322"
                    },
                    {
                        "label": "武宣县",
                        "value": "武宣县",
                        "code": "451323"
                    },
                    {
                        "label": "金秀瑶族自治县",
                        "value": "金秀瑶族自治县",
                        "code": "451324"
                    },
                    {
                        "label": "合山市",
                        "value": "合山市",
                        "code": "451381"
                    }
                ],
                "code": "451300"
            },
            {
                "label": "崇左市",
                "value": "崇左市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "451401"
                    },
                    {
                        "label": "江州区",
                        "value": "江州区",
                        "code": "451402"
                    },
                    {
                        "label": "扶绥县",
                        "value": "扶绥县",
                        "code": "451421"
                    },
                    {
                        "label": "宁明县",
                        "value": "宁明县",
                        "code": "451422"
                    },
                    {
                        "label": "龙州县",
                        "value": "龙州县",
                        "code": "451423"
                    },
                    {
                        "label": "大新县",
                        "value": "大新县",
                        "code": "451424"
                    },
                    {
                        "label": "天等县",
                        "value": "天等县",
                        "code": "451425"
                    },
                    {
                        "label": "凭祥市",
                        "value": "凭祥市",
                        "code": "451481"
                    }
                ],
                "code": "451400"
            }
        ],
        "code": "450000"
    },
    {
        "label": "海南省",
        "value": "海南省",
        "children": [
            {
                "label": "海口市",
                "value": "海口市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "460101"
                    },
                    {
                        "label": "秀英区",
                        "value": "秀英区",
                        "code": "460105"
                    },
                    {
                        "label": "龙华区",
                        "value": "龙华区",
                        "code": "460106"
                    },
                    {
                        "label": "琼山区",
                        "value": "琼山区",
                        "code": "460107"
                    },
                    {
                        "label": "美兰区",
                        "value": "美兰区",
                        "code": "460108"
                    }
                ],
                "code": "460100"
            },
            {
                "label": "三亚市",
                "value": "三亚市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "460201"
                    },
                    {
                        "label": "海棠区",
                        "value": "海棠区",
                        "code": "460202"
                    },
                    {
                        "label": "吉阳区",
                        "value": "吉阳区",
                        "code": "460203"
                    },
                    {
                        "label": "天涯区",
                        "value": "天涯区",
                        "code": "460204"
                    },
                    {
                        "label": "崖州区",
                        "value": "崖州区",
                        "code": "460205"
                    }
                ],
                "code": "460200"
            },
            {
                "label": "三沙市",
                "value": "三沙市",
                "children": [
                    {
                        "label": "西沙群岛",
                        "value": "西沙群岛",
                        "code": "460321"
                    },
                    {
                        "label": "南沙群岛",
                        "value": "南沙群岛",
                        "code": "460322"
                    },
                    {
                        "label": "中沙群岛的岛礁及其海域",
                        "value": "中沙群岛的岛礁及其海域",
                        "code": "460323"
                    }
                ],
                "code": "460300"
            },
            {
                "label": "五指山市",
                "value": "五指山市",
                "code": "469001"
            },
            {
                "label": "琼海市",
                "value": "琼海市",
                "code": "469002"
            },
            {
                "label": "儋州市",
                "value": "儋州市",
                "code": "469003"
            },
            {
                "label": "文昌市",
                "value": "文昌市",
                "code": "469005"
            },
            {
                "label": "万宁市",
                "value": "万宁市",
                "code": "469006"
            },
            {
                "label": "东方市",
                "value": "东方市",
                "code": "469007"
            },
            {
                "label": "定安县",
                "value": "定安县",
                "code": "469021"
            },
            {
                "label": "屯昌县",
                "value": "屯昌县",
                "code": "469022"
            },
            {
                "label": "澄迈县",
                "value": "澄迈县",
                "code": "469023"
            },
            {
                "label": "临高县",
                "value": "临高县",
                "code": "469024"
            },
            {
                "label": "白沙黎族自治县",
                "value": "白沙黎族自治县",
                "code": "469025"
            },
            {
                "label": "昌江黎族自治县",
                "value": "昌江黎族自治县",
                "code": "469026"
            },
            {
                "label": "乐东黎族自治县",
                "value": "乐东黎族自治县",
                "code": "469027"
            },
            {
                "label": "陵水黎族自治县",
                "value": "陵水黎族自治县",
                "code": "469028"
            },
            {
                "label": "保亭黎族苗族自治县",
                "value": "保亭黎族苗族自治县",
                "code": "469029"
            },
            {
                "label": "琼中黎族苗族自治县",
                "value": "琼中黎族苗族自治县",
                "code": "469030"
            }
        ],
        "code": "460000"
    },
    {
        "label": "重庆市",
        "value": "重庆市",
        "children": [
            {
                "label": "重庆市",
                "value": "重庆市",
                "children": [
                    {
                        "label": "万州区",
                        "value": "万州区",
                        "code": "500101"
                    },
                    {
                        "label": "涪陵区",
                        "value": "涪陵区",
                        "code": "500102"
                    },
                    {
                        "label": "渝中区",
                        "value": "渝中区",
                        "code": "500103"
                    },
                    {
                        "label": "大渡口区",
                        "value": "大渡口区",
                        "code": "500104"
                    },
                    {
                        "label": "江北区",
                        "value": "江北区",
                        "code": "500105"
                    },
                    {
                        "label": "沙坪坝区",
                        "value": "沙坪坝区",
                        "code": "500106"
                    },
                    {
                        "label": "九龙坡区",
                        "value": "九龙坡区",
                        "code": "500107"
                    },
                    {
                        "label": "南岸区",
                        "value": "南岸区",
                        "code": "500108"
                    },
                    {
                        "label": "北碚区",
                        "value": "北碚区",
                        "code": "500109"
                    },
                    {
                        "label": "綦江区",
                        "value": "綦江区",
                        "code": "500110"
                    },
                    {
                        "label": "大足区",
                        "value": "大足区",
                        "code": "500111"
                    },
                    {
                        "label": "渝北区",
                        "value": "渝北区",
                        "code": "500112"
                    },
                    {
                        "label": "巴南区",
                        "value": "巴南区",
                        "code": "500113"
                    },
                    {
                        "label": "黔江区",
                        "value": "黔江区",
                        "code": "500114"
                    },
                    {
                        "label": "长寿区",
                        "value": "长寿区",
                        "code": "500115"
                    },
                    {
                        "label": "江津区",
                        "value": "江津区",
                        "code": "500116"
                    },
                    {
                        "label": "合川区",
                        "value": "合川区",
                        "code": "500117"
                    },
                    {
                        "label": "永川区",
                        "value": "永川区",
                        "code": "500118"
                    },
                    {
                        "label": "南川区",
                        "value": "南川区",
                        "code": "500119"
                    },
                    {
                        "label": "璧山区",
                        "value": "璧山区",
                        "code": "500120"
                    },
                    {
                        "label": "铜梁区",
                        "value": "铜梁区",
                        "code": "500151"
                    },
                    {
                        "label": "潼南县",
                        "value": "潼南县",
                        "code": "500223"
                    },
                    {
                        "label": "荣昌县",
                        "value": "荣昌县",
                        "code": "500226"
                    },
                    {
                        "label": "梁平县",
                        "value": "梁平县",
                        "code": "500228"
                    },
                    {
                        "label": "城口县",
                        "value": "城口县",
                        "code": "500229"
                    },
                    {
                        "label": "丰都县",
                        "value": "丰都县",
                        "code": "500230"
                    },
                    {
                        "label": "垫江县",
                        "value": "垫江县",
                        "code": "500231"
                    },
                    {
                        "label": "武隆县",
                        "value": "武隆县",
                        "code": "500232"
                    },
                    {
                        "label": "忠县",
                        "value": "忠县",
                        "code": "500233"
                    },
                    {
                        "label": "开县",
                        "value": "开县",
                        "code": "500234"
                    },
                    {
                        "label": "云阳县",
                        "value": "云阳县",
                        "code": "500235"
                    },
                    {
                        "label": "奉节县",
                        "value": "奉节县",
                        "code": "500236"
                    },
                    {
                        "label": "巫山县",
                        "value": "巫山县",
                        "code": "500237"
                    },
                    {
                        "label": "巫溪县",
                        "value": "巫溪县",
                        "code": "500238"
                    },
                    {
                        "label": "石柱土家族自治县",
                        "value": "石柱土家族自治县",
                        "code": "500240"
                    },
                    {
                        "label": "秀山土家族苗族自治县",
                        "value": "秀山土家族苗族自治县",
                        "code": "500241"
                    },
                    {
                        "label": "酉阳土家族苗族自治县",
                        "value": "酉阳土家族苗族自治县",
                        "code": "500242"
                    },
                    {
                        "label": "彭水苗族土家族自治县",
                        "value": "彭水苗族土家族自治县",
                        "code": "500243"
                    }
                ],
                "code": "500000"
            }
        ],
        "code": "500000"
    },
    {
        "label": "四川省",
        "value": "四川省",
        "children": [
            {
                "label": "成都市",
                "value": "成都市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "510101"
                    },
                    {
                        "label": "锦江区",
                        "value": "锦江区",
                        "code": "510104"
                    },
                    {
                        "label": "青羊区",
                        "value": "青羊区",
                        "code": "510105"
                    },
                    {
                        "label": "金牛区",
                        "value": "金牛区",
                        "code": "510106"
                    },
                    {
                        "label": "武侯区",
                        "value": "武侯区",
                        "code": "510107"
                    },
                    {
                        "label": "成华区",
                        "value": "成华区",
                        "code": "510108"
                    },
                    {
                        "label": "龙泉驿区",
                        "value": "龙泉驿区",
                        "code": "510112"
                    },
                    {
                        "label": " 青白江区",
                        "value": "青白江区",
                        "code": "510113"
                    },
                    {
                        "label": "新都区",
                        "value": "新都区",
                        "code": "510114"
                    },
                    {
                        "label": "温江区",
                        "value": "温江区",
                        "code": "510115"
                    },
                    {
                        "label": "金堂县",
                        "value": "金堂县",
                        "code": "510121"
                    },
                    {
                        "label": "双流县",
                        "value": "双流县",
                        "code": "510122"
                    },
                    {
                        "label": "郫县",
                        "value": "郫县",
                        "code": "510124"
                    },
                    {
                        "label": "大邑县",
                        "value": "大邑县",
                        "code": "510129"
                    },
                    {
                        "label": "蒲江县",
                        "value": "蒲江县",
                        "code": "510131"
                    },
                    {
                        "label": "新津县",
                        "value": "新津县",
                        "code": "510132"
                    },
                    {
                        "label": "都江堰市",
                        "value": "都江堰市",
                        "code": "510181"
                    },
                    {
                        "label": "彭州市",
                        "value": "彭州市",
                        "code": "510182"
                    },
                    {
                        "label": "邛崃市",
                        "value": "邛崃市",
                        "code": "510183"
                    },
                    {
                        "label": "崇州市",
                        "value": "崇州市",
                        "code": "510184"
                    }
                ],
                "code": "510100"
            },
            {
                "label": "自贡市",
                "value": "自贡市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "510301"
                    },
                    {
                        "label": "自流井区",
                        "value": "自流井区",
                        "code": "510302"
                    },
                    {
                        "label": "贡井区",
                        "value": "贡井区",
                        "code": "510303"
                    },
                    {
                        "label": "大安区",
                        "value": "大安区",
                        "code": "510304"
                    },
                    {
                        "label": "沿滩区",
                        "value": "沿滩区",
                        "code": "510311"
                    },
                    {
                        "label": "荣县",
                        "value": "荣县",
                        "code": "510321"
                    },
                    {
                        "label": "富顺县",
                        "value": "富顺县",
                        "code": "510322"
                    }
                ],
                "code": "510300"
            },
            {
                "label": "攀枝花市",
                "value": "攀枝花市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "510401"
                    },
                    {
                        "label": "东区",
                        "value": "东区",
                        "code": "510402"
                    },
                    {
                        "label": "西区",
                        "value": "西区",
                        "code": "510403"
                    },
                    {
                        "label": "仁和区",
                        "value": "仁和区",
                        "code": "510411"
                    },
                    {
                        "label": "米易县",
                        "value": "米易县",
                        "code": "510421"
                    },
                    {
                        "label": "盐边县",
                        "value": "盐边县",
                        "code": "510422"
                    }
                ],
                "code": "510400"
            },
            {
                "label": "泸州市",
                "value": "泸州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "510501"
                    },
                    {
                        "label": "江阳区",
                        "value": "江阳区",
                        "code": "510502"
                    },
                    {
                        "label": "纳溪区",
                        "value": "纳溪区",
                        "code": "510503"
                    },
                    {
                        "label": "龙马潭区",
                        "value": "龙马潭区",
                        "code": "510504"
                    },
                    {
                        "label": "泸县",
                        "value": "泸县",
                        "code": "510521"
                    },
                    {
                        "label": "合江县",
                        "value": "合江县",
                        "code": "510522"
                    },
                    {
                        "label": "叙永县",
                        "value": "叙永县",
                        "code": "510524"
                    },
                    {
                        "label": "古蔺县",
                        "value": "古蔺县",
                        "code": "510525"
                    }
                ],
                "code": "510500"
            },
            {
                "label": "德阳市",
                "value": "德阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "510601"
                    },
                    {
                        "label": "旌阳区",
                        "value": "旌阳区",
                        "code": "510603"
                    },
                    {
                        "label": "中江县",
                        "value": "中江县",
                        "code": "510623"
                    },
                    {
                        "label": "罗江县",
                        "value": "罗江县",
                        "code": "510626"
                    },
                    {
                        "label": "广汉市",
                        "value": "广汉市",
                        "code": "510681"
                    },
                    {
                        "label": "什邡市",
                        "value": "什邡市",
                        "code": "510682"
                    },
                    {
                        "label": "绵竹市",
                        "value": "绵竹市",
                        "code": "510683"
                    }
                ],
                "code": "510600"
            },
            {
                "label": "绵阳市",
                "value": "绵阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "510701"
                    },
                    {
                        "label": "涪城区",
                        "value": "涪城区",
                        "code": "510703"
                    },
                    {
                        "label": "游仙区",
                        "value": "游仙区",
                        "code": "510704"
                    },
                    {
                        "label": "三台县",
                        "value": "三台县",
                        "code": "510722"
                    },
                    {
                        "label": "盐亭县",
                        "value": "盐亭县",
                        "code": "510723"
                    },
                    {
                        "label": "安县",
                        "value": "安县",
                        "code": "510724"
                    },
                    {
                        "label": "梓潼县",
                        "value": "梓潼县",
                        "code": "510725"
                    },
                    {
                        "label": "北川羌族自治县",
                        "value": "北川羌族自治县",
                        "code": "510726"
                    },
                    {
                        "label": "平武县",
                        "value": "平武县",
                        "code": "510727"
                    },
                    {
                        "label": "江油市",
                        "value": "江油市",
                        "code": "510781"
                    }
                ],
                "code": "510700"
            },
            {
                "label": "广元市",
                "value": "广元市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "510801"
                    },
                    {
                        "label": "利州区",
                        "value": "利州区",
                        "code": "510802"
                    },
                    {
                        "label": "昭化区",
                        "value": "昭化区",
                        "code": "510811"
                    },
                    {
                        "label": "朝天区",
                        "value": "朝天区",
                        "code": "510812"
                    },
                    {
                        "label": "旺苍县",
                        "value": "旺苍县",
                        "code": "510821"
                    },
                    {
                        "label": "青川县",
                        "value": "青川县",
                        "code": "510822"
                    },
                    {
                        "label": "剑阁县",
                        "value": "剑阁县",
                        "code": "510823"
                    },
                    {
                        "label": "苍溪县",
                        "value": "苍溪县",
                        "code": "510824"
                    }
                ],
                "code": "510800"
            },
            {
                "label": "遂宁市",
                "value": "遂宁市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "510901"
                    },
                    {
                        "label": "船山区",
                        "value": "船山区",
                        "code": "510903"
                    },
                    {
                        "label": "安居区",
                        "value": "安居区",
                        "code": "510904"
                    },
                    {
                        "label": "蓬溪县",
                        "value": "蓬溪县",
                        "code": "510921"
                    },
                    {
                        "label": "射洪县",
                        "value": "射洪县",
                        "code": "510922"
                    },
                    {
                        "label": "大英县",
                        "value": "大英县",
                        "code": "510923"
                    }
                ],
                "code": "510900"
            },
            {
                "label": "内江市",
                "value": "内江市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "511001"
                    },
                    {
                        "label": "市中区",
                        "value": "市中区",
                        "code": "511002"
                    },
                    {
                        "label": "东兴区",
                        "value": "东兴区",
                        "code": "511011"
                    },
                    {
                        "label": "威远县",
                        "value": "威远县",
                        "code": "511024"
                    },
                    {
                        "label": "资中县",
                        "value": "资中县",
                        "code": "511025"
                    },
                    {
                        "label": "隆昌县",
                        "value": "隆昌县",
                        "code": "511028"
                    }
                ],
                "code": "511000"
            },
            {
                "label": "乐山市",
                "value": "乐山市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "511101"
                    },
                    {
                        "label": "市中区",
                        "value": "市中区",
                        "code": "511102"
                    },
                    {
                        "label": "沙湾区",
                        "value": "沙湾区",
                        "code": "511111"
                    },
                    {
                        "label": "五通桥区",
                        "value": "五通桥区",
                        "code": "511112"
                    },
                    {
                        "label": "金口河区",
                        "value": "金口河区",
                        "code": "511113"
                    },
                    {
                        "label": "犍为县",
                        "value": "犍为县",
                        "code": "511123"
                    },
                    {
                        "label": "井研县",
                        "value": "井研县",
                        "code": "511124"
                    },
                    {
                        "label": "夹江县",
                        "value": "夹江县",
                        "code": "511126"
                    },
                    {
                        "label": "沐川县",
                        "value": "沐川县",
                        "code": "511129"
                    },
                    {
                        "label": "峨边彝族自治县",
                        "value": "峨边彝族自治县",
                        "code": "511132"
                    },
                    {
                        "label": "马边彝族自治县",
                        "value": "马边彝族自治县",
                        "code": "511133"
                    },
                    {
                        "label": "峨眉山市",
                        "value": "峨眉山市",
                        "code": "511181"
                    }
                ],
                "code": "511100"
            },
            {
                "label": "南充市",
                "value": "南充市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "511301"
                    },
                    {
                        "label": "顺庆区",
                        "value": "顺庆区",
                        "code": "511302"
                    },
                    {
                        "label": "高坪区",
                        "value": "高坪区",
                        "code": "511303"
                    },
                    {
                        "label": "嘉陵区",
                        "value": "嘉陵区",
                        "code": "511304"
                    },
                    {
                        "label": "南部县",
                        "value": "南部县",
                        "code": "511321"
                    },
                    {
                        "label": "营山县",
                        "value": "营山县",
                        "code": "511322"
                    },
                    {
                        "label": "蓬安县",
                        "value": "蓬安县",
                        "code": "511323"
                    },
                    {
                        "label": "仪陇县",
                        "value": "仪陇县",
                        "code": "511324"
                    },
                    {
                        "label": "西充县",
                        "value": "西充县",
                        "code": "511325"
                    },
                    {
                        "label": "阆中市",
                        "value": "阆中市",
                        "code": "511381"
                    }
                ],
                "code": "511300"
            },
            {
                "label": "眉山市",
                "value": "眉山市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "511401"
                    },
                    {
                        "label": "东坡区",
                        "value": "东坡区",
                        "code": "511402"
                    },
                    {
                        "label": "彭山区",
                        "value": "彭山区",
                        "code": "511403"
                    },
                    {
                        "label": "仁寿县",
                        "value": "仁寿县",
                        "code": "511421"
                    },
                    {
                        "label": "洪雅县",
                        "value": "洪雅县",
                        "code": "511423"
                    },
                    {
                        "label": "丹棱县",
                        "value": "丹棱县",
                        "code": "511424"
                    },
                    {
                        "label": "青神县",
                        "value": "青神县",
                        "code": "511425"
                    }
                ],
                "code": "511400"
            },
            {
                "label": "宜宾市",
                "value": "宜宾市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "511501"
                    },
                    {
                        "label": "翠屏区",
                        "value": "翠屏区",
                        "code": "511502"
                    },
                    {
                        "label": "南溪区",
                        "value": "南溪区",
                        "code": "511503"
                    },
                    {
                        "label": "宜宾县",
                        "value": "宜宾县",
                        "code": "511521"
                    },
                    {
                        "label": "江安县",
                        "value": "江安县",
                        "code": "511523"
                    },
                    {
                        "label": "长宁县",
                        "value": "长宁县",
                        "code": "511524"
                    },
                    {
                        "label": "高县",
                        "value": "高县",
                        "code": "511525"
                    },
                    {
                        "label": "珙县",
                        "value": "珙县",
                        "code": "511526"
                    },
                    {
                        "label": "筠连县",
                        "value": "筠连县",
                        "code": "511527"
                    },
                    {
                        "label": "兴文县",
                        "value": "兴文县",
                        "code": "511528"
                    },
                    {
                        "label": "屏山县",
                        "value": "屏山县",
                        "code": "511529"
                    }
                ],
                "code": "511500"
            },
            {
                "label": "广安市",
                "value": "广安市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "511601"
                    },
                    {
                        "label": "广安区",
                        "value": "广安区",
                        "code": "511602"
                    },
                    {
                        "label": "前锋区",
                        "value": "前锋区",
                        "code": "511603"
                    },
                    {
                        "label": "岳池县",
                        "value": "岳池县",
                        "code": "511621"
                    },
                    {
                        "label": "武胜县",
                        "value": "武胜县",
                        "code": "511622"
                    },
                    {
                        "label": "邻水县",
                        "value": "邻水县",
                        "code": "511623"
                    },
                    {
                        "label": "华蓥市",
                        "value": "华蓥市",
                        "code": "511681"
                    }
                ],
                "code": "511600"
            },
            {
                "label": "达州市",
                "value": "达州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "511701"
                    },
                    {
                        "label": "通川区",
                        "value": "通川区",
                        "code": "511702"
                    },
                    {
                        "label": "达川区",
                        "value": "达川区",
                        "code": "511703"
                    },
                    {
                        "label": "宣汉县",
                        "value": "宣汉县",
                        "code": "511722"
                    },
                    {
                        "label": "开江县",
                        "value": "开江县",
                        "code": "511723"
                    },
                    {
                        "label": "大竹县",
                        "value": "大竹县",
                        "code": "511724"
                    },
                    {
                        "label": "渠县",
                        "value": "渠县",
                        "code": "511725"
                    },
                    {
                        "label": "万源市",
                        "value": "万源市",
                        "code": "511781"
                    }
                ],
                "code": "511700"
            },
            {
                "label": "雅安市",
                "value": "雅安市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "511801"
                    },
                    {
                        "label": "雨城区",
                        "value": "雨城区",
                        "code": "511802"
                    },
                    {
                        "label": "名山区",
                        "value": "名山区",
                        "code": "511803"
                    },
                    {
                        "label": "荥经县",
                        "value": "荥经县",
                        "code": "511822"
                    },
                    {
                        "label": "汉源县",
                        "value": "汉源县",
                        "code": "511823"
                    },
                    {
                        "label": "石棉县",
                        "value": "石棉县",
                        "code": "511824"
                    },
                    {
                        "label": "天全县",
                        "value": "天全县",
                        "code": "511825"
                    },
                    {
                        "label": "芦山县",
                        "value": "芦山县",
                        "code": "511826"
                    },
                    {
                        "label": "宝兴县",
                        "value": "宝兴县",
                        "code": "511827"
                    }
                ],
                "code": "511800"
            },
            {
                "label": "巴中市",
                "value": "巴中市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "511901"
                    },
                    {
                        "label": "巴州区",
                        "value": "巴州区",
                        "code": "511902"
                    },
                    {
                        "label": "恩阳区",
                        "value": "恩阳区",
                        "code": "511903"
                    },
                    {
                        "label": "通江县",
                        "value": "通江县",
                        "code": "511921"
                    },
                    {
                        "label": "南江县",
                        "value": "南江县",
                        "code": "511922"
                    },
                    {
                        "label": "平昌县",
                        "value": "平昌县",
                        "code": "511923"
                    }
                ],
                "code": "511900"
            },
            {
                "label": "资阳市",
                "value": "资阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "512001"
                    },
                    {
                        "label": "雁江区",
                        "value": "雁江区",
                        "code": "512002"
                    },
                    {
                        "label": "安岳县",
                        "value": "安岳县",
                        "code": "512021"
                    },
                    {
                        "label": "乐至县",
                        "value": "乐至县",
                        "code": "512022"
                    },
                    {
                        "label": "简阳市",
                        "value": "简阳市",
                        "code": "512081"
                    }
                ],
                "code": "512000"
            },
            {
                "label": "阿坝藏族羌族自治州",
                "value": "阿坝藏族羌族自治州",
                "children": [
                    {
                        "label": "汶川县",
                        "value": "汶川县",
                        "code": "513221"
                    },
                    {
                        "label": "理县",
                        "value": "理县",
                        "code": "513222"
                    },
                    {
                        "label": "茂县",
                        "value": "茂县",
                        "code": "513223"
                    },
                    {
                        "label": "松潘县",
                        "value": "松潘县",
                        "code": "513224"
                    },
                    {
                        "label": "九寨沟县",
                        "value": "九寨沟县",
                        "code": "513225"
                    },
                    {
                        "label": "金川县",
                        "value": "金川县",
                        "code": "513226"
                    },
                    {
                        "label": "小金县",
                        "value": "小金县",
                        "code": "513227"
                    },
                    {
                        "label": "黑水县",
                        "value": "黑水县",
                        "code": "513228"
                    },
                    {
                        "label": "马尔康县",
                        "value": "马尔康县",
                        "code": "513229"
                    },
                    {
                        "label": "壤塘县",
                        "value": "壤塘县",
                        "code": "513230"
                    },
                    {
                        "label": "阿坝县",
                        "value": "阿坝县",
                        "code": "513231"
                    },
                    {
                        "label": "若尔盖县",
                        "value": "若尔盖县",
                        "code": "513232"
                    },
                    {
                        "label": "红原县",
                        "value": "红原县",
                        "code": "513233"
                    }
                ],
                "code": "513200"
            },
            {
                "label": "甘孜藏族自治州",
                "value": "甘孜藏族自治州",
                "children": [
                    {
                        "label": "康定县",
                        "value": "康定县",
                        "code": "513321"
                    },
                    {
                        "label": "泸定县",
                        "value": "泸定县",
                        "code": "513322"
                    },
                    {
                        "label": "丹巴县",
                        "value": "丹巴县",
                        "code": "513323"
                    },
                    {
                        "label": "九龙县",
                        "value": "九龙县",
                        "code": "513324"
                    },
                    {
                        "label": "雅江县",
                        "value": "雅江县",
                        "code": "513325"
                    },
                    {
                        "label": "道孚县",
                        "value": "道孚县",
                        "code": "513326"
                    },
                    {
                        "label": "炉霍县",
                        "value": "炉霍县",
                        "code": "513327"
                    },
                    {
                        "label": "甘孜县",
                        "value": "甘孜县",
                        "code": "513328"
                    },
                    {
                        "label": "新龙县",
                        "value": "新龙县",
                        "code": "513329"
                    },
                    {
                        "label": "德格县",
                        "value": "德格县",
                        "code": "513330"
                    },
                    {
                        "label": "白玉县",
                        "value": "白玉县",
                        "code": "513331"
                    },
                    {
                        "label": "石渠县",
                        "value": "石渠县",
                        "code": "513332"
                    },
                    {
                        "label": "色达县",
                        "value": "色达县",
                        "code": "513333"
                    },
                    {
                        "label": "理塘县",
                        "value": "理塘县",
                        "code": "513334"
                    },
                    {
                        "label": "巴塘县",
                        "value": "巴塘县",
                        "code": "513335"
                    },
                    {
                        "label": "乡城县",
                        "value": "乡城县",
                        "code": "513336"
                    },
                    {
                        "label": "稻城县",
                        "value": "稻城县",
                        "code": "513337"
                    },
                    {
                        "label": "得荣县",
                        "value": "得荣县",
                        "code": "513338"
                    }
                ],
                "code": "513300"
            },
            {
                "label": "凉山彝族自治州",
                "value": "凉山彝族自治州",
                "children": [
                    {
                        "label": "西昌市",
                        "value": "西昌市",
                        "code": "513401"
                    },
                    {
                        "label": "木里藏族自治县",
                        "value": "木里藏族自治县",
                        "code": "513422"
                    },
                    {
                        "label": "盐源县",
                        "value": "盐源县",
                        "code": "513423"
                    },
                    {
                        "label": "德昌县",
                        "value": "德昌县",
                        "code": "513424"
                    },
                    {
                        "label": "会理县",
                        "value": "会理县",
                        "code": "513425"
                    },
                    {
                        "label": "会东县",
                        "value": "会东县",
                        "code": "513426"
                    },
                    {
                        "label": "宁南县",
                        "value": "宁南县",
                        "code": "513427"
                    },
                    {
                        "label": "普格县",
                        "value": "普格县",
                        "code": "513428"
                    },
                    {
                        "label": "布拖县",
                        "value": "布拖县",
                        "code": "513429"
                    },
                    {
                        "label": "金阳县",
                        "value": "金阳县",
                        "code": "513430"
                    },
                    {
                        "label": "昭觉县",
                        "value": "昭觉县",
                        "code": "513431"
                    },
                    {
                        "label": "喜德县",
                        "value": "喜德县",
                        "code": "513432"
                    },
                    {
                        "label": "冕宁县",
                        "value": "冕宁县",
                        "code": "513433"
                    },
                    {
                        "label": "越西县",
                        "value": "越西县",
                        "code": "513434"
                    },
                    {
                        "label": "甘洛县",
                        "value": "甘洛县",
                        "code": "513435"
                    },
                    {
                        "label": "美姑县",
                        "value": "美姑县",
                        "code": "513436"
                    },
                    {
                        "label": "雷波县",
                        "value": "雷波县",
                        "code": "513437"
                    }
                ],
                "code": "513400"
            }
        ],
        "code": "510000"
    },
    {
        "label": "贵州省",
        "value": "贵州省",
        "children": [
            {
                "label": "贵阳市",
                "value": "贵阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "520101"
                    },
                    {
                        "label": "南明区",
                        "value": "南明区",
                        "code": "520102"
                    },
                    {
                        "label": "云岩区",
                        "value": "云岩区",
                        "code": "520103"
                    },
                    {
                        "label": "花溪区",
                        "value": "花溪区",
                        "code": "520111"
                    },
                    {
                        "label": "乌当区",
                        "value": "乌当区",
                        "code": "520112"
                    },
                    {
                        "label": "白云区",
                        "value": "白云区",
                        "code": "520113"
                    },
                    {
                        "label": "观山湖区",
                        "value": "观山湖区",
                        "code": "520115"
                    },
                    {
                        "label": "开阳县",
                        "value": "开阳县",
                        "code": "520121"
                    },
                    {
                        "label": "息烽县",
                        "value": "息烽县",
                        "code": "520122"
                    },
                    {
                        "label": "修文县",
                        "value": "修文县",
                        "code": "520123"
                    },
                    {
                        "label": "清镇市",
                        "value": "清镇市",
                        "code": "520181"
                    }
                ],
                "code": "520100"
            },
            {
                "label": "六盘水市",
                "value": "六盘水市",
                "children": [
                    {
                        "label": "钟山区",
                        "value": "钟山区",
                        "code": "520201"
                    },
                    {
                        "label": "六枝特区",
                        "value": "六枝特区",
                        "code": "520203"
                    },
                    {
                        "label": "水城县",
                        "value": "水城县",
                        "code": "520221"
                    },
                    {
                        "label": "盘县",
                        "value": "盘县",
                        "code": "520222"
                    }
                ],
                "code": "520200"
            },
            {
                "label": "遵义市",
                "value": "遵义市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "520301"
                    },
                    {
                        "label": "红花岗区",
                        "value": "红花岗区",
                        "code": "520302"
                    },
                    {
                        "label": "汇川区",
                        "value": "汇川区",
                        "code": "520303"
                    },
                    {
                        "label": "遵义县",
                        "value": "遵义县",
                        "code": "520321"
                    },
                    {
                        "label": "桐梓县",
                        "value": "桐梓县",
                        "code": "520322"
                    },
                    {
                        "label": "绥阳县",
                        "value": "绥阳县",
                        "code": "520323"
                    },
                    {
                        "label": "正安县",
                        "value": "正安县",
                        "code": "520324"
                    },
                    {
                        "label": "道真仡佬族苗族自治县",
                        "value": "道真仡佬族苗族自治县",
                        "code": "520325"
                    },
                    {
                        "label": "务川仡佬族苗族自治县",
                        "value": "务川仡佬族苗族自治县",
                        "code": "520326"
                    },
                    {
                        "label": "凤冈县",
                        "value": "凤冈县",
                        "code": "520327"
                    },
                    {
                        "label": "湄潭县",
                        "value": "湄潭县",
                        "code": "520328"
                    },
                    {
                        "label": "余庆县",
                        "value": "余庆县",
                        "code": "520329"
                    },
                    {
                        "label": "习水县",
                        "value": "习水县",
                        "code": "520330"
                    },
                    {
                        "label": "赤水市",
                        "value": "赤水市",
                        "code": "520381"
                    },
                    {
                        "label": "仁怀市",
                        "value": "仁怀市",
                        "code": "520382"
                    }
                ],
                "code": "520300"
            },
            {
                "label": "安顺市",
                "value": "安顺市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "520401"
                    },
                    {
                        "label": "西秀区",
                        "value": "西秀区",
                        "code": "520402"
                    },
                    {
                        "label": "平坝区",
                        "value": "平坝区",
                        "code": "520403"
                    },
                    {
                        "label": "普定县",
                        "value": "普定县",
                        "code": "520422"
                    },
                    {
                        "label": "镇宁布依族苗族自治县",
                        "value": "镇宁布依族苗族自治县",
                        "code": "520423"
                    },
                    {
                        "label": "关岭布依族苗族自治县",
                        "value": "关岭布依族苗族自治县",
                        "code": "520424"
                    },
                    {
                        "label": "紫云苗族布 依族自治县",
                        "value": "紫云苗族布依族自治县",
                        "code": "520425"
                    }
                ],
                "code": "520400"
            },
            {
                "label": "毕节市",
                "value": "毕节市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "520501"
                    },
                    {
                        "label": "七星关区",
                        "value": "七星关区",
                        "code": "520502"
                    },
                    {
                        "label": "大方县",
                        "value": "大方县",
                        "code": "520521"
                    },
                    {
                        "label": "黔西县",
                        "value": "黔西县",
                        "code": "520522"
                    },
                    {
                        "label": "金沙县",
                        "value": "金沙县",
                        "code": "520523"
                    },
                    {
                        "label": "织金县",
                        "value": "织金县",
                        "code": "520524"
                    },
                    {
                        "label": "纳雍县",
                        "value": "纳雍县",
                        "code": "520525"
                    },
                    {
                        "label": "威宁彝族回族苗族自治县",
                        "value": "威宁彝族回族苗族自治县",
                        "code": "520526"
                    },
                    {
                        "label": "赫章县",
                        "value": "赫章县",
                        "code": "520527"
                    }
                ],
                "code": "520500"
            },
            {
                "label": "铜仁市",
                "value": "铜仁市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "520601"
                    },
                    {
                        "label": "碧江区",
                        "value": "碧江区",
                        "code": "520602"
                    },
                    {
                        "label": "万山区",
                        "value": "万山区",
                        "code": "520603"
                    },
                    {
                        "label": "江口县",
                        "value": "江口县",
                        "code": "520621"
                    },
                    {
                        "label": "玉屏侗族自治县",
                        "value": "玉屏侗族自治县",
                        "code": "520622"
                    },
                    {
                        "label": "石阡县",
                        "value": "石阡县",
                        "code": "520623"
                    },
                    {
                        "label": "思南县",
                        "value": "思南县",
                        "code": "520624"
                    },
                    {
                        "label": "印江土家族苗族自治县",
                        "value": "印江土家族苗族自治县",
                        "code": "520625"
                    },
                    {
                        "label": "德江县",
                        "value": "德江县",
                        "code": "520626"
                    },
                    {
                        "label": "沿河土家族自治县",
                        "value": "沿河土家族自治县",
                        "code": "520627"
                    },
                    {
                        "label": "松桃苗族自治县",
                        "value": "松桃苗族自治县",
                        "code": "520628"
                    }
                ],
                "code": "520600"
            },
            {
                "label": "黔西南布依族苗族自治州",
                "value": "黔西南布依族苗族自治州",
                "children": [
                    {
                        "label": "兴义市",
                        "value": "兴义市",
                        "code": "522301"
                    },
                    {
                        "label": "兴仁县",
                        "value": "兴仁县",
                        "code": "522322"
                    },
                    {
                        "label": "普安县",
                        "value": "普安县",
                        "code": "522323"
                    },
                    {
                        "label": "晴隆县",
                        "value": "晴隆县",
                        "code": "522324"
                    },
                    {
                        "label": "贞丰县",
                        "value": "贞丰县",
                        "code": "522325"
                    },
                    {
                        "label": "望谟县",
                        "value": "望谟县",
                        "code": "522326"
                    },
                    {
                        "label": "册亨县",
                        "value": "册亨县",
                        "code": "522327"
                    },
                    {
                        "label": "安龙县",
                        "value": "安龙县",
                        "code": "522328"
                    }
                ],
                "code": "522300"
            },
            {
                "label": "黔东南苗族侗族自治州",
                "value": "黔东南苗族侗族自治州",
                "children": [
                    {
                        "label": "凯里市",
                        "value": "凯里市",
                        "code": "522601"
                    },
                    {
                        "label": "黄平县",
                        "value": "黄平县",
                        "code": "522622"
                    },
                    {
                        "label": "施秉县",
                        "value": "施秉县",
                        "code": "522623"
                    },
                    {
                        "label": "三穗县",
                        "value": "三穗县",
                        "code": "522624"
                    },
                    {
                        "label": "镇远县",
                        "value": "镇远县",
                        "code": "522625"
                    },
                    {
                        "label": "岑巩县",
                        "value": "岑巩县",
                        "code": "522626"
                    },
                    {
                        "label": "天柱县",
                        "value": "天柱县",
                        "code": "522627"
                    },
                    {
                        "label": "锦屏县",
                        "value": "锦屏县",
                        "code": "522628"
                    },
                    {
                        "label": "剑河县",
                        "value": "剑河县",
                        "code": "522629"
                    },
                    {
                        "label": "台江县",
                        "value": "台江县",
                        "code": "522630"
                    },
                    {
                        "label": "黎平县",
                        "value": "黎平县",
                        "code": "522631"
                    },
                    {
                        "label": "榕江县",
                        "value": "榕江县",
                        "code": "522632"
                    },
                    {
                        "label": "从江县",
                        "value": "从江县",
                        "code": "522633"
                    },
                    {
                        "label": "雷山县",
                        "value": "雷山县",
                        "code": "522634"
                    },
                    {
                        "label": "麻江县",
                        "value": "麻江县",
                        "code": "522635"
                    },
                    {
                        "label": "丹寨县",
                        "value": "丹寨县",
                        "code": "522636"
                    }
                ],
                "code": "522600"
            },
            {
                "label": "黔南布依族苗族自治州",
                "value": "黔南布依族苗族自治 州",
                "children": [
                    {
                        "label": "都匀市",
                        "value": "都匀市",
                        "code": "522701"
                    },
                    {
                        "label": "福泉市",
                        "value": "福泉市",
                        "code": "522702"
                    },
                    {
                        "label": "荔波县",
                        "value": "荔波县",
                        "code": "522722"
                    },
                    {
                        "label": "贵定县",
                        "value": "贵定县",
                        "code": "522723"
                    },
                    {
                        "label": "瓮安县",
                        "value": "瓮安县",
                        "code": "522725"
                    },
                    {
                        "label": "独山县",
                        "value": "独山县",
                        "code": "522726"
                    },
                    {
                        "label": "平塘县",
                        "value": "平塘县",
                        "code": "522727"
                    },
                    {
                        "label": "罗甸县",
                        "value": "罗甸县",
                        "code": "522728"
                    },
                    {
                        "label": "长顺县",
                        "value": "长顺县",
                        "code": "522729"
                    },
                    {
                        "label": "龙里县",
                        "value": "龙里县",
                        "code": "522730"
                    },
                    {
                        "label": "惠水县",
                        "value": "惠水县",
                        "code": "522731"
                    },
                    {
                        "label": "三都水族自治县",
                        "value": "三都水族自治县",
                        "code": "522732"
                    }
                ],
                "code": "522700"
            }
        ],
        "code": "520000"
    },
    {
        "label": "云南省",
        "value": "云南省",
        "children": [
            {
                "label": "昆明市",
                "value": "昆明市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "530101"
                    },
                    {
                        "label": "五华区",
                        "value": "五华区",
                        "code": "530102"
                    },
                    {
                        "label": "盘龙区",
                        "value": "盘龙区",
                        "code": "530103"
                    },
                    {
                        "label": "官渡区",
                        "value": "官渡区",
                        "code": "530111"
                    },
                    {
                        "label": "西山区",
                        "value": "西山区",
                        "code": "530112"
                    },
                    {
                        "label": "东川区",
                        "value": "东川区",
                        "code": "530113"
                    },
                    {
                        "label": "呈贡区",
                        "value": "呈贡区",
                        "code": "530114"
                    },
                    {
                        "label": "晋宁县",
                        "value": "晋宁县",
                        "code": "530122"
                    },
                    {
                        "label": "富民县",
                        "value": "富民县",
                        "code": "530124"
                    },
                    {
                        "label": "宜良县",
                        "value": "宜良县",
                        "code": "530125"
                    },
                    {
                        "label": "石林彝族自治县",
                        "value": "石林彝族自治县",
                        "code": "530126"
                    },
                    {
                        "label": "嵩明县",
                        "value": "嵩明县",
                        "code": "530127"
                    },
                    {
                        "label": "禄劝彝族苗族自治县",
                        "value": "禄劝彝族苗族自治县",
                        "code": "530128"
                    },
                    {
                        "label": "寻甸回族彝族自治县",
                        "value": "寻甸回族彝族自治县",
                        "code": "530129"
                    },
                    {
                        "label": "安宁市",
                        "value": "安宁市",
                        "code": "530181"
                    }
                ],
                "code": "530100"
            },
            {
                "label": "曲靖市",
                "value": "曲靖市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "530301"
                    },
                    {
                        "label": "麒麟区",
                        "value": "麒麟区",
                        "code": "530302"
                    },
                    {
                        "label": "马龙县",
                        "value": "马龙县",
                        "code": "530321"
                    },
                    {
                        "label": "陆良县",
                        "value": "陆良县",
                        "code": "530322"
                    },
                    {
                        "label": "师宗县",
                        "value": "师宗县",
                        "code": "530323"
                    },
                    {
                        "label": "罗平县",
                        "value": "罗平县",
                        "code": "530324"
                    },
                    {
                        "label": "富源县",
                        "value": "富源县",
                        "code": "530325"
                    },
                    {
                        "label": "会泽县",
                        "value": " 会泽县",
                        "code": "530326"
                    },
                    {
                        "label": "沾益县",
                        "value": "沾益县",
                        "code": "530328"
                    },
                    {
                        "label": "宣威市",
                        "value": "宣威市",
                        "code": "530381"
                    }
                ],
                "code": "530300"
            },
            {
                "label": "玉溪市",
                "value": "玉溪市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "530401"
                    },
                    {
                        "label": "红塔区",
                        "value": "红塔区",
                        "code": "530402"
                    },
                    {
                        "label": "江川县",
                        "value": "江川县",
                        "code": "530421"
                    },
                    {
                        "label": "澄江县",
                        "value": "澄江县",
                        "code": "530422"
                    },
                    {
                        "label": "通海县",
                        "value": "通海县",
                        "code": "530423"
                    },
                    {
                        "label": "华宁县",
                        "value": "华宁县",
                        "code": "530424"
                    },
                    {
                        "label": "易门县",
                        "value": "易门县",
                        "code": "530425"
                    },
                    {
                        "label": "峨山彝族自治县",
                        "value": "峨山彝族自治县",
                        "code": "530426"
                    },
                    {
                        "label": "新平彝族傣族自治县",
                        "value": "新平彝族傣族自治县",
                        "code": "530427"
                    },
                    {
                        "label": "元江哈尼族彝族傣族自治县",
                        "value": "元江哈尼族彝族傣族自治县",
                        "code": "530428"
                    }
                ],
                "code": "530400"
            },
            {
                "label": "保山市",
                "value": "保山市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "530501"
                    },
                    {
                        "label": "隆阳区",
                        "value": "隆阳区",
                        "code": "530502"
                    },
                    {
                        "label": "施甸县",
                        "value": "施甸县",
                        "code": "530521"
                    },
                    {
                        "label": "腾冲县",
                        "value": "腾冲县",
                        "code": "530522"
                    },
                    {
                        "label": "龙陵县",
                        "value": "龙陵县",
                        "code": "530523"
                    },
                    {
                        "label": "昌宁县",
                        "value": "昌宁县",
                        "code": "530524"
                    }
                ],
                "code": "530500"
            },
            {
                "label": "昭通市",
                "value": "昭通市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "530601"
                    },
                    {
                        "label": "昭阳区",
                        "value": "昭阳区",
                        "code": "530602"
                    },
                    {
                        "label": "鲁甸县",
                        "value": "鲁甸县",
                        "code": "530621"
                    },
                    {
                        "label": "巧家县",
                        "value": "巧家县",
                        "code": "530622"
                    },
                    {
                        "label": "盐津县",
                        "value": "盐津县",
                        "code": "530623"
                    },
                    {
                        "label": "大关县",
                        "value": "大关县",
                        "code": "530624"
                    },
                    {
                        "label": "永善县",
                        "value": "永善县",
                        "code": "530625"
                    },
                    {
                        "label": "绥江县",
                        "value": "绥江县",
                        "code": "530626"
                    },
                    {
                        "label": "镇雄县",
                        "value": "镇雄县",
                        "code": "530627"
                    },
                    {
                        "label": "彝良县",
                        "value": "彝良县",
                        "code": "530628"
                    },
                    {
                        "label": "威信县",
                        "value": "威信县",
                        "code": "530629"
                    },
                    {
                        "label": "水富县",
                        "value": "水富县",
                        "code": "530630"
                    }
                ],
                "code": "530600"
            },
            {
                "label": "丽江市",
                "value": "丽江市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "530701"
                    },
                    {
                        "label": "古城区",
                        "value": "古城区",
                        "code": "530702"
                    },
                    {
                        "label": "玉龙纳西族自治县",
                        "value": "玉龙纳西族自治县",
                        "code": "530721"
                    },
                    {
                        "label": "永胜县",
                        "value": "永胜县",
                        "code": "530722"
                    },
                    {
                        "label": "华坪县",
                        "value": "华坪县",
                        "code": "530723"
                    },
                    {
                        "label": "宁蒗彝族自治县",
                        "value": "宁蒗彝族自治县",
                        "code": "530724"
                    }
                ],
                "code": "530700"
            },
            {
                "label": "普洱市",
                "value": "普洱市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "530801"
                    },
                    {
                        "label": "思茅区",
                        "value": "思茅区",
                        "code": "530802"
                    },
                    {
                        "label": "宁洱哈尼族彝族自治县",
                        "value": "宁洱哈尼族彝族自治县",
                        "code": "530821"
                    },
                    {
                        "label": "墨江哈尼族自治县",
                        "value": "墨江哈尼族自治县",
                        "code": "530822"
                    },
                    {
                        "label": "景东彝族自治县",
                        "value": "景东彝族自治县",
                        "code": "530823"
                    },
                    {
                        "label": "景谷傣族彝族自治县",
                        "value": "景谷傣族彝族自治县",
                        "code": "530824"
                    },
                    {
                        "label": "镇沅彝族哈尼族拉祜族自治县",
                        "value": "镇沅彝族哈尼族拉祜族自治县",
                        "code": "530825"
                    },
                    {
                        "label": "江城哈尼族彝族自治县",
                        "value": "江城哈尼族彝族自治县",
                        "code": "530826"
                    },
                    {
                        "label": "孟连傣族拉祜族佤族自治县",
                        "value": "孟连傣族拉祜族佤族自治县",
                        "code": "530827"
                    },
                    {
                        "label": "澜沧拉祜族自治县",
                        "value": "澜沧拉祜族自治县",
                        "code": "530828"
                    },
                    {
                        "label": "西盟佤族自治县",
                        "value": "西盟佤族自治县",
                        "code": "530829"
                    }
                ],
                "code": "530800"
            },
            {
                "label": "临沧市",
                "value": "临沧市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "530901"
                    },
                    {
                        "label": "临翔区",
                        "value": "临翔区",
                        "code": "530902"
                    },
                    {
                        "label": "凤庆县",
                        "value": "凤庆县",
                        "code": "530921"
                    },
                    {
                        "label": "云县",
                        "value": "云县",
                        "code": "530922"
                    },
                    {
                        "label": "永德县",
                        "value": "永德县",
                        "code": "530923"
                    },
                    {
                        "label": "镇康县",
                        "value": "镇康县",
                        "code": "530924"
                    },
                    {
                        "label": "双江拉祜族佤族布朗族傣族自治县",
                        "value": "双江拉祜族佤族布朗族傣族自治县",
                        "code": "530925"
                    },
                    {
                        "label": "耿马傣族佤族自治县",
                        "value": "耿马傣族佤族自治县",
                        "code": "530926"
                    },
                    {
                        "label": "沧源佤族自治县",
                        "value": "沧源佤族自治县",
                        "code": "530927"
                    }
                ],
                "code": "530900"
            },
            {
                "label": "楚雄彝族自治州",
                "value": "楚雄彝族自治州",
                "children": [
                    {
                        "label": "楚雄市",
                        "value": "楚雄市",
                        "code": "532301"
                    },
                    {
                        "label": "双柏县",
                        "value": "双柏县",
                        "code": "532322"
                    },
                    {
                        "label": "牟定县",
                        "value": "牟定县",
                        "code": "532323"
                    },
                    {
                        "label": "南华县",
                        "value": "南华县",
                        "code": "532324"
                    },
                    {
                        "label": "姚安县",
                        "value": "姚安县",
                        "code": "532325"
                    },
                    {
                        "label": "大姚县",
                        "value": "大姚县",
                        "code": "532326"
                    },
                    {
                        "label": "永仁县",
                        "value": "永仁县",
                        "code": "532327"
                    },
                    {
                        "label": "元谋县",
                        "value": "元谋县",
                        "code": "532328"
                    },
                    {
                        "label": "武定县",
                        "value": "武定县",
                        "code": "532329"
                    },
                    {
                        "label": "禄丰县",
                        "value": "禄丰县",
                        "code": "532331"
                    }
                ],
                "code": "532300"
            },
            {
                "label": "红河哈尼族彝族自治州",
                "value": "红河哈尼族彝族自治州",
                "children": [
                    {
                        "label": "个旧市",
                        "value": "个旧市",
                        "code": "532501"
                    },
                    {
                        "label": "开远市",
                        "value": "开远市",
                        "code": "532502"
                    },
                    {
                        "label": "蒙自市",
                        "value": "蒙自市",
                        "code": "532503"
                    },
                    {
                        "label": "弥勒市",
                        "value": "弥勒市",
                        "code": "532504"
                    },
                    {
                        "label": "屏边苗族自治县",
                        "value": "屏边苗族自治县",
                        "code": "532523"
                    },
                    {
                        "label": "建水县",
                        "value": "建水县",
                        "code": "532524"
                    },
                    {
                        "label": "石屏县",
                        "value": "石屏县",
                        "code": "532525"
                    },
                    {
                        "label": "泸西县",
                        "value": "泸西县",
                        "code": "532527"
                    },
                    {
                        "label": "元阳县",
                        "value": "元阳县",
                        "code": "532528"
                    },
                    {
                        "label": "红河县",
                        "value": "红河县",
                        "code": "532529"
                    },
                    {
                        "label": "金平苗族瑶族傣族自治县",
                        "value": "金平苗族瑶族傣族自治县",
                        "code": "532530"
                    },
                    {
                        "label": "绿春县",
                        "value": "绿春县",
                        "code": "532531"
                    },
                    {
                        "label": "河口瑶族自治县",
                        "value": "河口瑶族自治县",
                        "code": "532532"
                    }
                ],
                "code": "532500"
            },
            {
                "label": "文山壮族苗族自治州",
                "value": "文山壮族苗族自治州",
                "children": [
                    {
                        "label": "文山市",
                        "value": "文山市",
                        "code": "532601"
                    },
                    {
                        "label": "砚山县",
                        "value": "砚山县",
                        "code": "532622"
                    },
                    {
                        "label": "西畴县",
                        "value": "西畴县",
                        "code": "532623"
                    },
                    {
                        "label": "麻栗坡县",
                        "value": "麻栗坡县",
                        "code": "532624"
                    },
                    {
                        "label": "马关县",
                        "value": "马关县",
                        "code": "532625"
                    },
                    {
                        "label": "丘北县",
                        "value": "丘北县",
                        "code": "532626"
                    },
                    {
                        "label": "广南县",
                        "value": "广南县",
                        "code": "532627"
                    },
                    {
                        "label": "富宁县",
                        "value": "富宁县",
                        "code": "532628"
                    }
                ],
                "code": "532600"
            },
            {
                "label": "西双版纳傣族自治州",
                "value": "西双版纳傣族自治州",
                "children": [
                    {
                        "label": "景洪市",
                        "value": "景洪市",
                        "code": "532801"
                    },
                    {
                        "label": "勐海县",
                        "value": "勐海县",
                        "code": "532822"
                    },
                    {
                        "label": "勐腊县",
                        "value": "勐腊县",
                        "code": "532823"
                    }
                ],
                "code": "532800"
            },
            {
                "label": "大理白族自治州",
                "value": "大理白族自治州",
                "children": [
                    {
                        "label": "大理市",
                        "value": "大理市",
                        "code": "532901"
                    },
                    {
                        "label": "漾濞彝族自治县",
                        "value": "漾濞彝族自治县",
                        "code": "532922"
                    },
                    {
                        "label": "祥云县",
                        "value": "祥云县",
                        "code": "532923"
                    },
                    {
                        "label": "宾川县",
                        "value": "宾川县",
                        "code": "532924"
                    },
                    {
                        "label": "弥渡县",
                        "value": "弥渡县",
                        "code": "532925"
                    },
                    {
                        "label": "南涧彝族自治县",
                        "value": "南涧彝族自治县",
                        "code": "532926"
                    },
                    {
                        "label": "巍山彝族回族自治县",
                        "value": "巍山彝族回族自治县",
                        "code": "532927"
                    },
                    {
                        "label": "永平县",
                        "value": "永平县",
                        "code": "532928"
                    },
                    {
                        "label": "云龙县",
                        "value": "云龙县",
                        "code": "532929"
                    },
                    {
                        "label": "洱源县",
                        "value": "洱源县",
                        "code": "532930"
                    },
                    {
                        "label": "剑川县",
                        "value": "剑川县",
                        "code": "532931"
                    },
                    {
                        "label": "鹤庆县",
                        "value": "鹤庆县",
                        "code": "532932"
                    }
                ],
                "code": "532900"
            },
            {
                "label": "德宏傣族景颇族自治州",
                "value": "德宏傣族景颇族自治州",
                "children": [
                    {
                        "label": "瑞丽市",
                        "value": "瑞丽市",
                        "code": "533102"
                    },
                    {
                        "label": "芒市",
                        "value": "芒市",
                        "code": "533103"
                    },
                    {
                        "label": "梁河县",
                        "value": "梁河县",
                        "code": "533122"
                    },
                    {
                        "label": "盈江县",
                        "value": "盈江县",
                        "code": "533123"
                    },
                    {
                        "label": "陇川县",
                        "value": "陇川县",
                        "code": "533124"
                    }
                ],
                "code": "533100"
            },
            {
                "label": "怒江傈僳族自治州",
                "value": "怒江傈僳族自治州",
                "children": [
                    {
                        "label": "泸水县",
                        "value": "泸水县",
                        "code": "533321"
                    },
                    {
                        "label": "福贡县",
                        "value": "福贡县",
                        "code": "533323"
                    },
                    {
                        "label": "贡山独龙族怒族自治县",
                        "value": "贡山独龙族怒族自治县",
                        "code": "533324"
                    },
                    {
                        "label": "兰坪白族普米族自治县",
                        "value": "兰坪白族普米族自治县",
                        "code": "533325"
                    }
                ],
                "code": "533300"
            },
            {
                "label": "迪庆藏族自治州",
                "value": "迪庆藏族自治州",
                "children": [
                    {
                        "label": "香格里拉市",
                        "value": "香格里拉市",
                        "code": "533401"
                    },
                    {
                        "label": "德钦县",
                        "value": "德钦县",
                        "code": "533422"
                    },
                    {
                        "label": "维西傈僳族自治县",
                        "value": "维西傈僳族自治县",
                        "code": "533423"
                    }
                ],
                "code": "533400"
            }
        ],
        "code": "530000"
    },
    {
        "label": "西藏自治区",
        "value": "西藏自治区",
        "children": [
            {
                "label": "拉萨市",
                "value": "拉萨市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "540101"
                    },
                    {
                        "label": "城关区",
                        "value": "城关区",
                        "code": "540102"
                    },
                    {
                        "label": "林周县",
                        "value": "林周县",
                        "code": "540121"
                    },
                    {
                        "label": "当雄县",
                        "value": "当雄县",
                        "code": "540122"
                    },
                    {
                        "label": "尼木县",
                        "value": "尼木县",
                        "code": "540123"
                    },
                    {
                        "label": "曲水县",
                        "value": "曲水县",
                        "code": "540124"
                    },
                    {
                        "label": "堆龙德庆县",
                        "value": "堆龙德庆县",
                        "code": "540125"
                    },
                    {
                        "label": "达孜县",
                        "value": "达孜县",
                        "code": "540126"
                    },
                    {
                        "label": "墨竹工卡县",
                        "value": "墨竹工卡县",
                        "code": "540127"
                    }
                ],
                "code": "540100"
            },
            {
                "label": "日喀则市",
                "value": "日喀则市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "540201"
                    },
                    {
                        "label": "桑珠孜区",
                        "value": "桑珠孜区",
                        "code": "540202"
                    },
                    {
                        "label": "南木林县",
                        "value": "南木林县",
                        "code": "540221"
                    },
                    {
                        "label": "江孜县",
                        "value": "江孜县",
                        "code": "540222"
                    },
                    {
                        "label": "定日县",
                        "value": "定日县",
                        "code": "540223"
                    },
                    {
                        "label": "萨迦县",
                        "value": "萨迦县",
                        "code": "540224"
                    },
                    {
                        "label": "拉孜县",
                        "value": "拉孜县",
                        "code": "540225"
                    },
                    {
                        "label": "昂仁县",
                        "value": "昂仁县",
                        "code": "540226"
                    },
                    {
                        "label": "谢通门县",
                        "value": "谢通门县",
                        "code": "540227"
                    },
                    {
                        "label": "白朗县",
                        "value": "白朗县",
                        "code": "540228"
                    },
                    {
                        "label": "仁布县",
                        "value": "仁布县",
                        "code": "540229"
                    },
                    {
                        "label": "康马县",
                        "value": "康马县",
                        "code": "540230"
                    },
                    {
                        "label": "定结县",
                        "value": "定结县",
                        "code": "540231"
                    },
                    {
                        "label": "仲巴县",
                        "value": "仲巴县",
                        "code": "540232"
                    },
                    {
                        "label": "亚东县",
                        "value": "亚东县",
                        "code": "540233"
                    },
                    {
                        "label": "吉隆县",
                        "value": "吉隆县",
                        "code": "540234"
                    },
                    {
                        "label": "聂拉木县",
                        "value": "聂拉木县",
                        "code": "540235"
                    },
                    {
                        "label": "萨嘎县",
                        "value": "萨嘎县",
                        "code": "540236"
                    },
                    {
                        "label": "岗巴县",
                        "value": "岗巴县",
                        "code": "540237"
                    }
                ],
                "code": "540200"
            },
            {
                "label": "昌都市",
                "value": "昌都市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "540301"
                    },
                    {
                        "label": "卡若区",
                        "value": "卡若区",
                        "code": "540302"
                    },
                    {
                        "label": "江达县",
                        "value": "江达县",
                        "code": "540321"
                    },
                    {
                        "label": "贡觉县",
                        "value": "贡觉县",
                        "code": "540322"
                    },
                    {
                        "label": "类乌齐县",
                        "value": "类乌齐县",
                        "code": "540323"
                    },
                    {
                        "label": "丁青县",
                        "value": "丁青县",
                        "code": "540324"
                    },
                    {
                        "label": "察雅县",
                        "value": "察雅县",
                        "code": "540325"
                    },
                    {
                        "label": "八宿县",
                        "value": "八宿县",
                        "code": "540326"
                    },
                    {
                        "label": "左贡县",
                        "value": "左贡县",
                        "code": "540327"
                    },
                    {
                        "label": "芒康县",
                        "value": "芒康县",
                        "code": "540328"
                    },
                    {
                        "label": "洛隆县",
                        "value": "洛隆县",
                        "code": "540329"
                    },
                    {
                        "label": "边坝县",
                        "value": "边坝县",
                        "code": "540330"
                    }
                ],
                "code": "540300"
            },
            {
                "label": "山南地区",
                "value": "山南地区",
                "children": [
                    {
                        "label": "乃东县",
                        "value": "乃东县",
                        "code": "542221"
                    },
                    {
                        "label": "扎囊县",
                        "value": "扎囊县",
                        "code": "542222"
                    },
                    {
                        "label": "贡嘎县",
                        "value": "贡嘎县",
                        "code": "542223"
                    },
                    {
                        "label": "桑日县",
                        "value": "桑日县",
                        "code": "542224"
                    },
                    {
                        "label": "琼结县",
                        "value": "琼结县",
                        "code": "542225"
                    },
                    {
                        "label": "曲松县",
                        "value": "曲松县",
                        "code": "542226"
                    },
                    {
                        "label": "措美县",
                        "value": "措美县",
                        "code": "542227"
                    },
                    {
                        "label": "洛扎县",
                        "value": "洛扎县",
                        "code": "542228"
                    },
                    {
                        "label": "加查县",
                        "value": "加查县",
                        "code": "542229"
                    },
                    {
                        "label": "隆子县",
                        "value": "隆子县",
                        "code": "542231"
                    },
                    {
                        "label": "错那县",
                        "value": "错那县",
                        "code": "542232"
                    },
                    {
                        "label": "浪卡子县",
                        "value": "浪卡子县",
                        "code": "542233"
                    }
                ],
                "code": "542200"
            },
            {
                "label": "那曲地区",
                "value": "那曲地区",
                "children": [
                    {
                        "label": "那曲县",
                        "value": "那曲县",
                        "code": "542421"
                    },
                    {
                        "label": "嘉黎县",
                        "value": "嘉黎县",
                        "code": "542422"
                    },
                    {
                        "label": "比如县",
                        "value": "比如县",
                        "code": "542423"
                    },
                    {
                        "label": "聂荣县",
                        "value": "聂荣县",
                        "code": "542424"
                    },
                    {
                        "label": "安多县",
                        "value": "安多县",
                        "code": "542425"
                    },
                    {
                        "label": "申扎县",
                        "value": "申扎县",
                        "code": "542426"
                    },
                    {
                        "label": "索县",
                        "value": "索县",
                        "code": "542427"
                    },
                    {
                        "label": "班戈县",
                        "value": "班戈县",
                        "code": "542428"
                    },
                    {
                        "label": "巴青县",
                        "value": "巴青县",
                        "code": "542429"
                    },
                    {
                        "label": "尼玛县",
                        "value": "尼玛县",
                        "code": "542430"
                    },
                    {
                        "label": "双湖县",
                        "value": "双湖县",
                        "code": "542431"
                    }
                ],
                "code": "542400"
            },
            {
                "label": "阿里地区",
                "value": "阿里地区",
                "children": [
                    {
                        "label": "普兰县",
                        "value": "普兰县",
                        "code": "542521"
                    },
                    {
                        "label": "札达县",
                        "value": "札达县",
                        "code": "542522"
                    },
                    {
                        "label": "噶尔县",
                        "value": "噶尔县",
                        "code": "542523"
                    },
                    {
                        "label": "日土县",
                        "value": "日土县",
                        "code": "542524"
                    },
                    {
                        "label": "革吉县",
                        "value": "革吉县",
                        "code": "542525"
                    },
                    {
                        "label": "改则县",
                        "value": "改则县",
                        "code": "542526"
                    },
                    {
                        "label": "措勤县",
                        "value": "措勤县",
                        "code": "542527"
                    }
                ],
                "code": "542500"
            },
            {
                "label": "林芝地区",
                "value": "林芝地区",
                "children": [
                    {
                        "label": "林芝县",
                        "value": "林芝县",
                        "code": "542621"
                    },
                    {
                        "label": "工布江达县",
                        "value": "工布江达县",
                        "code": "542622"
                    },
                    {
                        "label": "米林县",
                        "value": "米林县",
                        "code": "542623"
                    },
                    {
                        "label": "墨脱县",
                        "value": "墨脱县",
                        "code": "542624"
                    },
                    {
                        "label": "波密县",
                        "value": "波密县",
                        "code": "542625"
                    },
                    {
                        "label": "察隅县",
                        "value": "察隅县",
                        "code": "542626"
                    },
                    {
                        "label": "朗县",
                        "value": "朗县",
                        "code": "542627"
                    }
                ],
                "code": "542600"
            }
        ],
        "code": "540000"
    },
    {
        "label": "陕西省",
        "value": "陕西省",
        "children": [
            {
                "label": "西安市",
                "value": "西安市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "610101"
                    },
                    {
                        "label": "新城区",
                        "value": "新城区",
                        "code": "610102"
                    },
                    {
                        "label": "碑林区",
                        "value": "碑林区",
                        "code": "610103"
                    },
                    {
                        "label": "莲湖区",
                        "value": "莲湖区",
                        "code": "610104"
                    },
                    {
                        "label": "灞桥区",
                        "value": "灞桥区",
                        "code": "610111"
                    },
                    {
                        "label": "未央区",
                        "value": "未央区",
                        "code": "610112"
                    },
                    {
                        "label": "雁塔区",
                        "value": "雁塔区",
                        "code": "610113"
                    },
                    {
                        "label": "阎良区",
                        "value": "阎良区",
                        "code": "610114"
                    },
                    {
                        "label": "临潼区",
                        "value": "临潼区",
                        "code": "610115"
                    },
                    {
                        "label": "长安区",
                        "value": "长安区",
                        "code": "610116"
                    },
                    {
                        "label": "高陵区",
                        "value": "高陵区",
                        "code": "610117"
                    },
                    {
                        "label": "蓝田县",
                        "value": "蓝田县",
                        "code": "610122"
                    },
                    {
                        "label": "周至县",
                        "value": "周至县",
                        "code": "610124"
                    },
                    {
                        "label": "户县",
                        "value": "户县",
                        "code": "610125"
                    }
                ],
                "code": "610100"
            },
            {
                "label": "铜川市",
                "value": "铜川市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "610201"
                    },
                    {
                        "label": "王益区",
                        "value": "王益区",
                        "code": "610202"
                    },
                    {
                        "label": "印台区",
                        "value": "印台区",
                        "code": "610203"
                    },
                    {
                        "label": "耀州区",
                        "value": "耀州区",
                        "code": "610204"
                    },
                    {
                        "label": "宜君县",
                        "value": "宜君县",
                        "code": "610222"
                    }
                ],
                "code": "610200"
            },
            {
                "label": "宝鸡市",
                "value": "宝鸡市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "610301"
                    },
                    {
                        "label": "渭滨区",
                        "value": "渭滨区",
                        "code": "610302"
                    },
                    {
                        "label": "金台区",
                        "value": "金台区",
                        "code": "610303"
                    },
                    {
                        "label": "陈仓区",
                        "value": "陈仓区",
                        "code": "610304"
                    },
                    {
                        "label": "凤翔县",
                        "value": "凤翔县",
                        "code": "610322"
                    },
                    {
                        "label": "岐山县",
                        "value": "岐山县",
                        "code": "610323"
                    },
                    {
                        "label": "扶风县",
                        "value": "扶风县",
                        "code": "610324"
                    },
                    {
                        "label": "眉县",
                        "value": "眉县",
                        "code": "610326"
                    },
                    {
                        "label": "陇县",
                        "value": "陇县",
                        "code": "610327"
                    },
                    {
                        "label": "千阳县",
                        "value": "千阳县",
                        "code": "610328"
                    },
                    {
                        "label": "麟游县",
                        "value": "麟游县",
                        "code": "610329"
                    },
                    {
                        "label": "凤县",
                        "value": "凤县",
                        "code": "610330"
                    },
                    {
                        "label": "太白县",
                        "value": "太白县",
                        "code": "610331"
                    }
                ],
                "code": "610300"
            },
            {
                "label": "咸阳市",
                "value": "咸阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "610401"
                    },
                    {
                        "label": "秦都区",
                        "value": "秦都区",
                        "code": "610402"
                    },
                    {
                        "label": "杨陵区",
                        "value": "杨陵区",
                        "code": "610403"
                    },
                    {
                        "label": "渭城区",
                        "value": "渭城区",
                        "code": "610404"
                    },
                    {
                        "label": "三原县",
                        "value": "三原县",
                        "code": "610422"
                    },
                    {
                        "label": "泾阳县",
                        "value": "泾阳县",
                        "code": "610423"
                    },
                    {
                        "label": "乾县",
                        "value": "乾县",
                        "code": "610424"
                    },
                    {
                        "label": "礼泉县",
                        "value": "礼泉县",
                        "code": "610425"
                    },
                    {
                        "label": "永寿县",
                        "value": "永寿县",
                        "code": "610426"
                    },
                    {
                        "label": "彬县",
                        "value": "彬县",
                        "code": "610427"
                    },
                    {
                        "label": "长武县",
                        "value": "长武县",
                        "code": "610428"
                    },
                    {
                        "label": "旬邑县",
                        "value": "旬邑县",
                        "code": "610429"
                    },
                    {
                        "label": "淳化县",
                        "value": "淳化县",
                        "code": "610430"
                    },
                    {
                        "label": "武功县",
                        "value": "武功县",
                        "code": "610431"
                    },
                    {
                        "label": "兴平市",
                        "value": "兴平市",
                        "code": "610481"
                    }
                ],
                "code": "610400"
            },
            {
                "label": "渭南市",
                "value": "渭南市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "610501"
                    },
                    {
                        "label": "临渭区",
                        "value": "临渭区",
                        "code": "610502"
                    },
                    {
                        "label": "华县",
                        "value": "华县",
                        "code": "610521"
                    },
                    {
                        "label": "潼关县",
                        "value": "潼关县",
                        "code": "610522"
                    },
                    {
                        "label": "大荔县",
                        "value": "大荔县",
                        "code": "610523"
                    },
                    {
                        "label": "合阳县",
                        "value": "合阳县",
                        "code": "610524"
                    },
                    {
                        "label": "澄城县",
                        "value": "澄城县",
                        "code": "610525"
                    },
                    {
                        "label": "蒲城县",
                        "value": "蒲城县",
                        "code": "610526"
                    },
                    {
                        "label": "白水县",
                        "value": "白水县",
                        "code": "610527"
                    },
                    {
                        "label": "富平县",
                        "value": "富平县",
                        "code": "610528"
                    },
                    {
                        "label": "韩城市",
                        "value": "韩城市",
                        "code": "610581"
                    },
                    {
                        "label": "华阴市",
                        "value": "华阴市",
                        "code": "610582"
                    }
                ],
                "code": "610500"
            },
            {
                "label": "延安市",
                "value": "延安市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "610601"
                    },
                    {
                        "label": "宝塔区",
                        "value": "宝塔区",
                        "code": "610602"
                    },
                    {
                        "label": "延长县",
                        "value": "延长县",
                        "code": "610621"
                    },
                    {
                        "label": "延川县",
                        "value": "延川县",
                        "code": "610622"
                    },
                    {
                        "label": "子长县",
                        "value": "子长县",
                        "code": "610623"
                    },
                    {
                        "label": "安塞县",
                        "value": "安塞县",
                        "code": "610624"
                    },
                    {
                        "label": "志丹县",
                        "value": "志丹县",
                        "code": "610625"
                    },
                    {
                        "label": "吴起县",
                        "value": "吴起县",
                        "code": "610626"
                    },
                    {
                        "label": "甘泉县",
                        "value": "甘泉县",
                        "code": "610627"
                    },
                    {
                        "label": "富县",
                        "value": "富县",
                        "code": "610628"
                    },
                    {
                        "label": "洛川县",
                        "value": "洛川县",
                        "code": "610629"
                    },
                    {
                        "label": "宜川县",
                        "value": "宜川县",
                        "code": "610630"
                    },
                    {
                        "label": "黄龙县",
                        "value": "黄龙县",
                        "code": "610631"
                    },
                    {
                        "label": "黄陵县",
                        "value": "黄陵县",
                        "code": "610632"
                    }
                ],
                "code": "610600"
            },
            {
                "label": "汉中市",
                "value": "汉中市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "610701"
                    },
                    {
                        "label": "汉台区",
                        "value": "汉台区",
                        "code": "610702"
                    },
                    {
                        "label": "南郑县",
                        "value": "南郑县",
                        "code": "610721"
                    },
                    {
                        "label": "城固县",
                        "value": "城固县",
                        "code": "610722"
                    },
                    {
                        "label": "洋县",
                        "value": "洋县",
                        "code": "610723"
                    },
                    {
                        "label": "西乡县",
                        "value": "西乡县",
                        "code": "610724"
                    },
                    {
                        "label": "勉县",
                        "value": "勉县",
                        "code": "610725"
                    },
                    {
                        "label": "宁强县",
                        "value": "宁强县",
                        "code": "610726"
                    },
                    {
                        "label": "略阳县",
                        "value": "略阳县",
                        "code": "610727"
                    },
                    {
                        "label": "镇巴县",
                        "value": "镇巴县",
                        "code": "610728"
                    },
                    {
                        "label": "留坝县",
                        "value": "留坝县",
                        "code": "610729"
                    },
                    {
                        "label": "佛坪县",
                        "value": "佛坪县",
                        "code": "610730"
                    }
                ],
                "code": "610700"
            },
            {
                "label": "榆林市",
                "value": "榆林市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "610801"
                    },
                    {
                        "label": "榆阳区",
                        "value": "榆阳区",
                        "code": "610802"
                    },
                    {
                        "label": "神木县",
                        "value": "神木县",
                        "code": "610821"
                    },
                    {
                        "label": "府谷县",
                        "value": "府谷县",
                        "code": "610822"
                    },
                    {
                        "label": "横山县",
                        "value": "横山县",
                        "code": "610823"
                    },
                    {
                        "label": "靖边县",
                        "value": "靖边县",
                        "code": "610824"
                    },
                    {
                        "label": "定边县",
                        "value": "定边县",
                        "code": "610825"
                    },
                    {
                        "label": "绥德县",
                        "value": "绥德县",
                        "code": "610826"
                    },
                    {
                        "label": "米脂县",
                        "value": "米脂县",
                        "code": "610827"
                    },
                    {
                        "label": "佳县",
                        "value": "佳县",
                        "code": "610828"
                    },
                    {
                        "label": "吴堡县",
                        "value": "吴堡县",
                        "code": "610829"
                    },
                    {
                        "label": "清涧县",
                        "value": "清涧县",
                        "code": "610830"
                    },
                    {
                        "label": "子洲县",
                        "value": "子洲县",
                        "code": "610831"
                    }
                ],
                "code": "610800"
            },
            {
                "label": "安康市",
                "value": "安康市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "610901"
                    },
                    {
                        "label": "汉阴县",
                        "value": "汉阴县",
                        "code": "610921"
                    },
                    {
                        "label": "石泉县",
                        "value": "石泉县",
                        "code": "610922"
                    },
                    {
                        "label": "宁陕县",
                        "value": "宁陕县",
                        "code": "610923"
                    },
                    {
                        "label": "紫阳县",
                        "value": "紫阳县",
                        "code": "610924"
                    },
                    {
                        "label": "岚皋县",
                        "value": "岚皋县",
                        "code": "610925"
                    },
                    {
                        "label": "平利县",
                        "value": "平利县",
                        "code": "610926"
                    },
                    {
                        "label": "镇坪县",
                        "value": "镇坪县",
                        "code": "610927"
                    },
                    {
                        "label": "旬阳县",
                        "value": "旬阳县",
                        "code": "610928"
                    },
                    {
                        "label": "白河县",
                        "value": "白河县",
                        "code": "610929"
                    }
                ],
                "code": "610900"
            },
            {
                "label": "商洛市",
                "value": "商洛市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "611001"
                    },
                    {
                        "label": "商州区",
                        "value": "商州区",
                        "code": "611002"
                    },
                    {
                        "label": "洛南县",
                        "value": "洛南县",
                        "code": "611021"
                    },
                    {
                        "label": "丹凤县",
                        "value": "丹凤县",
                        "code": "611022"
                    },
                    {
                        "label": "商南县",
                        "value": "商南县",
                        "code": "611023"
                    },
                    {
                        "label": "山阳县",
                        "value": "山阳县",
                        "code": "611024"
                    },
                    {
                        "label": "镇安县",
                        "value": "镇安县",
                        "code": "611025"
                    },
                    {
                        "label": "柞水县",
                        "value": "柞水县",
                        "code": "611026"
                    }
                ],
                "code": "611000"
            }
        ],
        "code": "610000"
    },
    {
        "label": "甘肃省",
        "value": "甘肃省",
        "children": [
            {
                "label": "兰州市",
                "value": "兰州市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "620101"
                    },
                    {
                        "label": "城关区",
                        "value": "城关区",
                        "code": "620102"
                    },
                    {
                        "label": "七里河区",
                        "value": "七里河区",
                        "code": "620103"
                    },
                    {
                        "label": "西固区",
                        "value": "西固区",
                        "code": "620104"
                    },
                    {
                        "label": "安宁区",
                        "value": "安宁区",
                        "code": "620105"
                    },
                    {
                        "label": "红古区",
                        "value": "红古区",
                        "code": "620111"
                    },
                    {
                        "label": "永登县",
                        "value": "永登县",
                        "code": "620121"
                    },
                    {
                        "label": "皋兰县",
                        "value": "皋兰县",
                        "code": "620122"
                    },
                    {
                        "label": "榆中县",
                        "value": "榆中县",
                        "code": "620123"
                    }
                ],
                "code": "620100"
            },
            {
                "label": "嘉峪关市",
                "value": "嘉峪关市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "620201"
                    }
                ],
                "code": "620200"
            },
            {
                "label": "金昌市",
                "value": "金昌市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "620301"
                    },
                    {
                        "label": "金川区",
                        "value": "金川区",
                        "code": "620302"
                    },
                    {
                        "label": "永昌县",
                        "value": "永昌县",
                        "code": "620321"
                    }
                ],
                "code": "620300"
            },
            {
                "label": "白银市",
                "value": "白银市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "620401"
                    },
                    {
                        "label": "白银区",
                        "value": "白银区",
                        "code": "620402"
                    },
                    {
                        "label": "平川区",
                        "value": "平川区",
                        "code": "620403"
                    },
                    {
                        "label": "靖远县",
                        "value": "靖远县",
                        "code": "620421"
                    },
                    {
                        "label": "会宁县",
                        "value": "会宁县",
                        "code": "620422"
                    },
                    {
                        "label": "景泰县",
                        "value": "景泰县",
                        "code": "620423"
                    }
                ],
                "code": "620400"
            },
            {
                "label": "天水市",
                "value": "天水市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "620501"
                    },
                    {
                        "label": "秦州区",
                        "value": "秦州区",
                        "code": "620502"
                    },
                    {
                        "label": "麦积区",
                        "value": "麦积区",
                        "code": "620503"
                    },
                    {
                        "label": "清水县",
                        "value": "清水县",
                        "code": "620521"
                    },
                    {
                        "label": "秦安县",
                        "value": "秦安县",
                        "code": "620522"
                    },
                    {
                        "label": "甘谷县",
                        "value": "甘谷县",
                        "code": "620523"
                    },
                    {
                        "label": "武山县",
                        "value": "武山县",
                        "code": "620524"
                    },
                    {
                        "label": "张家川回族自治县",
                        "value": "张家川回族自治县",
                        "code": "620525"
                    }
                ],
                "code": "620500"
            },
            {
                "label": "武威市",
                "value": "武威市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "620601"
                    },
                    {
                        "label": "凉州区",
                        "value": "凉州区",
                        "code": "620602"
                    },
                    {
                        "label": "民勤县",
                        "value": "民勤县",
                        "code": "620621"
                    },
                    {
                        "label": "古浪县",
                        "value": "古浪县",
                        "code": "620622"
                    },
                    {
                        "label": "天祝藏族自治县",
                        "value": "天祝藏族自治县",
                        "code": "620623"
                    }
                ],
                "code": "620600"
            },
            {
                "label": "张掖市",
                "value": "张掖市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "620701"
                    },
                    {
                        "label": "甘州区",
                        "value": "甘州区",
                        "code": "620702"
                    },
                    {
                        "label": "肃南裕固族自治县",
                        "value": "肃南裕固族自治县",
                        "code": "620721"
                    },
                    {
                        "label": "民乐县",
                        "value": "民乐县",
                        "code": "620722"
                    },
                    {
                        "label": "临泽县",
                        "value": "临泽县",
                        "code": "620723"
                    },
                    {
                        "label": "高台县",
                        "value": "高台县",
                        "code": "620724"
                    },
                    {
                        "label": "山丹县",
                        "value": "山丹县",
                        "code": "620725"
                    }
                ],
                "code": "620700"
            },
            {
                "label": "平凉市",
                "value": "平凉市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "620801"
                    },
                    {
                        "label": "崆峒区",
                        "value": "崆峒区",
                        "code": "620802"
                    },
                    {
                        "label": "泾川县",
                        "value": "泾川县",
                        "code": "620821"
                    },
                    {
                        "label": "灵台县",
                        "value": "灵台县",
                        "code": "620822"
                    },
                    {
                        "label": "崇信县",
                        "value": "崇信县",
                        "code": "620823"
                    },
                    {
                        "label": "华亭县",
                        "value": "华亭县",
                        "code": "620824"
                    },
                    {
                        "label": "庄浪县",
                        "value": "庄浪县",
                        "code": "620825"
                    },
                    {
                        "label": "静宁县",
                        "value": "静宁县",
                        "code": "620826"
                    }
                ],
                "code": "620800"
            },
            {
                "label": "酒泉市",
                "value": "酒泉市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "620901"
                    },
                    {
                        "label": "肃州区",
                        "value": "肃州区",
                        "code": "620902"
                    },
                    {
                        "label": "金塔县",
                        "value": "金塔县",
                        "code": "620921"
                    },
                    {
                        "label": "瓜州县",
                        "value": "瓜州县",
                        "code": "620922"
                    },
                    {
                        "label": "肃北蒙古族自治县",
                        "value": "肃北蒙古族自治县",
                        "code": "620923"
                    },
                    {
                        "label": "阿克塞哈萨克族自治县",
                        "value": "阿克塞哈萨克族自治县",
                        "code": "620924"
                    },
                    {
                        "label": "玉门市",
                        "value": "玉门市",
                        "code": "620981"
                    },
                    {
                        "label": "敦煌市",
                        "value": "敦煌市",
                        "code": "620982"
                    }
                ],
                "code": "620900"
            },
            {
                "label": "庆阳市",
                "value": "庆阳市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "621001"
                    },
                    {
                        "label": "西峰区",
                        "value": "西峰区",
                        "code": "621002"
                    },
                    {
                        "label": "庆城县",
                        "value": "庆城县",
                        "code": "621021"
                    },
                    {
                        "label": "环县",
                        "value": "环县",
                        "code": "621022"
                    },
                    {
                        "label": "华池县",
                        "value": "华池县",
                        "code": "621023"
                    },
                    {
                        "label": "合水县",
                        "value": "合水县",
                        "code": "621024"
                    },
                    {
                        "label": "正宁县",
                        "value": "正宁县",
                        "code": "621025"
                    },
                    {
                        "label": "宁县",
                        "value": "宁县",
                        "code": "621026"
                    },
                    {
                        "label": "镇原县",
                        "value": "镇原县",
                        "code": "621027"
                    }
                ],
                "code": "621000"
            },
            {
                "label": "定西市",
                "value": "定西市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "621101"
                    },
                    {
                        "label": "安定区",
                        "value": "安定区",
                        "code": "621102"
                    },
                    {
                        "label": "通渭县",
                        "value": "通渭县",
                        "code": "621121"
                    },
                    {
                        "label": "陇西县",
                        "value": "陇西县",
                        "code": "621122"
                    },
                    {
                        "label": "渭源县",
                        "value": "渭源县",
                        "code": "621123"
                    },
                    {
                        "label": "临洮县",
                        "value": "临洮县",
                        "code": "621124"
                    },
                    {
                        "label": "漳县",
                        "value": "漳县",
                        "code": "621125"
                    },
                    {
                        "label": "岷县",
                        "value": "岷县",
                        "code": "621126"
                    }
                ],
                "code": "621100"
            },
            {
                "label": "陇南市",
                "value": "陇南市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "621201"
                    },
                    {
                        "label": "武都区",
                        "value": "武都区",
                        "code": "621202"
                    },
                    {
                        "label": "成县",
                        "value": "成县",
                        "code": "621221"
                    },
                    {
                        "label": "文县",
                        "value": "文县",
                        "code": "621222"
                    },
                    {
                        "label": "宕昌县",
                        "value": "宕昌县",
                        "code": "621223"
                    },
                    {
                        "label": "康县",
                        "value": "康县",
                        "code": "621224"
                    },
                    {
                        "label": "西和县",
                        "value": "西和县",
                        "code": "621225"
                    },
                    {
                        "label": "礼县",
                        "value": "礼县",
                        "code": "621226"
                    },
                    {
                        "label": "徽县",
                        "value": "徽县",
                        "code": "621227"
                    },
                    {
                        "label": "两当县",
                        "value": "两当县",
                        "code": "621228"
                    }
                ],
                "code": "621200"
            },
            {
                "label": "临夏回族自治州",
                "value": "临夏回族自治州",
                "children": [
                    {
                        "label": "临夏市",
                        "value": "临夏市",
                        "code": "622901"
                    },
                    {
                        "label": "临夏县",
                        "value": "临夏县",
                        "code": "622921"
                    },
                    {
                        "label": "康乐县",
                        "value": "康乐县",
                        "code": "622922"
                    },
                    {
                        "label": "永靖县",
                        "value": "永靖县",
                        "code": "622923"
                    },
                    {
                        "label": "广河县",
                        "value": "广河县",
                        "code": "622924"
                    },
                    {
                        "label": "和政县",
                        "value": "和政县",
                        "code": "622925"
                    },
                    {
                        "label": "东乡族自治县",
                        "value": "东乡族自治县",
                        "code": "622926"
                    },
                    {
                        "label": "积石山保安族东乡族撒拉族自治县",
                        "value": "积石山保安族东乡族撒拉族自治县",
                        "code": "622927"
                    }
                ],
                "code": "622900"
            },
            {
                "label": "甘南藏族自治州",
                "value": "甘南藏族自治州",
                "children": [
                    {
                        "label": "合作市",
                        "value": "合作市",
                        "code": "623001"
                    },
                    {
                        "label": "临潭县",
                        "value": "临潭县",
                        "code": "623021"
                    },
                    {
                        "label": "卓尼县",
                        "value": "卓尼县",
                        "code": "623022"
                    },
                    {
                        "label": "舟曲县",
                        "value": "舟曲县",
                        "code": "623023"
                    },
                    {
                        "label": "迭部县",
                        "value": "迭部县",
                        "code": "623024"
                    },
                    {
                        "label": "玛曲县",
                        "value": "玛曲县",
                        "code": "623025"
                    },
                    {
                        "label": "碌曲县",
                        "value": "碌曲县",
                        "code": "623026"
                    },
                    {
                        "label": "夏河县",
                        "value": "夏河县",
                        "code": "623027"
                    }
                ],
                "code": "623000"
            }
        ],
        "code": "620000"
    },
    {
        "label": "青海省",
        "value": "青海省",
        "children": [
            {
                "label": "西宁市",
                "value": "西宁市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "630101"
                    },
                    {
                        "label": "城东区",
                        "value": "城东区",
                        "code": "630102"
                    },
                    {
                        "label": "城中区",
                        "value": "城中区",
                        "code": "630103"
                    },
                    {
                        "label": "城西区",
                        "value": "城西区",
                        "code": "630104"
                    },
                    {
                        "label": "城北区",
                        "value": "城北区",
                        "code": "630105"
                    },
                    {
                        "label": "大通回族土族自治县",
                        "value": "大通回族土族自治县",
                        "code": "630121"
                    },
                    {
                        "label": "湟中县",
                        "value": "湟中县",
                        "code": "630122"
                    },
                    {
                        "label": "湟源县",
                        "value": "湟源县",
                        "code": "630123"
                    }
                ],
                "code": "630100"
            },
            {
                "label": "海东市",
                "value": "海东市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "630201"
                    },
                    {
                        "label": "乐都区",
                        "value": "乐都区",
                        "code": "630202"
                    },
                    {
                        "label": "平安县",
                        "value": "平安县",
                        "code": "630221"
                    },
                    {
                        "label": "民和回族土族自治县",
                        "value": "民和回族土族自治县",
                        "code": "630222"
                    },
                    {
                        "label": "互助土族自治县",
                        "value": "互助土族自治县",
                        "code": "630223"
                    },
                    {
                        "label": "化隆回族自治县",
                        "value": "化隆回族自治县",
                        "code": "630224"
                    },
                    {
                        "label": "循化撒拉族自治县",
                        "value": "循化撒拉族自治县",
                        "code": "630225"
                    }
                ],
                "code": "630200"
            },
            {
                "label": "海北藏族自治州",
                "value": "海北藏族自治州",
                "children": [
                    {
                        "label": "门源回族自治县",
                        "value": "门源回族自治县",
                        "code": "632221"
                    },
                    {
                        "label": "祁连县",
                        "value": "祁连县",
                        "code": "632222"
                    },
                    {
                        "label": "海晏县",
                        "value": "海晏县",
                        "code": "632223"
                    },
                    {
                        "label": "刚察县",
                        "value": "刚察县",
                        "code": "632224"
                    }
                ],
                "code": "632200"
            },
            {
                "label": "黄南藏族自治州",
                "value": "黄南藏族自治州",
                "children": [
                    {
                        "label": "同仁县",
                        "value": "同仁县",
                        "code": "632321"
                    },
                    {
                        "label": "尖扎县",
                        "value": "尖扎县",
                        "code": "632322"
                    },
                    {
                        "label": "泽库县",
                        "value": "泽库县",
                        "code": "632323"
                    },
                    {
                        "label": "河南蒙古族自治县",
                        "value": "河南蒙古族自治县",
                        "code": "632324"
                    }
                ],
                "code": "632300"
            },
            {
                "label": "海南藏族自治州",
                "value": "海南藏族自治州",
                "children": [
                    {
                        "label": "共和县",
                        "value": "共和县",
                        "code": "632521"
                    },
                    {
                        "label": "同德县",
                        "value": "同德县",
                        "code": "632522"
                    },
                    {
                        "label": "贵德县",
                        "value": "贵德县",
                        "code": "632523"
                    },
                    {
                        "label": "兴海县",
                        "value": "兴海县",
                        "code": "632524"
                    },
                    {
                        "label": "贵南县",
                        "value": "贵南县",
                        "code": "632525"
                    }
                ],
                "code": "632500"
            },
            {
                "label": "果洛藏族自治州",
                "value": "果洛藏族自治州",
                "children": [
                    {
                        "label": "玛沁县",
                        "value": "玛沁县",
                        "code": "632621"
                    },
                    {
                        "label": "班玛县",
                        "value": "班玛县",
                        "code": "632622"
                    },
                    {
                        "label": "甘德县",
                        "value": "甘德县",
                        "code": "632623"
                    },
                    {
                        "label": "达日县",
                        "value": "达日县",
                        "code": "632624"
                    },
                    {
                        "label": "久治县",
                        "value": "久治县",
                        "code": "632625"
                    },
                    {
                        "label": "玛多县",
                        "value": "玛多县",
                        "code": "632626"
                    }
                ],
                "code": "632600"
            },
            {
                "label": "玉树藏族自治州",
                "value": "玉树藏族自治州",
                "children": [
                    {
                        "label": "玉树市",
                        "value": "玉树市",
                        "code": "632701"
                    },
                    {
                        "label": "杂多县",
                        "value": "杂多县",
                        "code": "632722"
                    },
                    {
                        "label": "称多县",
                        "value": "称多县",
                        "code": "632723"
                    },
                    {
                        "label": "治多县",
                        "value": "治多县",
                        "code": "632724"
                    },
                    {
                        "label": "囊谦县",
                        "value": "囊谦县",
                        "code": "632725"
                    },
                    {
                        "label": "曲麻莱县",
                        "value": "曲麻莱县",
                        "code": "632726"
                    }
                ],
                "code": "632700"
            },
            {
                "label": "海西蒙古族藏族自治州",
                "value": "海西蒙古族藏族自治州",
                "children": [
                    {
                        "label": "格尔木市",
                        "value": "格尔木市",
                        "code": "632801"
                    },
                    {
                        "label": "德令哈市",
                        "value": "德令哈市",
                        "code": "632802"
                    },
                    {
                        "label": "乌兰县",
                        "value": "乌兰县",
                        "code": "632821"
                    },
                    {
                        "label": "都兰县",
                        "value": "都兰县",
                        "code": "632822"
                    },
                    {
                        "label": "天峻县",
                        "value": "天峻县",
                        "code": "632823"
                    }
                ],
                "code": "632800"
            }
        ],
        "code": "630000"
    },
    {
        "label": "宁夏回族自治区",
        "value": "宁夏回族自治区",
        "children": [
            {
                "label": "银川市",
                "value": "银川市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "640101"
                    },
                    {
                        "label": "兴庆区",
                        "value": "兴庆区",
                        "code": "640104"
                    },
                    {
                        "label": "西夏区",
                        "value": "西夏区",
                        "code": "640105"
                    },
                    {
                        "label": "金凤区",
                        "value": "金凤区",
                        "code": "640106"
                    },
                    {
                        "label": "永宁县",
                        "value": "永宁县",
                        "code": "640121"
                    },
                    {
                        "label": "贺兰县",
                        "value": "贺兰县",
                        "code": "640122"
                    },
                    {
                        "label": "灵武市",
                        "value": "灵武市",
                        "code": "640181"
                    }
                ],
                "code": "640100"
            },
            {
                "label": "石嘴山市",
                "value": "石嘴山市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "640201"
                    },
                    {
                        "label": "大武口区",
                        "value": "大武口区",
                        "code": "640202"
                    },
                    {
                        "label": "惠农区",
                        "value": "惠农区",
                        "code": "640205"
                    },
                    {
                        "label": "平罗县",
                        "value": "平罗县",
                        "code": "640221"
                    }
                ],
                "code": "640200"
            },
            {
                "label": "吴忠市",
                "value": "吴忠市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "640301"
                    },
                    {
                        "label": "利通区",
                        "value": "利通区",
                        "code": "640302"
                    },
                    {
                        "label": "红寺堡区",
                        "value": "红寺堡区",
                        "code": "640303"
                    },
                    {
                        "label": "盐池县",
                        "value": "盐池县",
                        "code": "640323"
                    },
                    {
                        "label": "同心县",
                        "value": "同心县",
                        "code": "640324"
                    },
                    {
                        "label": "青铜峡市",
                        "value": "青铜峡市",
                        "code": "640381"
                    }
                ],
                "code": "640300"
            },
            {
                "label": "固原市",
                "value": "固原市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "640401"
                    },
                    {
                        "label": "原州区",
                        "value": "原州区",
                        "code": "640402"
                    },
                    {
                        "label": "西吉县",
                        "value": "西吉县",
                        "code": "640422"
                    },
                    {
                        "label": "隆德县",
                        "value": "隆德县",
                        "code": "640423"
                    },
                    {
                        "label": "泾源县",
                        "value": "泾源县",
                        "code": "640424"
                    },
                    {
                        "label": "彭阳县",
                        "value": "彭阳县",
                        "code": "640425"
                    }
                ],
                "code": "640400"
            },
            {
                "label": "中卫市",
                "value": "中卫市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "640501"
                    },
                    {
                        "label": "沙坡头区",
                        "value": "沙坡头区",
                        "code": "640502"
                    },
                    {
                        "label": "中宁县",
                        "value": "中宁县",
                        "code": "640521"
                    },
                    {
                        "label": "海原县",
                        "value": "海原县",
                        "code": "640522"
                    }
                ],
                "code": "640500"
            }
        ],
        "code": "640000"
    },
    {
        "label": "新疆维吾尔自治区",
        "value": "新疆维吾尔自治区",
        "children": [
            {
                "label": "乌鲁木齐市",
                "value": "乌鲁木齐市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "650101"
                    },
                    {
                        "label": "天山区",
                        "value": "天山区",
                        "code": "650102"
                    },
                    {
                        "label": "沙依巴克区",
                        "value": "沙依巴克区",
                        "code": "650103"
                    },
                    {
                        "label": "新市区",
                        "value": "新市区",
                        "code": "650104"
                    },
                    {
                        "label": "水磨沟区",
                        "value": "水磨沟区",
                        "code": "650105"
                    },
                    {
                        "label": "头屯河区",
                        "value": "头屯河区",
                        "code": "650106"
                    },
                    {
                        "label": "达坂城区",
                        "value": "达坂城区",
                        "code": "650107"
                    },
                    {
                        "label": "米东区",
                        "value": "米东区",
                        "code": "650109"
                    },
                    {
                        "label": "乌鲁木齐县",
                        "value": "乌鲁木齐县",
                        "code": "650121"
                    }
                ],
                "code": "650100"
            },
            {
                "label": "克拉玛依市",
                "value": "克拉玛依市",
                "children": [
                    {
                        "label": "市辖区",
                        "value": "市辖区",
                        "code": "650201"
                    },
                    {
                        "label": "独山子区",
                        "value": "独山子区",
                        "code": "650202"
                    },
                    {
                        "label": "克拉玛依区",
                        "value": "克拉玛依区",
                        "code": "650203"
                    },
                    {
                        "label": "白碱滩区",
                        "value": "白碱滩区",
                        "code": "650204"
                    },
                    {
                        "label": "乌尔禾区",
                        "value": "乌尔禾区",
                        "code": "650205"
                    }
                ],
                "code": "650200"
            },
            {
                "label": "吐鲁番地区",
                "value": "吐鲁番地区",
                "children": [
                    {
                        "label": "吐鲁番市",
                        "value": "吐鲁番市",
                        "code": "652101"
                    },
                    {
                        "label": "鄯善县",
                        "value": "鄯善县",
                        "code": "652122"
                    },
                    {
                        "label": "托克逊县",
                        "value": "托克逊县",
                        "code": "652123"
                    }
                ],
                "code": "652100"
            },
            {
                "label": "哈密地区",
                "value": "哈密地区",
                "children": [
                    {
                        "label": "哈密市",
                        "value": "哈密市",
                        "code": "652201"
                    },
                    {
                        "label": "巴里坤哈萨克自治县",
                        "value": "巴里坤哈萨克自治县",
                        "code": "652222"
                    },
                    {
                        "label": "伊吾县",
                        "value": "伊吾县",
                        "code": "652223"
                    }
                ],
                "code": "652200"
            },
            {
                "label": "昌吉回族自治州",
                "value": "昌吉回族自治州",
                "children": [
                    {
                        "label": "昌吉市",
                        "value": "昌吉市",
                        "code": "652301"
                    },
                    {
                        "label": "阜康市",
                        "value": "阜康市",
                        "code": "652302"
                    },
                    {
                        "label": "呼图壁县",
                        "value": "呼图壁县",
                        "code": "652323"
                    },
                    {
                        "label": "玛纳斯县",
                        "value": "玛纳斯县",
                        "code": "652324"
                    },
                    {
                        "label": "奇台县",
                        "value": "奇台县",
                        "code": "652325"
                    },
                    {
                        "label": "吉木萨尔县",
                        "value": "吉木萨尔县",
                        "code": "652327"
                    },
                    {
                        "label": "木垒哈萨克自治县",
                        "value": "木垒哈萨克自治县",
                        "code": "652328"
                    }
                ],
                "code": "652300"
            },
            {
                "label": "博尔塔拉蒙古自治州",
                "value": "博尔塔拉蒙古自治州",
                "children": [
                    {
                        "label": "博乐市",
                        "value": "博乐市",
                        "code": "652701"
                    },
                    {
                        "label": "阿拉山口市",
                        "value": "阿拉山口市",
                        "code": "652702"
                    },
                    {
                        "label": "精河县",
                        "value": "精河县",
                        "code": "652722"
                    },
                    {
                        "label": "温泉县",
                        "value": "温泉县",
                        "code": "652723"
                    }
                ],
                "code": "652700"
            },
            {
                "label": "巴音郭楞蒙古自治州",
                "value": "巴音郭楞蒙古自治州",
                "children": [
                    {
                        "label": "库尔勒市",
                        "value": "库尔勒市",
                        "code": "652801"
                    },
                    {
                        "label": "轮台县",
                        "value": "轮台县",
                        "code": "652822"
                    },
                    {
                        "label": "尉犁县",
                        "value": "尉犁县",
                        "code": "652823"
                    },
                    {
                        "label": "若羌县",
                        "value": "若羌县",
                        "code": "652824"
                    },
                    {
                        "label": "且末县",
                        "value": "且末县",
                        "code": "652825"
                    },
                    {
                        "label": "焉耆回族自治县",
                        "value": "焉耆回族自治县",
                        "code": "652826"
                    },
                    {
                        "label": "和静县",
                        "value": "和静县",
                        "code": "652827"
                    },
                    {
                        "label": "和硕县",
                        "value": "和硕县",
                        "code": "652828"
                    },
                    {
                        "label": "博湖县",
                        "value": "博湖县",
                        "code": "652829"
                    }
                ],
                "code": "652800"
            },
            {
                "label": "阿克苏地区",
                "value": "阿克苏地区",
                "children": [
                    {
                        "label": "阿克苏市",
                        "value": "阿克苏市",
                        "code": "652901"
                    },
                    {
                        "label": "温宿县",
                        "value": "温宿县",
                        "code": "652922"
                    },
                    {
                        "label": "库车县",
                        "value": "库车县",
                        "code": "652923"
                    },
                    {
                        "label": "沙雅县",
                        "value": "沙雅县",
                        "code": "652924"
                    },
                    {
                        "label": "新和县",
                        "value": "新和县",
                        "code": "652925"
                    },
                    {
                        "label": "拜城县",
                        "value": "拜城县",
                        "code": "652926"
                    },
                    {
                        "label": "乌什县",
                        "value": "乌什县",
                        "code": "652927"
                    },
                    {
                        "label": "阿瓦提县",
                        "value": "阿瓦提县",
                        "code": "652928"
                    },
                    {
                        "label": "柯坪县",
                        "value": "柯坪县",
                        "code": "652929"
                    }
                ],
                "code": "652900"
            },
            {
                "label": "克孜勒苏柯尔克孜自治州",
                "value": "克孜勒苏柯尔克孜自治州",
                "children": [
                    {
                        "label": "阿图什市",
                        "value": "阿图什市",
                        "code": "653001"
                    },
                    {
                        "label": "阿克陶县",
                        "value": "阿克陶县",
                        "code": "653022"
                    },
                    {
                        "label": "阿合奇县",
                        "value": "阿合奇县",
                        "code": "653023"
                    },
                    {
                        "label": "乌恰县",
                        "value": "乌恰县",
                        "code": "653024"
                    }
                ],
                "code": "653000"
            },
            {
                "label": "喀什地区",
                "value": "喀什地区",
                "children": [
                    {
                        "label": "喀什市",
                        "value": "喀什市",
                        "code": "653101"
                    },
                    {
                        "label": "疏附县",
                        "value": "疏附县",
                        "code": "653121"
                    },
                    {
                        "label": "疏勒县",
                        "value": "疏勒县",
                        "code": "653122"
                    },
                    {
                        "label": "英吉沙县",
                        "value": "英吉沙县",
                        "code": "653123"
                    },
                    {
                        "label": "泽普县",
                        "value": "泽普县",
                        "code": "653124"
                    },
                    {
                        "label": "莎车县",
                        "value": "莎车县",
                        "code": "653125"
                    },
                    {
                        "label": "叶城县",
                        "value": "叶城县",
                        "code": "653126"
                    },
                    {
                        "label": "麦盖提县",
                        "value": "麦盖提县",
                        "code": "653127"
                    },
                    {
                        "label": "岳普湖县",
                        "value": "岳普湖县",
                        "code": "653128"
                    },
                    {
                        "label": "伽师县",
                        "value": "伽师县",
                        "code": "653129"
                    },
                    {
                        "label": "巴楚县",
                        "value": "巴楚县",
                        "code": "653130"
                    },
                    {
                        "label": "塔什库尔干塔吉克自治县",
                        "value": "塔什库尔干塔吉克自治县",
                        "code": "653131"
                    }
                ],
                "code": "653100"
            },
            {
                "label": "和田地区",
                "value": "和田地区",
                "children": [
                    {
                        "label": "和田市",
                        "value": "和田市",
                        "code": "653201"
                    },
                    {
                        "label": "和田县",
                        "value": "和田县",
                        "code": "653221"
                    },
                    {
                        "label": "墨玉县",
                        "value": "墨玉县",
                        "code": "653222"
                    },
                    {
                        "label": "皮山县",
                        "value": "皮山县",
                        "code": "653223"
                    },
                    {
                        "label": "洛浦县",
                        "value": "洛浦县",
                        "code": "653224"
                    },
                    {
                        "label": "策勒县",
                        "value": "策勒县",
                        "code": "653225"
                    },
                    {
                        "label": "于田县",
                        "value": "于田县",
                        "code": "653226"
                    },
                    {
                        "label": "民丰县",
                        "value": "民丰县",
                        "code": "653227"
                    }
                ],
                "code": "653200"
            },
            {
                "label": "伊犁哈萨克自治州",
                "value": "伊犁哈萨克自治州",
                "children": [
                    {
                        "label": "伊宁市",
                        "value": "伊宁市",
                        "code": "654002"
                    },
                    {
                        "label": "奎屯市",
                        "value": "奎屯市",
                        "code": "654003"
                    },
                    {
                        "label": "霍尔果斯市",
                        "value": "霍尔果斯市",
                        "code": "654004"
                    },
                    {
                        "label": "伊宁县",
                        "value": "伊宁县",
                        "code": "654021"
                    },
                    {
                        "label": "察布查尔锡伯自治县",
                        "value": "察布查尔锡伯自治县",
                        "code": "654022"
                    },
                    {
                        "label": "霍城县",
                        "value": "霍城县",
                        "code": "654023"
                    },
                    {
                        "label": "巩留县",
                        "value": "巩留县",
                        "code": "654024"
                    },
                    {
                        "label": "新源县",
                        "value": "新源县",
                        "code": "654025"
                    },
                    {
                        "label": "昭苏县",
                        "value": "昭苏县",
                        "code": "654026"
                    },
                    {
                        "label": "特克斯县",
                        "value": "特克斯县",
                        "code": "654027"
                    },
                    {
                        "label": "尼勒克县",
                        "value": "尼勒克县",
                        "code": "654028"
                    },
                    {
                        "label": "塔城地区",
                        "value": "塔城地区",
                        "code": "654200"
                    },
                    {
                        "label": "塔城市",
                        "value": "塔城市",
                        "code": "654201"
                    },
                    {
                        "label": "乌苏市",
                        "value": "乌苏市",
                        "code": "654202"
                    },
                    {
                        "label": "额敏县",
                        "value": "额敏县",
                        "code": "654221"
                    },
                    {
                        "label": "沙湾县",
                        "value": "沙湾县",
                        "code": "654223"
                    },
                    {
                        "label": "托里县",
                        "value": "托里县",
                        "code": "654224"
                    },
                    {
                        "label": "裕民县",
                        "value": "裕民县",
                        "code": "654225"
                    },
                    {
                        "label": "和布克赛尔蒙古自治县",
                        "value": "和布克赛尔蒙古自治县",
                        "code": "654226"
                    },
                    {
                        "label": "阿勒泰地区",
                        "value": "阿勒泰地区",
                        "code": "654300"
                    },
                    {
                        "label": "阿勒泰市",
                        "value": "阿勒泰市",
                        "code": "654301"
                    },
                    {
                        "label": "布尔津县",
                        "value": "布尔津县",
                        "code": "654321"
                    },
                    {
                        "label": "富蕴县",
                        "value": "富蕴县",
                        "code": "654322"
                    },
                    {
                        "label": "福海县",
                        "value": "福海县",
                        "code": "654323"
                    },
                    {
                        "label": "哈巴河县",
                        "value": "哈巴河县",
                        "code": "654324"
                    },
                    {
                        "label": "青河县",
                        "value": "青河县",
                        "code": "654325"
                    },
                    {
                        "label": "吉木乃县",
                        "value": "吉木乃县",
                        "code": "654326"
                    }
                ],
                "code": "654000"
            },
            {
                "label": "自治区直辖县级行政区划",
                "value": "自治区直辖县级行政区划",
                "children": [
                    {
                        "label": "石河子市",
                        "value": "石河子市",
                        "code": "659001"
                    },
                    {
                        "label": "阿拉尔市",
                        "value": "阿拉尔市",
                        "code": "659002"
                    },
                    {
                        "label": "图木舒克市",
                        "value": "图木舒克市",
                        "code": "659003"
                    },
                    {
                        "label": "五家渠市",
                        "value": "五家渠市",
                        "code": "659004"
                    },
                    {
                        "label": "北屯市",
                        "value": "北屯市",
                        "code": "659005"
                    },
                    {
                        "label": "铁门关市",
                        "value": "铁门关市",
                        "code": "659006"
                    },
                    {
                        "label": "双河市",
                        "value": "双河市",
                        "code": "659007"
                    }
                ],
                "code": "659000"
            }
        ],
        "code": "650000"
    },
    {
        "label": "台湾省",
        "value": "台湾省",
        "children": [
            {
                "label": "台北市",
                "value": "台北市",
                "children": [
                    {
                        "label": "松山区",
                        "value": "松山区",
                        "code": "710101"
                    },
                    {
                        "label": "信义区",
                        "value": "信义区",
                        "code": "710102"
                    },
                    {
                        "label": "大安区",
                        "value": "大安区",
                        "code": "710103"
                    },
                    {
                        "label": "中山区",
                        "value": "中山区",
                        "code": "710104"
                    },
                    {
                        "label": "中正区",
                        "value": "中正区",
                        "code": "710105"
                    },
                    {
                        "label": "大同区",
                        "value": "大同区",
                        "code": "710106"
                    },
                    {
                        "label": "万华区",
                        "value": "万华区",
                        "code": "710107"
                    },
                    {
                        "label": "文山区",
                        "value": "文山区",
                        "code": "710108"
                    },
                    {
                        "label": "南港区",
                        "value": "南港区",
                        "code": "710109"
                    },
                    {
                        "label": "内湖区",
                        "value": "内湖区",
                        "code": "710110"
                    },
                    {
                        "label": "士林区",
                        "value": "士林区",
                        "code": "710111"
                    },
                    {
                        "label": "北投区",
                        "value": "北投区",
                        "code": "710112"
                    }
                ],
                "code": "710100"
            },
            {
                "label": "高雄市",
                "value": "高雄市",
                "children": [
                    {
                        "label": "盐埕区",
                        "value": "盐埕区",
                        "code": "710201"
                    },
                    {
                        "label": "鼓山区",
                        "value": "鼓山区",
                        "code": "710202"
                    },
                    {
                        "label": "左营区",
                        "value": "左营区",
                        "code": "710203"
                    },
                    {
                        "label": "楠梓区",
                        "value": "楠梓区",
                        "code": "710204"
                    },
                    {
                        "label": "三民区",
                        "value": "三民区",
                        "code": "710205"
                    },
                    {
                        "label": "新兴区",
                        "value": "新兴区",
                        "code": "710206"
                    },
                    {
                        "label": "前金区",
                        "value": "前金区",
                        "code": "710207"
                    },
                    {
                        "label": "苓雅区",
                        "value": "苓雅区",
                        "code": "710208"
                    },
                    {
                        "label": "前镇区",
                        "value": "前镇区",
                        "code": "710209"
                    },
                    {
                        "label": "旗津区",
                        "value": "旗津区",
                        "code": "710210"
                    },
                    {
                        "label": "小港区",
                        "value": "小港区",
                        "code": "710211"
                    },
                    {
                        "label": "凤山区",
                        "value": "凤山区",
                        "code": "710212"
                    },
                    {
                        "label": "林园区",
                        "value": "林园区",
                        "code": "710213"
                    },
                    {
                        "label": "大寮区",
                        "value": "大寮区",
                        "code": "710214"
                    },
                    {
                        "label": "大树区",
                        "value": "大树区",
                        "code": "710215"
                    },
                    {
                        "label": "大社区",
                        "value": "大社区",
                        "code": "710216"
                    },
                    {
                        "label": "仁武区",
                        "value": "仁武区",
                        "code": "710217"
                    },
                    {
                        "label": "鸟松区",
                        "value": "鸟松区",
                        "code": "710218"
                    },
                    {
                        "label": "冈山区",
                        "value": "冈山区",
                        "code": "710219"
                    },
                    {
                        "label": "桥头区",
                        "value": "桥头区",
                        "code": "710220"
                    },
                    {
                        "label": "燕巢区",
                        "value": "燕巢区",
                        "code": "710221"
                    },
                    {
                        "label": "田寮区",
                        "value": "田寮区",
                        "code": "710222"
                    },
                    {
                        "label": "阿莲区",
                        "value": "阿莲区",
                        "code": "710223"
                    },
                    {
                        "label": "路竹区",
                        "value": "路竹区",
                        "code": "710224"
                    },
                    {
                        "label": "湖内区",
                        "value": "湖内区",
                        "code": "710225"
                    },
                    {
                        "label": "茄萣区",
                        "value": "茄萣区",
                        "code": "710226"
                    },
                    {
                        "label": "永安区",
                        "value": "永安区",
                        "code": "710227"
                    },
                    {
                        "label": "弥陀区",
                        "value": "弥陀区",
                        "code": "710228"
                    },
                    {
                        "label": "梓官区",
                        "value": "梓官区",
                        "code": "710229"
                    },
                    {
                        "label": "旗山区",
                        "value": "旗山区",
                        "code": "710230"
                    },
                    {
                        "label": "美浓区",
                        "value": "美浓区",
                        "code": "710231"
                    },
                    {
                        "label": "六龟区",
                        "value": "六龟区",
                        "code": "710232"
                    },
                    {
                        "label": "甲仙区",
                        "value": "甲仙区",
                        "code": "710233"
                    },
                    {
                        "label": "杉林区",
                        "value": "杉林区",
                        "code": "710234"
                    },
                    {
                        "label": "内门区",
                        "value": "内门区",
                        "code": "710235"
                    },
                    {
                        "label": "茂林区",
                        "value": "茂林区",
                        "code": "710236"
                    },
                    {
                        "label": "桃源区",
                        "value": "桃源区",
                        "code": "710237"
                    },
                    {
                        "label": "那玛夏区",
                        "value": "那玛夏区",
                        "code": "710238"
                    }
                ],
                "code": "710200"
            },
            {
                "label": "基隆市",
                "value": "基隆市",
                "children": [
                    {
                        "label": "中正区",
                        "value": "中正区",
                        "code": "710301"
                    },
                    {
                        "label": "七堵区",
                        "value": "七堵区",
                        "code": "710302"
                    },
                    {
                        "label": "暖暖区",
                        "value": "暖暖区",
                        "code": "710303"
                    },
                    {
                        "label": "仁爱区",
                        "value": "仁爱区",
                        "code": "710304"
                    },
                    {
                        "label": "中山区",
                        "value": "中山区",
                        "code": "710305"
                    },
                    {
                        "label": "安乐区",
                        "value": "安乐区",
                        "code": "710306"
                    },
                    {
                        "label": "信义区",
                        "value": "信义区",
                        "code": "710307"
                    }
                ],
                "code": "710300"
            },
            {
                "label": "台中市",
                "value": "台中市",
                "children": [
                    {
                        "label": "中区",
                        "value": "中区",
                        "code": "710401"
                    },
                    {
                        "label": "东区",
                        "value": "东区",
                        "code": "710402"
                    },
                    {
                        "label": "南区",
                        "value": "南区",
                        "code": "710403"
                    },
                    {
                        "label": "西区",
                        "value": "西区",
                        "code": "710404"
                    },
                    {
                        "label": "北区",
                        "value": "北区",
                        "code": "710405"
                    },
                    {
                        "label": "西屯区",
                        "value": "西屯区",
                        "code": "710406"
                    },
                    {
                        "label": "南屯区",
                        "value": "南屯区",
                        "code": "710407"
                    },
                    {
                        "label": "北屯区",
                        "value": "北屯区",
                        "code": "710408"
                    },
                    {
                        "label": "丰原区",
                        "value": "丰原区",
                        "code": "710409"
                    },
                    {
                        "label": "东势区",
                        "value": "东势区",
                        "code": "710410"
                    },
                    {
                        "label": "大甲区",
                        "value": "大甲区",
                        "code": "710411"
                    },
                    {
                        "label": "清水区",
                        "value": "清水区",
                        "code": "710412"
                    },
                    {
                        "label": "沙鹿区",
                        "value": "沙鹿区",
                        "code": "710413"
                    },
                    {
                        "label": "梧栖区",
                        "value": "梧栖区",
                        "code": "710414"
                    },
                    {
                        "label": "后里区",
                        "value": "后里区",
                        "code": "710415"
                    },
                    {
                        "label": "神冈区",
                        "value": "神冈区",
                        "code": "710416"
                    },
                    {
                        "label": "潭子区",
                        "value": "潭子区",
                        "code": "710417"
                    },
                    {
                        "label": "大雅区",
                        "value": "大雅区",
                        "code": "710418"
                    },
                    {
                        "label": "新社区",
                        "value": "新社区",
                        "code": "710419"
                    },
                    {
                        "label": "石冈区",
                        "value": "石冈区",
                        "code": "710420"
                    },
                    {
                        "label": "外埔区",
                        "value": "外埔区",
                        "code": "710421"
                    },
                    {
                        "label": "大安区",
                        "value": "大安区",
                        "code": "710422"
                    },
                    {
                        "label": "乌日区",
                        "value": "乌日区",
                        "code": "710423"
                    },
                    {
                        "label": "大肚区",
                        "value": "大肚区",
                        "code": "710424"
                    },
                    {
                        "label": "龙井区",
                        "value": "龙井区",
                        "code": "710425"
                    },
                    {
                        "label": "雾峰区",
                        "value": "雾峰区",
                        "code": "710426"
                    },
                    {
                        "label": "太平区",
                        "value": "太平区",
                        "code": "710427"
                    },
                    {
                        "label": "大里区",
                        "value": "大里区",
                        "code": "710428"
                    },
                    {
                        "label": "和平区",
                        "value": "和平区",
                        "code": "710429"
                    }
                ],
                "code": "710400"
            },
            {
                "label": "台南市",
                "value": "台南市",
                "children": [
                    {
                        "label": "东区",
                        "value": "东区",
                        "code": "710501"
                    },
                    {
                        "label": "南区",
                        "value": "南区",
                        "code": "710502"
                    },
                    {
                        "label": "北区",
                        "value": "北区",
                        "code": "710504"
                    },
                    {
                        "label": "安南区",
                        "value": "安南区",
                        "code": "710506"
                    },
                    {
                        "label": "安平区",
                        "value": "安平区",
                        "code": "710507"
                    },
                    {
                        "label": "中西区",
                        "value": "中西区",
                        "code": "710508"
                    },
                    {
                        "label": "新营区",
                        "value": "新营区",
                        "code": "710509"
                    },
                    {
                        "label": "盐水区",
                        "value": "盐水区",
                        "code": "710510"
                    },
                    {
                        "label": "白河区",
                        "value": "白河区",
                        "code": "710511"
                    },
                    {
                        "label": "柳营区",
                        "value": "柳营区",
                        "code": "710512"
                    },
                    {
                        "label": "后壁区",
                        "value": "后壁区",
                        "code": "710513"
                    },
                    {
                        "label": "东山区",
                        "value": "东山区",
                        "code": "710514"
                    },
                    {
                        "label": "麻豆区",
                        "value": "麻豆区",
                        "code": "710515"
                    },
                    {
                        "label": "下营区",
                        "value": "下营区",
                        "code": "710516"
                    },
                    {
                        "label": "六甲区",
                        "value": "六甲区",
                        "code": "710517"
                    },
                    {
                        "label": "官田区",
                        "value": "官田区",
                        "code": "710518"
                    },
                    {
                        "label": "大内区",
                        "value": "大内区",
                        "code": "710519"
                    },
                    {
                        "label": "佳里区",
                        "value": "佳里区",
                        "code": "710520"
                    },
                    {
                        "label": "学甲区",
                        "value": "学甲区",
                        "code": "710521"
                    },
                    {
                        "label": "西港区",
                        "value": "西港区",
                        "code": "710522"
                    },
                    {
                        "label": "七股区",
                        "value": "七股区",
                        "code": "710523"
                    },
                    {
                        "label": "将军区",
                        "value": "将军区",
                        "code": "710524"
                    },
                    {
                        "label": "北门区",
                        "value": "北门区",
                        "code": "710525"
                    },
                    {
                        "label": "新化区",
                        "value": "新化区",
                        "code": "710526"
                    },
                    {
                        "label": "善化区",
                        "value": "善化区",
                        "code": "710527"
                    },
                    {
                        "label": "新市区",
                        "value": "新市区",
                        "code": "710528"
                    },
                    {
                        "label": "安定区",
                        "value": "安定区",
                        "code": "710529"
                    },
                    {
                        "label": "山上区",
                        "value": "山上区",
                        "code": "710530"
                    },
                    {
                        "label": "玉井区",
                        "value": "玉井区",
                        "code": "710531"
                    },
                    {
                        "label": "楠西区",
                        "value": "楠西区",
                        "code": "710532"
                    },
                    {
                        "label": "南化区",
                        "value": "南化区",
                        "code": "710533"
                    },
                    {
                        "label": "左镇区",
                        "value": "左镇区",
                        "code": "710534"
                    },
                    {
                        "label": "仁德区",
                        "value": "仁德区",
                        "code": "710535"
                    },
                    {
                        "label": "归仁区",
                        "value": "归仁区",
                        "code": "710536"
                    },
                    {
                        "label": "关庙区",
                        "value": "关庙区",
                        "code": "710537"
                    },
                    {
                        "label": "龙崎区",
                        "value": "龙崎区",
                        "code": "710538"
                    },
                    {
                        "label": "永康区",
                        "value": "永康区",
                        "code": "710539"
                    }
                ],
                "code": "710500"
            },
            {
                "label": "新竹市",
                "value": "新竹市",
                "children": [
                    {
                        "label": "东区",
                        "value": "东区",
                        "code": "710601"
                    },
                    {
                        "label": "北区",
                        "value": "北区",
                        "code": "710602"
                    },
                    {
                        "label": "香山区",
                        "value": "香山区",
                        "code": "710603"
                    }
                ],
                "code": "710600"
            },
            {
                "label": "嘉义市",
                "value": "嘉义市",
                "children": [
                    {
                        "label": "东区",
                        "value": "东区",
                        "code": "710701"
                    },
                    {
                        "label": "西区",
                        "value": "西区",
                        "code": "710702"
                    }
                ],
                "code": "710700"
            },
            {
                "label": "新北市",
                "value": "新北市",
                "children": [
                    {
                        "label": "板桥区",
                        "value": "板桥区",
                        "code": "710801"
                    },
                    {
                        "label": "三重区",
                        "value": "三重区",
                        "code": "710802"
                    },
                    {
                        "label": "中和区",
                        "value": "中和区",
                        "code": "710803"
                    },
                    {
                        "label": "永和区",
                        "value": "永和区",
                        "code": "710804"
                    },
                    {
                        "label": "新庄区",
                        "value": "新庄区",
                        "code": "710805"
                    },
                    {
                        "label": "新店区",
                        "value": "新店区",
                        "code": "710806"
                    },
                    {
                        "label": "树林区",
                        "value": "树林区",
                        "code": "710807"
                    },
                    {
                        "label": "莺歌区",
                        "value": "莺歌区",
                        "code": "710808"
                    },
                    {
                        "label": "三峡区",
                        "value": "三峡区",
                        "code": "710809"
                    },
                    {
                        "label": "淡水区",
                        "value": "淡水区",
                        "code": "710810"
                    },
                    {
                        "label": "汐止区",
                        "value": "汐止区",
                        "code": "710811"
                    },
                    {
                        "label": "瑞芳区",
                        "value": "瑞芳区",
                        "code": "710812"
                    },
                    {
                        "label": "土城区",
                        "value": "土城区",
                        "code": "710813"
                    },
                    {
                        "label": "芦洲区",
                        "value": "芦洲区",
                        "code": "710814"
                    },
                    {
                        "label": "五股区",
                        "value": "五股区",
                        "code": "710815"
                    },
                    {
                        "label": "泰山区",
                        "value": "泰山区",
                        "code": "710816"
                    },
                    {
                        "label": "林口区",
                        "value": "林口区",
                        "code": "710817"
                    },
                    {
                        "label": "深坑区",
                        "value": "深坑区",
                        "code": "710818"
                    },
                    {
                        "label": "石碇区",
                        "value": "石碇区",
                        "code": "710819"
                    },
                    {
                        "label": "坪林区",
                        "value": "坪林区",
                        "code": "710820"
                    },
                    {
                        "label": "三芝区",
                        "value": "三芝区",
                        "code": "710821"
                    },
                    {
                        "label": "石门区",
                        "value": "石门区",
                        "code": "710822"
                    },
                    {
                        "label": "八里区",
                        "value": "八里区",
                        "code": "710823"
                    },
                    {
                        "label": "平溪区",
                        "value": "平溪区",
                        "code": "710824"
                    },
                    {
                        "label": "双溪区",
                        "value": "双溪区",
                        "code": "710825"
                    },
                    {
                        "label": "贡寮区",
                        "value": "贡寮区",
                        "code": "710826"
                    },
                    {
                        "label": "金山区",
                        "value": "金山区",
                        "code": "710827"
                    },
                    {
                        "label": "万里区",
                        "value": "万里区",
                        "code": "710828"
                    },
                    {
                        "label": "乌来区",
                        "value": "乌来区",
                        "code": "710829"
                    }
                ],
                "code": "710800"
            },
            {
                "label": "宜兰县",
                "value": "宜兰县",
                "children": [
                    {
                        "label": "宜兰市",
                        "value": "宜兰市",
                        "code": "712201"
                    },
                    {
                        "label": "罗东镇",
                        "value": "罗东镇",
                        "code": "712221"
                    },
                    {
                        "label": "苏澳镇",
                        "value": "苏澳镇",
                        "code": "712222"
                    },
                    {
                        "label": "头城镇",
                        "value": "头城镇",
                        "code": "712223"
                    },
                    {
                        "label": "礁溪乡",
                        "value": "礁溪乡",
                        "code": "712224"
                    },
                    {
                        "label": "壮围乡",
                        "value": "壮围乡",
                        "code": "712225"
                    },
                    {
                        "label": "员山乡",
                        "value": "员山乡",
                        "code": "712226"
                    },
                    {
                        "label": "冬山乡",
                        "value": "冬山乡",
                        "code": "712227"
                    },
                    {
                        "label": "五结乡",
                        "value": "五结乡",
                        "code": "712228"
                    },
                    {
                        "label": "三星乡",
                        "value": "三星乡",
                        "code": "712229"
                    },
                    {
                        "label": "大同乡",
                        "value": "大同乡",
                        "code": "712230"
                    },
                    {
                        "label": "南澳乡",
                        "value": "南澳乡",
                        "code": "712231"
                    }
                ],
                "code": "712200"
            },
            {
                "label": "桃园县",
                "value": "桃园县",
                "children": [
                    {
                        "label": "桃园市",
                        "value": "桃园市",
                        "code": "712301"
                    },
                    {
                        "label": "中坜市",
                        "value": "中坜市",
                        "code": "712302"
                    },
                    {
                        "label": "平镇市",
                        "value": "平镇市",
                        "code": "712303"
                    },
                    {
                        "label": "八德市",
                        "value": "八德市",
                        "code": "712304"
                    },
                    {
                        "label": "杨梅市",
                        "value": "杨梅市",
                        "code": "712305"
                    },
                    {
                        "label": "大溪镇",
                        "value": "大溪镇",
                        "code": "712321"
                    },
                    {
                        "label": "芦竹乡",
                        "value": "芦竹乡",
                        "code": "712323"
                    },
                    {
                        "label": "大园乡",
                        "value": "大园乡",
                        "code": "712324"
                    },
                    {
                        "label": "龟山乡",
                        "value": "龟山乡",
                        "code": "712325"
                    },
                    {
                        "label": "龙潭乡",
                        "value": "龙潭乡",
                        "code": "712327"
                    },
                    {
                        "label": "新屋乡",
                        "value": "新屋乡",
                        "code": "712329"
                    },
                    {
                        "label": "观音乡",
                        "value": "观音乡",
                        "code": "712330"
                    },
                    {
                        "label": "复兴乡",
                        "value": "复兴乡",
                        "code": "712331"
                    }
                ],
                "code": "712300"
            },
            {
                "label": "新竹县",
                "value": "新竹县",
                "children": [
                    {
                        "label": "竹北市",
                        "value": "竹北市",
                        "code": "712401"
                    },
                    {
                        "label": "竹东镇",
                        "value": "竹东镇",
                        "code": "712421"
                    },
                    {
                        "label": "新埔镇",
                        "value": "新埔镇",
                        "code": "712422"
                    },
                    {
                        "label": "关西镇",
                        "value": "关西镇",
                        "code": "712423"
                    },
                    {
                        "label": "湖口乡",
                        "value": "湖口乡",
                        "code": "712424"
                    },
                    {
                        "label": "新丰乡",
                        "value": "新丰乡",
                        "code": "712425"
                    },
                    {
                        "label": "芎林乡",
                        "value": "芎林乡",
                        "code": "712426"
                    },
                    {
                        "label": "橫山乡",
                        "value": "橫山乡",
                        "code": "712427"
                    },
                    {
                        "label": "北埔乡",
                        "value": "北埔乡",
                        "code": "712428"
                    },
                    {
                        "label": "宝山乡",
                        "value": "宝山乡",
                        "code": "712429"
                    },
                    {
                        "label": "峨眉乡",
                        "value": "峨眉乡",
                        "code": "712430"
                    },
                    {
                        "label": "尖石乡",
                        "value": "尖石乡",
                        "code": "712431"
                    },
                    {
                        "label": "五峰乡",
                        "value": "五峰乡",
                        "code": "712432"
                    }
                ],
                "code": "712400"
            },
            {
                "label": "苗栗县",
                "value": "苗栗县",
                "children": [
                    {
                        "label": "苗栗市",
                        "value": "苗栗市",
                        "code": "712501"
                    },
                    {
                        "label": "苑里镇",
                        "value": "苑里镇",
                        "code": "712521"
                    },
                    {
                        "label": "通霄镇",
                        "value": "通霄镇",
                        "code": "712522"
                    },
                    {
                        "label": "竹南镇",
                        "value": "竹南镇",
                        "code": "712523"
                    },
                    {
                        "label": "头份镇",
                        "value": "头份镇",
                        "code": "712524"
                    },
                    {
                        "label": "后龙镇",
                        "value": "后龙镇",
                        "code": "712525"
                    },
                    {
                        "label": "卓兰镇",
                        "value": "卓兰镇",
                        "code": "712526"
                    },
                    {
                        "label": "大湖乡",
                        "value": "大湖乡",
                        "code": "712527"
                    },
                    {
                        "label": "公馆乡",
                        "value": "公馆乡",
                        "code": "712528"
                    },
                    {
                        "label": "铜锣乡",
                        "value": "铜锣乡",
                        "code": "712529"
                    },
                    {
                        "label": "南庄乡",
                        "value": "南庄乡",
                        "code": "712530"
                    },
                    {
                        "label": "头屋乡",
                        "value": "头屋乡",
                        "code": "712531"
                    },
                    {
                        "label": "三义乡",
                        "value": "三义乡",
                        "code": "712532"
                    },
                    {
                        "label": "西湖乡",
                        "value": "西湖乡",
                        "code": "712533"
                    },
                    {
                        "label": "造桥乡",
                        "value": "造桥乡",
                        "code": "712534"
                    },
                    {
                        "label": "三湾乡",
                        "value": "三湾乡",
                        "code": "712535"
                    },
                    {
                        "label": "狮潭乡",
                        "value": "狮潭乡",
                        "code": "712536"
                    },
                    {
                        "label": "泰安乡",
                        "value": "泰安乡",
                        "code": "712537"
                    }
                ],
                "code": "712500"
            },
            {
                "label": "彰化县",
                "value": "彰化县",
                "children": [
                    {
                        "label": "彰化市",
                        "value": "彰化市",
                        "code": "712701"
                    },
                    {
                        "label": "鹿港镇",
                        "value": "鹿港镇",
                        "code": "712721"
                    },
                    {
                        "label": "和美镇",
                        "value": "和美镇",
                        "code": "712722"
                    },
                    {
                        "label": "线西乡",
                        "value": "线西乡",
                        "code": "712723"
                    },
                    {
                        "label": "伸港乡",
                        "value": "伸港乡",
                        "code": "712724"
                    },
                    {
                        "label": "福兴乡",
                        "value": "福兴乡",
                        "code": "712725"
                    },
                    {
                        "label": "秀水乡",
                        "value": "秀水乡",
                        "code": "712726"
                    },
                    {
                        "label": "花坛乡",
                        "value": "花坛乡",
                        "code": "712727"
                    },
                    {
                        "label": "芬园乡",
                        "value": "芬园乡",
                        "code": "712728"
                    },
                    {
                        "label": "员林镇",
                        "value": "员林镇",
                        "code": "712729"
                    },
                    {
                        "label": "溪湖镇",
                        "value": "溪湖镇",
                        "code": "712730"
                    },
                    {
                        "label": "田中镇",
                        "value": "田中镇",
                        "code": "712731"
                    },
                    {
                        "label": "大村乡",
                        "value": "大村乡",
                        "code": "712732"
                    },
                    {
                        "label": "埔盐乡",
                        "value": "埔盐乡",
                        "code": "712733"
                    },
                    {
                        "label": "埔心乡",
                        "value": "埔心乡",
                        "code": "712734"
                    },
                    {
                        "label": "永靖乡",
                        "value": "永靖乡",
                        "code": "712735"
                    },
                    {
                        "label": "社头乡",
                        "value": "社头乡",
                        "code": "712736"
                    },
                    {
                        "label": "二水乡",
                        "value": "二水乡",
                        "code": "712737"
                    },
                    {
                        "label": "北斗镇",
                        "value": "北斗镇",
                        "code": "712738"
                    },
                    {
                        "label": "二林镇",
                        "value": "二林镇",
                        "code": "712739"
                    },
                    {
                        "label": "田尾乡",
                        "value": "田尾乡",
                        "code": "712740"
                    },
                    {
                        "label": "埤头乡",
                        "value": "埤头乡",
                        "code": "712741"
                    },
                    {
                        "label": "芳苑乡",
                        "value": "芳苑乡",
                        "code": "712742"
                    },
                    {
                        "label": "大城乡",
                        "value": "大城乡",
                        "code": "712743"
                    },
                    {
                        "label": "竹塘乡",
                        "value": "竹塘乡",
                        "code": "712744"
                    },
                    {
                        "label": "溪州乡",
                        "value": "溪州乡",
                        "code": "712745"
                    }
                ],
                "code": "712700"
            },
            {
                "label": "南投县",
                "value": "南投县",
                "children": [
                    {
                        "label": "南投市",
                        "value": "南投市",
                        "code": "712801"
                    },
                    {
                        "label": "埔里镇",
                        "value": "埔里镇",
                        "code": "712821"
                    },
                    {
                        "label": "草屯镇",
                        "value": "草屯镇",
                        "code": "712822"
                    },
                    {
                        "label": "竹山镇",
                        "value": "竹山镇",
                        "code": "712823"
                    },
                    {
                        "label": "集集镇",
                        "value": "集集镇",
                        "code": "712824"
                    },
                    {
                        "label": "名间乡",
                        "value": "名间乡",
                        "code": "712825"
                    },
                    {
                        "label": "鹿谷乡",
                        "value": "鹿谷乡",
                        "code": "712826"
                    },
                    {
                        "label": "中寮乡",
                        "value": "中寮乡",
                        "code": "712827"
                    },
                    {
                        "label": "鱼池乡",
                        "value": "鱼池乡",
                        "code": "712828"
                    },
                    {
                        "label": "国姓乡",
                        "value": "国姓乡",
                        "code": "712829"
                    },
                    {
                        "label": "水里乡",
                        "value": "水里乡",
                        "code": "712830"
                    },
                    {
                        "label": "信义乡",
                        "value": "信义乡",
                        "code": "712831"
                    },
                    {
                        "label": "仁爱乡",
                        "value": "仁爱乡",
                        "code": "712832"
                    }
                ],
                "code": "712800"
            },
            {
                "label": "云林县",
                "value": "云林县",
                "children": [
                    {
                        "label": "斗六市",
                        "value": "斗六市",
                        "code": "712901"
                    },
                    {
                        "label": "斗南镇",
                        "value": "斗南镇",
                        "code": "712921"
                    },
                    {
                        "label": "虎尾镇",
                        "value": "虎尾镇",
                        "code": "712922"
                    },
                    {
                        "label": "西螺镇",
                        "value": "西螺镇",
                        "code": "712923"
                    },
                    {
                        "label": "土库镇",
                        "value": "土库镇",
                        "code": "712924"
                    },
                    {
                        "label": "北港镇",
                        "value": "北港镇",
                        "code": "712925"
                    },
                    {
                        "label": "古坑乡",
                        "value": "古坑乡",
                        "code": "712926"
                    },
                    {
                        "label": "大埤乡",
                        "value": "大埤乡",
                        "code": "712927"
                    },
                    {
                        "label": "莿桐乡",
                        "value": "莿桐乡",
                        "code": "712928"
                    },
                    {
                        "label": "林内乡",
                        "value": "林内乡",
                        "code": "712929"
                    },
                    {
                        "label": "二仑乡",
                        "value": "二仑乡",
                        "code": "712930"
                    },
                    {
                        "label": "仑背乡",
                        "value": "仑背乡",
                        "code": "712931"
                    },
                    {
                        "label": "麦寮乡",
                        "value": "麦寮乡",
                        "code": "712932"
                    },
                    {
                        "label": "东势乡",
                        "value": "东势乡",
                        "code": "712933"
                    },
                    {
                        "label": "褒忠乡",
                        "value": "褒忠乡",
                        "code": "712934"
                    },
                    {
                        "label": "台西乡",
                        "value": "台西乡",
                        "code": "712935"
                    },
                    {
                        "label": "元长乡",
                        "value": "元长乡",
                        "code": "712936"
                    },
                    {
                        "label": "四湖乡",
                        "value": "四湖乡",
                        "code": "712937"
                    },
                    {
                        "label": "口湖乡",
                        "value": "口湖乡",
                        "code": "712938"
                    },
                    {
                        "label": "水林乡",
                        "value": "水林乡",
                        "code": "712939"
                    }
                ],
                "code": "712900"
            },
            {
                "label": "嘉义县",
                "value": "嘉义县",
                "children": [
                    {
                        "label": "太保市",
                        "value": "太保市",
                        "code": "713001"
                    },
                    {
                        "label": "朴子市",
                        "value": "朴子市",
                        "code": "713002"
                    },
                    {
                        "label": "布袋镇",
                        "value": "布袋镇",
                        "code": "713023"
                    },
                    {
                        "label": "大林镇",
                        "value": "大林镇",
                        "code": "713024"
                    },
                    {
                        "label": "民雄乡",
                        "value": "民雄乡",
                        "code": "713025"
                    },
                    {
                        "label": "溪口乡",
                        "value": "溪口乡",
                        "code": "713026"
                    },
                    {
                        "label": "新港乡",
                        "value": "新港乡",
                        "code": "713027"
                    },
                    {
                        "label": "六脚乡",
                        "value": "六脚乡",
                        "code": "713028"
                    },
                    {
                        "label": "东石乡",
                        "value": "东石乡",
                        "code": "713029"
                    },
                    {
                        "label": "义竹乡",
                        "value": "义竹乡",
                        "code": "713030"
                    },
                    {
                        "label": "鹿草乡",
                        "value": "鹿草乡",
                        "code": "713031"
                    },
                    {
                        "label": "水上乡",
                        "value": "水上乡",
                        "code": "713032"
                    },
                    {
                        "label": "中埔乡",
                        "value": "中埔乡",
                        "code": "713033"
                    },
                    {
                        "label": "竹崎乡",
                        "value": "竹崎乡",
                        "code": "713034"
                    },
                    {
                        "label": "梅山乡",
                        "value": "梅山乡",
                        "code": "713035"
                    },
                    {
                        "label": "番路乡",
                        "value": "番路乡",
                        "code": "713036"
                    },
                    {
                        "label": "大埔乡",
                        "value": "大埔乡",
                        "code": "713037"
                    },
                    {
                        "label": "阿里山乡",
                        "value": "阿里山乡",
                        "code": "713038"
                    }
                ],
                "code": "713000"
            },
            {
                "label": "屏东县",
                "value": "屏东县",
                "children": [
                    {
                        "label": "屏东市",
                        "value": "屏东市",
                        "code": "713301"
                    },
                    {
                        "label": "潮州镇",
                        "value": "潮州镇",
                        "code": "713321"
                    },
                    {
                        "label": "东港镇",
                        "value": "东港镇",
                        "code": "713322"
                    },
                    {
                        "label": "恒春镇",
                        "value": "恒春镇",
                        "code": "713323"
                    },
                    {
                        "label": "万丹乡",
                        "value": "万丹乡",
                        "code": "713324"
                    },
                    {
                        "label": "长治乡",
                        "value": "长治乡",
                        "code": "713325"
                    },
                    {
                        "label": "麟洛乡",
                        "value": "麟洛乡",
                        "code": "713326"
                    },
                    {
                        "label": "九如乡",
                        "value": "九如乡",
                        "code": "713327"
                    },
                    {
                        "label": "里港乡",
                        "value": "里港乡",
                        "code": "713328"
                    },
                    {
                        "label": "盐埔乡",
                        "value": "盐埔乡",
                        "code": "713329"
                    },
                    {
                        "label": "高树乡",
                        "value": "高树乡",
                        "code": "713330"
                    },
                    {
                        "label": "万峦乡",
                        "value": "万峦乡",
                        "code": "713331"
                    },
                    {
                        "label": "内埔乡",
                        "value": "内埔乡",
                        "code": "713332"
                    },
                    {
                        "label": "竹田乡",
                        "value": "竹田乡",
                        "code": "713333"
                    },
                    {
                        "label": "新埤乡",
                        "value": "新埤乡",
                        "code": "713334"
                    },
                    {
                        "label": "枋寮乡",
                        "value": "枋寮乡",
                        "code": "713335"
                    },
                    {
                        "label": "新园乡",
                        "value": "新园乡",
                        "code": "713336"
                    },
                    {
                        "label": "崁顶乡",
                        "value": "崁顶乡",
                        "code": "713337"
                    },
                    {
                        "label": "林边乡",
                        "value": "林边乡",
                        "code": "713338"
                    },
                    {
                        "label": "南州乡",
                        "value": "南州乡",
                        "code": "713339"
                    },
                    {
                        "label": "佳冬乡",
                        "value": "佳冬乡",
                        "code": "713340"
                    },
                    {
                        "label": "琉球乡",
                        "value": "琉球乡",
                        "code": "713341"
                    },
                    {
                        "label": "车城乡",
                        "value": "车城乡",
                        "code": "713342"
                    },
                    {
                        "label": "满州乡",
                        "value": "满州乡",
                        "code": "713343"
                    },
                    {
                        "label": "枋山乡",
                        "value": "枋山乡",
                        "code": "713344"
                    },
                    {
                        "label": "三地门乡",
                        "value": "三地门乡",
                        "code": "713345"
                    },
                    {
                        "label": "雾台乡",
                        "value": "雾台乡",
                        "code": "713346"
                    },
                    {
                        "label": "玛家乡",
                        "value": "玛家乡",
                        "code": "713347"
                    },
                    {
                        "label": "泰武乡",
                        "value": "泰武乡",
                        "code": "713348"
                    },
                    {
                        "label": "来义乡",
                        "value": "来义乡",
                        "code": "713349"
                    },
                    {
                        "label": "春日乡",
                        "value": "春日乡",
                        "code": "713350"
                    },
                    {
                        "label": "狮子乡",
                        "value": "狮子乡",
                        "code": "713351"
                    },
                    {
                        "label": "牡丹乡",
                        "value": "牡丹乡",
                        "code": "713352"
                    }
                ],
                "code": "713300"
            },
            {
                "label": "台东县",
                "value": "台东县",
                "children": [
                    {
                        "label": "台东市",
                        "value": "台东市",
                        "code": "713401"
                    },
                    {
                        "label": "成功镇",
                        "value": "成功镇",
                        "code": "713421"
                    },
                    {
                        "label": "关山镇",
                        "value": "关山镇",
                        "code": "713422"
                    },
                    {
                        "label": "卑南乡",
                        "value": "卑南乡",
                        "code": "713423"
                    },
                    {
                        "label": "鹿野乡",
                        "value": "鹿野乡",
                        "code": "713424"
                    },
                    {
                        "label": "池上乡",
                        "value": "池上乡",
                        "code": "713425"
                    },
                    {
                        "label": "东河乡",
                        "value": "东河乡",
                        "code": "713426"
                    },
                    {
                        "label": "长滨乡",
                        "value": "长滨乡",
                        "code": "713427"
                    },
                    {
                        "label": "太麻里乡",
                        "value": "太麻里乡",
                        "code": "713428"
                    },
                    {
                        "label": "大武乡",
                        "value": "大武乡",
                        "code": "713429"
                    },
                    {
                        "label": "绿岛乡",
                        "value": "绿岛乡",
                        "code": "713430"
                    },
                    {
                        "label": "海端乡",
                        "value": "海端乡",
                        "code": "713431"
                    },
                    {
                        "label": "延平乡",
                        "value": "延平乡",
                        "code": "713432"
                    },
                    {
                        "label": "金峰乡",
                        "value": "金峰乡",
                        "code": "713433"
                    },
                    {
                        "label": "达仁乡",
                        "value": "达仁乡",
                        "code": "713434"
                    },
                    {
                        "label": "兰屿乡",
                        "value": "兰屿乡",
                        "code": "713435"
                    }
                ],
                "code": "713400"
            },
            {
                "label": "花莲县",
                "value": "花莲县",
                "children": [
                    {
                        "label": "花莲市",
                        "value": "花莲市",
                        "code": "713501"
                    },
                    {
                        "label": "凤林镇",
                        "value": "凤林镇",
                        "code": "713521"
                    },
                    {
                        "label": "玉里镇",
                        "value": "玉里镇",
                        "code": "713522"
                    },
                    {
                        "label": "新城乡",
                        "value": "新城乡",
                        "code": "713523"
                    },
                    {
                        "label": "吉安乡",
                        "value": "吉安乡",
                        "code": "713524"
                    },
                    {
                        "label": "寿丰乡",
                        "value": "寿丰乡",
                        "code": "713525"
                    },
                    {
                        "label": "光复乡",
                        "value": "光复乡",
                        "code": "713526"
                    },
                    {
                        "label": "丰滨乡",
                        "value": "丰滨乡",
                        "code": "713527"
                    },
                    {
                        "label": "瑞穗乡",
                        "value": "瑞穗乡",
                        "code": "713528"
                    },
                    {
                        "label": "富里乡",
                        "value": "富里乡",
                        "code": "713529"
                    },
                    {
                        "label": "秀林乡",
                        "value": "秀林乡",
                        "code": "713530"
                    },
                    {
                        "label": "万荣乡",
                        "value": "万荣乡",
                        "code": "713531"
                    },
                    {
                        "label": "卓溪乡",
                        "value": " 卓溪乡",
                        "code": "713532"
                    }
                ],
                "code": "713500"
            },
            {
                "label": "澎湖县",
                "value": "澎湖县",
                "children": [
                    {
                        "label": "马公市",
                        "value": "马公市",
                        "code": "713601"
                    },
                    {
                        "label": "湖西乡",
                        "value": "湖西乡",
                        "code": "713621"
                    },
                    {
                        "label": "白沙乡",
                        "value": "白沙乡",
                        "code": "713622"
                    },
                    {
                        "label": "西屿乡",
                        "value": "西屿乡",
                        "code": "713623"
                    },
                    {
                        "label": "望安乡",
                        "value": "望安乡",
                        "code": "713624"
                    },
                    {
                        "label": "七美乡",
                        "value": "七美乡",
                        "code": "713625"
                    }
                ],
                "code": "713600"
            }
        ],
        "code": "710000"
    },
    {
        "label": "香港特别行政区",
        "value": "香港特别行政区",
        "children": [
            {
                "label": "香港岛",
                "value": "香港岛",
                "children": [
                    {
                        "label": "中西区",
                        "value": "中西区",
                        "code": "810101"
                    },
                    {
                        "label": "湾仔区",
                        "value": "湾仔区",
                        "code": "810102"
                    },
                    {
                        "label": "东区",
                        "value": "东区",
                        "code": "810103"
                    },
                    {
                        "label": "南区",
                        "value": "南区",
                        "code": "810104"
                    }
                ],
                "code": "810100"
            },
            {
                "label": "九龙",
                "value": "九龙",
                "children": [
                    {
                        "label": "油尖旺区",
                        "value": "油尖旺区",
                        "code": "810201"
                    },
                    {
                        "label": "深水埗区",
                        "value": "深水埗区",
                        "code": "810202"
                    },
                    {
                        "label": "九龙城区",
                        "value": "九龙城区",
                        "code": "810203"
                    },
                    {
                        "label": "黄大仙区",
                        "value": "黄大仙区",
                        "code": "810204"
                    },
                    {
                        "label": "观塘区",
                        "value": "观塘区",
                        "code": "810205"
                    }
                ],
                "code": "810200"
            },
            {
                "label": "新界",
                "value": "新界",
                "children": [
                    {
                        "label": "荃湾区",
                        "value": "荃湾区",
                        "code": "810301"
                    },
                    {
                        "label": "屯门区",
                        "value": "屯门区",
                        "code": "810302"
                    },
                    {
                        "label": "元朗区",
                        "value": "元朗区",
                        "code": "810303"
                    },
                    {
                        "label": "北区",
                        "value": "北区",
                        "code": "810304"
                    },
                    {
                        "label": "大埔区",
                        "value": "大埔区",
                        "code": "810305"
                    },
                    {
                        "label": "西贡区",
                        "value": "西贡区",
                        "code": "810306"
                    },
                    {
                        "label": "沙田区",
                        "value": "沙田区",
                        "code": "810307"
                    },
                    {
                        "label": "葵青区",
                        "value": "葵青区",
                        "code": "810308"
                    },
                    {
                        "label": "离岛区",
                        "value": "离岛区",
                        "code": "810309"
                    }
                ],
                "code": "810300"
            }
        ],
        "code": "810000"
    },
    {
        "label": "澳门特别行政区",
        "value": "澳门特别行政区",
        "children": [
            {
                "label": "澳门半岛",
                "value": "澳门半岛",
                "children": [
                    {
                        "label": "花地玛堂区",
                        "value": "花地玛堂区",
                        "code": "820101"
                    },
                    {
                        "label": "圣安多尼堂区",
                        "value": "圣安多尼堂区",
                        "code": "820102"
                    },
                    {
                        "label": "大堂区",
                        "value": "大堂区",
                        "code": "820103"
                    },
                    {
                        "label": "望德堂区",
                        "value": "望德堂区",
                        "code": "820104"
                    },
                    {
                        "label": "风顺堂区",
                        "value": "风顺堂区",
                        "code": "820105"
                    }
                ],
                "code": "820100"
            },
            {
                "label": "氹仔岛",
                "value": "氹仔岛",
                "children": [
                    {
                        "label": "嘉模堂区",
                        "value": "嘉模堂区",
                        "code": "820201"
                    }
                ],
                "code": "820200"
            },
            {
                "label": "路环岛",
                "value": "路环岛",
                "children": [
                    {
                        "label": "圣方济各堂区",
                        "value": "圣方济各堂区",
                        "code": "820301"
                    }
                ],
                "code": "820300"
            }
        ],
        "code": "820000"
    }
]

export default city;