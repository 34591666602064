import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';


const BatchObtain = (props: Partial<CustomIconComponentProps>) => {
    const svg = (attr: any) => (
        <svg  {...attr} {...props} viewBox="0 0 11.708 13.07">
            <g id="批量获取" transform="translate(-53.371)">
                <path id="路径_4609" data-name="路径 4609" d="M59.185,12.017a.537.537,0,0,0-.4-.169H54.852a.22.22,0,0,1-.212-.228V1.464a.22.22,0,0,1,.22-.22h7.616a.22.22,0,0,1,.224.216V5.078a.322.322,0,0,0,.267.326l.762.148a.186.186,0,0,0,.224-.14.157.157,0,0,0,0-.047V.292A.292.292,0,0,0,63.67,0H53.663a.292.292,0,0,0-.292.292V12.779a.292.292,0,0,0,.292.292h6.055a.178.178,0,0,0,.178-.178.19.19,0,0,0-.042-.114Z" fill="#fff" />
                <path id="路径_4610" data-name="路径 4610" d="M217.132,192.6m.224,0h5.865a.2.2,0,0,1,.224.224v.787a.2.2,0,0,1-.224.224h-5.865a.2.2,0,0,1-.224-.224v-.787A.2.2,0,0,1,217.356,192.6Z" transform="translate(-161.67 -190.142)" fill="#fff" />
                <path id="路径_4611" data-name="路径 4611" d="M217.132,382.218m.224,0h5.018a.2.2,0,0,1,.224.224v.787a.2.2,0,0,1-.224.224h-5.018a.2.2,0,0,1-.224-.224v-.787A.2.2,0,0,1,217.356,382.218Z" transform="translate(-161.67 -377.34)" fill="#fff" />
                <path id="路径_4612" data-name="路径 4612" d="M217.132,579.129m.224,0h2.9a.2.2,0,0,1,.224.224v.787a.2.2,0,0,1-.224.224h-2.9a.2.2,0,0,1-.224-.224v-.787A.2.2,0,0,1,217.356,579.129Z" transform="translate(-161.67 -571.736)" fill="#fff" />
                <path id="路径_4613" data-name="路径 4613" d="M572.581,554.613l1.519,1.523a.292.292,0,0,0,.394,0l.69-.7a.279.279,0,0,0,0-.389l-.6-.6h2.145a.169.169,0,0,0,.173-.157v-1.185a.165.165,0,0,0-.173-.161h-2.116l.52-.525a.288.288,0,0,0,0-.4l-.694-.685a.275.275,0,0,0-.389,0l-1.46,1.46-.7.69a.275.275,0,0,0,0,.389Z" transform="translate(-511.822 -544.221)" fill="#fff" />
            </g>
        </svg>

    )
    return <Icon component={svg} {...props} />
}

const BatchObtainIcon: React.FC<any> = (props: any) => {
    let childProps = { ...props }
    Reflect.deleteProperty(childProps, 'onClick')
    return <span className={'icon'} onClick={props.onClick}><BatchObtain {...childProps} /></span>
}

export default BatchObtainIcon
