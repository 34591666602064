import { Suspense, useContext, useMemo } from "react";
import { useLocation, RouteObject, Navigate, useRoutes } from "react-router";
import { UserContext } from "../global/models/user";


export declare type routeParams = {
  component: any;
  path: string; /* 相对路径: user/ ; 绝对路径 /app/user/  */
  redirect?: string; /* 格式等同于 path ,跳转至对应的 path */
  requireAuth?: boolean;
  children?: Array<routeParams>;
};

/* --------------------------------------------------------------------------------------- */
interface checkProps {
  children?: any,
  requireAuth?: Boolean | undefined,
  pathname?: string,
  configPath?: string | undefined,
  redirect?: string | undefined,
  component?: any,
  key?: any
}
function CheckAuth({ configPath, requireAuth = false, redirect, children, }: checkProps) {
  let { user } = useContext(UserContext);
  let pathname = useLocation().pathname;
  let isAuthed = useMemo(() => (user && user.isAuthed) ? user.isAuthed : false, [user]);
  let navigatePath = useMemo(() => {
    if (!redirect) return '';
    let pathArr = pathname.split("/");
    let redirectArr = redirect.split("/");

    //匹配到最后一个路径才继续往下走
    if (configPath) {
      let configPathLastNode = configPath.split("/").filter(c => c && !/^[:]/.test(c)).reverse()[0]
      let pathnameLastNode = pathArr.filter(c => c && c !== 'undefined' && c !== 'null' && !/^[\d]/.test(c)).reverse()[0]
      if (configPathLastNode !== pathnameLastNode) return ''
    }

    if (/^\//.test(redirect)) {
      //判断路径为绝对路径 对比完整路径
      let filRedirStr = redirectArr.filter((c) => (c && !/^[:]/.test(c))).join('/')
      let filpathStr = pathArr.filter((c) => (c && !/^[\d]/.test(c))).join('/')
      if (filpathStr !== filRedirStr) {
        return redirectArr.map((c, i) => (/^[:]/.test(c) ? pathArr[i] : c)).join('/')
      }
    } else {
      //判断为相对路径 对比最后一个节点
      let lastNode = pathArr.filter(c => c).reverse()[0];
      let redirectPath = redirectArr.filter(c => c)[0];
      if (lastNode && redirectPath && lastNode !== redirectPath) return redirect
    }
    return ''
  }, [pathname, configPath, redirect])

  return (
    <>
      {
        navigatePath ?
          <Navigate to={navigatePath} /> :
          requireAuth && !isAuthed ?
            <Navigate to={'/'} replace /> :
            children
      }
    </>
  )
}

/* --------------------------------------------------------------------------------------- */

function createElement({ path, redirect, requireAuth, component: Component, children, }: routeParams): RouteObject {
  let itemChildren: RouteObject[] | undefined = children && children.length ? children.map(createElement) : undefined;
  return {
    path: path,
    element: (
      <CheckAuth {...{ configPath: path, redirect, requireAuth, }}>
        <Suspense fallback={true}>
          <Component />
        </Suspense>
      </CheckAuth>
    ),
    children: itemChildren,
  };
}

/* --------------------------------------------------------------------------------------- */

const createRoutes = (routes: routeParams[]) => {
  return () => useRoutes(routes.map(createElement))
};

/* --------------------------------------------------------------------------------------- */
// const generateRouteForArray = (routes: RouteObject[]) => (
//   routes.map(
//     ({ path, element, children }, i) => (
//       children ? <Route key={path + '' + i} path={path!} element={element}> {generateRouteForArray(children)} </Route>
//         : <Route key={path + '' + i} path={path!} element={element} />
//     )
//   )
// )

// const CreateRoutes: React.FC<{ routes: routeParams[] }> = ({ routes }) => {
//   return (
//     <Routes>
//       {generateRouteForArray(useMemo(() => routes.map(createElement), [routes]))}
//     </Routes>
//   )
// };

/* --------------------------------------------------------------------------------------- */
export default createRoutes;