import { lazy } from 'react';
import type { routeParams } from "../utils/createRoutes"
import createRoutes from "../utils/createRoutes"
import crmApp from "../crmApp/routes/index"
import global from "../global/routes/index"



const route_404 = {
  path:"*",
  component:lazy(() => import("../global/pages/404")),
}

const routes: Array<routeParams> = [
  ...global,
  crmApp,
  route_404
]

export default createRoutes(routes)