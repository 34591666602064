import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';


const PersonalCenter = (props: Partial<CustomIconComponentProps>) => {
    const svg = (attr: any) => (
        <svg  {...attr} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 880 880">
            <g id="我的-未选中" transform="translate(-1971 978)">
                <rect id="矩形_1910" transform="translate(1971 -978)" />
                <g id="组_2493" transform="translate(2125 -845)">
                    <path id="路径_4583" d="M385.114,527.264c-98.21,0-178.11-79.9-178.11-178.11s79.9-178.109,178.11-178.109,178.11,79.9,178.11,178.11S483.324,527.264,385.114,527.264Zm0-297.51a119.4,119.4,0,1,0,119.4,119.4A119.536,119.536,0,0,0,385.114,229.754Z" transform="translate(-98.978 -171.046)" />
                    <path id="路径_4584" d="M626.961,932.059a29.355,29.355,0,0,1-29.354-29.354c0-125.407-102.023-227.429-227.429-227.429S142.749,777.3,142.749,902.705a29.354,29.354,0,0,1-58.707,0c0-157.778,128.359-286.137,286.137-286.137S656.316,744.927,656.316,902.705A29.357,29.357,0,0,1,626.961,932.059Z" transform="translate(-84.042 -319.057)" />
                </g>
            </g>
        </svg>
    )
    return <Icon component={svg} {...props} />
}

const PersonalCenterIcon: React.FC<any> = (props: any) => {
    let childProps = { ...props }
    Reflect.deleteProperty(childProps, 'onClick')
    return <span className={'icon'} onClick={props.onClick}><PersonalCenter {...childProps} /></span>
}

export default PersonalCenterIcon
