import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import AddToProject from "../AddToProject";
import ProjectList from "../ProjectList";
import EmptyItem from "../EmptyItem";
import { Button, Input, message, Modal, Timeline, Empty, Checkbox, Steps, DatePicker } from "antd";
import { ManOutlined, WomanOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router";
import ChangeStatus from "../ChangeStatus";
import { useResume } from '../../../models/resume';

import type { ResumeDetailType, ResumeWorkHistory } from "../../../models/types/resume";
import "./index.less";
import { useLocation } from "react-router";

const { Step } = Steps;

interface resignType {
  resign_time: string;
  resign_reason: string;
}

const Blue: React.FC<{ title: string }> = ({ title }) => {
  return (
    <div className="resume-detail-common">
      <div className="resume-detail-common-blue"></div>
      <div className="resume-detail-common-title">{title}：</div>
    </div>
  );
};
const Card: React.FC<{ title: string; children: any }> = ({
  title,
  children,
}) => {
  return (
    <div className="resume-card">
      <div className="resume-card-header">
        <div className="resume-card-header-blue"></div>
        <div className="resume-card-header-title">{title}</div>
      </div>
      {children}
    </div>
  );
};

const resumeStateList: Record<string, { color: string; text: string }> = {
  "1": { color: "#FC0E0E", text: "待处理" },
  "2": { color: "#1890FF", text: "面试" },
  "4": { color: "green", text: "已入职" },
  "7": { color: "#cccccc", text: "未入职" },
  "8": { color: "green", text: "到面" },
  "9": { color: "green", text: "面试通过" },
  "10": { color: "#cccccc", text: "已离职" },
  "11": { color: "#cccccc", text: "未通过面试" },
  "13": { color: "#cccccc", text: "未到面" },
};

const ResumeDetail: React.FC<{
  resumeData: ResumeDetailType;
  tool?: { svg: JSX.Element; text: string; index: number; click?: any }[];
  toolModalData?: any;
  children?: any;
  method?: any;
  type?: string;
  upMethod?: any
}> = (props) => {
  const { resumeId, companyId } = useParams();
  const location = useLocation()
  const { resumeData, tool, method, toolModalData, type, upMethod } = props;

  const [projectModal, setProjectModal] = useState(false);
  const [stepCurrent, setStepCurrent] = useState(0);
  const [createMarkModal, setCreateMarkModal] = useState(false);
  const [toolIndex, setToolIndex] = useState(-1);
  const [projectId, setProjectId] = useState<Array<number>>([]);
  const [markValue, setMarkValue] = useState("");
  const [projectLoading, setProjectLoading] = useState(false);
  const [backLoading, setBackLoading] = useState(false);
  const [workStatus, setWorkStatus] = useState<string[]>([])
  const navigate = useNavigate();

  // passModal 
  const [passModal, setPassModal] = useState(false);
  const [unpassModal, setUnPassModal] = useState(false);
  //boardModal
  const [boardModel, setBoardModal] = useState(false);
  const [unBoardModel, setUnBoardModal] = useState(false);
  // 离职
  const [isResignVisible, setIsResignVisible] = useState(false);

  const [resignData, setResignData] = useState<resignType>({
    resign_time: '',
    resign_reason: '',
  });

  // 简历状态 considerationStatus
  const [considerationStatus, setConsiderationStatus] = useState(true);

  // 步骤条数据
  const [stepData, setStepData] = useState([
    {
      idStep: 1,
      title: "待处理",
    },
    {
      idStep: 2,
      title: "待签到",
    },
    {
      idStep: 3,
      title: "待录取",
    },
    {
      idStep: 4,
      title: "待入职",
    },
    {
      idStep: 5,
      title: "在职",
    }
  ]);

  // 备注
  const [remark, setRemark] = useState("")
  // 入职时间
  const [entryDate, setEntryDate] = useState('');

  const {
    putInterviewResumeModifyInfo
  } = useResume(companyId!);

  useEffect(() => {
    if (!props.toolModalData.workStatus) {
      setWorkStatus([])
    } else {
      setWorkStatus(props.toolModalData.workStatus.split(","))
    }
  }, [props.toolModalData.workStatus])
  const resumeState =
    resumeStateList[resumeData.resume_record_processing_status ?? ""];
  // 添加后简历会从简历库中消失
  const modalMessage = (location.pathname.indexOf("projects") !== -1, location.pathname.indexOf("myresume") !== -1) ? "" : ""

  // 通过简历状态来修改步骤条的进度
  function setCurrentSum() {
    if (resumeData.resume_record_processing_status == 1) {
      // 待处理
      setStepCurrent(0)
    } else if (resumeData.resume_record_processing_status == 2) {
      // 邀约了面试
      stepData.map((item) => {
        if (item.idStep == 1) {
          item.title = "已处理"
        }
      })
      setStepData(stepData)
      // upMethod()
      setStepCurrent(1)
    } else if (resumeData.resume_record_processing_status == 8) {
      // 签到
      setStepData([{
        idStep: 1,
        title: "已处理",
      },
      {
        idStep: 2,
        title: "已签到",
      },
      {
        idStep: 3,
        title: "待录取",
      },
      {
        idStep: 4,
        title: "待入职",
      },
      {
        idStep: 5,
        title: "在职",
      }])
      setStepCurrent(2)
    } else if (resumeData.resume_record_processing_status == 13) {
      // 未签到
      setStepCurrent(1)
      setStepData([{
        idStep: 1,
        title: "已处理",
      },
      {
        idStep: 2,
        title: "未签到",
      },
      {
        idStep: 3,
        title: "待录取",
      },
      {
        idStep: 4,
        title: "待入职",
      },
      {
        idStep: 5,
        title: "在职",
      }])
      setConsiderationStatus(false)
    } else if (resumeData.resume_record_processing_status == 9) {
      // 通过
      setStepData([{
        idStep: 1,
        title: "已处理",
      },
      {
        idStep: 2,
        title: "已签到",
      },
      {
        idStep: 3,
        title: "已通过",
      },
      {
        idStep: 4,
        title: "待入职",
      },
      {
        idStep: 5,
        title: "在职",
      }])
      setStepCurrent(3)
    } else if (resumeData.resume_record_processing_status == 11) {
      // 未通过
      setStepCurrent(2)
      setStepData([{
        idStep: 1,
        title: "已处理",
      },
      {
        idStep: 2,
        title: "已签到",
      },
      {
        idStep: 3,
        title: "未通过",
      },
      {
        idStep: 4,
        title: "待入职",
      },
      {
        idStep: 5,
        title: "在职",
      }])
      setConsiderationStatus(false)
    } else if (resumeData.resume_record_processing_status == 4) {
      // 入职
      setStepData([{
        idStep: 1,
        title: "已处理",
      },
      {
        idStep: 2,
        title: "已签到",
      },
      {
        idStep: 3,
        title: "已通过",
      },
      {
        idStep: 4,
        title: "已入职",
      },
      {
        idStep: 5,
        title: "在职",
      }])
      setStepCurrent(4)
    } else if (resumeData.resume_record_processing_status == 7) {
      // 未入职
      setStepData([{
        idStep: 1,
        title: "已处理",
      },
      {
        idStep: 2,
        title: "已签到",
      },
      {
        idStep: 3,
        title: "已通过",
      },
      {
        idStep: 4,
        title: "未入职",
      },
      {
        idStep: 5,
        title: "已在职",
      }])
      setStepCurrent(3)
      setConsiderationStatus(false)
    } else if (resumeData.resume_record_processing_status == 10) {
      // 离职
      setStepData([{
        idStep: 1,
        title: "已处理",
      },
      {
        idStep: 2,
        title: "已签到",
      },
      {
        idStep: 3,
        title: "已通过",
      },
      {
        idStep: 4,
        title: "已入职",
      },
      {
        idStep: 5,
        title: "已离职",
      }])
      setStepCurrent(4)
    }
  }

  const createMark = () => {
    let kind = type === "project" ? 2 : 1;
    if (!markValue.trim()) {
      message.error("备注不能为空");
      return;
    }
    method.marks.post(resumeId!, markValue.trim(), kind);
    setCreateMarkModal(false);
    setMarkValue("");
  };

  const markInputChange = (e: any) => {
    setMarkValue(e.target.value);
  };
  const addToProject = () => {
    if (projectId.length === 0) {
      message.error("请选择项目");
      setProjectModal(false);
      return;
    }
    setProjectLoading(true);
    method.project.put(projectId[0], resumeId!, { job_ids: projectId }).then((res: any) => {
      console.log(res)
      setProjectModal(false);
      setProjectLoading(false);
      setToolIndex(-1);
      if (res) {
        if (res.reason === "简历拉取成功") {
          message.success(res.reason)
        } else {
          message.error(res.reason)
        }
      }
      // if (method.project.data !== "") {
      //   message.success("添加成功");
      // }
    });
  };
  const closeProject = () => {
    setProjectModal(false);
  };
  const showTool = (e: any) => {
    setToolIndex(e);
  };
  const sendBackResume = () => {
    if (!resumeData.resume_record_id) {
      message.error("退回失败,记录Id不存在")
      return
    }
    setBackLoading(true);

    method.back.put(resumeData.resume_record_id, { processing_status: 6 }).then((res: any) => {
      message.success("退回成功");
      setBackLoading(false);
      navigate("../");
    });
  };

  const stateFinish = (stateValue: any, values: any) => {
    if (stateValue === -1) {
      message.error("请选择状态");
      return;
    }
    const data = {
      interview_time: dayjs(values.interview_time).format(),
      interview_city: values.interview_city ? values.interview_city.join("") : '',
      interview_address: values.interview_address || '',
      processing_status: stateValue
    }
    method.state
      .put(resumeData.resume_record_id,
        data
      )
      .then((res: any) => {
        message.success("更新成功");
        setToolIndex(-1);
      });
  }

  const workStatusChange = (value: any) => {
    setWorkStatus(value)
  }
  const putWorkStatus = () => {
    method.work.put(resumeData.resume_record_id, { communication_process: workStatus.join(",") }).then(() => {
      message.success("修改成功")
      setToolIndex(-1)
    })
  }
  // 通过面试 
  const psssModalOk = async () => {
    await putInterviewResumeModifyInfo(resumeData.resume_record_id, { interview_pass: true }).then((res) => {
      setStepCurrent(3)
      upMethod()
      message.success(res.reason)
    });
    setPassModal(false)
  }

  // 未通过面试
  const unPassModalOk = async () => {
    if (!remark) {
      message.error("备注不能为空")
      return
    }
    await putInterviewResumeModifyInfo(resumeData.resume_record_id, { interview_pass: false, remark }).then((res) => {
      setStepCurrent(2)
      setConsiderationStatus(false)
      upMethod()
      message.success(res.reason)
    });
    setUnPassModal(false)
  }

  // 顺利入职
  const boardModelOk = async () => {

    if (!entryDate) {
      message.error('入职时间不能为空！')
      return
    }
    await putInterviewResumeModifyInfo(resumeData.resume_record_id, { is_induction: true, induction_time: entryDate }).then((res) => {
      setStepCurrent(4)
      upMethod()
      message.success(res.reason)
    });
    setBoardModal(false)
  }
  // 未入职

  const unBoardModelOk = async () => {
    await putInterviewResumeModifyInfo(resumeData.resume_record_id, { is_induction: false }).then((res) => {
      setConsiderationStatus(false)
      setStepCurrent(3)
      upMethod()
      message.success(res.reason)
    });
    setUnBoardModal(false)
  }
  // 离职
  const resignModalOk = async () => {
    if (!resignData.resign_reason) {
      message.error('离职原因不能为空');
      return;
    }
    if (!resignData.resign_time) {
      message.error('离职时间不能为空');
      return;
    }
    await putInterviewResumeModifyInfo(resumeData.resume_record_id, {
      ...resignData,
      resign: 10,
    }).then((res) => {
      upMethod()
      setStepCurrent(4)
      if (!res) {
        message.error('修改失败');
        return;
      }
      message.success('修改成功');
    }

    );
    setIsResignVisible(false);

  };
  // 离职表单
  const onResignDataChange = (value: string, key: any) => {
    setResignData({ ...resignData, [key]: value });
  };
  // 关闭 离职弹出库
  const resignModalClose = () => {
    setIsResignVisible(false);
    setResignData({
      resign_reason: '',
      resign_time: '',
    });
  };


  useEffect(() => {
    setProjectId([])
    // 判断求职进度
  }, [toolIndex])
  useEffect(() => {
    setCurrentSum()
  }, [resumeData])

  const defaultImg =
    resumeData.sex === "男"
      ? "https://ats-mini-app-1257093263.cos.ap-shanghai.myqcloud.com/headshot/boy_1.jpg"
      : "https://ats-mini-app-1257093263.cos.ap-shanghai.myqcloud.com/headshot/girl_1.png";

  return (
    <div>
      <div className="resume-detail">
        {tool && (
          <div className="resume-detail-tool">
            {tool &&
              tool.map((item) => {
                return (
                  <div
                    className="resume-detail-tool-item"
                    key={item.index}
                    onClick={() => {
                      item.click ? item.click() : showTool(item.index);
                    }}
                  >
                    {item.svg}
                    {item.text}
                  </div>
                );
              })}
          </div>
        )}

        <div className="resume-detail-info">
          <div className="resume-detail-info-tool">
            {toolIndex === 1 ? (
              <div className="resume-detail-info-tool-project">
                <div className="resume-detail-info-tool-title">{location.pathname.indexOf("projects") !== -1 ? "添加至项目" : "获取至项目"} </div>
                <AddToProject
                  data={toolModalData.resumeProject ?? []}
                  setProjectId={setProjectId}
                  searchProject={method.project.select}
                ></AddToProject>
                <div className="resume-detail-info-tool-project-button">
                  <Button
                    onClick={() => {
                      setToolIndex(-1);
                    }}
                  >
                    取消
                  </Button>
                  <Button
                    disabled={projectId.length === 0 ? true : false}
                    type="primary"
                    onClick={() => {
                      setProjectModal(true);
                    }}
                  >
                    确认
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}
            {toolIndex === 2 ? (
              <div className="resume-detail-info-tool-remark">
                <div className="resume-detail-info-tool-title">备注</div>
                <div className="resume-detail-info-tool-remark-list">
                  <Timeline>
                    {toolModalData.resumeMarks ? (
                      (toolModalData.resumeMarks ?? []).map((item: any) => {
                        return (
                          <Timeline.Item>
                            <span className="resume-detail-info-tool-remark-list-item">
                              {dayjs(item.created_at).format("YYYY-MM-DD") +
                                " "}{" "}
                              {item.content}
                            </span>
                          </Timeline.Item>
                        );
                      })
                    ) : (
                      <Empty />
                    )}
                  </Timeline>
                </div>

                <div className="resume-detail-info-tool-remark-create">
                  <Button
                    onClick={() => setCreateMarkModal(true)}
                    type="primary"
                  >
                    添加备注
                  </Button>
                </div>
                <Modal
                  visible={createMarkModal}
                  onCancel={() => setCreateMarkModal(false)}
                  onOk={createMark}
                >
                  <div>
                    备注：
                    <Input onChange={markInputChange} value={markValue} />
                  </div>
                </Modal>

                <div className="resume-detail-info-tool-project-button">
                  <Button
                    onClick={() => {
                      setToolIndex(-1);
                    }}
                  >
                    取消
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}

            {toolIndex === 3 ? (
              <ChangeStatus setToolIndex={setToolIndex} stateFinish={stateFinish} />
            ) : (
              ""
            )}

            {toolIndex === 4 ? (
              <Modal
                visible={true}
                onCancel={() => {
                  setToolIndex(-1);
                }}
                onOk={sendBackResume}
                okButtonProps={{ loading: backLoading }}
              >
                <div style={{ textAlign: "center", fontSize: 18, padding: 10 }}>

                  退回简历后,简历将从所有项目中退回到简历库中
                </div>
              </Modal>
            ) : (
              ""
            )}

            {
              toolIndex === 5 ? (
                <div className="resume-detail-info-tool-interview">
                  <div>
                    <div className="resume-detail-info-tool-interview-time">
                      面试时间：{
                        resumeData.resume_record_interview_time ?
                          dayjs(resumeData.resume_record_interview_time).format("YYYY年M月D日 HH:mm") : "暂无"}</div>
                    <div className="resume-detail-info-tool-interview-address">
                      <div className="resume-detail-info-tool-interview-address-title">
                        面试地点：
                      </div>
                      <div>
                        {resumeData.resume_record_interview_city ? resumeData.resume_record_interview_city + resumeData.resume_record_interview_address : "暂无"}
                      </div>
                    </div>
                  </div>
                  <div className="resume-detail-info-tool-interview-button">
                    <Button onClick={() => { setToolIndex(-1) }}>取消</Button>
                  </div>
                </div>
              ) :
                ("")
            }
            {
              toolIndex === 6 ? <div className="resume-detail-info-tool-in-project">
                <ProjectList data={toolModalData.projectList ?? []} />
                <div className="resume-detail-info-tool-interview-button">
                  <Button onClick={() => { setToolIndex(-1) }}>取消</Button>
                </div>
              </div>
                : ""
            }
            {
              toolIndex === 7 && <div className="resume-detail-info-tool-status">
                <div className="resume-detail-info-tool-title">沟通状态</div>
                <div className="resume-detail-info-tool-status-flex">
                  <Checkbox.Group onChange={workStatusChange} value={workStatus}>
                    <Checkbox value={'电话沟通'}>电话沟通</Checkbox>
                    <Checkbox value={'添加微信'}>添加微信</Checkbox>
                  </Checkbox.Group>
                  <div className="resume-detail-info-tool-project-button" style={{ marginTop: 100 }}>
                    <Button
                      onClick={() => {
                        setToolIndex(-1);
                      }}
                    >
                      取消
                    </Button>
                    <Button
                      type="primary"
                      onClick={putWorkStatus}
                    >
                      确认
                    </Button>
                  </div>

                </div>

              </div>
            }
            {
              toolIndex === 8 && <div className="resume-detail-info-tool-work">
                <div className="resume-detail-info-tool-title">工作经历</div>
                <div style={{ display: "flex", marginBottom: 20 }}>
                  <div className="resume-detail-info-tool-work-round-item">
                    <div className="resume-detail-info-tool-work-round">
                    </div>
                    入职

                  </div>
                  <div className="resume-detail-info-tool-work-round-item">
                    <div className="resume-detail-info-tool-work-round" style={{ color: "#52c41a", borderColor: "#52c41a" }}>
                    </div>
                    面试
                  </div>
                </div>
                <div className="resume-detail-info-tool-work-list">
                  <Timeline>
                    {
                      (toolModalData.workHistory ?? []).map(((item: ResumeWorkHistory) => {
                        return <Timeline.Item color={item.employee_status_flag === 16 ? "blue" : "green"}>
                          {`${dayjs().format("YYYY-MM-DD")} ${item.title} ${item.recruitment_unit}`}
                        </Timeline.Item>
                      }))}
                  </Timeline>
                </div>

                <div className="resume-detail-info-tool-project-button">
                  <Button
                    onClick={() => {
                      setToolIndex(-1);
                    }}
                  >
                    取消
                  </Button>
                </div>
              </div>

            }
          </div>
          {/* 头部展示 */}
          <div className="resume-detail-info-header">

            {/* 进度条 */}
            {
              resumeData.resume_record_processing_status && (
                <div className="resume-detail-info-header-steps">
                  <Card title="求职进度" >

                    <div className="operate">
                      <div className="name"><Blue title="职位" />{resumeData.resume_job_title}</div>
                      <div className="btn-operate">
                        {
                          stepCurrent == 0 ? <>
                            <Button type="primary" className="first-btn" size={'middle'}
                              onClick={() => {
                                setToolIndex(3);
                              }
                              }>邀请面试</Button>
                            {/* <Button size={'middle'}>标为不合适</Button> */}
                          </> : stepCurrent == 1 && resumeData.resume_record_processing_status == 2 ? <>
                            <Button type="primary" className="first-btn" size={'middle'}
                              onClick={() => {
                                putInterviewResumeModifyInfo(resumeData.resume_record_id, { is_sign_in: true }).then((res) => {
                                  setStepCurrent(2)
                                  message.success(res.reason)
                                  upMethod()
                                });
                              }
                              }
                            >面试签到</Button>
                            <Button size={'middle'}
                              onClick={() => {
                                putInterviewResumeModifyInfo(resumeData.resume_record_id, { is_sign_in: false }).then((res) => {
                                  setStepCurrent(2)
                                  setConsiderationStatus(false)
                                  // stepData.map((item) => {
                                  //   if (item.idStep == 2) {
                                  //     item.title = "未签到"
                                  //   }
                                  // })
                                  // setStepData(stepData)
                                  message.success(res.reason)
                                  upMethod()
                                });

                              }
                              }
                            >未签到</Button>
                          </> : stepCurrent == 2 && resumeData.resume_record_processing_status == 8 ? <>
                            <Button type="primary" className="first-btn" size={'middle'}
                              onClick={() => {
                                setPassModal(true)
                              }
                              }
                            >面试通过</Button>
                            <Button size={'middle'}
                              onClick={() => {
                                setUnPassModal(true)
                              }
                              }
                            >未通过</Button>
                          </> : stepCurrent == 3 && resumeData.resume_record_processing_status == 9 ? <>
                            <Button type="primary" className="first-btn" size={'middle'}
                              onClick={() =>
                                setBoardModal(true)
                              }
                            >入职</Button>
                            <Button size={'middle'}
                              onClick={() =>
                                setUnBoardModal(true)
                              }
                            >未入职</Button>
                          </> : stepCurrent == 4 && resumeData.resume_record_processing_status == 4 ? <>
                            <Button type="primary" className="first-btn" size={'middle'}
                              onClick={() => setIsResignVisible(true)
                              }
                            >离职</Button>
                          </> : ''
                        }
                      </div>
                    </div>

                    <Steps current={stepCurrent} size={'small'} status={considerationStatus ? 'finish' : 'error'}>
                      {
                        stepData ? stepData.map((item) => {
                          return (
                            <Step title={item.title} key={item.idStep} />
                          )
                        }) : ""
                      }
                    </Steps>
                  </Card>
                </div>
              )
            }

            <div className="resume-detail-info-header-title">简历备注</div>
            {resumeData.resume_record_processing_status && (

              <div className="resume-detail-info-header-state">
                <Blue title="简历状态" />
                <div
                  style={{ color: resumeState?.color }}
                  className="resume-detail-info-header-state-box"
                >
                  {resumeState?.text}
                </div>
              </div>
            )}

            <div className="resume-detail-info-header-remark">
              <div className="resume-detail-info-header-remark-tips">
                <div>
                  <Blue title="简历备注" />
                </div>
                <div>
                  <div>
                    {resumeData.resume_mark && resumeData.resume_mark.created_at
                      ? dayjs(resumeData.resume_mark.created_at).format(
                        "YYYY年M月D日 HH:mm"
                      )
                      : "无"}
                  </div>
                  {resumeData.resume_mark && resumeData.resume_mark.content}
                </div>
              </div>

            </div>
            <div className="resume-detail-info-header-pull">
              {
                resumeData.resume_creator && (
                  <>
                    <div className="resume-detail-info-header-pull-detail">
                      <Blue title="归属人" />
                      <div className="cont">{resumeData.resume_creator}</div>
                    </div>
                  </>
                )
              }
            </div>
            <div className="resume-detail-info-header-date">
              {resumeData.created_at && (
                <>
                  <div className="resume-detail-info-header-date-start">
                    <Blue title="入库时间" />
                    {dayjs(resumeData.created_at).format("YYYY年M月D日 HH:mm")}
                  </div>
                  <div className="resume-detail-info-header-date-start">
                    <Blue title="更新时间" />
                    {dayjs(resumeData.updated_at).format("YYYY年M月D日 HH:mm")}
                  </div>
                </>
              )}
              {(resumeData.resume_record_created_at && !resumeData.resume_record_interview_time && !resumeData.resume_record_induction_time) && (
                <>
                  <div className="resume-detail-info-header-date-start">
                    <Blue title="添加时间" />
                    {dayjs(resumeData.resume_record_created_at).format(
                      "YYYY年M月D日 HH:mm"
                    )}
                  </div>
                  <div className="resume-detail-info-header-date-start">
                    <Blue title="到期时间" />
                    {dayjs(resumeData.resume_record_effective_time).format(
                      "YYYY年M月D日 HH:mm"
                    )}
                  </div>
                </>
              )}
              {(resumeData.resume_record_interview_time) &&
                <>
                  <div className="resume-detail-info-header-date-start">
                    <Blue title="面试时间" />
                    {dayjs(resumeData.resume_record_interview_time).format(
                      "YYYY年M月D日 HH:mm"
                    )}
                  </div>
                  <div className="resume-detail-info-header-date-start">
                    <Blue title="操作时间" />
                    {dayjs(resumeData.resume_record_updated_at).format(
                      "YYYY年M月D日 HH:mm"
                    )}
                  </div>
                </>
              }
              {
                (resumeData.resume_record_induction_time) &&
                <>
                  <div className="resume-detail-info-header-date-start">
                    <Blue title="入职时间" />
                    {dayjs(resumeData.resume_record_induction_time).format(
                      "YYYY年M月D日 HH:mm"
                    )}
                  </div>
                  {
                    resumeData.resume_record_resign_time &&
                    <div className="resume-detail-info-header-date-start">
                      <Blue title="离职时间" />
                      {dayjs(resumeData.resume_record_resign_time).format(
                        "YYYY年M月D日 HH:mm"
                      )}
                    </div>
                  }
                </>
              }
            </div>
            {
              resumeData.effective_time &&
              <div className="resume-detail-info-header-date">
                <div className="resume-detail-info-header-date-start">
                  <Blue title="过期时间" />
                  {dayjs(resumeData.effective_time).format(
                    "YYYY年M月D日 HH:mm"
                  )}
                </div>
              </div>
            }

            {
              resumeData.resume_record_resign_reason &&
              <div className="resume-detail-info-header-date mar-top" >
                <div className="resume-detail-info-header-date-start">
                  <Blue title="离职原因" />
                  {resumeData.resume_record_resign_reason ? resumeData.resume_record_resign_reason : '无'}
                </div>
              </div>
            }
            {
              resumeData.resume_record_remark &&
              <div className="resume-detail-info-header-date mar-top" >
                <div className="resume-detail-info-header-date-start">
                  <Blue title="未进面备注" />
                  {resumeData.resume_record_remark ? resumeData.resume_record_remark : '无'}
                </div>
              </div>
            }
            {
              workStatus
            }
          </div>

          <div style={{ padding: "0 20px" }}>
            {/* 个人信息 */}
            <div className="resume-detail-info-user">
              <div className="resume-detail-info-user-left">
                <img src={resumeData.avatar ?? defaultImg} alt="" />
                <div className="resume-detail-info-user-left-sex">
                  {resumeData.sex === "男" ? (
                    <ManOutlined style={{ fontSize: 16, color: "#0E79FD" }} />
                  ) : (
                    <WomanOutlined style={{ fontSize: 16, color: "#F575FA" }} />
                  )}
                </div>
              </div>
              <div className="resume-detail-info-user-right">
                <div>
                  <span className="resume-detail-info-user-right-name">
                    {resumeData.name}
                    <div className="resume-detail-info-user-right-name-essential">
                      <span>
                        {resumeData.age ? resumeData.age + "岁" : "无年龄"}
                      </span>
                      <span style={{ margin: " 0 8px" }}>|</span>
                      <span>{resumeData.degree ?? "无学历"}</span>
                      <span style={{ margin: " 0 8px" }}>|</span>
                      <span>
                        {resumeData.worked_years
                          ? resumeData.worked_years + "年经验"
                          : "无工作经验"}
                      </span>
                      <span style={{ margin: " 0 8px" }}>|</span>
                      <span>
                        {resumeData.expect_employment_type
                          ? "找" + resumeData.expect_employment_type
                          : "无工作类型"}
                      </span>
                    </div>
                  </span>
                </div>
                <div className="resume-detail-info-user-right-contact">
                  <div>
                    <Blue title="联系电话" />
                    {resumeData.phone || "无"}
                  </div>
                  <div>
                    <Blue title="联系邮箱" />
                    {resumeData.email || "无"}
                  </div>
                </div>
              </div>
            </div>
            {/* 求职意向 */}
            <div className="resume-detail-info-want">
              <Card title="求职意向">
                <div className="resume-detail-info-want-content">
                  <div style={{ marginBottom: 16, display: "flex" }}>
                    <div
                      className="resume-detail-info-want-content-common"
                      style={{ width: 300 }}
                    >
                      <Blue title="期望职位" />
                      {resumeData.expect_positions
                        ? resumeData.expect_positions
                        : "无"}
                    </div>
                    <div className="resume-detail-info-want-content-common">
                      <Blue title="期望行业" />
                      {resumeData.expect_industry
                        ? resumeData.expect_industry
                        : "无"}
                    </div>
                  </div>
                  <div style={{ marginBottom: 16, display: "flex" }}>
                    <div
                      className="resume-detail-info-want-content-common"
                      style={{ width: 300 }}
                    >
                      <Blue title="期望地点" />
                      {resumeData.expect_areas ? resumeData.expect_areas : "无"}
                    </div>
                    <div className="resume-detail-info-want-content-common">
                      <Blue title="期望月薪" />
                      {resumeData.expect_salary
                        ? resumeData.expect_salary
                        : "无"}
                    </div>
                  </div>
                </div>
              </Card>
            </div>

            {/* 个人简介 */}
            <div className="resume-detail-info-intention">
              <Card title="个人简介">
                <div>
                  <div className="resume-detail-info-intention-skill">
                    <div>
                      <Blue title="技能标签" />
                    </div>
                    <div className="resume-detail-info-intention-skill-tags">
                      {resumeData.skills
                        ? resumeData.skills.map(
                          (item: string, index: number) => {
                            return (
                              <div
                                key={index}
                                className="resume-detail-info-intention-skill-tag"
                              >
                                {item}
                              </div>
                            );
                          }
                        )
                        : "无"}
                    </div>
                  </div>

                  <div className="resume-detail-info-intention-introduce">
                    <Blue title="个人描述" />
                    <div>{resumeData.summary || "无"}</div>
                  </div>
                </div>
              </Card>
            </div>
            {/* 教育经历 */}
            <div className="resume-detail-info-edu">
              <Card title="教育经历">
                {resumeData.educations && resumeData.educations.length ? (
                  resumeData.educations.map((item: any, index: number) => {
                    return (
                      <div className="resume-detail-info-edu-item">
                        <div className="resume-detail-common-card-title">
                          <div className="resume-detail-common-card-title-name">
                            {item.school_name}
                          </div>
                          <div className="resume-detail-common-card-title-time">
                            {dayjs(item.start_date).format("YYYY.MM") +
                              "-" +
                              dayjs(item.end_date).format("YYYY.MM")}
                          </div>
                        </div>
                        <div className="resume-detail-info-edu-item-edu">
                          <div style={{ marginRight: 60 }}>
                            <Blue title="学历" />
                            {item.degree ?? "无"}
                          </div>
                          <div>
                            <Blue title="专业" />
                            {item.major ?? "无"}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <EmptyItem />
                )}
              </Card>
            </div>
            {/* 工作经历 */}
            <div className="resume-detail-info-company">
              <Card title="工作经历">
                {
                  //resumeData.work_experiences &&
                  //resumeData.work_experiences.length ?
                  //    (resumeData.work_experiences.map((item, index: number) => {
                  //     return (
                  //       <div key={index}>
                  //         <div className="resume-detail-common-card-title">
                  //           <div className="resume-detail-common-card-title-name">
                  //             {item.company_name}
                  //           </div>
                  //           <div className="resume-detail-common-card-title-time">
                  //             {dayjs(item.start_date).format("YYYY.MM") +
                  //               "-" +
                  //               dayjs(item.end_date).format("YYYY.MM")}
                  //           </div>
                  //         </div>
                  //         <div className="resume-detail-info-company-job">
                  //           <Blue title="职位"></Blue>
                  //           <div>{item.job_title}</div>
                  //         </div>
                  //         <div className="resume-detail-info-company-jobDetail">
                  //           <Blue title="工作描述"></Blue>
                  //           <div>{item.summary}</div>
                  //         </div>
                  //       </div>
                  //     );
                  //   })
                  // )
                  resumeData.work_experience ? <span>{resumeData.work_experience} </span>
                    : (
                      <EmptyItem />
                    )}
              </Card>
            </div>
            {/* 项目经历 */}
            <div className="resume-detail-info-project">
              <Card title="项目经历">
                {resumeData.projects && resumeData.projects.length ? (
                  resumeData.projects.map((item, index) => {
                    return (
                      <div
                        className="resume-detail-info-project-item"
                        key={index}
                      >
                        <div className="resume-detail-common-card-title">
                          <div className="resume-detail-common-card-title-name">
                            {item.project_name}
                          </div>
                          <div className="resume-detail-common-card-title-time">
                            {dayjs(item.start_date).format("YYYY.MM") +
                              "-" +
                              dayjs(item.end_date).format("YYYY.MM")}
                          </div>
                        </div>

                        <div className="resume-detail-info-project-item-content">
                          <Blue title="内容描述" />
                          <div>{item.summary}</div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <EmptyItem />
                )}
              </Card>
            </div>
            {/* 其他信息 */}
            <div>
              <Card title="其他信息">
                <div style={{ marginBottom: 20, display: "flex" }}>
                  <Blue title="简历来源" />{resumeData.source_from ? resumeData.source_from : "无"}
                </div>
                <div style={{ marginBottom: 20, display: "flex" }}>
                  <Blue title="所属地区" />{resumeData.resume_source_area ? resumeData.resume_source_area : "无"}
                </div>
                <div style={{ marginBottom: 20, display: "flex" }}>
                  <Blue title="收集人" />{resumeData.collector ? resumeData.collector : "无"}
                </div>
                <div style={{ display: "flex" }}>
                  <Blue title="首次归属人" />{resumeData.recruiter ? resumeData.recruiter : "无"}
                </div>
              </Card>

            </div>
          </div>
        </div>
      </div >
      <Modal
        visible={projectModal}
        onCancel={closeProject}
        onOk={addToProject}
        okButtonProps={{ loading: projectLoading }}
      >
        <div className="project-modal">
          <div>是否要将简历添加到项目中</div>
          {modalMessage}
        </div>
      </Modal>
      {/* 是否通过 /通过 */}
      <Modal
        visible={passModal}
        onOk={psssModalOk}
        onCancel={() => {
          setPassModal(false)
        }}
      >
        <div style={{ fontSize: 18 }}>
          点击确定后，该简历将通过面试
        </div>
      </Modal>
      {/* 未通过 */}
      <Modal
        visible={unpassModal}
        onOk={unPassModalOk}
        onCancel={() => {
          setUnPassModal(false)
        }}
      >
        <div style={{ fontSize: 16, marginBottom: 18 }}>
          点击确定后，该简历将退回项目
        </div>
        <div style={{ display: "flex", whiteSpace: "nowrap", fontSize: 16, alignItems: "center" }}>

          备注：  <Input width={200} value={remark} onChange={(e) => setRemark(e.target.value)} />

        </div>
      </Modal>
      {/* 入职 */}
      <Modal
        title="入职时间"
        visible={boardModel}
        onOk={boardModelOk}
        onCancel={() => setBoardModal(false)}
        destroyOnClose>
        <DatePicker
          style={{ width: '100%' }}
          onChange={(v: any) => setEntryDate(dayjs(v).format("YYYY-MM-DD"))}
        />
      </Modal>

      {/* 未入职 */}

      <Modal
        visible={unBoardModel}
        onOk={unBoardModelOk}
        onCancel={() => {
          setUnBoardModal(false)
        }}
      >
        <div style={{ fontSize: 16 }}>
          点击确定后，该简历将退回项目
        </div>
      </Modal>
      {/* 离职 */}
      <Modal
        title="离职原因"
        visible={isResignVisible}
        onOk={resignModalOk}
        onCancel={resignModalClose}
        destroyOnClose>
        <div style={{ display: 'flex', marginBottom: 15, whiteSpace: 'nowrap' }}>
          离职原因：
          <Input onChange={e => onResignDataChange(e.target.value, 'resign_reason')} />
        </div>

        <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
          离职时间：{' '}
          <DatePicker
            style={{ width: '100%' }}
            onChange={(e: any) => onResignDataChange(e ?? dayjs(e).format("YYYY-MM-DD"), 'resign_time')}
          />
        </div>
      </Modal>
    </div >
  );
};
export default ResumeDetail;
