import { message } from "antd";
import { useState } from "react";
import {
  getDataWithToken,
  postDataWithToken,
  putDataWithToken,
  to,
  deleteDataWithToken,
} from "../../utils/request";
import type { CommonListType } from "./types/common";
import type { ProjectDetailType } from "./types/project";
enum requestUrl {
  project = "/companies/{hrtj_company_id}/jobs/",
  projectDetail = "/companies/{hrtj_company_id}/jobs/{job_id}/",
  projectResumes = "/companies/{hrtj_company_id}/jobs/{job_id}/resumes/",
  projectResumesDetail = "/companies/{hrtj_company_id}/jobs/{job_id}/resumes/{resume_id}/",
  projectDetailRecord = "/companies/{hrtj_company_id}/jobs/{job_id}/operation_records/",
  updateResumeRecord = "/companies/{hrtj_company_id}/resumes/resume_record_information/{resume_record_id}/",
  // projectResumesDetail = '/companies/{hrtj_company_id}/jobs/{job_id}/resume_record/{resume_record_id}/',
  projectMembers = "/user_roles/companies/{hrtj_company_id}/jobs/{job_id}/account_list/",
  companyMembers = "/user_roles/companies/{hrtj_company_id}/user_list/",
  deleteProjectMember = "/companies/{hrtj_company_id}/jobs/{job_id}/delete_user/{user_id}/",
  selectJobs = "/companies/{hrtj_company_id}/jobs/select_job/",
  allProject = '/companies/{hrtj_company_id}/jobs/all_job/'
}

export function useProject(companyId: string) {
  const [projectList, setProjectList] = useState<any>([]);
  const [selectJobs, setSelectJobs] = useState<any>([]);
  const [allProjectList, setAllProjectList] = useState<any>([])
  const [projectListCount, setProjectListCount] = useState<any>([]);
  const [companyMembersList, setCompanyMemberList] = useState<any>([]);
  const [projectMembersList, setProjectMemberList] = useState<any>([]);

  async function getProjectList(params: any) {
    let url = requestUrl.project.replace("{hrtj_company_id}", companyId);
    const [err, res] = await to(getDataWithToken(url, params));

    if (!err) {
      console.log(params.process_status,'projectList');
      setProjectList(res.data.results);
      setProjectListCount(res.data.count);
    }
  }
  async function getSelectJobs(params: any = {}) {
    let url = requestUrl.selectJobs.replace("{hrtj_company_id}", companyId);
    const [err, res] = await to(getDataWithToken(url, params));

    if (!err) {
    
      setSelectJobs(res.data.results);
    
    }
  }

  async function getCompanyMemberList(params?: any) {
    let url = requestUrl.companyMembers.replace("{hrtj_company_id}", companyId);
    const [err, res] = await to(getDataWithToken(url, params));
    if (!err) {
      // debugger
      setCompanyMemberList(res.data.results);
    }
  }
  async function getAllProjectList(params: any) {
    let url = requestUrl.allProject.replace("{hrtj_company_id}", companyId);
    const [err, res] = await to(getDataWithToken(url, params));

    if (!err) {
      setAllProjectList(res.data.results);

    }
  }
  async function getProjectMemberList(projectId: any, params?: any) {
    let url = requestUrl.projectMembers
      .replace("{hrtj_company_id}", companyId)
      .replace("{job_id}", projectId);
    const [err, res] = await to(getDataWithToken(url, params));
    if (!err) {
      setProjectMemberList(res.data.results);
    }
  }
  //创建项目
  async function postCreateProject(
    data: any,
    extra?: { cb?: Function; msg?: string; msgCloseCb?: Function }
  ) {
    let url = requestUrl.project.replace("{hrtj_company_id}", companyId);
    const [err, res] = await to(postDataWithToken(url, data));
    if (!err)
      message
        .success(extra?.msg || "保存成功", 0.5)
        .then(() => extra?.msgCloseCb && extra?.msgCloseCb(res.data));
    if (extra?.cb) extra?.cb(res.data);
  }

  //修改项目
  async function putModifyProject(
    projectId: string,
    data: any,
    extra?: { cb?: Function; msg?: string; msgCloseCb?: Function }
  ) {
    let url = requestUrl.projectDetail
      .replace("{hrtj_company_id}", companyId)
      .replace("{job_id}", projectId);
    const [err, res] = await to(putDataWithToken(url, data));
    if (!err)
      message
        .success(extra?.msg || "保存成功", 0.5)
        .then(() => extra?.msgCloseCb && extra?.msgCloseCb(res.data));
    if (extra?.cb) extra?.cb(res.data);
  }
  //添加成员
  async function putModifyProjectMembers(
    projectId: string,
    data: any,
    extra?: { cb?: Function; msg?: string; msgCloseCb?: Function }
  ) {
    let url = requestUrl.projectMembers
      .replace("{hrtj_company_id}", companyId)
      .replace("{job_id}", projectId);
    const [err, res] = await to(postDataWithToken(url, data));

    if (!err && extra?.cb) extra?.cb(res.data);
  }
  
  //删除成员
  async function deleteModifyProjectMembers(
    projectId: string,
    userId: any,
    data: any,
    extra?: { cb?: Function; msg?: string; msgCloseCb?: Function }
  ) {
    let url = requestUrl.deleteProjectMember
      .replace("{hrtj_company_id}", companyId)
      .replace("{job_id}", projectId)
      .replace("{user_id}", userId);
    const [err, res] = await to(deleteDataWithToken(url, data));
    if (!err) message.success(extra?.msg || "删除成功", 0.5);
    if (!err && extra?.cb) extra?.cb(res.data);
  }

  //获取项目详情
  const [projectDetail, setProjectDetail] = useState<ProjectDetailType>({});
  async function getProjectDetail(
    projectId: string,
    extra?: { cb?: Function }
  ) {
    let url = requestUrl.projectDetail
      .replace("{hrtj_company_id}", companyId)
      .replace("{job_id}", projectId);
    const [error, res] = await to(getDataWithToken(url));
    if (!error) {
      setProjectDetail(res.data);
    }
    extra?.cb && extra?.cb(res.data);
  }

  //获取项目详情中的简历列表
  const [projectResumes, setProjectResumes] = useState<CommonListType<any>>({
    count: 0,
    next: "",
    results: [],
  });
  async function getProjectResumes(projectId: string, params: any) {
    let url = requestUrl.projectResumes
      .replace("{hrtj_company_id}", companyId)
      .replace("{job_id}", projectId);
    const [error, res] = await to(getDataWithToken(url, params));
    if (!error) {
      setProjectResumes(res.data);
    }
  }

  //获取操作记录
  const [records, setRecords] = useState<CommonListType>({
    count: 0,
    next: "",
    results: [],
  });
  async function getRecords(projectId: string, params: any) {
    let url = requestUrl.projectDetailRecord
      .replace("{hrtj_company_id}", companyId)
      .replace("{job_id}", projectId);
    const [error, res] = await to(getDataWithToken(url, params));
    if (!error) {
      setRecords(res.data);
    }
  }

  //获取项目中的简历详情
  const [projectResumeDetail, setProjectResumeDetail] = useState<any>({});
  async function getProjectResumeDetail(jobId: string, resumeId: string) {
    let url = requestUrl.projectResumesDetail
      .replace("{hrtj_company_id}", companyId)
      .replace("{job_id}", jobId)
      .replace("{resume_id}", resumeId);
    const [error, res] = await to(getDataWithToken(url));
    if (!error) {
      setProjectResumeDetail(res.data);
      return res.data
    }
  }

  //修改简历状态  退回简历
  async function updateResumeRecord(recordId: number, data: any) {
    let url = requestUrl.updateResumeRecord
      .replace("{hrtj_company_id}", companyId)
      .replace("{resume_record_id}", recordId.toString());
    const [error] = await to(putDataWithToken(url, data));
    if (!error) {

      setProjectResumeDetail({
        ...projectResumeDetail,
        resume_record_interview_time: data.interview_time,
        resume_record_interview_city: data.interview_city,
        resume_record_interview_address: data.interview_address,
        resume_record_processing_status: data.processing_status
      });
    }
  }



  return {
    projectResumes, getProjectResumes,
    projectDetail, getProjectDetail,
    records, getRecords,
    projectList, projectListCount, getProjectList,
    postCreateProject,
    putModifyProject,
    projectResumeDetail,
    getProjectResumeDetail,
    getAllProjectList,
    updateResumeRecord,
    companyMembersList,
    getProjectMemberList,
    allProjectList,
    projectMembersList,
    getCompanyMemberList,
    putModifyProjectMembers,
    deleteModifyProjectMembers,
    selectJobs,
getSelectJobs
  };
}
