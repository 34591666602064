import React, { useEffect, useState } from 'react';
import {
  Tabs,
  Input,
  Select,
  Pagination,
  Form,
  Empty,
  Spin,
  InputNumber,
  Modal,
  DatePicker,
  Checkbox,
  message,
  Drawer,
  Radio,
} from 'antd';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { useResume } from '../../models/resume';
import { Link } from 'react-router-dom';
import ResumeItem from '../../components/resume/ResumeItem';
import dayjs from 'dayjs';
import './index.less';
import { useDebounceFn } from 'ahooks';
import type { CheckboxOptionType, CheckboxValueType } from 'antd/es/checkbox/Group';
import { BatchObtainIcon, UploadFileIcon, DownloadTemplateIcon } from '../../components/Iconfont';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import BatchObtain from '../../components/resume/BatchObtain';
import { useThrottleFn } from 'ahooks';

const CheckboxGroup = Checkbox.Group;
const resumeStateList: any[] = [
   { value: "", label: "简历状态不限" },
   { value: "1", label: "待处理" },
   { value: "2", label: "面试" },
   { value: "4", label: "已入职" },
   { value: "7", label: "未入职" },
   { value: "8", label: "到面" },
   { value: "9", label: "面试通过" },
   { value: "10", label: "已离职" },
   { value: "11", label: "未通过面试" },
];

const { RangePicker } = DatePicker;

const expectSalary = [
  {
    value: '',
    text: '期望薪资不限',
  },
  {
    value: `${0},${3 * 1000}`,
    text: '3k以下',
  },
  {
    value: `${5 * 1000},${10 * 1000}`,
    text: '5-10k',
  },
  {
    value: `${10 * 1000},${20 * 1000}`,
    text: '10-20k',
  },
  {
    value: `${20 * 1000},${50 * 1000}`,
    text: '20-50',
  },
  {
    value: `${50 * 1000},${100 * 1000}`,
    text: '50k以上',
  },
];
const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;
const MyResume: React.FC<any> = () => {
  const [form] = Form.useForm();
  const { companyId } = useParams();
  const { getMyResumeList, myResumeList, getMyProjects, myProjects, multipleBatchResumes, } = useResume(
    companyId!
  );
  //列表项
  const [checkOptions, setCheckOptions] = useState<CheckboxOptionType[]>([]);
  //选中项
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  //用于全选
  const [plainOptions, setPlainOptions] = useState<number[]>([]);
  //checkbox的全选样式
  const [indeterminate, setIndeterminate] = useState(false);
  //全选按钮选中状态
  const [checkAll, setCheckAll] = useState(false);
  const [projectOptions, setProjectOptions] = useState<any>([{ label: '不限', value: '' }]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFirst, setIsFirst] = useState(true);
  const location = useLocation();
  const nav = useNavigate();
  const [drawLoading, setDrawLoading] = useState(false);
  const initParams = {
    limit: 7,
    offset: 0,
    //年龄
    age: '',
    //性别
    sex: '',
    // 学历
    degree: '',
    //招聘类型
    expect_employment_type: '',
    //期望薪资
    expect_salary: '',
    //搜索
    search_content: '',
    //工作经验
    work_experience: '',
    //
  };
  //重置筛选条件
  const resetParams = () => {
    setParams({ ...initParams });
  };
  const [params, setParams] = useState({
    ...initParams,
  });
  //拉取简历
  const batchResumes = (data: { job_id: number | string; resume_ids: number[] }) => {
    setDrawLoading(true);
    multipleBatchResumes(data)
      .then(
        res => {
          message.success('简历获取成功');
          drawClose();
          resetCheckBox();
          resetParams();
        },
        error => {
          if (!error?.response?.data?.reason) message.error('简历获取失败');
        }
      )
      .finally(() => setDrawLoading(false));
  };
  //抽屉title
  const [drawerTitle, setDrawerTitle] = useState<string>('');
  //抽屉index
  const [drawerIndex, setDrawerIndex] = useState<number>(-1);
  const formChange = (fields: any, allFields: any) => {
    if (fields.job_id === undefined) {
      fields.job_id = '';
    }
    fields.age = `${fields.min_age ?? 0},${fields.max_age ?? 100}`;
    fields.created_start_at =
      fields.created_at && fields.created_at[0]
        ? dayjs(fields.created_at[0]).format('YYYY-MM-DD')
        : '';
    fields.created_end_at =
      fields.created_at && fields.created_at[1]
        ? dayjs(fields.created_at[1]).format('YYYY-MM-DD')
        : '';
    fields.updated_start_at =
      fields.updated_at && fields.updated_at[0]
        ? dayjs(fields.updated_at[0]).format('YYYY-MM-DD')
        : '';
    fields.updated_end_at =
      fields.updated_at && fields.updated_at[1]
        ? dayjs(fields.updated_at[1]).format('YYYY-MM-DD')
        : '';
    delete fields.min_age;
    delete fields.max_age;
    delete fields.created_at;
    delete fields.updated_at;
    // console.log(fields)
    setParams({ ...params, ...fields, offset: 0 });
    setCurrentPage(1);
  };

  const paginationChange = (current: number, pageSize: number) => {
    setCurrentPage(current);
    setParams({ ...params, offset: (current - 1) * 7 });
    //清除选中状态
    setCheckedList([]);
    if (params.limit !== pageSize) {
      current = 1;
    }
    resetCheckBox();
    //设置当前页
    setCurrentPage(current);

    setParams({ ...params, offset: (current - 1) * pageSize, limit: pageSize });
  };
  // 重置全选样式
  const resetCheckBox = () => {
    //清除全选样式
    setCheckAll(false);
    //清除全选样式
    setIndeterminate(false);
    setCheckedList([])
  };
  //抽屉loading状态
  useEffect(() => {
    setLoading(true);
    getMyResumeList(params).then(() => {
      setLoading(false);
    });
    getMyProjects({ limit: 20, offset: 0 }).then();
    setIsFirst(false);
  }, [params]);

  useEffect(() => {
    if (isFirst) {
      return;
    }
    const temp = location.pathname.split('/');
    if (temp[temp.length - 1] !== '') {
      return;
    }
    setLoading(true);
    getMyResumeList(params).then(() => {
      setLoading(false);
    });
  }, [location]);

  useEffect(() => {
    if (Array.isArray(myProjects)) {
      let tempArr: any = [];
      myProjects.map((item: any) => {
        tempArr.push({ label: item.job_title, value: item.job_id });
      });
      setProjectOptions(tempArr);
    }
  }, [myProjects]);

  const { run: selectSearch } = useDebounceFn(
    e => {
      getMyProjects({ search_content: e });
    },
    {
      wait: 500,
    }
  );

  const stopLinkEvent = (e: any, resume_id: any) => {
    e.stopPropagation();
    nav(`${resume_id}`);
  };
  //监听简历列表数据，对其进行整理
  useEffect(() => {
    if (myResumeList.results.length) {
      const { results } = myResumeList;

      const plainOptions: any[] = [];
      const checkOptions: CheckboxOptionType[] = results.map(item => {
        plainOptions.push(item.resume_id);
        return {
          value: item.resume_id,
          label: (
            <Link to={item.resume_id.toString()} key={item.resume_id}>
              <ResumeItem {...item} isPublic={true} />
            </Link>
          ),
        };
      });

      setCheckOptions(checkOptions);
      setPlainOptions(plainOptions);
    }
  }, [myResumeList]);
  const onCheckGroupChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  //设置抽屉index和title
  const drawChange = (title: string, index: number) => {
    if (index === 2 && checkedList.length === 0) {
      message.error('还未选择简历');
      return;
    }
    setDrawerIndex(index);
    setDrawerTitle(title);
  };
  //关闭抽屉
  const drawClose = () => {
    setDrawerIndex(-1);
  };

  //获取项目
  const { run } = useThrottleFn(
    e => {
      if (getMyProjects) {
        getMyProjects({
          search_content: e,
        }).then(() => {});
      }
    },
    {
      wait: 500,
    }
  );
  return (
    <div className="page-resume">
      <Spin tip="Loading..." spinning={loading}>
        <div>
          <Tabs defaultActiveKey="1">
            <TabPane tab={<span style={{ fontSize: 16 }}>全部简历</span>} key={'1'}></TabPane>
          </Tabs>
        </div>
        <Form onValuesChange={formChange} form={form}>
          <div className="page-resume-filter">
            <div style={{ display: 'flex', gap: 16 }}>
              <Form.Item name={'search_content'} style={{ margin: '0', width: 234 }}>
                <Search placeholder="关键词搜索" className="page-resume-filter-search"></Search>
              </Form.Item>
              <Form.Item name={'job_id'} style={{ margin: '0', width: 234 }}>
                <Select
                  showSearch
                  options={projectOptions}
                  placeholder="搜索项目"
                  filterOption={false}
                  allowClear={true}
                />
              </Form.Item>
              <Form.Item name={'created_at'} style={{ margin: '0', width: 300 }}>
                <RangePicker placeholder={['入库开始时间', '入库结束时间']} />
              </Form.Item>
              <Form.Item name={'updated_at'} style={{ margin: '0', width: 300 }}>
                <RangePicker placeholder={['更新开始时间', '更新结束时间']} />
              </Form.Item>
              <Form.Item name={'processing_status'} style={{ margin: '0', width: 160  }}>
                <Select placeholder="简历状态不限" options={resumeStateList} >
                  
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="page-resume-filter-params">
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item name={'sex'}>
                <Select placeholder="性别不限" className="page-resume-filter-common">
                  <Option value="">不限</Option>
                  <Option value="男">男</Option>
                  <Option value="女">女</Option>
                </Select>
              </Form.Item>
              <Form.Item name={'degree'}>
                <Select placeholder="学历不限" className="page-resume-filter-common">
                  <Option value="">学历不限</Option>
                  <Option value="初中">初中</Option>
                  <Option value="中专">中专</Option>
                  <Option value="高中">高中</Option>
                  <Option value="大专">大专</Option>
                  <Option value="本科">本科</Option>
                  <Option value="硕士">硕士</Option>
                  <Option value="博士">博士</Option>
                </Select>
              </Form.Item>
              <Form.Item name={'work_experience'}>
                <Select placeholder="工作经验不限" className="page-resume-filter-common">
                  <Option value="">工作经验不限</Option>
                  <Option value="0,1">1年以内</Option>
                  <Option value="1,3">1-3年</Option>
                  <Option value="3,5">3-5年</Option>
                  <Option value="5,10">5-10年</Option>
                  <Option value="10,100">10年以上</Option>
                </Select>
              </Form.Item>

              <Form.Item name={'expect_employment_type'}>
                <Select placeholder="求职类型不限" className="page-resume-filter-common">
                  <Option value="">求职类型不限</Option>
                  <Option value="全职">全职</Option>
                  <Option value="兼职">兼职</Option>
                </Select>
              </Form.Item>
              <Form.Item name={'expect_salary'}>
                <Select
                  placeholder="期望薪资不限"
                  className="page-resume-filter-common"
                  style={{ marginRight: 0 }}>
                  {expectSalary.map((item, index) => {
                    return (
                      <Option key={'expectSalary' + index} value={item.value}>
                        {item.text}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item name={'min_age'}>
                <InputNumber className="page-resume-filter-common" placeholder="最低年龄" />
              </Form.Item>
              <Form.Item name={'max_age'}>
                <InputNumber
                  className="page-resume-filter-common"
                  placeholder="最大年龄"></InputNumber>
              </Form.Item>
              <Form.Item name="communication_process">
                <Select placeholder="沟通状态" style={{ width: 120 }}>
                  <Option value={''}>沟通状态不限</Option>
                  <Option value={'添加微信'}>电话</Option>
                  <Option value={'电话沟通'}>微信</Option>
                  <Option value={'添加微信,电话沟通'}>电话+微信</Option>
                </Select>
              </Form.Item>
            </div>
          </div>
        </Form>
        <div className="page-resume-list">
          <div className="page-resume-list-header">
            <div>
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}>
                全选
              </Checkbox>
            </div>

            <div className="page-resume-button-group">
              <div
                className="page-resume-button-group-primary"
                onClick={() => {
                  drawChange('批量获取', 2);
                }}>
                <BatchObtainIcon />
                批量获取
              </div>
            </div>
          </div>
          {myResumeList.results.length === 0 ? (
            <Empty />
          ) : (
            <CheckboxGroup
              options={checkOptions}
              value={checkedList}
              onChange={onCheckGroupChange}
              style={{ width: '100%' }}></CheckboxGroup>
          )}
        </div>
        <div className="page-resume-pagination">
          <Pagination
            onChange={paginationChange}
            pageSize={params.limit}
            pageSizeOptions={[7, 20, 50]}
            total={myResumeList.count}
            hideOnSinglePage={false}
            current={currentPage}></Pagination>
        </div>
      </Spin>
      <Drawer
        visible={drawerIndex !== -1}
        title={drawerTitle}
        width={700}
        onClose={drawClose}
        closable={false}>
        <Spin tip="Loading..." spinning={drawLoading}>
          {/* 批量获取 */}
          {drawerIndex === 2 && (
            <BatchObtain
            myResume
              drawClose={drawClose}
              batchResumes={batchResumes}
              resumeIds={checkedList}
              selectOptions={myProjects}
              onSearch={run}
            />
          )}
        </Spin>
      </Drawer>
      <Outlet context={{ pathName: `/crm/${companyId}/myresume/`, type: 'resume' }} />
    </div>
  );
};
export default MyResume;
