import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';


const Resume = (props: Partial<CustomIconComponentProps>) => {
    const svg = (attr: any) => (
        <svg  {...attr} {...props} viewBox="0 0 880 880">
            <g id="简历管理-未选中" transform="translate(-5328 978)">
                <rect transform="translate(5328 -978)" />
                <g transform="translate(5461 -845)">
                    <path d="M44.251,153.25V65.668A21.993,21.993,0,0,1,66.357,43.791H546.644a21.993,21.993,0,0,1,22.106,21.877V153.25a21.991,21.991,0,0,1-22.106,21.877H66.357A21.991,21.991,0,0,1,44.251,153.25ZM546.644,437.875a21.993,21.993,0,0,1,22.106,21.877v87.583a21.991,21.991,0,0,1-22.106,21.876H66.357a21.991,21.991,0,0,1-22.106-21.876V459.751a21.993,21.993,0,0,1,22.106-21.877H546.644Zm0-218.957c36.665,0,66.357-29.386,66.357-65.668V65.668C613,29.386,583.31,0,546.644,0H66.357C29.692,0,0,29.386,0,65.668V153.25c0,36.282,29.692,65.668,66.357,65.668H175.165V306.5A21.837,21.837,0,0,0,197,328.377h197.08v65.706H66.357C29.692,394.084,0,423.469,0,459.751v87.583C0,583.616,29.692,613,66.357,613H546.644C583.31,613,613,583.616,613,547.334V459.751c0-36.282-29.692-65.668-66.357-65.668H437.836V306.5A21.876,21.876,0,0,0,416,284.624H218.88V218.918H546.606Z"  />
                    <path d="M562.462,168.181A21.877,21.877,0,0,0,540.586,146.3H277.8a21.9,21.9,0,1,0,0,43.791H540.547a21.877,21.877,0,0,0,21.915-21.915M277.838,584.141H540.586a21.9,21.9,0,1,0,0-43.791H277.8a21.9,21.9,0,1,0,0,43.791" transform="translate(-102.711 -58.721)" />
                </g>
            </g>
        </svg>

    )
    return <Icon component={svg} {...props} />
}

const ResumeIcon: React.FC<any> = (props: any) => {
    let childProps = { ...props }
    Reflect.deleteProperty(childProps, 'onClick')
    return <span className={'icon'} onClick={props.onClick}><Resume {...childProps} /></span>
}

export default ResumeIcon
