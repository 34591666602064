import React, { useEffect, useReducer } from "react";
import { postData, to, getDataWithToken, putDataWithToken } from "../../utils/request";
import { getUserToCache, setUser, rememberUser, deleteUser } from "./context"
import { message } from "antd";

import type { User as typeUser, UserReducerProps as typeUserReducerProps, UserLoginData as typeUserLoginData, CreateUserData as typeCreateUserData } from "./types/user";
import type { ReducerAction } from "./types/context";


enum requestUrl {
  login = '/token-auth/',
  register = '/user-registration/',
  userInfo = '/users/{user_id}/',
};



export const UserContext = React.createContext<Partial<typeUserReducerProps>>({});

export function useUser() {
  // const {companyDetail,getCompnayDetails} = useCompanies()
  const initialState = getUserToCache();

  const [user, dispatch] = useReducer((state: typeUser, action: ReducerAction) => {
    switch (action.type) {
      case 'GET_USER': {
        return { ...state, ...action.data }
      }
      case 'VERIFY_USER_FAIL': {
        return { ...state, isAuthed: false }
      }
      case 'LOG_IN': {
        return { ...state, isAuthed: true, ...action.data };
      }
      case 'LOG_OUT': {
        return { isAuthed: false, token: "", user_id: null, loginLoading: false };
      }
      case 'REGISTER': {
        return { ...state, isAuthed: true, ...action.data };
      }
      case 'GET_USER_START': {
        return { ...state, loginLoading: true };
      }
      case 'GET_USER_END': {
        return { ...state, loginLoading: false };
      }
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    if (user.isAuthed && user.token && !user.email) getUserInfo(initialState.user_id!)
  }, [user.isAuthed, user.token, user.email])

  async function login(data: { loginData: { username: string; password: string }, remember: boolean }, cb?: Function) {
    let [error, res] = await to(postData(requestUrl.login, data.loginData));

    if (!error) {
      localStorage.setItem('rememberMe',JSON.stringify(data.remember))
      if (data.remember) {
        rememberUser(res.data, 7);
      } else {
        setUser(res.data)
      }
      dispatch({ type: 'LOG_IN', data: res.data });
      if (cb) {
        cb();
      }
    }
    return [error, res?.data]
  }

  function setUserAuthSync(data: { token: string, user_id: number }) {
    rememberUser(data, 7);
    dispatch({ type: 'LOG_IN', data: data });
  }

  async function getUserInfo(user_id: number) {
    dispatch({ type: 'GET_USER_START' });
    let url = requestUrl.userInfo.replace('{user_id}', String(user_id))
    let [error, res] = await to(getDataWithToken(url));
    if (!error) {
      dispatch({ type: 'GET_USER', data: res.data });
      localStorage.setItem("default_company_id", res.data.default_hrtjcompany_id);
      localStorage.setItem("default_government_id", res.data.default_government_id);
      localStorage.setItem("default_level_type", res.data.level_type);
      dispatch({ type: 'GET_USER_END' });
      return res.data
    }
    dispatch({ type: 'GET_USER_END' });
  }

  async function createUser(data: typeCreateUserData) {
    let [err, res] = await to(postData(requestUrl.register, data));
    if (!err) {
      setUser(res.data)
      dispatch({ type: 'LOG_IN', data: res.data });
    }
  }

  async function modifyUserInfo(user_id: string, data: { name?: string, password?: string, phone?: string }) {
    const url = requestUrl.userInfo.replace('{user_id}', user_id);
    let [err, res] = await to(putDataWithToken(url, data));
    if (!err) {
      message.success('修改成功')
      getUserInfo(Number(user_id))
      return true
    }
  }

  async function forgetPassword(data: any) {
    const url = requestUrl.register;
    let [err, res] = await to(postData(`${url}forget_password/`, data));
    message.success('修改密码链接已发送至您的邮箱');

  }

  async function changeforgetPassword(data: any, token: string) {
    const url = requestUrl.register;
    let [err, res] = await to(postData(`${url}forget_password/${token}/`, data))
    message.success('密码修改成功');

  }

  // async function getVideoSig() {
  //   const url = requestUrl.user.replace('{user_id}', user.user_id);
  //   return to(getDataWithToken(`${url}required_information/`));
  // }

  async function logout() {
    deleteUser();
    //调用7鱼退出登陆
    if (window.qiConnect) {
      qiConnect.destory()
      const sdkDOm = document.getElementById("sdk-qy")
      if (sdkDOm) {
        sdkDOm.remove()
      }
    }
    dispatch({ type: 'LOG_OUT' });
  }

  return {
    user, login, setUserAuthSync, createUser, logout, getUserInfo, forgetPassword, changeforgetPassword, modifyUserInfo
  };

}