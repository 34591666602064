import React from "react";
import { Empty } from "antd"

const ProjectItem: React.FC<{ job_id: number, job_title: string, employment_type: string, recruitment_unit: string }> = (props) => {
    const { job_title, employment_type, recruitment_unit } = props
    return (
        <div className="resume-detail-info-tool-project-item">
            <div style={{ marginLeft: 20 }}>
                <div className="resume-detail-info-tool-project-item-title">
                    <div className="resume-detail-info-tool-project-item-title-job">
                        {job_title}
                    </div>
                    <span className="resume-detail-info-tool-project-item-title-type">
                        {employment_type}
                    </span>
                </div>
                <div className="resume-detail-info-tool-project-item-company">
                    招聘单位：{recruitment_unit}
                </div>
            </div>
        </div>

    )
}

const ProjectList: React.FC<{ data: any[] }> = ({ data }) => {
    return (
        <>
            <div className="resume-detail-info-tool-title">所在项目</div>
            <div className="resume-detail-info-tool-project-list">


                {data.length !== 0 ?
                    data.map(item => {
                        return <ProjectItem {...item} key={item.job_id} />
                    }) : <Empty />}
            </div>
        </>

    )
}
export default ProjectList