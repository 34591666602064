import { Children, lazy } from 'react';
import { routeParams } from "../../utils/createRoutes"

const Login = lazy(()=>import("../pages/Login/index"))
const Desktop = lazy(()=>import("../pages/Desktop/index"))
const STEST = lazy(()=>import("../pages/STest/index"))
const Inher = lazy(()=>import("../pages/Inher/index"))

const routes: Array<routeParams> = [
  {
    path:'/',
    component:Login,
  },
  {
    path:'/login/',
    component:Login,
  },
  {
    path:'/inher/',
    component:Inher,
  },
  {
    path:'/desktop/',
    component:Desktop,
  },
  {
    path:'/stest/',
    component:STEST,
  }
]

export default routes