import axios from "axios";
import { message } from 'antd';
import { BASE_URL } from "./BASE";

axios.defaults.baseURL = BASE_URL
// 响应拦截器
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response && error.response.data) {
    console.log(error.response)
    if (error.response.data && error.response.status === 400) {
      message.error(error.response.data.reason);
    } else if (error.response.status === 403) {
      message.error(error.response.data.reason || '验证失效，请退出重新登录');
      // localStorage.clear()
      // sessionStorage.clear()
    } else {
      message.error('服务器连接错误');
    }
  }
  return Promise.reject(error);
});

export function to(promise: Promise<any>) {
  return promise.then(data => {
    return [null, data]
  }).catch(err => [err])
}

function buildStringArr(params: any): Array<any> {
  let str = [];
  if (!params) {
    return [];
  }
  for (let p in params) {
    if (params.hasOwnProperty(p)) {
      if (Array.isArray(params[p])) {
        let s = [];
        let list = params[p];
        for (let i in list) {
          s.push(encodeURIComponent(list[i]));
        }
        str.push(encodeURIComponent(p) + "=" + s.join(","));
      } else {
        if (params[p] !== null)
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
      }
    }
  }
  return str;
}

export const createCookieString = (params: any) => {
  let str = buildStringArr(params);
  return str.join("&");
}

export const createParamsString = (params: any, spiltor: string = ",") => {
  let str = buildStringArr(params);
  return "?" + str.join("&");
};


// 不需要token的请求
export async function getData(url: string, params?: object): Promise<any> {
  let paramsString = createParamsString(params);
  let requestUrl = `${url}${paramsString}`;
  return axios.get(requestUrl);
}


export async function deleteData(url: string, params?: object): Promise<any> {
  let paramsString = createParamsString(params);
  let requestUrl = `${url}${paramsString}`;
  return axios.delete(requestUrl);
}
export async function postData(url: string, data: object): Promise<any> {
  return axios.post(url, data)
}
export async function putData(url: string, data: any): Promise<any> {
  return axios.put(url, data)
}


// 需要token的请求
export async function putDataWithToken(url: string, data: any): Promise<any> {
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  if (!token) {
    return Promise.reject({ status: 403, reason: "登录态丢失" });
  }
  return axios.put(url, data, { headers: { jwt: token } })
}

export async function postDataWithToken(url: string, data: any): Promise<any> {
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  if (!token) {
    return Promise.reject({ status: 403, reason: "登录态丢失" });
  }
  return axios.post(url, data, { headers: { jwt: token } })
}

export async function getDataWithToken(url: string, params?: object, type?: any ): Promise<any> {
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  if (!token) {
    return Promise.reject({ status: 403, reason: "登录态丢失" });
  }
  let paramsString = createParamsString(params);
  return axios.get(`${url}${paramsString}`, { headers: { jwt: token }, responseType: type })
}

export async function deleteDataWithToken(url: string, params?: object, data?: object): Promise<any> {
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  if (!token) {
    return Promise.reject({ status: 403, reason: "登录态丢失" });
  }
  let paramsString = createParamsString(params);
  return axios.delete(`${url}${paramsString}`, { headers: { jwt: token }, data })
}







